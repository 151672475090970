import { PrimaryButton } from 'common/Button/buttons';
import React, { FunctionComponent } from 'react';
import { Layer } from '../common';
import { titles } from '../config';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';
import { useRouter } from '../hooks/useRouter';

const createNewBatchButtonStyle = {
  padding: '0px 10px'
};
const CreateNewBatchButton: FunctionComponent = () => {
  const { selectedIntegrantType } = useIntegrantTypeStore();
  const { navigate } = useRouter();
  const gotoRoute = (route: string): void => {
    navigate(route);
  };

  const gotoRouteCreateBatch = (): void => gotoRoute(`/batches/create/${selectedIntegrantType.id}`);

  return (
    <Layer style={createNewBatchButtonStyle}>
      <PrimaryButton icon="add" maxContent={true} secondaryButton={true} onClick={gotoRouteCreateBatch} children={`Create New ${titles.tracking}`} />
    </Layer>
  );
};

export { CreateNewBatchButton };
