import { useEffect, useState } from 'react';
import { useAuthStore } from './useAuthStore';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useIntegrantTypeStore } from './useIntegrantTypeStore';
const useRequestIntegrantTypes = (): void => {
  const { initIntegrantType, initIntegrantData } = useIntegrantTypeStore();
  const {reset} = useIntegrantGraphStore();
  const {selectedSubscription} = useAuthStore();

  const [initialized, setInit] = useState(false);

  useEffect(() => {
    if(selectedSubscription === "tracer"){
      setInit(true);
      const requestIntegrantTypes = async (): Promise<void> => {
        return await initIntegrantType();
      };
      const requestIntegrantData = async (): Promise<void> => {
        reset();
        return await initIntegrantData();
      };
      
      if (!initialized) {
        requestIntegrantTypes();
        requestIntegrantData();
      }
    }
  }, [initIntegrantType, initIntegrantData, initialized, selectedSubscription]);
};

export { useRequestIntegrantTypes };
