import React, { FunctionComponent } from 'react';
import UseAnimations from 'react-useanimations';
import { Button } from '../common';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { defaultButtonStyle, largeButtonStyle, primaryButtonStyle, primaryColor } from '../styles/default';

const IntegrantSaveButton: FunctionComponent = () => {
  const { isDraft, isSaveIntegrantDisabled, saveButtonMessage } = useCreateBatchStore();
  const { onSave, isLoading } = useIntegrantPaneStore();
  const { currentIntegrant, batchViewPopup, batchViewPopupVal } = useIntegrantGraphStore();

  const buttonStyle = isDraft ? primaryButtonStyle : defaultButtonStyle;
  const isSaveEnabled = saveButtonMessage === 'Save';

  const handleOnSave = (): void => {
    batchViewPopup(true);
    onSave(currentIntegrant);
  };
  return (
    <Button
      disabled={isLoading || isSaveIntegrantDisabled}
      onClick={handleOnSave}
      center
      style={{
        ...buttonStyle,
        ...largeButtonStyle,
        width: '100%',
        backgroundColor: "#008F2B"
      }}
    >
      <UseAnimations
        animationKey="loading"
        size={15}
        style={{ color: 'white', marginRight: 10, opacity: isLoading ? 1 : 0 }}
      />{' '}
      {isLoading ? `Saving` : saveButtonMessage}
    </Button>
  );
};

export { IntegrantSaveButton };
