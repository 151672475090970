import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  FormLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { PrimaryButton } from 'common';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { useNIHStore } from 'hooks/useNIHStore';
import React from 'react';
import { concatArrayOfObjectStrings, getOnlyDigit } from 'utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    coaSelectionformContainer: {
      margin: '20px 0'
    },
    formDiv: {
      display: 'flex',
      flexDirection: 'column',
      '&>label': {
        marginBottom: 5
      },
      '&:not(:last-child)': {
        marginBottom: 15
      }
    },
    selectBox: {
      height: 40,
      minWidth: 250,
      border: `1px solid rgb(204, 204, 204)`,
      borderRadius: 4,
      '&>div': {
        paddingLeft: 10
      }
    },
    textfieldRoot: {
      border: `1px solid rgb(204, 204, 204)`,
      borderRadius: 4,
      width: '100%',
      height: 40,
      '&>div': {
        paddingLeft: 10,
        height: '100%'
      }
    },
    autocompleteOption: {
      padding: '20px 10px',
      '&:not(:last-child)': {
        borderBottom: `1px solid rgba(0,0,0,0.1)`
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#EAF7ED'
      }
    },
    productQuickInfo: {
      display: 'flex',
      flexDirection: 'column',
      '&>h6': {
        fontWeight: 'bold',
        marginBottom: 10
      },
      '&>p': {
        '&>span': {
          fontWeight: 'bold',
          marginRight: 5
        }
      }
    },
    productImg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    selectBoxPaper: {
      maxHeight: 400
    }
  })
);

export const COASelectionForm = () => {
  const classes = useStyle();
  const {
    updateIngredientComparisionData,
    ingredientComparisionData: { integrant_type_id, integrant_id, exhibit_id, exhibit_url },
    productAndBatchesList,
    integrantDetailData,
    initGetIntegrantDetail,
    initSearchIntegrantTypeIntoNIHDB,
    initReadDocument,
    documentAiCoaData,
    nihLabelsList,
    initGetNIHLabelDetail,
    clearState
  } = useNIHStore();
  return (
    <Grid container spacing={2} className={classes.coaSelectionformContainer}>
      <Grid item sm={6} xs={12}>
        <div>
          <Typography variant="h6" style={{ marginBottom: 15 }}>
            Select Product COA From HealthLOQ Database
          </Typography>
          <div className={classes.formDiv}>
            <FormLabel required htmlFor="integrant-type-select-box">
              Select Product
            </FormLabel>
            <Select
              id="integrant-type-select-box"
              value={integrant_type_id}
              disableUnderline
              className={classes.selectBox}
              MenuProps={{
                classes: {
                  paper: classes.selectBoxPaper
                }
              }}
            >
              {!productAndBatchesList?.loading && (
                <MenuItem value="" disabled>
                  Select Product
                </MenuItem>
              )}
              {productAndBatchesList?.loading ? (
                <MenuItem value="">
                  Loading...
                  <MuiCircularProgress style={{ marginLeft: 5 }} />
                </MenuItem>
              ) : (
                productAndBatchesList?.data?.map((item: any, i: number) => {
                  return (
                    <MenuItem
                      value={item?.id}
                      key={i}
                      onClick={e => {
                        updateIngredientComparisionData({
                          integrant_type_id: item?.id,
                          integrant_type_name: item?.title,
                          integrant_id: '',
                          external_id_slug: '',
                          exhibit_id: '',
                          exhibit_url: ''
                        });
                        initSearchIntegrantTypeIntoNIHDB({
                          q: item?.title
                        });
                      }}
                    >
                      {item?.title}
                    </MenuItem>
                  );
                })
              )}
            </Select>
          </div>
          <div className={classes.formDiv}>
            <FormLabel required htmlFor="integrant-select-box">
              Select Product Batch
            </FormLabel>
            <Select id="integrant-select-box" value={integrant_id} disableUnderline className={classes.selectBox}>
              {!productAndBatchesList?.loading && (
                <MenuItem value="" disabled>
                  Select Product Batch
                </MenuItem>
              )}
              {productAndBatchesList?.loading ? (
                <MenuItem value="">
                  Loading...
                  <MuiCircularProgress style={{ marginLeft: 5 }} />
                </MenuItem>
              ) : (
                productAndBatchesList?.data?.map((product: any) => {
                  if (product?.id === integrant_type_id) {
                    return product?.integrantsType?.map((item: any, i: number) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={i}
                          onClick={e => {
                            updateIngredientComparisionData({
                              integrant_id: item?.id,
                              external_id_slug: item?.external_id_slug,
                              exhibit_id: '',
                              exhibit_url: ''
                            });
                            initGetIntegrantDetail({
                              external_id_slug: item?.external_id_slug,
                              short_code: product?.organization?.short_code,
                              type: 'ingredient-comparison'
                            });
                          }}
                        >
                          {item?.external_id}
                        </MenuItem>
                      );
                    });
                  }
                  return null;
                })
              )}
            </Select>
          </div>
          <div className={classes.formDiv}>
            <FormLabel required htmlFor="coa-select-box">
              Select COA
            </FormLabel>
            <Select id="coa-select-box" value={exhibit_id} disableUnderline className={classes.selectBox}>
              {!integrantDetailData?.loading && (
                <MenuItem value="" disabled>
                  Select COA
                </MenuItem>
              )}
              {integrantDetailData?.loading ? (
                <MenuItem value="">
                  Loading...
                  <MuiCircularProgress style={{ marginLeft: 5 }} />
                </MenuItem>
              ) : (
                integrantDetailData?.data?.exhibitsInfo
                  ?.flatMap((item: any) => item?.exhibitsType)
                  ?.map((item: any, i: number) => {
                    return (
                      <MenuItem
                        value={item?.id}
                        key={i}
                        onClick={e => {
                          updateIngredientComparisionData({
                            exhibit_id: item?.id,
                            exhibit_url: item?.url
                          });
                        }}
                      >
                        {item?.title}
                      </MenuItem>
                    );
                  })
              )}
            </Select>
          </div>
          <div className={classes.formDiv}>
            <FormLabel required htmlFor="nih-labels-autocomplete">
              Select NIH Label
            </FormLabel>
            <Autocomplete
              id="nih-labels-autocomplete"
              renderInput={function(params: AutocompleteRenderInputParams): React.ReactNode {
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    InputProps={{ disableUnderline: true, ...params.InputProps }}
                    autoFocus
                    className={classes.textfieldRoot}
                  />
                );
              }}
              loading={nihLabelsList.loading}
              loadingText={
                <Grid container alignItems="center" justifyContent="center">
                  Loading...&nbsp;
                  <MuiCircularProgress />
                </Grid>
              }
              freeSolo
              onInputChange={(e: any, value: string) => {
                updateIngredientComparisionData({ nihSearchLabelName: value?.trim() });
                if (value?.trim()) {
                  initSearchIntegrantTypeIntoNIHDB({
                    q: value?.trim()
                  });
                }
              }}
              options={nihLabelsList?.data?.hits || []}
              onChange={(e: any, value: any) => {
                if (value?._id) {
                  updateIngredientComparisionData({
                    nihLabelId: value?._id
                  });
                  initGetNIHLabelDetail(value?._id);
                }
              }}
              getOptionLabel={(option: any): string => option?._source?.fullName || ''}
              noOptionsText={<Typography variant="body1">0 labels found.</Typography>}
              filterOptions={(option: any) => option}
              classes={{ option: classes.autocompleteOption }}
              renderOption={(option: any): React.ReactNode => {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={8}>
                      <div className={classes.productQuickInfo}>
                        <Typography variant="h6">{option?._source?.fullName}</Typography>
                        <Typography variant="body1">
                          <span>Brand:</span>
                          {option?._source?.brandName}
                        </Typography>
                        <Typography variant="body1">
                          <span>Net Contents:</span>
                          {concatArrayOfObjectStrings(option?._source?.netContents, 'display')}
                        </Typography>
                        {option?._source?.events?.map((item: any, key: number) => {
                          return (
                            <Typography variant="body1" key={key}>
                              <span>{item?.type}:</span>
                              {item?.date}
                            </Typography>
                          );
                        })}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={classes.productImg}>
                      <div>
                        <img
                          src={`https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/thumbnails/${option?._id}.jpg`}
                          alt={option?._source?.fullName || ''}
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </div>
          <PrimaryButton
            children={'Submit'}
            loading={documentAiCoaData.loading}
            disabled={documentAiCoaData.loading}
            onClick={() => {
              clearState();
              initReadDocument({
                url: exhibit_url
              });
            }}
            maxContent
            secondaryGreenButton
          />
        </div>
      </Grid>
      <Grid item sm={6} xs={12}></Grid>
    </Grid>
  );
};
export default COASelectionForm;
