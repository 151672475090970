import React, { FunctionComponent, CSSProperties,useEffect } from 'react';
import { Layer } from '../.././common/Layer';
import { CreateIntegrantTypePane } from '../../components/CreateIntegrantTypePane';
import { useCreateIntegrantTypeStore } from '../../hooks/useCreateIntegrantTypeStore';
import { rightContentWrapperStyle } from '../../utils';
import { CircularProgress } from '@material-ui/core';
import { ProductOffers } from '../../components/ProductOffers';
import ReactTooltip from 'react-tooltip';
import { HR } from '../../components/HR';
import { IntegrantCancelButton } from '../../components/IntegrantCancelButton';
import { useHistory,useParams } from "react-router-dom";
import { errorColor, tabItemStyle } from '../../styles/default';

const bottomMenuStyle: CSSProperties = { position: 'absolute', bottom: 0, left: 0, width: '100%' };

const CreateSurveyQuestion: FunctionComponent = () => {
  const { openSurveyQuestion, setOpenSurveyQuestion, gotoSlideIndex, slideIndex } = useCreateIntegrantTypeStore();
  const history = useHistory();
  const {tabIndex,integrantTypeId} = useParams();

  const CreateSurveyQuestionStyle: CSSProperties = {
    ...rightContentWrapperStyle(openSurveyQuestion ? '1' : ''),
    position: 'fixed',
    top: 0,
    zIndex: 100
  };

  const handleOnCancel = (): void => {
    setOpenSurveyQuestion(false);
    history.push("/");
  };

  const isTabSelected = (n: number, isError: boolean): CSSProperties =>
    n === slideIndex ? tabItemStyle : { ...tabItemStyle, color: isError ? errorColor : '#888' };

    const handleTabIndexChange = (index: number)=>{
      history.push(`/survey-questions/${integrantTypeId}/${index}`)
    }

    useEffect(()=>{
      if(tabIndex){
        const tab_index = parseInt(tabIndex);
        if(tab_index !== slideIndex){
          gotoSlideIndex(tab_index,false)
        }
      }
    },[tabIndex])

  return (
    <Layer fill direction="column" style={CreateSurveyQuestionStyle} width={'100%'}>
      <Layer fill direction={'column'} style={{ padding: 20 }}>
        {/* <CreateIntegrantTypePane /> */}
        <Layer fill direction="column" >
          <Layer direction={'column'} className='createIntegrantTypePane' style={{ maxWidth: 800, margin: '0 auto', width: '100%' }}>
            <Layer>
              <ReactTooltip />
              {/* <h5 data-tip="Survey Questions"> Survey Questions </h5> */}
              <h5 data-tip="Survey" onClick={(): void => handleTabIndexChange(0)} style={isTabSelected(0, false)}>
                Survey
              </h5>
              <h5 data-tip="Survey Submissions" onClick={(): void => handleTabIndexChange(1)} style={isTabSelected(1, false)}>
                Survey Submissions
              </h5>
              <h5 data-tip="Offers" onClick={(): void => handleTabIndexChange(2)} style={isTabSelected(2, false)}>
                Offers
              </h5>
              <h5 data-tip="Batch Feedback" onClick={(): void => handleTabIndexChange(3)} style={isTabSelected(3, false)}>
                Batch Feedback
              </h5>
            </Layer>
            <HR />
            <ProductOffers />
            <Layer style={bottomMenuStyle} direction="column">
              <Layer direction="row" style={{ maxWidth: 800, margin: '0 auto', width: '100%' }}>
                <IntegrantCancelButton onCancel={handleOnCancel} isVisible={true} btnLable="Back to Dashboard" />
              </Layer>
            </Layer>
          </Layer>
        </Layer>
      </Layer>
    </Layer>
  );
};

export { CreateSurveyQuestion };
