import { integrant_type_ } from '../../common/models';

const defaults = {
  isOpen: false,
  isLoading: true,
  integrants: [],
  selectedIntegrant: integrant_type_
};

export { defaults };
