import React, { FunctionComponent } from 'react';
import { TransferInWrapper } from '../../wrappers/TransferInWrapper';

const Wrapper: FunctionComponent = ({ children }) => {
  return (
    <TransferInWrapper>
      <>{children}</>
    </TransferInWrapper>
  );
};

export { Wrapper };
