import { useContext, useEffect } from 'react';
import { IIntegrantTypeDTO } from '../../lib/integrantType';
import { SuggestIntegrantChildContext } from '../context/suggestIntegrantChild/suggestIntegrantChildContext';
import { ISuggestIntegrantChildState } from '../context/suggestIntegrantChild/types';
import { isPopulated, isUndefined } from '../utils';

interface ISuggestIntegrantChild extends ISuggestIntegrantChildState {
  onToggleMenu: (isOpen: boolean) => void;
}

const useSuggestIntegrantChildStore = (id?: IIntegrantTypeDTO['id']): ISuggestIntegrantChild => {
  const [state, setState] = useContext(SuggestIntegrantChildContext);
  const { integrants, isLoading, selectedIntegrant, isOpen } = state;

  useEffect(() => {
    if (!isUndefined(id) && isPopulated(integrants)) {
      setState(s => ({
        ...s,
        selectedProduct: integrants.filter((item: IIntegrantTypeDTO) => item.id === id)[0]
      }));
    }
  }, [id, integrants, setState]);

  const onToggleMenu: ISuggestIntegrantChild['onToggleMenu'] = (isOpen): void => {
    setState({ ...state, isOpen });
  };
  return {
    integrants,
    isOpen,
    isLoading,
    selectedIntegrant,
    onToggleMenu
  };
};

export { useSuggestIntegrantChildStore };
