const thresholdUX = (test: any) => {
  let thresholdContainer = document.createElement('div')
  thresholdContainer.className = 'threshold-Container'
  thresholdContainer.innerHTML = `<div class="threshold-popup"><div class="threshold-popup-close" id="tc">+</div><div class="threshold-title">Your Organization Threshold Limit exceeded</div><div class="threshold-body">${test}</div><div><a class="manage-link" href="/subscription">Manage your subscription</a></div></div>`
  document.body.appendChild(thresholdContainer)
  var btn: any = document.getElementById("tc");
  btn.addEventListener("click", function (e: any) {
    e.target.parentNode.parentNode.remove()
  }, false)
};
export { thresholdUX };