import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layer } from '../common';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import Icon from 'common/Icon';
import { useUserStore } from 'hooks/useUserStore';
import { UserDrawer } from 'common/Drawers';
import ReactTooltip from 'react-tooltip';
import { Grid } from '@material-ui/core';

const ProductFeedbackQuestion: FunctionComponent = () => {
  const { getfeedbackData, feedbackList } = useIntegrantsStore();
  const { integrantTypeId } = useParams();
  const { selectedOrganization } = useOrganizationStore();
  const { getUserById } = useUserStore();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [externalId, setExternalId] = useState('');
  useEffect(() => {
    getfeedbackData(selectedOrganization.id, integrantTypeId);
  }, []);
  return (
    <Layer className="integrantExhibits" direction="column" fill scrollY style={{ width: '100%' }}>
      <ReactTooltip />
      <div style={{ padding: 10, width: '100%' }}>
        <div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div className="title_transit">Feedback Details</div>
            <select style={{ width: 'max-content' }} onChange={e => setExternalId(e.target.value)} value={externalId}>
              <option value="">Select Batch</option>
              {feedbackList.length > 0
                ? feedbackList.map((feedback: any) => {
                    return <option value={feedback?.external_id}>{feedback?.external_id}</option>;
                  })
                : ''}
            </select>
          </div>
          <div>
            <div className="table_transit">
              <div className="head_transit row_transit row">
                <div className="col-2">User</div>
                <div className="col-2">Batch</div>
                <div className="col-3">Where did you buy this product?</div>
                <div className="col-2">Feedback message</div>
                <div className="col-3">Rating</div>
              </div>
              <div>
                {feedbackList.length > 0 &&
                feedbackList?.map((item: any) => item?.feedbacks)?.flatMap((item: any) => item)?.length > 0 ? (
                  !Boolean(externalId) ||
                  (externalId &&
                    Boolean(
                      feedbackList?.filter((feedback_obj: any) => feedback_obj?.external_id === externalId)[0]
                        ?.feedbacks?.length
                    )) ? (
                    feedbackList.map((feedback_obj: any) => {
                      let feedbacks = externalId
                        ? feedback_obj?.external_id === externalId
                          ? feedback_obj?.feedbacks
                          : []
                        : feedback_obj?.feedbacks;
                      return Boolean(feedbacks?.length)
                        ? feedbacks?.map((feedback: any, i: number) => {
                            return (
                              <div className="row_transit row">
                                <div className="col-2">
                                  {feedback?.user ? (
                                    <Grid container alignItems="center" justifyContent="flex-start">
                                      <span>{feedback?.user?.name}</span>
                                      <Icon
                                        onClick={() => {
                                          getUserById(feedback?.user_id);
                                          setToggleDrawer(true);
                                        }}
                                        style={{ marginLeft: '5px' }}
                                        icon="info"
                                        onlyIcon={true}
                                        dataTip={'View user detail'}
                                      />
                                    </Grid>
                                  ) : (
                                    'Anonymous'
                                  )}
                                </div>
                                <div className="col-2">{feedback_obj?.external_id}</div>
                                <div className="col-3">{feedback?.product_buying_info}</div>
                                <div className="col-2">{feedback?.message}</div>
                                <div className="col-3">
                                  {[...Array(5)].map((el, i) => {
                                    return feedback.rating >= i + 1 ? (
                                      <StarIcon style={{ color: '#FFE11B' }} />
                                    ) : (
                                      <StarOutlineIcon />
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })
                        : null;
                    })
                  ) : (
                    <div className="col-12" style={{ textAlign: 'center' }}>
                      No feedback submissions found for the batch {externalId}.
                    </div>
                  )
                ) : (
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    No feedback submissions found.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ minHeight: 10 }}></div>
        </div>
      </div>
      <UserDrawer open={toggleDrawer} handleClose={() => setToggleDrawer(false)} />
    </Layer>
  );
};

export { ProductFeedbackQuestion };
