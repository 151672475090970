import React, { CSSProperties, FunctionComponent } from 'react';
import { Layer } from '../common';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { LoadingTitle } from '../types/common';
import { isEmptyString } from '../utils';
import { D3Graph } from './D3Graph';
import { Loading } from './Loading';

const IntegrantGraph: FunctionComponent = () => {
  const { currentIntegrant } = useIntegrantGraphStore();

  return !isEmptyString(currentIntegrant.id) ? (
    <Layer className='integrantGraph' direction="row" fill>
      <D3Graph />
    </Layer>
  ) : (
    <Loading title={LoadingTitle.Updating} />
  );
};

export { IntegrantGraph };
