import { useEffect, useState, useRef } from 'react';

export const useDebounce = (data: any, time: number) => {
  const [debounceData, setDebounceData] = useState('');
  let debounceRef: any = useRef(null);

  const debounceTimeout = () => {
    debounceRef.current = setTimeout(() => {
      setDebounceData(data);
    }, time);
  };

  useEffect(() => {
    debounceTimeout();
    return () => {
      debounceRef.current && clearTimeout(debounceRef.current);
    };
  }, [data]);

  return { debounceData };
};
