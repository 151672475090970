import React, { useState } from 'react';
import { CreateContext } from '../../types/common';
import { defaults } from './suggestIntegrantCreatedAlreadyState';
import { ISuggestIntegrantCreatedAlreadyState } from './types';

const SuggestIntegrantCreatedAlreadyContext = React.createContext<CreateContext<ISuggestIntegrantCreatedAlreadyState>>([
  defaults,
  (): ISuggestIntegrantCreatedAlreadyState => defaults
]);

const SuggestIntegrantCreatedAlreadyProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ISuggestIntegrantCreatedAlreadyState>(defaults);

  return (
    <SuggestIntegrantCreatedAlreadyContext.Provider value={[state, setState]}>
      {props.children}
    </SuggestIntegrantCreatedAlreadyContext.Provider>
  );
};

export { SuggestIntegrantCreatedAlreadyContext, SuggestIntegrantCreatedAlreadyProvider };
