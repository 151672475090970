import React, { CSSProperties, FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Layer } from '../common';
import { titles, titlesTooltips } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { errorColor, tabItemStyle } from '../styles/default';
import { IntegrantKind } from '../types/common';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { HR } from './HR';

const IntegrantPaneHeader: FunctionComponent = () => {
  const { onChangeIndex, tabIndex } = useIntegrantPaneStore();
  const {
    isDetailsTrackingId,
    isDetailsBuyAgainUrl,
    isExhibitsDisabled,
    isFacetsDisabled,
    isLocationDisabled
  } = useCreateBatchStore();
  const { integrantKind, currentIntegrant } = useIntegrantGraphStore();
  const { currentTransit } = useCreateTransitStore();

  const isTabSelected = (n: number, isError: boolean): CSSProperties =>
    n === tabIndex ? tabItemStyle : { ...tabItemStyle, color: isError ? errorColor : '#888' };
  //eslint-disable-next-line
  const isDetail = isDetailsTrackingId && isDetailsTrackingId

  let exhibitsCount = 0

  const { exhibit } = currentTransit

  if (exhibit) {
    exhibit.forEach(() => {
      exhibitsCount = 1 + exhibitsCount
    })
  }

  if (currentIntegrant.exhibits.length > 0) {
    if (integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer) {
      currentIntegrant.exhibits.forEach(item => {
        if (item.description === '') {
          exhibitsCount = 1 + exhibitsCount
        }
      })

    } else {
      exhibitsCount = currentIntegrant.exhibits.length
    }
  }


  return (
    <>
      <Layer className='integrantPaneHeader'>
        <ReactTooltip />
        <div className='integrant-page-header-div'>
        {integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer ? null : (
          <>
            <h5
              data-tip={titlesTooltips.options}
              onClick={(): void => onChangeIndex(0)}
              style={isTabSelected(0, false)}
            >
              Options
            </h5>
            <h5
              data-tip={titlesTooltips.details}
              onClick={(): void => onChangeIndex(1)}
              style={isTabSelected(1, isDetail)}
            >
              Details
            </h5>
            <h5
              data-tip={titlesTooltips.facets}
              onClick={(): void => onChangeIndex(2)}
              style={isTabSelected(2, isFacetsDisabled)}
            >
              {titles.facetsPlural} {currentIntegrant.facets.length > 0 ? `(${currentIntegrant.facets.length})` : null}
            </h5>
          </>
        )}
        <h5
          data-tip={titlesTooltips.location}
          onClick={(): void => onChangeIndex(3)}
          style={isTabSelected(3, isLocationDisabled)}
        >
          Location
        </h5>
        <h5
          data-tip={titlesTooltips.exhibits}
          onClick={(): void => onChangeIndex(4)}
          style={isTabSelected(4, isExhibitsDisabled)}
        >
          {titles.exhibitsPlural}{' '}
          {'(' + exhibitsCount + ')'}
        </h5>
        </div>
      </Layer>
      <HR />
    </>
  );
};

export { IntegrantPaneHeader };
