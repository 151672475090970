import { IIntegrantDTO } from '../../lib/integrant';
import React, { useCallback, useEffect, useState } from 'react';
import { IEdge } from '../context/integrantGraph/types';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { CreateContext } from '../types/common';
import { isPopulated, isUndefined } from '../utils';

export interface ILayerState {
  id: string;
  parent_id: string;
  integrant: IIntegrantDTO;
  children: ILayerState[];
}

export interface ILayerPaneContextState {
  layers: ILayerState[];
  selectedParentId: string;
}

const defaults = {
  selectedParentId: '',
  layers: []
};

const LayerPaneContext = React.createContext<CreateContext<ILayerPaneContextState>>([
  defaults,
  (): ILayerPaneContextState => defaults
]);

const LayerPaneProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ILayerPaneContextState>(defaults);
  const { data, currentIntegrant, currentParentId } = useIntegrantGraphStore();
  const convertChildrenToFlatList = useCallback(
    (node: IIntegrantDTO): ILayerState[] => {
      const filterEdges = data.edges.filter((e: IEdge) => e.source === node.id);
      const filterNodes = filterEdges.map((e: IEdge) => {
        const childNode = data.nodes.filter((n: IIntegrantDTO) => n.id === e.target)[0];
        return {
          id: childNode.id,
          integrant: childNode,
          children: convertChildrenToFlatList(childNode),
          parent_id: node.id
        };
      });

      return filterNodes;
    },
    [data.nodes, data.edges]
  );

  const convertDataToFlatList = useCallback((): void => {
    const layerArray: ILayerState[] = [];
    const rootNode = data.nodes[0];
    layerArray.push({
      id: rootNode.id,
      integrant: rootNode,
      children: convertChildrenToFlatList(rootNode),
      parent_id: ''
    });
    setState(s => ({ ...s, layers: layerArray }));
  }, [convertChildrenToFlatList, data.nodes]);

  // initial init to convert graph data to flat list
  useEffect(() => {
    if (isPopulated(data.nodes)) {
      convertDataToFlatList();
    }
  }, [convertDataToFlatList, data, currentIntegrant]);

  // when new integrant is updated / added as a subchild
  useEffect(() => {
    if (!isUndefined(currentIntegrant)) {
      setState(s => ({ ...s, selectedParentId: currentParentId }));
    }
  }, [currentIntegrant, currentParentId]);

  return <LayerPaneContext.Provider value={[state, setState]}>{props.children}</LayerPaneContext.Provider>;
};

export { LayerPaneContext, LayerPaneProvider };
