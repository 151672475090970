import { Layer, PrimaryButton } from 'common';
import { useTestingLabStore } from 'hooks/useTestingLabStore';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { defaultBorderStyle, labelStyle } from 'styles/default';
import { FormHelperText, makeStyles, createStyles } from '@material-ui/core';
import CreateTestingLabDialog from 'common/Dialogs/CreateTestingLabDialog';

const useStyle = makeStyles(theme => createStyles({}));

interface ISelectTestingLabProps {
  value: string;
  handleTestingLabId: (id: string) => any;
}

export const SelectTestingLab: FunctionComponent<ISelectTestingLabProps> = ({ value, handleTestingLabId }) => {
  const classes = useStyle();
  const { testingLabs, getTestingLabsList } = useTestingLabStore();
  const [openCreateTestingDialog, setOpenCreateTestingDialog] = useState(false);
  useEffect(() => {
    getTestingLabsList();
  }, []);
  return (
    <Layer style={{ padding: 20, marginTop: 20, ...defaultBorderStyle, border: '1px solid #ccc' }} direction="column">
      <div>
        <h5 style={labelStyle}>
          Select Organization<span className="asterisk-symbol">*</span>
        </h5>
        <select value={value} onChange={(e: any) => handleTestingLabId(e.target.value)}>
          <option value="">Please Select</option>
          {Boolean(testingLabs?.data?.length) &&
            testingLabs?.data?.map((item: any) => {
              return (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              );
            })}
        </select>
      </div>
      <FormHelperText>
        If you can't find the organization you are looking for, please create using below button.
      </FormHelperText>
      <PrimaryButton
        onClick={() => setOpenCreateTestingDialog(true)}
        children={'Create Organization'}
        icon="add"
        maxContent
        style={{ marginTop: 10 }}
      />
      <CreateTestingLabDialog open={openCreateTestingDialog} handleClose={() => setOpenCreateTestingDialog(false)} />
    </Layer>
  );
};

export default SelectTestingLab;
