import { IOrganizationDTO } from '../../lib/organization';
import { useContext, useEffect } from 'react';
import { AppContext, IAppProviderState } from '../context/appContext';

interface IAppStore extends IAppProviderState {
  setOrganization: (organization: IOrganizationDTO) => void;
}

const useOrganizationStore = (): IAppStore => {
  const [state, dispatch] = useContext(AppContext);
  const { isLoading, selectedOrganization } = state;

  const setOrganization: IAppStore['setOrganization'] = org_id => {
    dispatch({ type: 'setLoading', payload: false });
    dispatch({ type: 'setOrganization', payload: org_id });
  };

  useEffect(() => {
    dispatch({ type: 'setLoading', payload: isLoading });
  }, [isLoading, dispatch]);

  return {
    isLoading,
    selectedOrganization,
    setOrganization
  };
};

export { useOrganizationStore };
