import { useContext } from 'react';
import { DocumentHashContext, IDocumentHashProviderState } from '../context/documentHashContext';
import { useDocumentHashService } from './api/useDocumentHashService';

interface IDocumentHashStore extends IDocumentHashProviderState {
  initGetDocToolJWT: (data: any) => any;
  initCreateFreeTrialDocument: (data: any) => any;
  initUpdateFreeTrialDocument: (data: any) => any;
  initDeleteFreeTrialDocument: (id: string) => any;
  initGetFreeTrialDocuments: (params: any) => any;
}

const useDocumentHashStore = (): IDocumentHashStore => {
  const [state, setState] = useContext(DocumentHashContext);
  const {
    getDocToolJWT,
    createFreeTrialDocument,
    deleteFreeTrialDocument,
    getFreeTrialDocuments,
    updateFreeTrialDocument
  } = useDocumentHashService();
  const {
    docToolJwtData,
    createFreeTrialDocumentData,
    deleteFreeTrialDocumentData,
    getFreeTrialDocumentsData,
    updateFreeTrialDocumentData
  } = state;

  const initGetDocToolJWT: IDocumentHashStore['initGetDocToolJWT'] = async (data: any) => {
    setState({ ...state, docToolJwtData: { ...state.docToolJwtData, loading: true } });
    const response = await getDocToolJWT(data);
    setState({ ...state, docToolJwtData: { ...state.docToolJwtData, loading: false, ...response } });
  };

  const initCreateFreeTrialDocument: IDocumentHashStore['initCreateFreeTrialDocument'] = async (data: any) => {
    setState(pre => ({
      ...pre,
      createFreeTrialDocumentData: { ...pre.createFreeTrialDocumentData, loading: true }
    }));
    const response = await createFreeTrialDocument(data);
    setState(pre => ({
      ...pre,
      createFreeTrialDocumentData: { ...pre.createFreeTrialDocumentData, loading: false, ...response },
      getFreeTrialDocumentsData: response?.data
        ? {
            ...pre.getFreeTrialDocumentsData,
            data: [...response?.data, ...(pre.getFreeTrialDocumentsData?.data || [])],
            count: pre?.getFreeTrialDocumentsData?.count + response?.data?.length
          }
        : pre.getFreeTrialDocumentsData
    }));
  };

  const initUpdateFreeTrialDocument: IDocumentHashStore['initUpdateFreeTrialDocument'] = async (data: any) => {
    setState(pre => ({
      ...pre,
      updateFreeTrialDocumentData: { ...pre.updateFreeTrialDocumentData, loading: true, data }
    }));
    const response = await updateFreeTrialDocument(data);
    setState(pre => ({
      ...pre,
      updateFreeTrialDocumentData: { ...pre.updateFreeTrialDocumentData, loading: false, ...response, data: null },
      getFreeTrialDocumentsData: response?.data?.documentIds
        ? {
            ...pre.getFreeTrialDocumentsData,
            data: pre.getFreeTrialDocumentsData?.data?.map((item: any) =>
              response?.data?.documentIds?.includes(item?.id)
                ? { ...item, effective_date: response?.data?.effective_date }
                : item
            )
          }
        : pre.getFreeTrialDocumentsData
    }));
  };

  const initDeleteFreeTrialDocument: IDocumentHashStore['initDeleteFreeTrialDocument'] = async (id: string) => {
    setState(pre => ({
      ...pre,
      deleteFreeTrialDocumentData: { ...pre.deleteFreeTrialDocumentData, loading: true, data: id }
    }));
    const response = await deleteFreeTrialDocument(id);
    setState(pre => ({
      ...pre,
      deleteFreeTrialDocumentData: { ...pre.deleteFreeTrialDocumentData, loading: false, ...response, data: null },
      getFreeTrialDocumentsData: response?.data?.id
        ? {
            ...pre.getFreeTrialDocumentsData,
            data: pre.getFreeTrialDocumentsData?.data?.filter((item: any) => item?.id !== response?.data?.id),
            count: pre?.getFreeTrialDocumentsData?.count ? pre?.getFreeTrialDocumentsData?.count - 1 : 0
          }
        : pre.getFreeTrialDocumentsData
    }));
  };

  const initGetFreeTrialDocuments: IDocumentHashStore['initGetFreeTrialDocuments'] = async (params: any) => {
    setState(pre => ({
      ...pre,
      getFreeTrialDocumentsData: { ...pre.getFreeTrialDocumentsData, loading: true }
    }));
    const response = await getFreeTrialDocuments(params);
    setState(pre => ({
      ...pre,
      getFreeTrialDocumentsData: {
        ...pre.getFreeTrialDocumentsData,
        loading: false,
        ...response,
        data: (pre?.getFreeTrialDocumentsData?.data || []).concat(response?.data?.rows),
        count: response?.data?.count || 0
      }
    }));
  };

  return {
    docToolJwtData,
    createFreeTrialDocumentData,
    deleteFreeTrialDocumentData,
    getFreeTrialDocumentsData,
    updateFreeTrialDocumentData,
    initGetDocToolJWT,
    initCreateFreeTrialDocument,
    initDeleteFreeTrialDocument,
    initGetFreeTrialDocuments,
    initUpdateFreeTrialDocument
  };
};

export { useDocumentHashStore };
