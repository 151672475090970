import { useHistory } from 'react-router-dom';

export interface IUseRouter {
  navigate: (route: string) => void;
}

const useRouter = (): IUseRouter => {
  const history = useHistory();
  const navigate = (route: string): void => {
    history.replace(route);
  };

  return {
    navigate
  };
};

export { useRouter };
