import { ICreateTransferDTO, ITransferDTO } from '../../../lib/transfer';
import { useCallback } from 'react';
import useFetch from 'use-http';
import { url, formAxiosHeader } from '../../config';
import { useAuthStore } from '../useAuthStore';
import axiosInstance from './../../utils/api-interceptor'
import { useIntegrantGraphStore } from '../useIntegrantGraphStore';

interface ITransferService {
  isLoading: boolean;
  postTransfer: (createTransfer: ICreateTransferDTO) => Promise<ITransferDTO>;
  deleteTransfer: (transfer_id: string) => Promise<ITransferDTO>;
  getTransferList: (transfer_id: string) => Promise<any>;
}

const useTransferService = (): ITransferService => {
  const { currentIntegrant } = useIntegrantGraphStore();
  const integrantsURL = `${url}integrants/${currentIntegrant.id}/`;
  const [request, , isLoading] = useFetch(integrantsURL);
  const { individual } = useAuthStore();

  const postTransfer: ITransferService['postTransfer'] = useCallback(
    async createTransfer => {
      const updatedTransit: ITransferDTO = await request.post('transfers', createTransfer);
      return updatedTransit;
    },
    [request]
  );

  const deleteTransfer: ITransferService['deleteTransfer'] = useCallback(
    async transfer_id => {
      const updatedIntegrant: ITransferDTO = await request.delete(`transfers/${transfer_id}`);
      return updatedIntegrant;
    },
    [request]
  );



  const getTransferList: ITransferService['getTransferList'] =
    async (transfer_id: any) => {
      return await axiosInstance.get(`transfer/${transfer_id}`, formAxiosHeader(individual.access_token)).then((res: any) => {
        return res
      }).catch(err => {
        return err
      })
      // const transferApiReq: any = await fetch(`${url}/transfer/${transfer_id}`, {
      //   method: 'GET',
      //   headers: new Headers({
      //     Authorization: `Bearer ${individual.access_token}`
      //   })
      // });
      // const transferList = await transferApiReq.json()
      // return transferList;
    }

  return { isLoading, postTransfer, deleteTransfer, getTransferList };
};

export { useTransferService };
