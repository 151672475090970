import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { useAuthStore } from '../../hooks/useAuthStore';
import { defaultBoxShadow, defaultGreyBackgroundColor } from '../../styles/default';
import { useToastStore } from '../../hooks/useToastStore';
import { PrimaryButton } from 'common/Button/buttons';
import { Checkbox, FormControlLabel, Grid, Box, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    primaryColor: {
      color: '#008F2B !important'
    }
  })
);

const labelSpacingStyle: CSSProperties = { marginBottom: 20, display: 'block' };
const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  maxWidth: 600,
  width: '95%',
  padding: 20,
  backgroundColor: 'white'
};
const VerifyOTP: FunctionComponent = () => {
  const classes = useStyle();
  const [otp, setOtp] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { initVerifyOTP, resendOTPData, initResendOTP, verifyOTPData } = useAuthStore();
  const { isOpenToast } = useToastStore();

  const handleSubmit = () => {
    if (!otp?.trim()) {
      isOpenToast('warn', 'OTP is required.');
      return;
    }
    if (otp.length !== 6) {
      isOpenToast('warn', 'OTP length should be 6 digit.');
      return;
    }
    initVerifyOTP({ otp, rememberMe });
  };

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor, height: 'calc(100vh - 70px)' }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <h2>Verify OTP</h2>
        <br />

        <label style={labelSpacingStyle} className="col-md-12">
          Enter 6 digit OTP
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const otpVal = event.currentTarget.value;
              if (otpVal === '' || (otpVal.length < 7 && /^\d+$/.test(otpVal))) {
                setOtp(otpVal);
              }
            }}
            value={otp}
            type="text"
            autoFocus
            placeholder="ex. 123456"
          ></Input>
        </label>
        <Grid style={{ marginBottom: 15 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}
                color="primary"
                classes={{ colorPrimary: classes.primaryColor, checked: classes.primaryColor }}
              />
            }
            label="Remember Me for 7 days."
          />
        </Grid>
        <Box display="flex">
          <PrimaryButton
            children={'Verify'}
            secondaryGreenButton={true}
            maxContent={true}
            loading={verifyOTPData?.loading}
            disabled={verifyOTPData?.loading}
            onClick={handleSubmit}
          />
          <PrimaryButton
            children={'Resend OTP'}
            style={{ marginLeft: 10 }}
            secondaryButton={true}
            maxContent={true}
            loading={resendOTPData?.loading}
            disabled={resendOTPData?.loading}
            onClick={() => initResendOTP()}
          />
        </Box>
      </Layer>
    </Layer>
  );
};

export { VerifyOTP };
