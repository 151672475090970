import React, { FunctionComponent } from 'react';
import { primaryButtonStyle } from '../styles/default';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { NextButton } from './NextButton';

interface IIntegrantNextButtonProps {
  isVisible?: boolean;
  isUpdate?: boolean;
  type?: string;
}

const IntegrantTypeNextButton: FunctionComponent<IIntegrantNextButtonProps> = ({
  isVisible = false,
  isUpdate = false,
  type = ''
}) => {
  const { gotoSlideIndex, slideIndex, isDetailsDisabled } = useCreateIntegrantTypeStore();
  const isButtonEnabled = isDetailsDisabled;

  return (
    <NextButton
      buttonStyle={primaryButtonStyle}
      isVisible={isVisible}
      errorMessage={`Required fields must be filled.`}
      onChangeIndex={(i: number) => gotoSlideIndex(i, isUpdate)}
      index={slideIndex}
      isButtonEnabled={isButtonEnabled}
      type={type}
    />
  );
};

export { IntegrantTypeNextButton };
