import React from 'react';
const PaymentOptions = (props: any) => {
    const {
        children,
        handleSubmit,
        cartItem: { product_plan }
    } = props
    if (typeof product_plan !== 'object') {
        return null
    } else {
        return <div className="payment-options">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="payment-options-price">
                            <p className="txt-price">Price Details:</p>
                            {children}
                        </div>
                        <button
                            onClick={() => handleSubmit()} className="btn_registration_submit">Pay now</button>
                    </div>
                </div>
            </div>
        </div >
    }

}
export default PaymentOptions