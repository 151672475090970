import { ICreateIntegrantResponseDTO, IIntegrantDTO } from '../../lib/integrant';
import { useCallback, useContext } from 'react';
import { integrant_ } from '../common/models';
import { IntegrantsContext } from '../context/products/integrantsContext';
import { IIntegrantState } from '../context/products/types';
import { useIntegrantsService } from './api/useIntegrantsService';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { formDataConfig, url, formDataConfigType, formAxiosHeader } from './../config';
import { useAuthStore } from './useAuthStore';
import axiosInstance from './../utils/api-interceptor'
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

interface IIntegrantsStore extends IIntegrantState {
  onSelectIntegrant: (integrant: IIntegrantDTO) => void;
  setIntegrants: (integrants: IIntegrantDTO[]) => void;
  setTransferredIntegrants: (integrants: IIntegrantDTO[]) => void;
  onCloneIntegrant: (
    integrant_id: IIntegrantDTO['id'],
    tracking_id: IIntegrantDTO['tracking_id']
  ) => Promise<ICreateIntegrantResponseDTO | undefined>;
  setLoading: (isLoading: boolean) => void;
  resetIntegrantsStore: () => void;
  batchListUpdate: (searchValue: string, selectedIntegrantTypeId: string) => void;
  locationSuggestions: () => void;
  locationSuggestionsReturn: (organization_id?: string) => any;
  getfeedbackData: (organization_id: string, integrant_type_id: string) => any;
  feedbackList: any;
  getCategoryData: () => any;
  categoryList: any;
  getSurveyQuestionStoreData: (data:any) => any;
  getSurveyQuestionData: () => any;
  surveyQuestionList: any;
  initDeleteAttachment: (fileName: string) => any;
  getSurveyQuestionUpdateData: (data:any) => any;
  surveyQuestionDeleteData: (data:any) => any;
  getSurveyQuestionAnswerData: () => any;
  surveyQuestionAnswerList: any;
  surveyQuestionLoading: boolean;
  getOfferStoreData: (data:any) => any;
  getOfferData: () => any;
  offerList: any;
}

const useIntegrantsStore = (): IIntegrantsStore => {
  const { selectedOrganization } = useOrganizationStore();
  const { individual } = useAuthStore();
  const [state, setState] = useContext(IntegrantsContext);
  const { clone, initialize, deleteAttachment } = useIntegrantsService();
  const { isLoading, selectedIntegrant, integrants, transferredIntegrants, locationList, feedbackList, categoryList, surveyQuestionList, surveyQuestionLoading, surveyQuestionAnswerList, offerList } = state;
  const { batchId, integrantTypeId } = useParams();
  const { setCurrentIntegrant,currentIntegrant } = useIntegrantGraphStore()

  const onSelectIntegrant: IIntegrantsStore['onSelectIntegrant'] = integrant => {
    setState({ ...state, selectedIntegrant: integrant });
  };

  const setIntegrants: IIntegrantsStore['setIntegrants'] = integrantTypes => {
    //setState({ ...state, integrants: [], isLoading: false });
    setState({ ...state, integrants: integrantTypes, isLoading: false });
  };

  const setTransferredIntegrants: IIntegrantsStore['setTransferredIntegrants'] = integrants => {
    setState({ ...state, transferredIntegrants: integrants });
  };

  const setLoading: IIntegrantsStore['setLoading'] = isLoading => {
    setState({ ...state, isLoading: isLoading });
  };

  const onCloneIntegrant: IIntegrantsStore['onCloneIntegrant'] = async (integrant_id, tracking) => {
    const requestClone = await clone(integrant_id, tracking);
    if (requestClone.id !== '') {
      return requestClone;
    }
  };

  const resetIntegrantsStore = useCallback((): void => {
    setState({ integrants: [], selectedIntegrant: integrant_, isLoading: false, transferredIntegrants: [], locationList: [], feedbackList: [], categoryList: [], surveyQuestionList: [], surveyQuestionLoading: false, surveyQuestionAnswerList: [], offerList: {} });
  }, [setState]);

  //eslint-disable-next-line
  const batchListUpdate = async (searchValue: string, selectedIntegrantTypeId: string) => {
    const batchList = await initialize(selectedIntegrantTypeId, '', searchValue);
    setIntegrants(batchList ? batchList : []);
  };


  const locationSuggestions = async () => {
    const organizationObj = { organization_id: '' };
    organizationObj.organization_id = individual.organization_id
    const locationList = await fetch(
      `${url}location/location-suggestions`,
      formDataConfigType(JSON.stringify(organizationObj), individual.access_token)
    ).then(async response => await response.json());
    setState({ ...state, locationList: locationList });
  }

  const locationSuggestionsReturn = async (organization_id?: string) => {
    const organizationObj: any = {};
    if ((typeof organization_id === 'string') && (organization_id !== '')) {
      organizationObj.organization_id = organization_id
    } else {
      organizationObj.organization_id = individual.organization_id
    }
    return await axiosInstance.post('location/location-suggestions', organizationObj, formAxiosHeader(individual.access_token)).then((locationResponse: any) => {
      return locationResponse
    }).catch(err => {
      return err
    })
  }

  const getfeedbackData = async (organization_id: string, integrant_type_id: string) => {    
    await axiosInstance.post('feedback/lists', {organization_id, integrant_type_id}, formAxiosHeader(individual.access_token)).then((feedbackResponse: any) => {
      setState({ ...state, feedbackList: feedbackResponse.data });
      // return feedbackResponse;
    }).catch(err => {
      console.log(err)
      // return err
    })
  }

  const getCategoryData = async () => {    
    await axiosInstance.post('category/lists', formAxiosHeader(individual.access_token)).then((categoryResponse: any) => {
      setState({ ...state, categoryList: categoryResponse.data });
    }).catch(err => {
      console.log(err)
      // return err
    })
  }

  const getSurveyQuestionStoreData = async (data: any) => {
    const dataObj: any = {...data};
    dataObj.organization_id = individual.organization_id;
    // dataObj.integrant_id = batchId;
    dataObj.integrant_type_id = integrantTypeId;
    
    return await axiosInstance.post('surveyquestions/create', dataObj, formAxiosHeader(individual.access_token));
  }

  const getSurveyQuestionData = async () => {
    // let organization_id = individual.organization_id;
    let integrant_type_id = integrantTypeId
    if(integrant_type_id){
      setState({ ...state, surveyQuestionLoading: true })
      await axiosInstance.post('surveyquestions/lists', {integrant_type_id}, formAxiosHeader(individual.access_token)).then((responseData: any) => {
        setState({ ...state, surveyQuestionLoading: false, surveyQuestionList: responseData.data?.map((item: any) => {
          return {...item, options: item.options ? JSON.parse(item.options) : [] }
        }) });
      }).catch(err => {
        console.log(err)
        // return err
      })
    }
  }

  const getSurveyQuestionAnswerData = async () => {
    let integrant_type_id = integrantTypeId
    if(integrant_type_id){
      setState({ ...state, surveyQuestionLoading: true })
      await axiosInstance.post('surveyquestionanswers/lists', {integrant_type_id}, formAxiosHeader(individual.access_token)).then((responseData: any) => {
        setState({ ...state, surveyQuestionLoading: false, surveyQuestionAnswerList: responseData.data });
      }).catch(err => {
        console.log(err)
        // return err
      })
    }
  }

  const getSurveyQuestionUpdateData = async (data: any) => {
    const dataObj: any = data;
    return await axiosInstance.post('surveyquestions/update', dataObj, formAxiosHeader(individual.access_token));
  }

  const surveyQuestionDeleteData = async (data: any) => {
    const survey_question_id: any = data;
    return await axiosInstance.post('surveyquestions/delete', {survey_question_id}, formAxiosHeader(individual.access_token));
  }

  const initDeleteAttachment = async (fileName: string) => {
    const data = await deleteAttachment(fileName);
    if(data.status === '1'){
      toast.success(data.message);
      setCurrentIntegrant({...currentIntegrant,exhibits: currentIntegrant.exhibits.filter((item: any)=>item.url !== fileName)})
    }else{
      toast.error(data.message);
    }
  }

  const getOfferStoreData = async (data: any) => {
    const dataObj: any = {...data};
    dataObj.organization_id = individual.organization_id;
    dataObj.integrant_type_id = integrantTypeId;
    
    return await axiosInstance.post('offers/create', dataObj, formAxiosHeader(individual.access_token));
  }

  const getOfferData = async () => {
    let integrant_type_id = integrantTypeId
    if(integrant_type_id){
      setState({ ...state, surveyQuestionLoading: true })
      await axiosInstance.post('offers/list', {integrant_type_id}, formAxiosHeader(individual.access_token)).then((responseData: any) => {
        setState({ ...state, surveyQuestionLoading: false, offerList: responseData.data });
      }).catch(err => {
        console.log(err)
        // return err
      })
    }
  }

  return {
    integrants,
    isLoading,
    onSelectIntegrant,
    setIntegrants,
    onCloneIntegrant,
    setLoading,
    resetIntegrantsStore,
    selectedIntegrant,
    setTransferredIntegrants,
    batchListUpdate,
    transferredIntegrants,
    locationSuggestions,
    locationList,
    locationSuggestionsReturn,
    getfeedbackData,
    feedbackList,
    getCategoryData,
    categoryList,
    getSurveyQuestionStoreData,
    getSurveyQuestionData,
    surveyQuestionList,
    getSurveyQuestionUpdateData,
    surveyQuestionDeleteData,
    initDeleteAttachment,
    getSurveyQuestionAnswerData,
    surveyQuestionAnswerList,
    surveyQuestionLoading,
    getOfferStoreData,
    getOfferData,
    offerList
  };
};

export { useIntegrantsStore };
