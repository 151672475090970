import { useContext, useEffect } from 'react';
import { NIHContext, INIHProviderState } from '../context/nihContext';
import { useExhibitsService } from './api/useExhibitsService';
import { useNIHService } from './api/useNIHService';
import { useToastStore } from './useToastStore';

interface INIHStore extends INIHProviderState {
  initGetProductAndBatchesList: () => any;
  updateIngredientComparisionData: (data: any) => any;
  initGetIntegrantDetail: (data: any) => any;
  initSearchIntegrantTypeIntoNIHDB: (data: any) => any;
  initGetNIHLabelDetail: (id: number) => any;
  initReadDocument: (data: any) => any;
  clearState: () => any;
}

const useNIHStore = (): INIHStore => {
  const [state, setState] = useContext(NIHContext);
  const { productAndBatchesApi, getIntegrantDetailApi, searchProduct, getLabelById, readDocumentApi } = useNIHService();
  const {
    productAndBatchesList,
    ingredientComparisionData,
    integrantDetailData,
    nihLabelsList,
    nihLabelDetail,
    documentAiCoaData
  } = state;
  const { isOpenToast } = useToastStore();

  const clearState: INIHStore['clearState'] = async () => {
    setState(pre => ({ ...pre, documentAiCoaData: { status: '', message: '', loading: false, data: null } }));
  };

  const updateIngredientComparisionData: INIHStore['updateIngredientComparisionData'] = async (data: any = {}) => {
    setState(pre => ({ ...pre, ingredientComparisionData: Object.assign(pre.ingredientComparisionData, data) }));
  };

  const initGetProductAndBatchesList: INIHStore['initGetProductAndBatchesList'] = async () => {
    setState(pre => ({ ...pre, productAndBatchesList: { ...pre.productAndBatchesList, loading: true } }));
    const response = await productAndBatchesApi();
    setState(pre => ({ ...pre, productAndBatchesList: { ...pre.productAndBatchesList, loading: false, ...response } }));
    if (response?.status && response?.status !== '1') {
      isOpenToast('isError', response.message);
    }
  };

  const initGetIntegrantDetail: INIHStore['initGetIntegrantDetail'] = async (data: any) => {
    setState(pre => ({ ...pre, integrantDetailData: { ...pre.integrantDetailData, loading: true } }));
    const response = await getIntegrantDetailApi(data);
    setState(pre => ({ ...pre, integrantDetailData: { ...pre.integrantDetailData, loading: false, data: response } }));
    if (response?.status && response?.status !== '1') {
      isOpenToast('isError', response.message);
    }
  };

  const initSearchIntegrantTypeIntoNIHDB: INIHStore['initSearchIntegrantTypeIntoNIHDB'] = async (data: any) => {
    setState(pre => ({ ...pre, nihLabelsList: { ...pre.nihLabelsList, loading: true } }));
    const response = await searchProduct({ ...data, method: 'by_letter', from: 0, size: 100 });
    setState(pre => ({ ...pre, nihLabelsList: { ...pre.nihLabelsList, loading: false, data: response } }));
    if (response?.status && response?.status !== '1') {
      isOpenToast('isError', response.message);
    }
  };

  const initGetNIHLabelDetail: INIHStore['initGetNIHLabelDetail'] = async (id: number) => {
    setState(pre => ({ ...pre, nihLabelDetail: { ...pre.nihLabelDetail, loading: true } }));
    const response = await getLabelById(id);
    setState(pre => ({ ...pre, nihLabelDetail: { ...pre.nihLabelDetail, loading: false, data: response } }));
    if (response?.status && response?.status !== '1') {
      isOpenToast('isError', response.message);
    }
  };

  const initReadDocument: INIHStore['initReadDocument'] = async (data: any) => {
    setState(pre => ({ ...pre, documentAiCoaData: { ...pre.documentAiCoaData, loading: true } }));
    const response = await readDocumentApi(data);
    setState(pre => ({ ...pre, documentAiCoaData: { ...pre.documentAiCoaData, loading: false, ...response } }));
    if (response?.status && response?.status !== '1') {
      isOpenToast('isError', response.message);
    }
  };

  return {
    productAndBatchesList,
    ingredientComparisionData,
    integrantDetailData,
    nihLabelsList,
    nihLabelDetail,
    documentAiCoaData,
    initGetProductAndBatchesList,
    updateIngredientComparisionData,
    initGetIntegrantDetail,
    initSearchIntegrantTypeIntoNIHDB,
    initGetNIHLabelDetail,
    initReadDocument,
    clearState
  };
};

export { useNIHStore };
