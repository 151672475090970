import React, { FunctionComponent } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import { ErrorFallback } from '../../components/ErrorFallback';
import { IntegrantGraph } from '../../components/IntegrantGraph';
import { IntegrantPane } from '../../components/IntegrantPane';
import { LayerPane } from '../../components/LayerPane';
import { IntegrantActionsWrapper } from '../../wrappers/IntegrantActionsWrapper';
import { BatchView } from './BatchView';
import { Wrapper } from './wrapper';
import "../../styles/batch.css";
import { useCreateBatchStore } from '../../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../../hooks/useIntegrantGraphStore';

interface IUseBatchParams {
  integrantTypeId: string;
  batchId: string;
}

const Batch: FunctionComponent = () => {
  const { integrantTypeId = '', batchId = '' } = useParams<IUseBatchParams>();
  const { batchValue } = useCreateBatchStore();
  const { currentIntegrant, batchViewPopup, batchViewPopupVal } = useIntegrantGraphStore();
  return (
    <Wrapper>
      <Beforeunload onBeforeunload={(event: any) => {
          if(!batchViewPopupVal && (currentIntegrant?.tracking_id || currentIntegrant?.buy_again_url || currentIntegrant?.facets.length > 0 || currentIntegrant?.location?.id || currentIntegrant?.exhibits.length > 0)){
            event.preventDefault()
          }
        }} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BatchView integrantTypeId={integrantTypeId} batchId={batchId}>
          <LayerPane />
          <IntegrantGraph />
          <IntegrantActionsWrapper>
            <IntegrantPane />
          </IntegrantActionsWrapper>
        </BatchView>
      </ErrorBoundary>
    </Wrapper>
  );
};

export { Batch };
