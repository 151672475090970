import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Img, Layer } from '../common';
import { isClientId, returnQRPath } from '../config';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { imgAvatarStyle } from '../styles/default';
import { returnKindTitle } from '../utils';
import { AddIntegrant } from './AddIntegrant';
import { AddMutation } from './AddMutation';
import { AddTransfer } from './AddTransfer';
import { AddTransit } from './AddTransit';
import { GenerateQRCode } from './GenerateQRCode';
import { IntegrantAlert } from './IntegrantAlert';
import { PublishButton } from './PublishButton';
import {PreviewButton} from './PreviewButton';
import { RemoveIntegrant } from './RemoveIntegrant';
import { CurrentBatch } from './CurrentBatch';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { IExhibitDTO } from '../../lib/exhibit';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore'
import { IntegrantKind } from '../types/common';
import { exhibit_ } from '../common/models';
import { useToastStore } from '../hooks/useToastStore';
import Icon from 'common/Icon';
const potrace = require('potrace')
const integrantTitleStyle: CSSProperties = { textAlign: 'left', display: 'block', width: '100%' };

const integrantGraphHeaderStyle: CSSProperties = {
  // paddingLeft: 200,
  // position: 'absolute',
  // top: 50,
  // left: 0,
  // maxWidth: 500,
  // right: 0,
  // margin: 'auto',
  // height: 145,
  // padding: 10
  padding: '0 10%',
  overflowY: 'auto'
};

const integrantDescriptionStyle: CSSProperties = {
  //overflow: 'hidden',
  //height: 40,
  fontSize: 13
  //marginTop: 5,
  //marginBottom: 10
};

interface IIntegrantOptionsProps {
  isVisible: boolean;
}

const qrStyle: CSSProperties = { position: 'absolute', top: 0, left: 0 };

const IntegrantOptions: FunctionComponent<IIntegrantOptionsProps> = ({ isVisible }) => {
  const { selectedOrganization } = useOrganizationStore();
  const { currentIntegrant, batch_id, currentEdge } = useIntegrantGraphStore();
  const isParentIntegrant = !isClientId(currentIntegrant.id) && batch_id === currentIntegrant.id;

  const downloadQRCodePng = () => {
    const qrCodeCanvas: any = document.getElementById('react-qrcode-logo')
    const qrCodeURL = qrCodeCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeWebp = () => {
    const qrCodeCanvas: any = document.getElementById('react-qrcode-logo')
    const qrCodeURL = qrCodeCanvas.toDataURL("image/webp")
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.webp";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeJpg = () => {
    const qrCodeCanvas: any = document.getElementById('react-qrcode-logo')
    const qrCodeURL = qrCodeCanvas.toDataURL("image/jpeg")
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.jpeg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeSvg = async () => {
    const qrCodeCanvas: any = document.getElementById('react-qrcode-logo')
    const qrCodeURL = qrCodeCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    await potrace.trace(qrCodeURL, function (err: any, svg: any) {
      if (err) throw err;
      let svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
      let url = URL.createObjectURL(svgBlob)
      let aEl = document.createElement("a");
      aEl.href = url;
      aEl.download = "QR_Code.svg";
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    });
  }

  const renderQR = currentIntegrant && currentIntegrant.qr_url ? (
    <div className="qrCodeCurrentIntegrant">
      <GenerateQRCode
        size={1000}
        //style={qrStyle}
        //value={returnQRPath(selectedOrganization.short_code, currentIntegrant.id)}
        value={currentIntegrant.qr_url}
      />
    </div>
  ) : null;
  const { transit, transfer } = currentIntegrant
  return isVisible ? (
    <Layer className='integrantOptions' style={integrantGraphHeaderStyle} fill direction="column">
      <div className='integrant-options-qr-detail-div' style={{ paddingBottom: 10, paddingTop: 10 }}>
        <div>
          {renderQR}
          <Img style={imgAvatarStyle} width={100} src={currentIntegrant.image_thumb_url} />
        </div>
        <div>
          <strong style={integrantTitleStyle}>{currentIntegrant.title}</strong>
          <p style={integrantDescriptionStyle}>{currentIntegrant.description}</p>
          {currentIntegrant && currentIntegrant.qr_url && <div className="download_btn_qr">
            <span className="txt_download">Download QR Code:</span>
            <div>
              <button onClick={() => downloadQRCodePng()} className="btn_qr">PNG</button>
              <button onClick={() => downloadQRCodeWebp()} className="btn_qr">WEBP</button>
              <button onClick={() => downloadQRCodeJpg()} className="btn_qr">JPEG</button>
              <button onClick={() => downloadQRCodeSvg()} className="btn_qr">SVG</button>
            </div>
          </div>}
        </div>
      </div>
      <Layer direction="column" style={{ width: '100%' }}>
        <Layer fill direction="column">
          {typeof currentEdge !== 'undefined' ? <IntegrantAlert title={returnKindTitle(currentEdge)} /> : null}
          <AddIntegrant />
          <AddMutation />
          <AddTransit />
          <AddTransfer />
          <RemoveIntegrant />
          <CurrentBatch />
          <PreviewButton />
          <PublishButton />
          <Transit transit={transit} />
          <Transfer transfer={transfer} />
        </Layer>
      </Layer>
    </Layer >
  ) : null;
};
export { IntegrantOptions };

const Transfer = (props: any) => {
  const { isDraft } = useCreateBatchStore();
  const { transfer } = props
  const [isEdit, setIsEdit] = useState(false)
  const { onAddTransit, setCurrentTransitLocation, setCurrentTransit, setedit_location } = useCreateTransitStore();
  const { getTransfer, initGetOrganizations, setSelectedOrganization } = useCreateTransferStore();
  const { setIntegrantKind } = useIntegrantGraphStore();
  const { isOpenToast } = useToastStore();
  const editTransit = async (item: any) => {
    if (isEdit) {
      setedit_location(false);
      return false
    }
    setedit_location(true);
    setIsEdit(true)
    const { description, effective_date, fromTransferOrganization, id, title } = item
    const transitList: any = await getTransfer(id)
    const { transferExhibits, fromTransferLocation, toTransferLocation, to_location_id, from_location_id, to_organization_id, toTransferOrganization } = transitList
    if (!toTransferLocation && !fromTransferLocation) {
      isOpenToast('isError');
      setIsEdit(false)
      return false
    }
    toTransferLocation.id = to_location_id
    fromTransferLocation.id = from_location_id
    const updatedExhibitList: any = []
    transferExhibits && transferExhibits.forEach((element: any, index: number) => {
      const transferItem = { ...exhibit_ }
      transferItem.title = element.title
      transferItem.url = element.url
      transferItem.id = ((index + 1) * 1000).toString()
      transferItem.isOld = true
      updatedExhibitList.push(transferItem)
    });
    const transitObj = {
      description: description,
      integrant_id: '',
      title: title,
      oldTransitId: id,
      exhibit: updatedExhibitList
    }
    initGetOrganizations()
    setSelectedOrganization(toTransferOrganization)
    setCurrentTransit(transitObj, { from_location: fromTransferLocation, to_location: toTransferLocation, effective_date: new Date(effective_date) })
    onAddTransit(transitObj, IntegrantKind.Transfer)
    setIntegrantKind(IntegrantKind.Transfer)
  }
  if (!isDraft && transfer && transfer.length) {
    return <div>
      <div className="title_transit">Transfer Details</div>
      <div style={{overflow:'auto'}}>
      <div className="table_transit">
        <div className="head_transit row_transit row">
          <div className="col-2">Date</div>
          <div className="col-4">From Organization</div>
          <div className="col-4">To Organization</div>
          <div className="col-2">Action</div>
        </div>
        <div className="body_transit">
          {transfer.map((item: any) => {
            const { effective_date, id, fromTransferOrganization, toTransferOrganization } = item
            let date = ''
            if (effective_date !== '') {
              date = new Date(effective_date).getMonth() + 1 + '/' + new Date(effective_date).getDate() + '/' + new Date(effective_date).getFullYear()
            }
            return <div key={id} className="row_transit row">
              <div className="col-2">{date}</div>
              <div className="col-4">{fromTransferOrganization.name}</div>
              <div className="col-4">{toTransferOrganization.name}</div>
              <div className="col-2"><Icon loading={isEdit} icon='edit' dataTip='Edit Transfer' onClick={() => editTransit(item)} /></div>
            </div>
          })
          }
        </div>
      </div>
      </div>
      <div style={{ minHeight: 10 }}></div>
    </div>
  } else {
    return null
  }
}


const Transit = (props: any) => {
  const { transit } = props
  const [isEdit, setIsEdit] = useState(false)
  const { onAddTransit, setCurrentTransit, setCurrentTransitLocation, getTransit, setedit_location } = useCreateTransitStore();
  const { onUpdateExhibit } = useIntegrantPaneStore()
  const { setIntegrantKind } = useIntegrantGraphStore();
  const { isDraft } = useCreateBatchStore();
  const editTransit = async (item: any) => {
    if (isEdit) {
      setedit_location(false);
      return false
    }
    setedit_location(true);
    setIsEdit(true)
    const { title, id, description, fromLocation, toLocation, effective_date } = item
    const updatedExhibitList: any = []
    const transitList: any = await getTransit(id)
    const { transitExhibits } = transitList
    transitExhibits && transitExhibits.forEach((element: any, index: number) => {
      const transitItem = { ...exhibit_ }
      transitItem.title = element.title
      transitItem.url = element.url
      transitItem.id = ((index + 1) * 1000).toString()
      transitItem.isOld = true
      updatedExhibitList.push(transitItem)
    });
    const transitObj = {
      description: description,
      integrant_id: '',
      title: title,
      oldTransitId: id,
      exhibit: updatedExhibitList
    }
    setCurrentTransit(transitObj, { from_location: fromLocation, to_location: toLocation, effective_date: new Date(effective_date) }, updatedExhibitList)
    onAddTransit(transitObj, IntegrantKind.Transit)
    //setIntegrantKind(IntegrantKind.Transit)
  }
  if (!isDraft && transit && transit.length) {
    return <div>
      <div className="title_transit">Transit Details</div>
      <div style={{overflow:'auto'}}>
        <div className="table_transit">
          <div className="head_transit row_transit row">
            <div className="col-2">Date</div>
            <div className="col-4">From Location</div>
            <div className="col-4">To Location</div>
            <div className="col-2">Action</div>
          </div>
          <div className="body_transit">
            {transit.map((item: any) => {
              const { fromLocation, toLocation, effective_date, id } = item
              let date = ''
              if (effective_date !== '') {
                date = new Date(effective_date).getMonth() + 1 + '/' + new Date(effective_date).getDate() + '/' + new Date(effective_date).getFullYear()
              }
              return <div key={id} className="row_transit row">
                <div className="col-2">{date}</div>
                {fromLocation ? <div className="col-4">{fromLocation.line_1} {fromLocation.city} {fromLocation.state} {fromLocation.country} {fromLocation.zip}</div> : null}
                {toLocation ? <div className="col-4">{toLocation.line_1} {toLocation.city} {toLocation.state} {toLocation.country} {toLocation.zip}</div> : null}
                <div className="col-2"><Icon loading={isEdit} icon='edit' dataTip='Edit Transit' onClick={() => editTransit(item)} /></div>
              </div>
            })
            }
          </div>
        </div>
      </div>
      <div style={{ minHeight: 10 }}></div>
    </div>
  } else {
    return null
  }
}
