import { useContext, useEffect } from 'react';
import { UserContext, IUserProviderState } from '../context/userContext';
import { useUserService } from './api/useUserService';
import { IGetFilterUsersCount, IGetMyConnections } from '../../lib/user';
import { useToastStore } from './useToastStore';
import { useAuthStore } from './useAuthStore';

interface IUserStore extends IUserProviderState {
  getUserById: (user_id: string) => any;
  getFilterUsersCount: (data: IGetFilterUsersCount) => any;
  initSendSurveyInvitation: (params: any) => any;
  resetFilterUsersCount: () => any;
  initGetMyConnections: (params: IGetMyConnections) => any;
  initVerifyDomain: () => any;
}

const useUserStore = (): IUserStore => {
  const [state, setState] = useContext(UserContext);
  const {
    getUserByIdAPI,
    getUsersFilterCount,
    sendSurveyInvitation,
    getMyConnnectionService,
    verifyDomainAPI
  } = useUserService();
  const { updateDomainInfo } = useAuthStore();
  const { userDataById, userFilteredCount, sendSurveyInvitationData, myConnections, verifyDomainData } = state;
  const { isOpenToast } = useToastStore();

  const getUserById: IUserStore['getUserById'] = async (user_id: string) => {
    setState({ ...state, userDataById: { status: '', message: '', loading: true, data: null } });
    const data = await getUserByIdAPI(user_id);
    setState({ ...state, userDataById: { ...data, loading: false } });
  };

  const getFilterUsersCount: IUserStore['getFilterUsersCount'] = async (params: IGetFilterUsersCount) => {
    setState({ ...state, userFilteredCount: { ...state.userFilteredCount, loading: true } });
    const data = await getUsersFilterCount(params);
    setState({ ...state, userFilteredCount: { ...data, loading: false } });
  };

  const initSendSurveyInvitation: IUserStore['initSendSurveyInvitation'] = async (params: any) => {
    setState({ ...state, sendSurveyInvitationData: { ...state.sendSurveyInvitationData, loading: true } });
    const data = await sendSurveyInvitation(params);
    setState({ ...state, sendSurveyInvitationData: { ...data, loading: false } });
  };

  const initVerifyDomain: IUserStore['initVerifyDomain'] = async () => {
    setState({ ...state, verifyDomainData: { ...state.verifyDomainData, loading: true } });
    const data = await verifyDomainAPI();
    if (data?.status === '1') {
      isOpenToast('success', data?.message);
      updateDomainInfo({ is_valid_domain_name: true });
    } else {
      isOpenToast('isError', data?.message);
    }
    setState({ ...state, verifyDomainData: { ...data, loading: false } });
  };

  const initGetMyConnections: IUserStore['initGetMyConnections'] = async (params: IGetMyConnections) => {
    setState({ ...state, myConnections: { ...state.myConnections, loading: true } });
    const data = await getMyConnnectionService(params);
    setState({
      ...state,
      myConnections: {
        ...data,
        data: state.myConnections?.data
          ? { ...data?.data, rows: state?.myConnections?.data?.rows.concat(data?.data?.rows) }
          : data?.data,
        loading: false
      }
    });
  };

  const resetFilterUsersCount: IUserStore['resetFilterUsersCount'] = () => {
    setState({
      ...state,
      userFilteredCount: {
        status: '',
        message: '',
        data: null,
        loading: false
      }
    });
  };

  return {
    verifyDomainData,
    myConnections,
    userDataById,
    userFilteredCount,
    sendSurveyInvitationData,
    getUserById,
    getFilterUsersCount,
    initSendSurveyInvitation,
    resetFilterUsersCount,
    initGetMyConnections,
    initVerifyDomain
  };
};

export { useUserStore };
