import React, { FunctionComponent } from 'react';
import { Layer } from '../../common/Layer';
import { Loading } from '../../components/Loading';
import { useCreateBatchStore } from '../../hooks/useCreateBatchStore';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import { useRequestIntegrantTypes } from '../../hooks/useRequestIntegrantTypes';
import { gridBackgroundStyle } from '../../styles/default';
import { LoadingTitle } from '../../types/common';

const BatchView: FunctionComponent<{ integrantTypeId: string; batchId: string }> = ({
  integrantTypeId,
  children,
  batchId
}) => {
  const { isLoading } = useCreateBatchStore(batchId);

  useRequestIntegrantTypes();
  useIntegrantTypeStore(integrantTypeId);

  return (
    <Layer className='batchview' style={gridBackgroundStyle} fill>
      {isLoading ? <Loading title={LoadingTitle.Integrant}></Loading> : <>{children}</>}
    </Layer>
  );
};

export { BatchView };
