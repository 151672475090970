import React, { FunctionComponent, CSSProperties } from 'react';
import { Layer } from '../../common/Layer';
import { Img } from '../../common';
import { useCreateTransferInStore } from '../../hooks/useCreateTransferInStore';
import { ListView } from '../../components/ListView';
import { titles } from '../../config';
import { primaryColor } from '../../styles/default';
import { IntegrantAlert } from '../../components/IntegrantAlert';
import {useTheme,useMediaQuery} from "@material-ui/core"

const productInfoStyle: CSSProperties = {
    marginLeft: 0,
    width: '100%'
};

const productImageStyle: CSSProperties = {
    width: 50,
    minWidth: 50,
    marginRight: 10
};

const titleStyle: CSSProperties = {
    textOverflow: 'ellipses',
    wordBreak: 'break-word',
    margin: 0
};

const TransferInSlide: FunctionComponent = () => {

    const {
        transferList,
        selectedProduct,
        isOpen,
        selectedIntegrantId
    } = useCreateTransferInStore()

    const renderListItem = (product: any): React.ReactNode => {
        const { title, description, transfer, organizations: { name }, integrantsType: { image_url } } = product;
        return (<>
            <Layer direction="row">
                <div>
                    <Img style={productImageStyle} alt={title} src={image_url} />
                </div>
                <div style={productInfoStyle}>
                    <h5 style={titleStyle}>{title}</h5>
                    <small>{description}</small>
                </div>
            </Layer>
        </>
        );
    };

    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down(768)); 

    return (
        <Layer width={selectedIntegrantId || isOpen ? match ? '100%' : '50%' : '100%'}>
            <div style={{ width: '100%',maxWidth:800, margin: '0 auto', paddingTop: 20 }}>
                <p className="page-title prod-list">
                    View {titles.trackingPlural} from Other {titles.organization}
                </p>
                {transferList.length > 0 ? (
                    <div className='transferred-list-leftview'>
                        <ListView
                            searchPlaceholder={`Search by ${titles.integrantType} Name`}
                            filter={(integrantType, searchResult): boolean =>
                                integrantType.title.toLowerCase().includes(searchResult.toLowerCase())
                            }
                            onListItemSelect={selectedProduct}
                            renderListItem={renderListItem}
                            dataSource={transferList}
                            listItemStyle={(currItem): CSSProperties => {
                                const isCurr = currItem.id === selectedIntegrantId
                                return {
                                    //padding: 0,
                                    borderWidth: '0 0 1px',
                                    borderColor: isCurr ? `${primaryColor}` : '#ccc',
                                    backgroundColor: isCurr ? `rgba(0, 0, 0, 0.05)` : `#ffffff`,
                                    borderStyle: 'solid',
                                    borderRadius: 0
                                };
                            }}
                        />
                    </div>
                ) : (
                    <IntegrantAlert
                        title={`Looks like no ${titles.organization} have transferred any ${titles.trackingPlural}`}
                    />
                )}
            </div>
        </Layer>
    );
};

export { TransferInSlide };
