import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../../hooks/useAuthStore';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';
import { formAxiosHeader } from '../../config';
import { isEmptyObj } from '../../utils';
import axiosInstance from '../../utils/api-interceptor';
import '../../styles/csv.css';
import { CommonLink, PrimaryButton } from 'common';
// import demo_csv from '../../assets/healthloq-demo.csv';
// import demo_csv from '../../assets/healthloq.csv';
import Icon from '../../common/Icon';
import ReactTooltip from 'react-tooltip';
import {CSVLink, CSVDownload} from 'react-csv';
const acceptedFileTypes = '.csv';
let csvData: any = [];

const CSV = (props: any) => {
  const [viewMessage, setViewMessage] = useState({type: "", msg: ""});
  const [csvObj, setCsvObj] = useState<any>({})
  const { csvCurrentFile } = csvObj;
  const { individual } = useAuthStore();
  const { selectedOrganization } = useOrganizationStore();
  const [csvLoader, setCsvLoader] = useState(false);

  const inputsHandlerCSV = async (e: any) => {
    const { name, value, files } = e.target
    if (files && typeof files === 'object') {
      const [fileData, theFile]: any = await readFile(files[0]);
      if (typeof fileData === 'object' && isEmptyObj(fileData)) {
        setCsvObj({ ...csvObj, csvCurrentFile: fileData, [name]: theFile.result })
      }
      setViewMessage({type: "", msg: ""});
    } else {
      setCsvObj({ ...csvObj, [name]: value })
    }
  }

  async function submitHandler() {
    const formDataImage = new FormData();
    formDataImage.append('organization_id', selectedOrganization?.id);
    formDataImage.append('type', 'csvFile');
    formDataImage.append('files[]', csvCurrentFile);
    if(csvCurrentFile?.name){
      // axiosInstance.post(
      //   'integrant/csv-upload',
      //   formDataImage,
      //   formAxiosHeader(individual.access_token)
      // ).then((response: any) => {
      //   // isOpenToast('success', "Your CSV details has been successfully updated.");
      //   setViewMessage({type: "success", msg: "Your CSV details has been successfully updated." });
      //   setTimeout(() => setViewMessage({ type: "", msg: "" }), 8000);
      //   if(response?.csv.length){
      //     csvData = response?.csv
      //   }
      // }).catch(err => {
      //   // return err
      //   setViewMessage({type: "error", msg: err});
      //   setTimeout(() => setViewMessage({ type: "", msg: "" }), 8000);
      // })
      setCsvLoader(true);
      const response: any = await axiosInstance.post(
        'integrant/csv-upload',
        formDataImage,
        formAxiosHeader(individual.access_token)
      );
        setViewMessage({type: "success", msg: "Your CSV details has been successfully updated, if we face any error while running csv then we are generating csv for you in which you find an error messages." });
        setTimeout(() => setViewMessage({ type: "", msg: "" }), 8000);
        // if(response?.status == true && response?.csv?.length > 0){
        if(response?.csv && response?.csv?.length > 0){
          csvData = response?.csv
        }
        setCsvLoader(false);
      // setViewMessage({type: "success", msg: "Your CSV details has been successfully updated, if we face any error while running csv then we are generating csv for you in which you find an error messages."});
    } else {
      setViewMessage({type: "error", msg: "Please select file!"});
      setTimeout(() => setViewMessage({ type: "", msg: "" }), 8000);
    }
  }

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const theFile = event.target;
        return resolve([file, theFile]);
      };
      fileReader.readAsDataURL(file);
    });
  };

  return (
    <div className="cp">
      <ReactTooltip />
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <div className="subscription-title">Import Products</div>
          </div>
        </div>
        <div className="row align-items-center _pt15">
          <div className="col-md-12">
             <div className={'d-flex csv-strip '}>
              {/* <div className='csv-label'>
                CSV
              </div> */}
              <div className='csv-field'>
                <div>
                  <div className={"justify-content-left section-csv"} >
                    <label htmlFor="csv_file" className='csv-file width-auto'>
                      {csvCurrentFile ? csvCurrentFile.name : `Select CSV File`}
                    </label>
                    <input accept={acceptedFileTypes} onChange={inputsHandlerCSV} type="file" id="csv_file" name="csv_file" hidden />
                    <PrimaryButton style={{marginLeft:"10px"}} onClick={() => submitHandler()} maxContent={true} secondaryButton={true} children={"Upload"} icon='save' loading={csvLoader}/>
                    <div className='center' style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
                      {/* <a href={demo_csv} download="healthloq.csv"><Icon icon="cloudDownload" dataTip="Download Demo CSV" /></a> */}
                      <a href="healthloq.csv" download="healthloq.csv"><Icon icon="cloudDownload" dataTip="Download Demo CSV" /></a>
                    </div>
                    { csvData.length > 0 &&
                      <>
                        <CSVDownload data={csvData} target="_blank" />
                      </>
                    }
                  </div>
                  <p style={{ color:viewMessage.type === "success" ? "green" : "red" }}>{viewMessage.msg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CSV };