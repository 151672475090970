import React, { useState } from 'react';
import { ICreateTransitDTO } from '../../lib/transit';
import { create_transit, location_ } from '../common/models';
import { CreateContext } from '../types/common';
import { ILocationDTO } from '../../lib/location';

export interface ICreateTransitState {
  isLoading: boolean;
  isOpen: boolean;
  currentTransit: ICreateTransitDTO;
  from_location?: ILocationDTO;
  to_location?: ILocationDTO;
  effective_date: any;
  to_organization_id: any;
  edit_location: boolean;
}

const defaults = { currentTransit: create_transit, isOpen: false, isLoading: false, from_location: location_, to_location: location_, effective_date: new Date(), to_organization_id: '', edit_location: false };

const CreateTransitContext = React.createContext<CreateContext<ICreateTransitState>>([
  defaults,
  (): ICreateTransitState => defaults
]);

const CreateTransitProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ICreateTransitState>(defaults);

  return <CreateTransitContext.Provider value={[state, setState]}>{props.children}</CreateTransitContext.Provider>;
};

export { CreateTransitContext, CreateTransitProvider };
