import { ICreateTransferDTO } from '../../lib/transfer';
import { IOrganizationDTO } from '../../lib/organization';
import { useContext } from 'react';
import { CreateTransferContext, ICreateTransferState } from '../context/createTransferContext';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useOrganizationsService } from './api/useOrganizationsService';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { useTransferService } from './api/useTransferService';
import { IntegrantKind } from '../types/common';
import { location_ } from '../common/models';

interface ICreateTransferStore extends ICreateTransferState {
  initGetOrganizations: () => Promise<void>;
  setCurrentTransfer: (transfer: ICreateTransferDTO) => void;
  setOpen: (isOpen: boolean) => void;
  onAddTransfer: (transfer: ICreateTransferDTO) => Promise<void>;
  setSelectedOrganization: (selectedTransferOrganization: IOrganizationDTO) => void;
  getTransfer: (id: string) => Promise<void>;
}

const useCreateTransferStore = (): ICreateTransferStore => {
  const [state, setState] = useContext(CreateTransferContext);
  const { isOpen, currentTransfer, isLoading, organizations, selectedTransferOrganization } = state;
  const { getAll } = useOrganizationsService();
  const { currentIntegrant, onAddIntegrant } = useIntegrantGraphStore();
  const { setCurrentTransitLocation } = useCreateTransitStore();
  const { getTransferList } = useTransferService()

  const initGetOrganizations: ICreateTransferStore['initGetOrganizations'] = async () => {
    const organizations = await getAll();
    setState({ ...state, organizations: organizations });
  };

  const setCurrentTransfer: ICreateTransferStore['setCurrentTransfer'] = currentTransfer => {
    setState((s: ICreateTransferState) => ({ ...s, currentTransfer }));
  };

  const setSelectedOrganization: ICreateTransferStore['setSelectedOrganization'] = selectedTransferOrganization => {
    const TransferObj = {
      from_location: location_,
      to_location: location_,
      effective_date: new Date(),
      to_organization_id: selectedTransferOrganization.id
    }
    setCurrentTransitLocation(TransferObj)
    setState((s: ICreateTransferState) => ({ ...s, selectedTransferOrganization }));
  };

  const onAddTransfer: ICreateTransferStore['onAddTransfer'] = async transfer => {
    setState((s: ICreateTransferState) => ({ ...s, isLoading: true }));
    setCurrentTransfer({ ...transfer, integrant_id: currentIntegrant.id });

    onAddIntegrant(
      { ...currentIntegrant, organization_id: selectedTransferOrganization.id },
      currentIntegrant.id,
      IntegrantKind.Transfer
    );

    setState((s: ICreateTransferState) => ({ ...s, isLoading: false, isOpen: false }));
  };

  const setOpen: ICreateTransferStore['setOpen'] = async (isOpen: boolean) => {
    if (isOpen) {
      const organizations = await getAll();
      setState((s: ICreateTransferState) => ({ ...s, isOpen, organizations: organizations }));
    } else {
      setState((s: ICreateTransferState) => ({ ...s, isOpen }))
    }
  };

  const getTransfer: ICreateTransferStore['getTransfer'] = async (id: string) => {
    let transitList = await getTransferList(id)
    return transitList
  }

  return {
    currentTransfer,
    isLoading,
    isOpen,
    organizations,
    selectedTransferOrganization,
    initGetOrganizations,
    setCurrentTransfer,
    setOpen,
    onAddTransfer,
    setSelectedOrganization,
    getTransfer
  };
};

export { useCreateTransferStore };
