import React, { useEffect, useState } from 'react';
import { integrant_type_, integrant_type_ingredients } from '../../common/models';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import { CreateContext } from '../../types/common';
import { IIntegrantTypeState } from './types';

const defaults: IIntegrantTypeState = {
  isLoading: false,
  integrantTypes: [],
  selectedIntegrantType: integrant_type_,
  productIngredientsData: integrant_type_ingredients,
  surveyList: {
    loading: false,
    status: '',
    message: '',
    data: null
  },
  surveyQuestionsList: {
    loading: false,
    status: '',
    message: '',
    data: null,
    offerData: null
  },
  surveyInvitationList: {
    loading: false,
    status: '',
    message: '',
    data: null
  },
  getBunnyVideoUploadInfoData: {
    loading: false,
    status: '',
    message: '',
    data: null
  }
};

const IntegrantTypeContext = React.createContext<CreateContext<IIntegrantTypeState>>([
  defaults,
  (): IIntegrantTypeState => defaults
]);

const IntegrantTypeProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const { integrantTypes } = useIntegrantTypeStore();
  const [state, setState] = useState<IIntegrantTypeState>({
    ...defaults
  });

  useEffect(() => {
    setState(s => ({ ...s, isLoading: false, products: integrantTypes }));
  }, [integrantTypes]);

  return <IntegrantTypeContext.Provider value={[state, setState]}>{props.children}</IntegrantTypeContext.Provider>;
};

export { IntegrantTypeContext, IntegrantTypeProvider };
