import React, { useState } from 'react';
import { CreateContext } from '../../types/common';
import { defaults } from './integrantGraphContextState';
import { IIntegrantGraphProviderState } from './types';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';

const GraphPaneContext = React.createContext<CreateContext<IIntegrantGraphProviderState>>([
  defaults,
  (): IIntegrantGraphProviderState => defaults
]);

const GraphPaneProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const { selectedOrganization } = useOrganizationStore();
  const [state, setState] = useState<IIntegrantGraphProviderState>({
    ...defaults,
    currentIntegrant: { ...defaults.currentIntegrant, organization_id: selectedOrganization.id },
    firstCurrentIntegrant: { ...defaults.currentIntegrant, organization_id: selectedOrganization.id }
  });

  return <GraphPaneContext.Provider value={[state, setState]}>{props.children}</GraphPaneContext.Provider>;
};

export { GraphPaneContext, GraphPaneProvider };
