import { useState } from 'react';
import { requestDataConfig, url } from '../../config';
import { IIndividualRole } from '../../types/common';
import { useAuthStore } from '../useAuthStore';

interface IIndividualRolesService {
  isLoading: boolean;
  initializeIndividualRoles: () => Promise<IIndividualRole[] | undefined>;
}

const useIndividualRolesService = (): IIndividualRolesService => {
  const { individual } = useAuthStore();
  const [isLoading, setLoading] = useState(false);

  const requestIndividualRoles = async (): Promise<IIndividualRole[]> => {
    const individualRoles = await fetch(
      `${url}individual-roles/${individual.id}`,
      requestDataConfig(individual.access_token)
    );
    const data = await individualRoles.json();
    return data;
  };

  async function initializeIndividualRoles(): Promise<IIndividualRole[] | undefined> {
    setLoading(true);
    const initialRoles: IIndividualRole[] = await requestIndividualRoles();
    if (initialRoles.length > 0) {
      setLoading(false);
      return initialRoles;
    }
  }

  return {
    isLoading,
    initializeIndividualRoles
  };
};

export { useIndividualRolesService };
