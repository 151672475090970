import React from 'react';
import { Input } from '../../common/Input';
import { currencyFormat, monthFormat, sortList } from './../../config';
const CardForm = (props: any) => {
  const { product_plan, setSubscriptionValue, subscriptionValue, name } = props;
  const priceList = sortList(product_plan)
  return (
    <>
      <div className="plan-list">
        {priceList.map((item: any, index: any) => {
          const { interval, interval_count, amount_decimal, currency } = item;
          return (
            <label className="d-flex price-list align-items-center justify-content-between" key={index}>
              <div>
                <Input
                  name={name}
                  checked={subscriptionValue === index}
                  type="radio"
                  style={{ width: 'auto' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setSubscriptionValue(index)}
                />
                {name !== 'planCost01' ?
                  monthFormat(interval_count, interval) :
                  currencyFormat(amount_decimal, currency)
                }
              </div>

              {name !== 'planCost01' ?
                <strong>{currencyFormat(amount_decimal, currency)}</strong> :
                monthFormat(interval_count, interval)
              }

            </label>
          );
        })}
      </div>
      <div className="item-cart-amount">
        Total Amount :{' '}
        {currencyFormat(priceList[subscriptionValue].amount_decimal, priceList[subscriptionValue].currency)}
      </div>
    </>
  )
};

export default CardForm