import React,{FunctionComponent} from 'react';
import MuiCircularProgress from './MuiCircularProgress';
import {makeStyles,Theme, createStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    lazyLoadingRoot:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    }
}));

interface ILazyLoadingProps {
    setRef?: any;
    padding?: string;
    style?: any;
}

export const LazyLoading: FunctionComponent<ILazyLoadingProps> = ({setRef,padding,style={}})=>{
    const classes = useStyle();
    return <div ref={setRef} style={{padding,...style}} className={clsx(classes.lazyLoadingRoot)}>Loading...&nbsp;<MuiCircularProgress /></div>
}
