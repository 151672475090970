import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import {IAPIResponseDTO} from '../../lib/common'


export interface INotificationProviderState {
  notificationList: IAPIResponseDTO,
  readNotificationData: IAPIResponseDTO
}

export const defaults = { 
    notificationList:{
        status:"",
        message:"",
        loading: false,
        data: null
    },
    readNotificationData:{
        status:"",
        message:"",
        loading: false,
        data: null
    }
};

const NotificationContext = React.createContext<CreateContext<INotificationProviderState>>([
  defaults,
  (): INotificationProviderState => defaults
]);

const NotificationProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<INotificationProviderState>(defaults);
  return <NotificationContext.Provider value={[state, setState]}>{props.children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationProvider };
