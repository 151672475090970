import { PrimaryButton } from 'common/Button/buttons';
import React, { FunctionComponent } from 'react';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import {makeStyles,Theme, createStyles} from '@material-ui/core';
import { clientURL } from './../config';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    primaryButtonRoot:{
        marginBottom: 10,
        textDecoration:'none',
    }
}));

const PreviewButton: FunctionComponent = () => {
  const { currentIntegrant }: any = useIntegrantGraphStore();
  const { isDraft } = useCreateBatchStore();
  const classes = useStyle();
  const productURL = `${clientURL}${clientURL?.charAt(clientURL.length - 1) === "/" ? "":"/"}p/${currentIntegrant?.organizations?.short_code}/b/${currentIntegrant?.external_id_slug}?preview=${window.btoa("true")}`;

  return !isDraft ? (
    <a className={classes.primaryButtonRoot} href={productURL} target="_blank">
       <PrimaryButton icon='visibility' disabled={!currentIntegrant.id} children={'Preview'} />
    </a>
  ) : null;
};

export { PreviewButton };
