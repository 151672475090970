import React, { CSSProperties, FunctionComponent } from 'react';
import { Layer } from '../common';
import { titles } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useSuggestIntegrantCreatedAlreadyStore } from '../hooks/useSuggestIntegrantCreatedAlreadyStore';
import { SuggestIntegrantCreatedAlready } from './SuggestIntegrantCreatedAlready';
import { PrimaryButton } from 'common/Button/buttons';

const mutationWrapper: CSSProperties = { position: 'relative', zIndex: 4, marginBottom: 20 };

const AddMutation: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();
  const { currentIntegrant, data } = useIntegrantGraphStore();
  const { onToggleMenu, isOpen } = useSuggestIntegrantCreatedAlreadyStore();

  // NOTE JI data node === 3 as it doesn't make sense to allow linking of edges less than that.
  return isDraft || data.nodes.length < 3 ? null : (
    <>
      <Layer style={mutationWrapper} direction="column">
        <PrimaryButton icon='arrowBoth' onClick={(): void => onToggleMenu(!isOpen)} children={!isOpen ? `Connect ${currentIntegrant.title} to another ${titles.subIntegrant}` : 'Close'} />
        <SuggestIntegrantCreatedAlready />
      </Layer>
    </>
  );
};

export { AddMutation };
