import React, { FunctionComponent } from 'react';
import { Img, Layer } from '../common';
import { listItemImgStyle, listItemTitleStyle, listItemWrapperStyle } from '../styles/default';
import { isEmptyString } from '../utils';

interface IListItemProps {
  title: string;
  photoURL: string;
  external_id_slug?: string;
}

const ListItem: FunctionComponent<IListItemProps> = ({ title, photoURL, external_id_slug }) => {
  return (
    <Layer style={listItemWrapperStyle} align="left">
      {
        isEmptyString(photoURL) ? <span className='batch-stamp'>B</span> : <Img alt={title} style={listItemImgStyle} height={26} src={photoURL} />
      }
      <Layer direction="column" style={{ overflow: 'hidden' }}>
        <h5 style={listItemTitleStyle}>{title}{(isEmptyString(photoURL) && external_id_slug) && ' - ' + external_id_slug}</h5>
      </Layer>
    </Layer>
  );
};

export { ListItem };
