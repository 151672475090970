import React,{ FunctionComponent } from 'react';
import { makeStyles,createStyles,Theme } from '@material-ui/core';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import Icon from './Icon';

interface ILinkProps{
    to: string;
    style?: any;
    children?: string;
    disabledLink?: boolean;
    icon?: string;
}

const defaultProps: ILinkProps = {
    to: "",
    style: {},
    children: "",
    disabledLink: false,
    icon: ""
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryLink: {
        width:"max-content",
        borderRadius: 5,
        border: "1px solid #008F2B",
        cursor: 'pointer',
        transition: "0.5s all",
        backgroundColor: "transparent",
        padding: "8px 10px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        color: "#008F2B",
        textDecoration:"none",
        fontSize:"14px",
        fontWeight:500,
        lineHeight:1.75,
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif`,
        '&>span>svg':{
            marginRight: 5,
            width: 20,
            height: 20
        },
        '&:hover':{
            backgroundColor: "#008F2B",
            color: "#fff",
        }
    },
    disabledLink:{
        pointerEvents:'none',
        border: "1px solid #888",
        color: "#888",
        '&:hover':{
            backgroundColor: "transparent",
            color: "#888",
        }
    }
  }),
);

const CommonLink: FunctionComponent<ILinkProps> = ({to,children,style,disabledLink,icon}) => {
    const classes = useStyles();
  return <Link to={to} className={clsx(classes.primaryLink,disabledLink && classes.disabledLink)} style={style}>{icon ? <Icon icon={icon} onlyIcon={true} /> : "" }{children}</Link>
}

CommonLink.defaultProps = defaultProps;

export default CommonLink