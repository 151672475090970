import React, { FunctionComponent } from 'react';
import { CreateTransferProvider } from '../context/createTransferContext';
import { CreateTransitProvider } from '../context/createTransitContext';
import { SuggestIntegrantChildProvider } from '../context/suggestIntegrantChild/suggestIntegrantChildContext';
import { SuggestIntegrantCreatedAlreadyProvider } from '../context/suggestIntegrantCreatedAlready/suggestIntegrantCreatedAlreadyContext';

const IntegrantActionsWrapper: FunctionComponent = ({ children }) => {
  return (
    <SuggestIntegrantChildProvider>
      <SuggestIntegrantCreatedAlreadyProvider>
        <CreateTransitProvider>
          <CreateTransferProvider>
            <>{children}</>
          </CreateTransferProvider>
        </CreateTransitProvider>
      </SuggestIntegrantCreatedAlreadyProvider>
    </SuggestIntegrantChildProvider>
  );
};

export { IntegrantActionsWrapper };
