import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  showCheckbox?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells = [],
    showCheckbox = false
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              classes={{ colorSecondary: classes.checkboxColor }}
            />
          </TableCell>
        )}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'action' ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: '#FBFBFB'
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: '#008F2B',
            backgroundColor: lighten('#008F2B', 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: '#008F2B'
          },
    title: {
      flex: '1 1 100%'
    },
    bulkActionRoot: {
      padding: '10px 0'
    }
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  tableHeading: string;
  getBulkActionInfo?: any;
  getFilters?: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, tableHeading, getBulkActionInfo = null, getFilters } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {tableHeading}
        </Typography>
      )}
      {numSelected > 0 ? <Box className={classes.bulkActionRoot}>{getBulkActionInfo}</Box> : getFilters}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    checkboxColor: {
      color: '#008F2B !important'
    },
    rowSelected: {
      backgroundColor: `${lighten('#008F2B', 0.85)} !important`
    }
  })
);

interface PrimaryTableProps {
  isLoading: boolean;
  data: any;
  totalCount: number;
  columns: any;
  tableHeading: string;
  defaultOrderBy?: string;
  showCheckbox?: boolean;
  getBulkActionInfo?: any;
  selected?: string[];
  setSelected?: (pre: string[]) => string[] | void;
  handleFilterChange?: (payload: any) => void;
  getFilters?: any;
}

export default function PrimaryTable({
  isLoading = false,
  data = [],
  totalCount = 0,
  columns = [],
  tableHeading = '',
  defaultOrderBy = '',
  showCheckbox = false,
  getBulkActionInfo = null,
  getFilters = null,
  setSelected = () => [],
  selected = [],
  handleFilterChange = () => {}
}: PrimaryTableProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy || '');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  useEffect(() => {
    handleFilterChange({
      order,
      order_by: orderBy,
      limit: rowsPerPage,
      offset: rowsPerPage * page
    });
  }, [order, orderBy, rowsPerPage, page]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          tableHeading={tableHeading}
          getBulkActionInfo={getBulkActionInfo}
          getFilters={getFilters}
        />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={columns}
              showCheckbox={showCheckbox}
            />
            <TableBody>
              {isLoading ? (
                [...Array(5)].map((e: any, i: number) => {
                  return (
                    <TableRow key={i}>
                      {showCheckbox && (
                        <TableCell>
                          <Skeleton variant="rect" height={20} animation="wave" />
                        </TableCell>
                      )}
                      {columns?.map((column: any) => {
                        return (
                          <TableCell key={column?.id}>
                            <Skeleton variant="rect" height={20} animation="wave" />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : !data?.length ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns?.length + (showCheckbox ? 1 : 0)}>
                    Data not available.
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        classes={{ selected: classes.rowSelected }}
                      >
                        {showCheckbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={event => handleClick(event, row.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                              classes={{ colorSecondary: classes.checkboxColor }}
                            />
                          </TableCell>
                        )}
                        {columns?.map((column: any) => {
                          return (
                            <TableCell align={column?.id === 'action' ? 'center' : column?.numeric ? 'right' : 'left'}>
                              {row[column?.id]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
