import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useNIHStore } from 'hooks/useNIHStore';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import LinkIcon from '@material-ui/icons/Link';
import { MuiIconButton, PrimaryButton } from 'common';

const advisoryList: any = [
  {
    name: '1,4-DMAA',
    otherNames: ['1,4 dimethylamylamine', '1,4 dimethylpentylamine']
  },
  {
    name: 'Andarine',
    otherNames: [
      'GTx 007',
      'GTX-007',
      'Propanamide, 3-(4-(acetylamino)phenoxy)-2-hydroxy-2-methyl-N-(4-nitro-3-(trifluoromethyl)phenyl)-, (2S)-',
      'SARM S-4',
      'S-3-(4-acetylaminophenoxy)-2-hydroxy-2-methyl-N-(4-nitro-3-trifluoromethylpheanyl)propionamide',
      'S-4 cpd'
    ]
  },
  {
    name: 'Bismuth nitrate',
    otherNames: [
      'Bismuth(III) nitrate',
      'Bismuth trinitrate',
      'Nitric acid, bismuth(3+) salt',
      'Bismuth trinitrate pentahydrate',
      'Bismuth(III) nitrate pentahydrate'
    ]
  },
  {
    name: 'N-Methyltyramine',
    otherNames: [
      'NMT',
      'Methyl-4-tyramine',
      'N-Methyltyramine',
      'p-(2-Methylaminoethyl)phenol',
      'N-Methyl-tyramine',
      '4-Hydroxy-N-methylphenethylamine',
      'p-(2-(Methylamino)ethyl)phenol',
      'p-(2-Methylaminoethyl)-phenol'
    ]
  },
  {
    name: 'Sodium tetrachloroaurate',
    otherNames: [
      'EINECS 239-241-3',
      'Gold chloride sodium',
      'Gold sodium chloride',
      'Hydrochloroauric acid, sodium salt',
      'Natrium tetrachloroauratum',
      'Sodium chloroaurate',
      'Sodium gold chloride',
      'Sodium gold (III) chloride',
      'Sodium tetrachloroaurate (1-)',
      'Sodium tetrachloroaurate (3+)',
      'Tetrachloroaurate (1-) sodium',
      'Tetrachloroauric (3+) acid, sodium salt',
      'UNII-7FT6QUT299'
    ]
  },
  {
    name: 'Sulbutiamine',
    otherNames: [
      'Sulbutiamine',
      'Sulbutiamina',
      'Sulbutiaminum',
      'Arcalion',
      'Bis(1-(2-isobutyryloxyethyl)-2-(N-((4-amino-2-methyl-5-pyrimidinyl)methyl)formamido)-1-propenyl)disulfid',
      'Bis(1-(4-amino-2-methyl-5-pyrimidinyl)-2-formyl-3,9-dimethyl-8-oxo-2-aza-7-oxa-3-decen-4-yl)disulfid',
      'Bisibuthiamine',
      'Bisibutiamin',
      'Bisibutiamine',
      'BRN 0741531',
      'EINECS 221-937-3',
      `N,N'-(Dithiobis(2-(2-hydroxyethyl)-1-methylvinylene))bis(N-((4-amino-2-methyl-5-pyrimidinyl)methyl)formamide)diisobutyrate (ester)`,
      `O,O'-Diisobutyrylthiamine disulfide`,
      'O-Isobutyroylthiamine disulfide',
      'UNII-42NCM1BW43',
      'Vitaberin'
    ]
  }
];

interface ICOAIngredientComparisionProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    coaIngredientComparisionContainer: {
      padding: '20px 0',
      '&>div': {
        marginBottom: 30,
        '&>h6': {
          marginBottom: 20,
          fontWeight: 'bold'
        }
      }
    },
    ingredientComparisionResult: {
      '&>h6': {
        marginBottom: '0px !important'
      },
      '&>p': {
        marginTop: 0,
        marginBottom: 20
      }
    },
    productComparision: {
      '&>div': {
        '&>p': {
          '&:nth-child(1)': {
            fontWeight: 'bold',
            marginBottom: 15
          },
          '&:nth-child(3)': {
            '&>span': {
              fontWeight: 'bold',
              marginRight: 5
            }
          }
        },
        '&>div': {
          height: 200,
          width: '100%',
          marginBottom: 15,
          '&>img': {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
            maxWidth: 'max-content'
          }
        }
      }
    },
    tableContainer: {
      marginBottom: 30
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700,
          border: `1px solid rgba(224, 224, 224, 1)`
        }
      }
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    },
    tableBody: {
      '&>tr': {
        '&>td': {
          border: `1px solid rgba(224, 224, 224, 1)`
        }
      }
    },
    advisoryDataDiv: {
      marginTop: 20,
      '&>p': {
        '&>span': {}
      },
      '&>h6': {
        fontWeight: 'bold',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        '&>a': {
          display: 'flex',
          alignItems: 'center',
          '&>svg': {
            marginLeft: 5,
            cursor: 'pointer',
            color: '#008F2B'
          }
        },
        '&>span': {
          width: 15,
          height: 15,
          borderRadius: '50%',
          backgroundColor: 'red',
          marginRight: 5,
          animation: '$blinkDot 1s infinite'
        }
      }
    },
    tableCellLabels: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      marginRight: 5
    },
    '@keyframes blinkDot': {
      '0%': {
        opacity: 1
      },
      '50%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    formDiv: {
      display: 'flex',
      flexDirection: 'column',
      '&>label': {
        marginBottom: 5
      }
    },
    selectBox: {
      height: 40,
      minWidth: 250,
      border: `1px solid rgb(204, 204, 204)`,
      borderRadius: 4,
      '&>div': {
        paddingLeft: 10
      }
    },
    submitButton: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start'
    },
    manualComparisonForm: {
      '&>*:last-child': {
        marginTop: 20
      }
    }
  })
);

export const COAIngredientComparision: FunctionComponent<ICOAIngredientComparisionProps> = ({}) => {
  const classes = useStyle();
  const advisorySecRef: any = useRef(null);
  const { nihLabelDetail, integrantDetailData, documentAiCoaData, nihLabelId }: any = useNIHStore();

  const [ingredients, setIngredients] = useState<any>({
    healthloq: [],
    nih: [],
    coa: []
  });
  const [otherDetails, setOtherDetails] = useState<any>({
    healthloq: [],
    nih: [],
    coa: []
  });
  const [ingredientComparisionResult, setIngredientComparisionResult] = useState<any>({
    matchedIngredients: [],
    unmatchedIngredients: [],
    nihIngredients: [],
    coaIngredients: [],
    selectedNIHIngredient: '',
    selectedCOAIngredient: '',
    error: '',
    selectedIngredients: [],
    manualMatchedIngredients: []
  });

  const handleFilter = () => {
    if (!ingredientComparisionResult.selectedNIHIngredient) {
      setIngredientComparisionResult((pre: any) => ({ ...pre, error: 'Please select NIH Ingredient.' }));
      return;
    } else if (!ingredientComparisionResult.selectedCOAIngredient) {
      setIngredientComparisionResult((pre: any) => ({ ...pre, error: 'Please select COA Ingredient.' }));
      return;
    } else {
      setIngredientComparisionResult((pre: any) => ({
        ...pre,
        selectedIngredients: [
          ...pre.selectedIngredients,
          {
            nihIngredient: pre.selectedNIHIngredient,
            coaIngredient: pre.selectedCOAIngredient
          }
        ],
        manualMatchedIngredients: [
          ...pre.manualMatchedIngredients,
          {
            ...(pre?.nihIngredients
              ?.filter((item: any) => item?.name === pre.selectedNIHIngredient)
              ?.map((item: any) => ({ ...item, nihName: item?.name }))?.[0] || {}),
            ...(pre?.coaIngredients
              ?.filter((item: any) => item?.name === pre.selectedCOAIngredient)
              ?.map((item: any) => ({ ...item, coaName: item?.name }))?.[0] || {})
          }
        ],
        selectedNIHIngredient: '',
        selectedCOAIngredient: ''
      }));
    }
  };

  useEffect(() => {
    if (integrantDetailData?.data?.sourceLinks?.length) {
      setIngredients((pre: any) => ({
        ...pre,
        healthloq: integrantDetailData?.data?.sourceLinks
          ?.map((item: any) => [item?.sourceInfo?.title, ...item?.nodes?.map((node: any) => node?.sourceInfo?.title)])
          ?.flatMap((item: any) => item)
      }));
    }
    if (integrantDetailData?.data?.integrantInfo?.facets) {
      const facets = JSON.parse(integrantDetailData?.data?.integrantInfo?.facets);
      if (facets?.length) {
        setOtherDetails((pre: any) => ({
          ...pre,
          healthloq: facets?.map((facet: any) => ({
            key: facet?.title,
            value: facet?.description
          }))
        }));
      }
    }
  }, [integrantDetailData]);

  useEffect(() => {
    let arr: any = [];
    if (nihLabelDetail?.data?.ingredientRows?.length) {
      arr = [
        ...arr,
        ...nihLabelDetail?.data?.ingredientRows?.map(
          (item: any) =>
            `${item?.name} ${
              item?.forms?.length
                ? `(${item?.forms?.reduce(
                    (str: any, form: any, i: number, arr: any) =>
                      `${str}${form?.name}${i !== arr?.length - 1 ? ', ' : ''}`,
                    ''
                  )})`
                : ''
            }`
        )
      ];
    }
    if (nihLabelDetail?.data?.otheringredients?.ingredients?.length) {
      arr = [
        ...arr,
        ...nihLabelDetail?.data?.otheringredients?.ingredients?.map(
          (item: any) =>
            `${item?.name} ${
              item?.forms?.length
                ? `(${item?.forms?.reduce(
                    (str: any, form: any, i: number, arr: any) =>
                      `${str}${form?.name}${i !== arr?.length - 1 ? ', ' : ''}`,
                    ''
                  )})`
                : ''
            }`
        )
      ];
    }
    if (arr?.length) {
      setIngredients((pre: any) => ({ ...pre, nih: arr }));
    }
    setOtherDetails((pre: any) => ({
      ...pre,
      nih: [
        {
          key: 'General Information',
          value: ''
        },
        {
          key: 'Product Name',
          value: nihLabelDetail?.data?.fullName
        },
        {
          key: 'Brand',
          value: nihLabelDetail?.data?.brandName
        },
        {
          key: 'Bar Code',
          value: nihLabelDetail?.data?.upcSku
        },
        {
          key: 'Net Contents',
          value: nihLabelDetail?.data?.netContents?.reduce(
            (str: any, item: any, i: number, arr: any) => `${str}${item?.display}${i !== arr?.length - 1 ? ', ' : ''}`,
            ''
          )
        },
        {
          key: 'Market Status',
          value: nihLabelDetail?.data?.offMarket ? 'Off Market' : 'On Market'
        },
        ...(nihLabelDetail?.data?.events?.map((event: any) => ({
          key: event?.type,
          value: event?.date
        })) || []),
        {
          key: 'DSLD ID',
          value: nihLabelDetail?.data?.id
        },
        {},
        {
          key: 'Product Classification'
        },
        {
          key: 'Product Type',
          value: nihLabelDetail?.data?.productType?.langualCodeDescription
        },
        {
          key: 'Supplement Form',
          value: nihLabelDetail?.data?.physicalState?.langualCodeDescription
        },
        {
          key: 'Dietary Claim(s) or Use(s)',
          value: nihLabelDetail?.data?.claims?.reduce(
            (str: any, item: any, i: number, arr: any) =>
              `${str}${item?.langualCodeDescription}${i !== arr?.length - 1 ? ', ' : ''}`,
            ''
          )
        },
        {
          key: 'Intended Target Group(s)',
          value: nihLabelDetail?.data?.targetGroups?.reduce(
            (str: any, item: any, i: number, arr: any) => `${str}${item}${i !== arr?.length - 1 ? ', ' : ''}`,
            ''
          )
        },
        {},
        {
          key: 'Supplement Facts'
        },
        {
          key: 'Daily Value (DV) Target Group(s)',
          value: nihLabelDetail?.data?.userGroups?.reduce(
            (str: any, item: any, i: number, arr: any) =>
              `${str}${item?.dailyValueTargetGroupName}${i !== arr?.length - 1 ? ', ' : ''}`,
            ''
          )
        },
        {
          key: 'Serving Size',
          value: nihLabelDetail?.data?.servingSizes?.reduce(
            (str: any, item: any, i: number, arr: any) =>
              `${str}${item?.order} ${item?.unit}${i !== arr?.length - 1 ? ', ' : ''}`,
            ''
          )
        },
        {
          key: 'Alternate Serving Size',
          value: '-'
        },
        {
          key: 'Servings per Container',
          value: nihLabelDetail?.data?.servingsPerContainer
        },
        {},
        {
          key: 'Label Statements'
        },
        ...(nihLabelDetail?.data?.statements?.map((statement: any) => ({
          key: statement.type,
          value: statement?.notes
        })) || []),
        {},
        {
          key: 'Manufacturer'
        },
        ...(nihLabelDetail?.data?.contacts?.map((contact: any) => ({
          key: contact?.text,
          value: `${contact?.contactDetails?.name}, ${contact?.contactDetails?.streetAddress}, ${contact?.contactDetails?.city}, ${contact?.contactDetails?.state}, ${contact?.contactDetails?.zipCode}, ${contact?.contactDetails?.phoneNumber}, ${contact?.contactDetails?.email}`
        })) || [])
      ]
    }));
  }, [nihLabelDetail, nihLabelId]);

  useEffect(() => {
    setIngredients((pre: any) => ({
      ...pre,
      coa: documentAiCoaData?.data?.pages
        ?.map((page: any) =>
          page?.tables?.map((tabel: any) => tabel?.rows?.map((row: any) => row[0]))?.flatMap((item: any) => item)
        )
        ?.flatMap((item: any) => item)
        ?.filter((item: any) => Boolean(item))
    }));
    setOtherDetails((pre: any) => ({
      ...pre,
      coa: documentAiCoaData?.data?.pages?.map((page: any) => page?.formFields)?.flatMap((item: any) => item)
    }));
  }, [documentAiCoaData]);

  const getIngredientAdvisoryData = (ingredient: string) => {
    let arr: any =
      advisoryList
        ?.filter((item: any) => {
          for (let str of [...item?.otherNames, item?.name]) {
            if (str?.trim()?.toLowerCase() === ingredient?.trim()?.toLowerCase()) {
              return true;
            }
          }
          return false;
        })
        ?.map((item: any) =>
          [...item?.otherNames, item?.name]?.filter(
            (str: string) => str?.trim()?.toLowerCase() !== ingredient?.trim()?.toLowerCase()
          )
        )?.[0] || [];
    if (!arr?.length) return null;
    return (
      <div className={classes.advisoryDataDiv} ref={advisorySecRef}>
        <Typography variant="h6">
          <span></span>
          <mark>Dietary Supplement Ingredient Advisory List For {ingredient}</mark>
          <a
            href="https://www.fda.gov/food/dietary-supplement-products-ingredients/dietary-supplement-ingredient-advisory-list"
            target={'_blank'}
            title="View more about Dietary Supplement Ingredient Advisory List"
          >
            <LinkIcon />
          </a>
        </Typography>
        <Typography variant="body2">
          <span>{ingredient}&nbsp;</span>
          also known as:
        </Typography>
        <ul>
          {arr?.map((str: string, i: number) => {
            return <li key={i}>{str}</li>;
          })}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    if (advisorySecRef.current !== null && documentAiCoaData?.data) {
      advisorySecRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [advisorySecRef, documentAiCoaData]);

  useEffect(() => {
    if (documentAiCoaData?.data && nihLabelDetail?.data) {
      let coaIngredientsArr: any = documentAiCoaData?.data?.pages
        ?.map((page: any) =>
          page?.tables
            ?.map((table: any) =>
              table?.rows
                ?.map((row: any) => ({
                  name: row[0],
                  perCoa: row[2],
                  coaQuantity: Boolean(row?.[2]?.replace(/\D/g, '')) ? parseInt(row?.[2]?.replace(/\D/g, '')) : null
                }))
                ?.flatMap((item: any) => item)
            )
            ?.flatMap((item: any) => item)
        )
        ?.flatMap((item: any) => item);
      let nihIngredientsArr: any = [];
      if (nihLabelDetail?.data?.ingredientRows?.length) {
        nihIngredientsArr = nihIngredientsArr.concat(
          nihLabelDetail?.data?.ingredientRows?.map((item: any) => ({
            name: item?.name,
            perLabel: Boolean(item?.quantity?.[0]?.quantity)
              ? `${item?.quantity?.[0]?.quantity} ${item?.quantity?.[0]?.unit}`
              : '-',
            nihQuantity: item?.quantity?.[0]?.quantity || null
          }))
        );
      }
      if (nihLabelDetail?.data?.otheringredients?.ingredients?.length) {
        nihIngredientsArr = nihIngredientsArr.concat(
          nihLabelDetail?.data?.otheringredients?.ingredients?.map((item: any) => ({
            name: item?.name,
            perLabel: Boolean(item?.quantity?.[0]?.quantity)
              ? `${item?.quantity?.[0]?.quantity} ${item?.quantity?.[0]?.unit}`
              : '-'
          }))
        );
      }
      let matchedIngredientsArr: any = [];
      let unmatchedIngredientsArr: any = [];
      nihIngredientsArr?.forEach((item: any) => {
        let matchedData =
          coaIngredientsArr?.filter(
            (el: any) => el?.name?.trim()?.toLowerCase() === item?.name?.trim()?.toLowerCase()
          )?.[0] || null;
        if (matchedData) {
          matchedIngredientsArr.push({
            ...item,
            perCoa: matchedData?.perCoa,
            coaQuantity: matchedData?.coaQuantity,
            result:
              matchedData?.coaQuantity && item?.nihQuantity
                ? matchedData?.coaQuantity >= item?.nihQuantity
                  ? 'PASS'
                  : 'FAIL'
                : '-'
          });
        } else {
          unmatchedIngredientsArr.push({
            ...item,
            perCoa: '-'
          });
        }
      });
      coaIngredientsArr?.forEach((el: any) => {
        let arr = [
          ...matchedIngredientsArr?.map((item: any) => item?.name?.trim()?.toLowerCase()),
          ...unmatchedIngredientsArr?.map((item: any) => item?.name?.trim()?.toLowerCase())
        ];
        if (!arr?.includes(el?.name?.trim()?.toLowerCase())) {
          unmatchedIngredientsArr.push({
            ...el,
            perLabel: '-'
          });
        }
      });
      setIngredientComparisionResult({
        nihIngredients: nihIngredientsArr,
        coaIngredients: coaIngredientsArr,
        matchedIngredients: matchedIngredientsArr,
        unmatchedIngredients: unmatchedIngredientsArr,
        selectedCOAIngredient: '',
        selectedNIHIngredients: '',
        error: '',
        selectedIngredients: [],
        manualMatchedIngredients: []
      });
    }
  }, [documentAiCoaData, nihLabelDetail, nihLabelId]);

  if (!integrantDetailData?.data?.integrantInfo?.title || !nihLabelDetail?.data?.fullName || !documentAiCoaData?.data) {
    return <></>;
  }
  return (
    <div className={classes.coaIngredientComparisionContainer}>
      <div>
        <Typography variant="h6">Product Comparision Between Our Product With NIH Product</Typography>
        <Grid container spacing={2} className={classes.productComparision}>
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">{integrantDetailData?.data?.integrantInfo?.title} (From HealthLOQ)</Typography>
            <div>
              <img alt="" src={integrantDetailData?.data?.integrantInfo?.integrantsType?.image_url} />
            </div>
            <Typography variant="body1">
              <span>Brand:</span>
              {integrantDetailData?.data?.integrantInfo?.organizations?.name}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body1">{nihLabelDetail?.data?.fullName} (From NIH)</Typography>
            <div>
              <img
                alt=""
                src={`https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/thumbnails/${nihLabelDetail?.data?.id}.jpg`}
              />
            </div>
            <Typography variant="body1">
              <span>Brand:</span>
              {nihLabelDetail?.data?.brandName}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h6">Ingredients Comparision</Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">HealthLOQ Ingredients</TableCell>
                <TableCell align="left">COA Ingredients</TableCell>
                <TableCell align="left">NIH Ingredients</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {[
                ...Array(
                  Math.max(
                    ingredients.coa?.length || 0,
                    ingredients.nih?.length || 0,
                    ingredients.healthloq?.length || 0
                  )
                )
              ]?.map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{ingredients?.healthloq?.[index]}</TableCell>
                    <TableCell>{ingredients?.coa?.[index]}</TableCell>
                    <TableCell>{ingredients?.nih?.[index]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.ingredientComparisionResult}>
        <Typography variant="h6">Matched Ingredients</Typography>
        <FormHelperText>* Amount per COA must be equal to or greater for a PASS</FormHelperText>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2} align="center">
                  Amount Per Serving
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ingredient Name</TableCell>
                <TableCell>Per Label</TableCell>
                <TableCell>Per COA</TableCell>
                <TableCell>Result*</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {ingredientComparisionResult?.matchedIngredients?.length ? (
                ingredientComparisionResult?.matchedIngredients?.map((item: any, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.perLabel}</TableCell>
                      <TableCell>{item?.perCoa}</TableCell>
                      <TableCell>{item?.result}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    We could not find any matching ingredients between the COA and NIH ingredients.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <Typography variant="h6">Unmatched Ingredients</Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={2} align="center">
                  Amount Per Serving
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ingredient Name</TableCell>
                <TableCell>Per Label</TableCell>
                <TableCell>Per COA</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {ingredientComparisionResult?.unmatchedIngredients?.length ? (
                ingredientComparisionResult?.unmatchedIngredients?.map((item: any, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.perLabel}</TableCell>
                      <TableCell>{item?.perCoa}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    We could not find any unmatching ingredients between the COA and NIH ingredients.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <Typography variant="h6">Manual comparison between NIH and COA Ingredients</Typography>
        <div className={classes.manualComparisonForm}>
          <Grid container spacing={2}>
            <Grid item sm={5} xs={12}>
              <div className={classes.formDiv}>
                <FormLabel required htmlFor="nih-ingredient-select-box">
                  Select NIH Ingredient
                </FormLabel>
                <Select
                  id="nih-ingredient-select-box"
                  value={ingredientComparisionResult.selectedNIHIngredient}
                  disableUnderline
                  className={classes.selectBox}
                >
                  <MenuItem value="" disabled>
                    Select NIH Ingredient
                  </MenuItem>
                  {ingredientComparisionResult?.nihIngredients
                    ?.filter(
                      (item: any) =>
                        !ingredientComparisionResult?.selectedIngredients
                          ?.map((el: any) => el?.nihIngredient)
                          .includes(item?.name)
                    )
                    ?.map((item: any, i: number) => {
                      return (
                        <MenuItem
                          value={item?.name}
                          key={i}
                          onClick={e => {
                            setIngredientComparisionResult((pre: any) => ({
                              ...pre,
                              selectedNIHIngredient: item?.name,
                              error: ''
                            }));
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid>
            <Grid item sm={5} xs={12}>
              <div className={classes.formDiv}>
                <FormLabel required htmlFor="coa-ingredient-select-box">
                  Select COA Ingredient
                </FormLabel>
                <Select
                  id="coa-ingredient-select-box"
                  value={ingredientComparisionResult.selectedCOAIngredient}
                  disableUnderline
                  className={classes.selectBox}
                >
                  <MenuItem value="" disabled>
                    Select COA Ingredient
                  </MenuItem>
                  {ingredientComparisionResult?.coaIngredients
                    ?.filter(
                      (item: any) =>
                        !ingredientComparisionResult?.selectedIngredients
                          ?.map((el: any) => el?.coaIngredient)
                          .includes(item?.name)
                    )
                    ?.map((item: any, i: number) => {
                      return (
                        <MenuItem
                          value={item?.name}
                          key={i}
                          onClick={e => {
                            setIngredientComparisionResult((pre: any) => ({
                              ...pre,
                              selectedCOAIngredient: item?.name,
                              error: ''
                            }));
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className={classes.submitButton}>
                <PrimaryButton children={'Save'} onClick={handleFilter} maxContent secondaryGreenButton />
              </div>
            </Grid>
          </Grid>
          {Boolean(ingredientComparisionResult?.error) && (
            <FormHelperText error children={ingredientComparisionResult?.error} />
          )}
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell colSpan={2} align="center">
                    Amount Per Serving
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NIH Ingredient Name</TableCell>
                  <TableCell>COA Ingredient Name</TableCell>
                  <TableCell>Per Label</TableCell>
                  <TableCell>Per COA</TableCell>
                  <TableCell>Result*</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {ingredientComparisionResult?.manualMatchedIngredients?.length ? (
                  ingredientComparisionResult?.manualMatchedIngredients?.map((item: any, key: number) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{item?.nihName}</TableCell>
                        <TableCell>{item?.coaName}</TableCell>
                        <TableCell>{item?.perLabel}</TableCell>
                        <TableCell>{item?.perCoa}</TableCell>
                        <TableCell>
                          {item?.coaQuantity && item?.nihQuantity
                            ? item?.coaQuantity >= item?.nihQuantity
                              ? 'PASS'
                              : 'FAIL'
                            : '-'}
                        </TableCell>
                        <TableCell align="center">
                          <MuiIconButton
                            icon="delete"
                            onClick={() => {
                              setIngredientComparisionResult((pre: any) => ({
                                ...pre,
                                manualMatchedIngredients: pre?.manualMatchedIngredients?.filter(
                                  (el: any) => el?.nihName !== item?.nihName
                                ),
                                selectedIngredients: pre?.selectedIngredients?.filter(
                                  (el: any) => el?.nihIngredient !== item?.nihName
                                )
                              }));
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      We could not find any matching ingredients between your selected COA and NIH ingredients.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div>
        <Typography variant="h6">COA Ingredients Detail Analysis</Typography>
        {documentAiCoaData?.data?.pages?.map((page: any, key: number) => {
          return page?.tables?.map((table: any, tableKey: number) => {
            return (
              <TableContainer component={Paper} className={classes.tableContainer} key={tableKey}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      {table?.columns?.map((column: any, columnKey: number) => {
                        return <TableCell key={columnKey}>{column}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {table?.rows?.map((row: any, rowKey: number) => {
                      return (
                        <TableRow key={rowKey}>
                          {row?.map((str: any, strKey: number) => {
                            return (
                              <TableCell key={strKey}>
                                {str}
                                {strKey === 0 && getIngredientAdvisoryData(str)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          });
        })}
      </div>
      <div>
        <Typography variant="h6">NIH Ingredients Detail Analysis</Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Ingredient</TableCell>
                <TableCell>Amount per Serving</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {nihLabelDetail?.data?.ingredientRows?.map((item: any, key: number) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      {`${item?.name} ${
                        item?.forms?.length
                          ? `(${item?.forms?.reduce(
                              (str: any, form: any, i: number, arr: any) =>
                                `${str}${form?.name}${i !== arr?.length - 1 ? ', ' : ''}`,
                              ''
                            )})`
                          : ''
                      }`}
                      {getIngredientAdvisoryData(item?.name)}
                    </TableCell>
                    <TableCell>{`${item?.quantity?.[0]?.quantity} ${item?.quantity?.[0]?.unit}`}</TableCell>
                  </TableRow>
                );
              })}
              {nihLabelDetail?.data?.otheringredients?.ingredients?.map((item: any, key: number) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      {`${item?.name} ${
                        item?.forms?.length
                          ? `(${item?.forms?.reduce(
                              (str: any, form: any, i: number, arr: any) =>
                                `${str}${form?.name}${i !== arr?.length - 1 ? ', ' : ''}`,
                              ''
                            )})`
                          : ''
                      }`}
                      {getIngredientAdvisoryData(item?.name)}
                    </TableCell>
                    <TableCell>
                      {Boolean(item?.quantity?.length) &&
                        `${item?.quantity?.[0]?.quantity} ${item?.quantity?.[0]?.unit}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <Typography variant="h6">Other Details</Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">HealthLOQ Other Details</TableCell>
                <TableCell align="left">COA Other Details</TableCell>
                <TableCell align="left">NIH Other Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {[
                ...Array(
                  Math.max(
                    otherDetails.coa?.length || 0,
                    otherDetails.nih?.length || 0,
                    otherDetails.healthloq?.length || 0
                  )
                )
              ]?.map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell width={'25%'}>
                      <span className={classes.tableCellLabels}>
                        {otherDetails?.healthloq?.[index]?.key?.toLowerCase()}
                        {otherDetails?.healthloq?.[index] && ':'}
                      </span>
                      {otherDetails?.healthloq?.[index]?.value}
                    </TableCell>
                    <TableCell width={'25%'}>
                      <span className={classes.tableCellLabels}>
                        {otherDetails?.coa?.[index]?.key?.toLowerCase()}
                        {otherDetails?.coa?.[index] && !otherDetails?.coa?.[index]?.key?.includes(':') && ':'}
                      </span>
                      {otherDetails?.coa?.[index]?.value}
                    </TableCell>
                    <TableCell width={'50%'}>
                      <span className={classes.tableCellLabels}>
                        {otherDetails?.nih?.[index]?.key?.toLowerCase()}
                        {Boolean(otherDetails?.nih?.[index]?.value) && ':'}
                      </span>
                      {otherDetails?.nih?.[index]?.value}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default COAIngredientComparision;
