import { IIntegrantDTO } from '../../lib/integrant';
import React, { CSSProperties, FunctionComponent } from 'react';
import { GiTruck, GiHouse } from 'react-icons/gi';
import { GoEyeClosed } from 'react-icons/go';
import UseAnimations from 'react-useanimations';
import { Img } from '../common';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { graphNodeImgStyle, graphNodeWrapperStyle, primaryColor } from '../styles/default';
import { IntegrantKind } from '../types/common';
import { nodeIsKind } from '../utils';

const iconStyle: CSSProperties = {
  padding: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  width: 30
};

const graphTitle: CSSProperties = { fontSize: 10, whiteSpace: 'nowrap' };

export type IIntegrantNode = IIntegrantDTO & { x: number; y: number; edge_type: IntegrantKind };

const GraphNode: FunctionComponent<{ node: IIntegrantNode }> = ({ node }) => {
  const { isDraft } = useCreateBatchStore();

  const { currentIntegrant, setCurrentIntegrant } = useIntegrantGraphStore();

  const { onChangeIndex } = useIntegrantPaneStore();

  const handleLayerSelect = (node: IIntegrantNode): void => {
    onChangeIndex(0);
    //setCurrentIntegrant(node);
  };

  const integrantNodeStyle = (node: IIntegrantDTO): CSSProperties => {
    return node.id === currentIntegrant.id
      ? { ...graphNodeWrapperStyle, backgroundColor: primaryColor }
      : graphNodeWrapperStyle;
  };

  //{ ...graphNodeWrapperStyle, opacity: 0.3, pointerEvents: 'none' }
  //graphNodeWrapperStyle;


  const isTransit = nodeIsKind(node, IntegrantKind.Transit);
  const isTransfer = nodeIsKind(node, IntegrantKind.Transfer);
  const isPrimary = node.primary;
  const nodeKindStyle: CSSProperties = { ...iconStyle, fontSize: 30 };
  const graphNodeDimensions = 60;

  return (
    <>
      <div
        onClick={(): void | null => (!isDraft ? handleLayerSelect(node) : null)}
        style={{ ...integrantNodeStyle(node), cursor: 'pointer' }}
      >
        <Img
          onClick={(): void | null => (!isDraft ? handleLayerSelect(node) : null)}
          alt={`${currentIntegrant.title}_node_img`}
          style={{ ...graphNodeImgStyle, width: graphNodeDimensions, height: graphNodeDimensions }}
          src={node.image_thumb_url}
        />
        {isTransit ? <GiTruck color={'white'} style={nodeKindStyle} /> : null}
        {isTransfer ? <GiHouse color={'white'} style={nodeKindStyle} /> : null}
        {/* {!isPrimary ? <GoEyeClosed color={'white'} style={nodeKindStyle} /> : null} */}
        {/* {isDraft ? <UseAnimations play animationKey="activity" size={26} style={iconStyle} /> : null} */}
      </div>
      <small style={graphTitle}>{node.title}</small>
    </>
  );
};

export { GraphNode };
