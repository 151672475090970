import { ILocationDTO } from '../../lib/location';

async function convertAddressToLatLng(address: string): Promise<ILocationDTO['coordinates'] | undefined> {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );

  if (typeof response !== 'undefined') {
    const data = response.json();

    const value = await data;
    const results = value.results;
    if (results.length > 0) {
      const geometry = results[0].geometry;
      const lat: ILocationDTO['coordinates'][0] = geometry.location.lat.toString();
      const lng: ILocationDTO['coordinates'][1] = geometry.location.lng.toString();
      return [lat, lng];
    }
  }
}

export { convertAddressToLatLng };
