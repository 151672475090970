import React, { useEffect, useState } from 'react';
import UseAnimations from 'react-useanimations';
import { Link, useHistory, useLocation } from "react-router-dom";
import { titles, updatedUrl } from 'config';
import { useIndividualSubscriptionDetailStore } from 'hooks/useIndividualSubscriptionDetailStore';
import queryString from 'querystring';
import { useAuthStore } from 'hooks/useAuthStore';

let finalSubDetailUpdateFlag = true;

export const BuyNewSubThankYou = (props: any) => {
    const location = useLocation();
    const query = queryString.parse(location.search);
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const { initBuyNewProduct, buyNewProductDetail, setQuerySearch, querySearch } = useIndividualSubscriptionDetailStore();
    const { individual:{access_token}, setSelectedSubscription } = useAuthStore();
    useEffect(() => {
        if(query?.individualSubId){
            setQuerySearch(query);
            finalSubDetailUpdateFlag = true;
        }
    }, [query?.session_id, query?.individualSubId]);
    useEffect(()=>{
        async function APIDataUpdate(obj: any) {
            if(obj){
                const getTransactionIdUrl = updatedUrl + '/subscription/create-portal-session'
                await fetch(getTransactionIdUrl, {
                    method: 'POST',
                    body: JSON.stringify(obj),
                    headers: new Headers({
                        'Content-type': 'application/json'
                    })
                }).then(response => response.json()).then(async data => {
                    const { subscription } = data
                    await initBuyNewProduct({
                        transaction_id: subscription,
                        status: querySearch?.success || true,
                        id: querySearch?.individualSubId
                    });
                    setPageLoading(false);
                    setQuerySearch("")
                }).catch(async(error) => {
                    await initBuyNewProduct({
                        status: false,
                        id: querySearch?.individualSubId
                    });
                    alert(titles.error);
                    setPageLoading(false)
                    console.error('Error:', error);
                    setQuerySearch("");
                })
            }else{
                await initBuyNewProduct({
                    status: false,
                    id: querySearch?.individualSubId
                });
                setPageLoading(false);
                setQuerySearch("")
            }
        }
        if(Object.keys(querySearch || {})?.length > 0 && access_token && finalSubDetailUpdateFlag){
            if(querySearch?.session_id){
                setPageLoading(true)
                APIDataUpdate({
                    session_id: querySearch?.session_id
                })
                finalSubDetailUpdateFlag = false;
            }else{
                setPageLoading(true)
                APIDataUpdate(false);
                finalSubDetailUpdateFlag = false;
            }
        }
    },[access_token, querySearch])

    useEffect(()=>{
        if(buyNewProductDetail?.status === '1' && !buyNewProductDetail?.data){
            alert(`Something went wrong! Please try after sometime.`);
            history.push("/");
        }else if(buyNewProductDetail?.status === "1" && buyNewProductDetail?.data){
            history.replace({
                search: queryString.stringify({subscription_type: buyNewProductDetail?.data?.subscription_type})
            })
        }
    },[buyNewProductDetail])
    if (pageLoading) {
        return <div className="subscription-checkout-load">
            <UseAnimations animationKey="loading" size={40} />
            <div className="txt-please-wait">Please Wait...</div>
        </div>
    }
  return (
    <div className="thankYou">
        <div>
            <h1>Thank You!</h1>
            {query["?subscription_type"] && (
                <>
                    <p>New Product Purchased Successfully.</p>
                    <div className='d-flex align-items-center justify-content-center'>
                        <Link to={"/"} onClick={() => {
                            setSelectedSubscription(query["?subscription_type"])
                        }} className='btn-subscription'>Go To Dashboard</Link>    
                    </div>
                </>    
            )}
        </div>
    </div>
  )
}