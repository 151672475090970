import { Typography } from '@material-ui/core';
import { useNIHStore } from 'hooks/useNIHStore';
import React, { useEffect } from 'react';
import COAIngredientComparision from './COAIngredientComparision';
import COASelectionForm from './COASelectionForm';

export default function IngredientComparision() {
  const { initGetProductAndBatchesList } = useNIHStore();
  useEffect(() => {
    initGetProductAndBatchesList();
  }, []);
  return (
    <div className="cp">
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <Typography variant="h5">Compare NIH Labels With COA</Typography>
          </div>
        </div>
        <COASelectionForm />
        <COAIngredientComparision />
      </div>
    </div>
  );
}
