import { ILocationDTO } from '../../lib/location';
import React, { FunctionComponent } from 'react';
import { isNull, isEmptyString } from '../utils';

interface IAddressViewProps {
  location: ILocationDTO;
}
const AddressView: FunctionComponent<IAddressViewProps> = ({ location }) => {
  const { line_1, line_2, city, state, zip, country, coordinates, description } = location;
  let updateCoordinates = coordinates
  if (typeof coordinates === 'string') {
    updateCoordinates = JSON.parse(coordinates)
  }
  if (isEmptyString(line_1)) {
    return <div></div>;
  }
  return (
    <div>
      <h5 style={{ marginTop: 10, marginBottom: 0 }}>{description && description + ' '}Address</h5>
      <small>{`${line_1},`}</small>
      {!isNull(line_2) ? <small>{`${line_2},`}</small> : null}
      <small>{`${city}, ${state} ${zip}`}</small>
      <small>{`${country}`}</small>
      <br />
      <small>{`Lat Long (${updateCoordinates[0]}, ${updateCoordinates[1]})`}</small>
    </div>
  );
};

export { AddressView };
