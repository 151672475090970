import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { useIntegrantsService } from '../hooks/api/useIntegrantsService';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';
import { ProductsList } from './ProductsList';
import { IntegrantAlert } from './IntegrantAlert';
import { titlesAlert } from '../config';

const selectProductsStyle: CSSProperties = {
  padding: '20px 10px',
  cursor: 'pointer',
  overflow: 'auto',
  height: '100%',
  maxHeight: 'calc(100vh - 136px)'
};

const SelectProducts: FunctionComponent = () => {
  const { selectedIntegrantType, integrantTypes } = useIntegrantTypeStore();
  const { setIntegrants, setLoading, selectedIntegrant } = useIntegrantsStore();
  const { initialize } = useIntegrantsService();
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    const requestIntegrants = async (): Promise<void> => {
      setLoading(true);
      const integrants = await initialize(selectedIntegrantType.id, selectedIntegrant.id, '');
      if (typeof integrants !== 'undefined') {
        setIntegrants(integrants);
      }
    };
    if (selectedIntegrantType.id !== selectedId) {
      setIntegrants([]);
      requestIntegrants();
      setSelectedId(selectedIntegrantType.id);
    }
  }, [selectedIntegrantType.id, selectedId, initialize, setIntegrants, setLoading, selectedIntegrant.id]);

  return (
    <div style={selectProductsStyle}>
      <ProductsList />
    </div>
  );

  // return integrantTypes.length > 0 ? (
  //   <div style={selectProductsStyle}>
  //     <ProductsList />
  //   </div>
  // ) : (
  //   <IntegrantAlert title={titlesAlert.emptyBatches} />
  // );
};

export { SelectProducts };
