import React, { CSSProperties, FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import UseAnimations from 'react-useanimations';
import { Button } from '../../common/Button';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { useAuthService } from '../../hooks/api/useAuthService';
import { isEmptyString, validateEmail } from '../../utils';
import { useToastStore } from '../../hooks/useToastStore'
import {
  defaultBoxShadow,
  defaultGreyBackgroundColor,
  defaultModalStyle,
  defaultParagraphStyle,
  formButtonStyle
} from '../../styles/default';
import { PrimaryButton } from 'common/Button/buttons';

const labelSpacingStyle: CSSProperties = { marginBottom: 20, display: 'block' };
const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  maxWidth: 600,
  width: "95%",
  padding: 20,
  backgroundColor: 'white'
};
const defaults = { email: '' };
const Recover: FunctionComponent = () => {
  const [credentials, setCredentials] = useState(defaults);
  const { recover } = useAuthService();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { isOpenToast } = useToastStore();
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} style={defaultModalStyle} isOpen>
        <strong style={defaultParagraphStyle}>You will receive an email concerning the password reset.</strong>
        <div style={{ paddingTop: 20 }}>
          <a href="/" style={formButtonStyle}>
            Login
          </a>
        </div>
      </ReactModal>
    ),
    [credentials, isError]
  );

  const handleRecover = async (): Promise<void> => {
    if (isEmptyString(credentials.email)) {
      isOpenToast('warn', "Email cannot be empty.");
    } else if (validateEmail(credentials.email)) {
      isOpenToast('warn', "Please enter a valid email address.");
    } else {
      setLoading(true);
      const responseObj: any = await recover(credentials.email)
      if (typeof responseObj === 'object' && responseObj.statusCode === 400) {
        setError(true);
      } else {
        showModal();
      }
      setLoading(false);
    }
  };

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor, height: 'calc(100vh - 70px)' }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <h2>Reset Password</h2>
        <p>Please enter your email address. We will send you an email with your new password.</p>
        <br />
        <label style={labelSpacingStyle} className="col-md-12">
          Email
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ email: event.currentTarget.value })
            }
            value={credentials.email}
            type="email"
            autoFocus
            placeholder="e.g. Dwight.Schrute@healthloq.com"
          ></Input>
        </label>
        <PrimaryButton loading={isLoading} maxContent={true} secondaryButton={true} icon='edit' children="Reset Password" disabled={isLoading} onClick={handleRecover} style={{ marginBottom: 20 }} />
      </Layer>
    </Layer>
  );
};

export { Recover };