import React, { FunctionComponent } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IToastProps {
  //autoClose: number;
}

const Toast: FunctionComponent<IToastProps> = (props) => {
  return <ToastContainer
    position="top-right"
    //theme="colored"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    style={{ width: '300px' }}
  />
};

export { Toast };
