import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { Layer } from '../.././common/Layer';
import { SelectProducts } from '../../components/SelectProducts';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import { primaryColor, primaryButtonStyle } from '../../styles/default';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../../src/common';
import { titles } from '../../../src/config';
import { useCreateIntegrantTypeStore } from '../../hooks/useCreateIntegrantTypeStore';
import { isEmptyString } from '../../utils';
import { useTheme, useMediaQuery, Dialog } from '@material-ui/core';
import LineChart from 'components/LineChart';
import { useIntegrantTypesService } from 'hooks/api/useIntegrantTypesService';
import { useAuthStore } from 'hooks/useAuthStore';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { MuiSelect } from 'common/formComponents';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const linkStyle: CSSProperties = { textDecoration: 'none', color: primaryColor };

let getIntegrantTypeFlag = false;

const SelectIntegrantTypeSlide: FunctionComponent = () => {
  const location = useLocation();
  const { selectedIntegrantType, initIntegrantData } = useIntegrantTypeStore();
  const {
    isOpen,
    setOpen,
    onProductUpdateStoreInitialData,
    setOpenSurveyQuestion,
    openSurveyQuestion
  } = useCreateIntegrantTypeStore();
  const hasSelectedIntegrantTypeId = !isEmptyString(selectedIntegrantType.id);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));

  useEffect(() => {
    const { pathname } = location;
    const path = pathname.split('/')[1];
    if (['create-new-product', 'update-product'].includes(path)) {
      setOpen(true);
      if (path === 'update-product') {
        initIntegrantData();
        getIntegrantTypeFlag = true;
      }
    }
    if (['survey-questions'].includes(path)) {
      setOpenSurveyQuestion(true);
    }
  }, [location]);
  useEffect(() => {
    if (getIntegrantTypeFlag && selectedIntegrantType?.id !== '') {
      getIntegrantTypeFlag = false;
      onProductUpdateStoreInitialData(selectedIntegrantType);
    }
  }, [selectedIntegrantType]);

  return (
    <Layer
      direction="column"
      style={{ paddingTop: 20 }}
      width={hasSelectedIntegrantTypeId || isOpen || openSurveyQuestion ? (match ? '100%' : '50%') : '100%'}
    >
      <div className="home-page-heading-search-div">
        {/* <Button
          width={180}
          style={{ ...primaryButtonStyle, borderRadius: 10 }}
          onClick={(): void => setOpen(!isOpen)}
        >
          Create New {titles.integrantType}
        </Button> */}
        {/* <Link style={{ width: 288, marginBottom: 10, ...linkStyle }} to={'transferred'}>
          View {titles.trackingPlural} from other {titles.organization}
        </Link> */}
        <p className="page-title prod-list">Products</p>
        <SelectProducts />
      </div>
    </Layer>
  );
};

export { SelectIntegrantTypeSlide };
