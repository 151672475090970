import React, { FunctionComponent, CSSProperties } from 'react';
import UseAnimations from 'react-useanimations';
import { Layer } from '../common';
import { titleStyle } from './ProductListItem';
import { defaultBoxShadow } from '../styles/default';

interface IIntegrantAlertProps {
  title: string;
}
const alertWrapperStyle: CSSProperties = {
  paddingRight: 10,
  paddingLeft: 10,
  background: '#fdfdfd',
  borderRadius: 30,
  boxShadow: defaultBoxShadow
};

const IntegrantAlert: FunctionComponent<IIntegrantAlertProps> = ({ title }) => {
  return typeof title == 'undefined' || title !== '' ? (
    <Layer className='integrantAlert' direction="column" center style={{ padding: 20 }}>
      <Layer style={alertWrapperStyle}>
        <UseAnimations play animationKey="alertCircle" size={30} />
        <small style={{ ...titleStyle, padding: 10 }}>{title}</small>
      </Layer>
    </Layer>
  ) : null;
};

export { IntegrantAlert };
