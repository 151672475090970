import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import { IAPIResponseDTO } from '../../../lib/common';
import { IGetFilterUsersCount, IGetMyConnections } from '../../../lib/user';
import axiosInstance from './../../utils/api-interceptor';

interface IUserService {
  getUserByIdAPI: (user_id: string) => Promise<IAPIResponseDTO>;
  getUsersFilterCount: (data: IGetFilterUsersCount) => Promise<IAPIResponseDTO>;
  sendSurveyInvitation: (params: any) => Promise<IAPIResponseDTO>;
  getMyConnnectionService: (params: IGetMyConnections) => Promise<IAPIResponseDTO>;
  verifyDomainAPI: () => Promise<IAPIResponseDTO>;
}

const useUserService = (): IUserService => {
  const { individual } = useAuthStore();

  const getUserByIdAPI: IUserService['getUserByIdAPI'] = async user_id => {
    try {
      return await axiosInstance.post('/user/detail', { user_id, type: 'producer' });
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  const getUsersFilterCount: IUserService['getUsersFilterCount'] = async data => {
    try {
      return await axiosInstance.post('/user/get-filter-users-count', data, formAxiosHeader(individual.access_token));
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  const sendSurveyInvitation: IUserService['sendSurveyInvitation'] = async params => {
    try {
      return await axiosInstance.post('/user/send-survey-invitation', params, formAxiosHeader(individual.access_token));
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  const verifyDomainAPI: IUserService['verifyDomainAPI'] = async (): Promise<any> => {
    try {
      return await axiosInstance.post('/organization/verify-domain', {}, formAxiosHeader(individual.access_token));
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  const getMyConnnectionService: IUserService['getMyConnnectionService'] = async (
    params: IGetMyConnections
  ): Promise<IAPIResponseDTO> => {
    try {
      return await axiosInstance.post(
        '/producer-connections/get-my-connections',
        params,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  return { getUserByIdAPI, getUsersFilterCount, sendSurveyInvitation, getMyConnnectionService, verifyDomainAPI };
};

export { useUserService };
