import { IExhibitDTO, ICreateExhibitDTO } from '../../../lib/exhibit';
import { useCallback } from 'react';
import { IIntegrantDTO } from '../../../lib/integrant';
import { formDataConfig, url, requestDataConfig, formAxiosHeader } from '../../config';
import { useAuthStore } from '../useAuthStore';
import { useOrganizationStore } from '../useOrganizationStore';
import { useIntegrantGraphStore } from '../useIntegrantGraphStore';
import useFetch from 'use-http';
import axiosInstance from './../../utils/api-interceptor';

interface IExhibitsService {
  postExhibit: (integrant_id: IIntegrantDTO['id'], file: File, exhibit: ICreateExhibitDTO) => Promise<any>;
  createTestingLabApi: (data: any) => Promise<any>;
  getTestingLabsApi: () => Promise<any>;
}

const useExhibitsService = (): IExhibitsService => {
  const { individual } = useAuthStore();
  const postExhibit: IExhibitsService['postExhibit'] = useCallback(
    async (integrant_id, file, exhibit) => {
      const formDataImage = new FormData();
      formDataImage.append('files[]', file);
      formDataImage.append('organization_id', individual.organization_id);
      return await axiosInstance
        .post('integrant/upload', formDataImage, formAxiosHeader(individual.access_token))
        .then((res: any) => {
          return res[0];
        })
        .catch(err => {
          return err;
        });
      // const responseImage = await fetch(
      //   `${url}integrant/upload`,
      //   formDataConfig(formDataImage, individual.access_token)
      // ).then(async response => await response.json());
      // return responseImage[0];
    },
    [individual.access_token]
  );

  const createTestingLabApi: IExhibitsService['createTestingLabApi'] = async (data: any) => {
    return await axiosInstance.post(`testing-labs/create`, data, formAxiosHeader(individual.access_token));
  };

  const getTestingLabsApi: IExhibitsService['getTestingLabsApi'] = async () => {
    return await axiosInstance.post(`testing-labs/getAll`, {}, formAxiosHeader(individual.access_token));
  };

  return {
    postExhibit,
    createTestingLabApi,
    getTestingLabsApi
  };
};

export { useExhibitsService };
