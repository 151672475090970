import React, { useState } from 'react';
import { CreateContext } from '../types/common';

export interface ICreateBatchState {
  isLoading: boolean;
  isDraftAlertVisible: boolean;
  isDraft: boolean;
  batchValue: boolean;
}

const defaults = { isLoading: false, isDraftAlertVisible: true, isDraft: true, batchValue: false };

const CreateBatchContext = React.createContext<CreateContext<ICreateBatchState>>([
  defaults,
  (): ICreateBatchState => defaults
]);

const CreateBatchProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ICreateBatchState>(defaults);

  return <CreateBatchContext.Provider value={[state, setState]}>{props.children}</CreateBatchContext.Provider>;
};

export { CreateBatchContext, CreateBatchProvider };
