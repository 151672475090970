import React, { useState } from 'react';
import { Input } from '../../common/Input';
import { isEmptyString, validateEmail, validatePhoneNo } from './../../utils';

import { acceptedIMGFileTypes, titles } from './../../config';
import { GoFile } from 'react-icons/go';

const OrganizationInformation = (props: any) => {
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const {
    organization_ref_id,
    handleSubmit,
    inputsHandler,
    inputField: { organization_name, domain_name, fileName, description, tag_line, logo_url },
    isRequiredField
  } = props;
  let isPreRegistration = true;
  if (
    !isEmptyString(domain_name) &&
    !isEmptyString(organization_name) &&
    !isEmptyString(description) &&
    !isEmptyString(tag_line) &&
    (!isEmptyString(logo_url) || (fileName !== null && !isEmptyString(fileName)))
  ) {
    isPreRegistration = false;
  }
  const fileListRender = () => {
    if (fileName !== null && !isEmptyString(fileName)) {
      return Array.from(fileName).map((file: any, index) => {
        return (
          <span className="list-filename" key={index}>
            {file.name}
          </span>
        );
      });
    } else if (!isEmptyString(logo_url)) {
      return <span className="list-filename">{logo_url}</span>;
    }
  };
  return (
    <div className="section-pre-registration-form">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Organization Name<span>*</span>
            </label>
            <Input
              value={organization_name}
              disabled={organization_ref_id !== ''}
              name="organization_name"
              required={isSubmitForm && isEmptyString(organization_name)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(organization_name) && <p className="error-message">Please enter a value</p>}
          </div>
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Tag Line<span>*</span>
            </label>
            <Input
              value={tag_line}
              name="tag_line"
              required={isSubmitForm && isEmptyString(tag_line)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(tag_line) && <p className="error-message">Please enter a value.</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <label className="col-form-label">
              Description<span>*</span>
            </label>
            <Input
              value={description}
              name="description"
              required={isSubmitForm && isEmptyString(description)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(description) && <p className="error-message">Please enter a value</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Domain Name<span>*</span>
            </label>
            <Input
              value={domain_name}
              name="domain_name"
              required={isSubmitForm && isEmptyString(domain_name)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(domain_name) && <p className="error-message">Please enter a value</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <label className="col-form-label">
              Logo<span>*</span>
            </label>
            <div className="d-flex align-items-center">
              <div className="btn-browse">
                <div className="text-btn-browse">Browse</div>
                <input accept={acceptedIMGFileTypes} onChange={inputsHandler} type="file" id="file" name="fileName" />
              </div>
              {fileListRender()}
            </div>
          </div>
        </div>
        <div>
          <button
            disabled={!organization_name || !tag_line || !description || !domain_name || !(logo_url || fileName)}
            onClick={() => {
              setIsSubmitForm(true);
              if (!isPreRegistration) handleSubmit();
            }}
            className="btn_registration_submit"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default OrganizationInformation;
