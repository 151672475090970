import { IOrganizationDTO } from '../../../lib/organization';
import { IIntegrantDTO } from '../../../lib/integrant';
import { useCallback } from 'react';
import useFetch from 'use-http';
import { url, formDataConfigType } from '../../config';
import { useAuthStore } from './../../hooks/useAuthStore';

interface IOrganizationsService {
  isLoading: boolean;
  getAll: () => Promise<IOrganizationDTO[]>;
  getTransferredIntegrants: (organizationId: string) => Promise<IIntegrantDTO[]>;
}

const useOrganizationsService = (): IOrganizationsService => {
  const { individual } = useAuthStore();
  const integrantsURL = `${url}organization`;
  const [request, , isLoading] = useFetch(integrantsURL);

  const getAll: IOrganizationsService['getAll'] = useCallback(async () => {
    const formData = {
      offset: 0,
      limit: null,
      name: ""
    };
    const organizations: IOrganizationDTO[] = await fetch(
      `${url}organization/search`,
      formDataConfigType(JSON.stringify(formData), individual.access_token)
    ).then(async response => await response.json());
    //const organizations: IOrganizationDTO[] = await request.get('/search');
    return organizations;
  }, [request]);

  const getTransferredIntegrants: IOrganizationsService['getTransferredIntegrants'] = useCallback(
    async (organizationId: string) => {
      const transferredIntegrants: IIntegrantDTO[] = await request.get(`/${organizationId}/transferred-integrants`);
      return transferredIntegrants;
    },
    [request]
  );

  return { isLoading, getAll, getTransferredIntegrants };
};

export { useOrganizationsService };
