import React from 'react';
import {
  makeStyles,
  Theme,
  Box,
  createStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Divider
} from '@material-ui/core';
import { Layer } from 'common';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    organizationVerificationSteps: {
      marginTop: 20,
      '&>div': {
        '&>p': {
          '&>span': {
            color: '#546178'
          }
        },
        margin: '10px 0'
      }
    },
    checkbox: {
      color: '#008F2B !important'
    }
  })
);

export default function OrganizationVerification(props: any) {
  const classes = useStyle();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));
  return (
    <Layer
      direction="column"
      style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0' }}
      width={match ? '100%' : '50%'}
    >
      <Box display="flex" flexDirection={'column'} width={'100%'}>
        <Typography variant="h5">Organization's approval is pending from admin side</Typography>
        <Divider />
        <Box display={'flex'} flexDirection="column" className={classes.organizationVerificationSteps}>
            <>
              <p>Your organization's approval is pending from Admin side. After approval your features are unlocked.</p>
            </>
        </Box>
      </Box>
    </Layer>
  );
}
