import { integrant_, integrant_detail_ } from '../../common/models';
import { IntegrantKind } from '../../types/common';
import { IIntegrantGraphProviderState } from './types';

const defaults: IIntegrantGraphProviderState = {
  isLoading: false,
  integrantKind: IntegrantKind.Default,
  batch_id: '',
  detail: integrant_detail_,
  currentIntegrant: { ...integrant_, x: 0, y: 0, edge_type: IntegrantKind.Default },
  currentEdge: { source: '', target: '', edge_id: '', edge_type: IntegrantKind.Default },
  currentParentId: '',
  data: {
    edges: [],
    nodes: []
  },
  integrantsData: {
    integrantsTree: [],
    integrantsGraph: {
      nodes: [],
      links: []
    }
  },
  batchViewPopupVal: false
};

export { defaults };
