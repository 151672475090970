import React,{ FunctionComponent,useState,useEffect } from 'react';
import { makeStyles,Theme,Grid, createStyles } from '@material-ui/core';
import SummaryLeft from './SummaryLeft';
import SummaryRight from './SummaryRight';
import { useIntegrantTypeStore } from 'hooks/useIntegrantTypeStore';
import { useUserStore } from 'hooks/useUserStore';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    surveyDetailContainer:{
        marginBottom: 30,
    },
    surveyDetailRightContainer:{
        [theme.breakpoints.down('sm')]:{
            marginTop: 20
        }
    }
}))

interface ISurveyDetailProps {
    integrant_type_id: string;
    activeLink: number;
}

let filteredUserCountFlag = false;

export const SurveyDetail: FunctionComponent<ISurveyDetailProps> = ({integrant_type_id,activeLink}) => {
    const classes = useStyle();
    const {initGetSurveyInvitationList,surveyInvitationList} = useIntegrantTypeStore();
    const {getFilterUsersCount,userFilteredCount} = useUserStore();
    const [audience_filter, setAudience_filter] = useState({});

    useEffect(()=>{
        if(!surveyInvitationList.loading && surveyInvitationList.status === "1" && filteredUserCountFlag){
            if(surveyInvitationList.data?.length){
                let query: any = null;
                for(let item of surveyInvitationList.data){
                    if(item.audience_filter){
                        query = item.audience_filter
                        break;
                    }
                }
                setAudience_filter(query ? query : {})
                getFilterUsersCount(query ? query : {})
                filteredUserCountFlag = false;
            }else{
                setAudience_filter({})
                getFilterUsersCount({})
                filteredUserCountFlag = false;
            }
        }
    },[surveyInvitationList])
    useEffect(()=>{
        initGetSurveyInvitationList(integrant_type_id)
        filteredUserCountFlag = true;
    },[])
    return <Grid container className={classes.surveyDetailContainer}>
        <Grid item md={8} sm={12} xs={12}>
            <SummaryLeft {...{integrant_type_id}} />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
            <Grid container className={classes.surveyDetailRightContainer}>
                <SummaryRight {...{audienceFormFields: Object.entries(audience_filter).map(([label,value])=>{
                    return {
                        label,
                        value
                    }
                }),chartCaption:"Your audience is defined.",heading:"Summary"}} />
            </Grid>
        </Grid>    
    </Grid>
}

export default SurveyDetail