import React, { FunctionComponent } from 'react';
import { Layer } from '../../common/Layer';
import { CreateBatchProvider } from '../../context/createBatchContext';
import { IntegrantPanesWrapper } from '../../wrappers/IntegrantPanesWrapper';
import { IntegrantTypesWrapper } from '../../wrappers/IntegrantTypesWrapper';

const Wrapper: FunctionComponent<{className?:string }> = ({ children,className="" }) => {
  return (
    <IntegrantTypesWrapper>
      <CreateBatchProvider>
        <IntegrantPanesWrapper>
          <Layer className={`wrapper-layer-root ${className}`} fill center style={{ position: 'relative' }} direction="column" width={'100%'}>
            {children}
          </Layer>
        </IntegrantPanesWrapper>
      </CreateBatchProvider>
    </IntegrantTypesWrapper>
  );
};

export { Wrapper };
