import React from 'react';
import { GoCheck } from 'react-icons/go';
const PreRegistrationTitle = (props: any) => {
    const { text, sno, isDone, handleChanges } = props
    return <div className="s_title">
        <div>
            <span className="s_sno">{sno + 1}</span>
            <div className="s_text">{text}{isDone && <GoCheck />}</div>
        </div>
        {isDone && <span className="btn_change" onClick={() => handleChanges(sno)}>{sno ? 'CHANGE' : 'VIEW'}</span>}
    </div>
}
export default PreRegistrationTitle