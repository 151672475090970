import React, { useState } from 'react';
import { makeStyles, Theme, Box, Typography, Button, Tooltip, createStyles } from '@material-ui/core';
import { useDocumentHashStore } from 'hooks/useDocumentHashStore';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { CopyToClipboardIcon } from 'static/icons';
import HelpIcon from '@material-ui/icons/Help';
import { useLocation } from 'react-router-dom';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      backgroundColor: '#008F2B',
      borderRadius: 5,
      padding: '8px 15px',
      fontSize: 16,
      lineHeight: '21px',
      fontWeight: 500,
      color: '#fff',
      minWidth: 'max-content',
      maxWidth: 'max-content',
      width: '100%',
      margin: '10px 0',
      '&:hover': {
        backgroundColor: '#008F2B40',
        color: '#008F2B'
      }
    },
    jwtTokenBox: {
      position: 'relative',
      borderRadius: 10,
      backgroundColor: '#e5e5e5',
      padding: 20,
      '&>p': {
        lineBreak: 'anywhere'
      }
    },
    copyToClipboardBtn: {
      position: 'absolute',
      top: 5,
      right: 5,
      borderRadius: 4,
      background: '#fff',
      padding: 5,
      width: 30,
      height: 30,
      cursor: 'pointer',
      boxShadow: '0 0 5px rgb(0 0 0 / 15%)',
      '&>svg': {
        width: 20,
        height: 20
      }
    },
    actionBtns: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&>button:not(:last-child)': {
        marginRight: 10
      }
    }
  })
);

export default function DocToolJWTGenerator(props: any) {
  const classes = useStyle();
  const location = useLocation();
  const { initGetDocToolJWT, docToolJwtData } = useDocumentHashStore();
  const [copyToClipboardTooltipText, setCopyToClipboardTooltipText] = useState('Copy');
  const handleCopyToClipboard = () => {
    setCopyToClipboardTooltipText('Copied');
    navigator.clipboard.writeText(docToolJwtData.data?.token);
    setTimeout(() => {
      setCopyToClipboardTooltipText('Copy');
    }, 5000);
  };
  return (
    <Box display={'flex'} flexDirection="column">
      <div className={classes.actionBtns}>
        <Button
          className={classes.primaryButton}
          style={{ textTransform: 'capitalize' }}
          onClick={() => initGetDocToolJWT({})}
          disabled={docToolJwtData.loading}
          startIcon={docToolJwtData.loading && <MuiCircularProgress />}
        >
          Generate Doc Tool JWT Token
        </Button>
        {location.pathname === '/' && (
          <Button
            style={{ textTransform: 'capitalize' }}
            variant="contained"
            href="/doc-tool-guide"
            endIcon={<HelpIcon />}
          >
            Setup Guide
          </Button>
        )}
      </div>
      {docToolJwtData?.data?.token && (
        <>
          <Box className={classes.jwtTokenBox}>
            <Typography variant="body2">{docToolJwtData.data?.token}</Typography>
            <Tooltip title={copyToClipboardTooltipText} arrow>
              <Box className={classes.copyToClipboardBtn} onClick={handleCopyToClipboard}>
                <CopyToClipboardIcon />
              </Box>
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  );
}
