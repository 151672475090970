import React,{ FunctionComponent } from 'react';
import '../../styles/commonComponent.css';
import Icon, { getIcon } from '../Icon';
import { Button, makeStyles,createStyles,Theme,InputLabel, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import MuiCircularProgress from 'common/MuiCircularProgress';

interface IPrimaryButtonProps{
    onClick?: (e?: any) => any;
    dataTip?: string;
    icon?: string;
    disabled?: boolean;
    children?: string;
    loading?: boolean;
    maxContent?: boolean;
    style?: any;
    secondaryButton?: boolean;
    secondaryGreenButton?: boolean;
    hoverZoomAnimation?: boolean;
    showIconOnHover?: boolean;
    endIcon?: string; 
}

const defaultProps: IPrimaryButtonProps = {
  onClick: () => {},
  dataTip: '',
  icon: '',
  endIcon:"",
  disabled: false,
  children: '',
  loading: false,
  maxContent: false,
  style: {},
  secondaryButton: false,
  secondaryGreenButton: false,
  showIconOnHover: false,
  hoverZoomAnimation: false,
}

interface IIconButtonProps{
  icon?: string; 
  title?: string; 
  onClick?: (e?: any) => any;
  loading?: boolean;
  disabled?: boolean; 
}

const iconButtonDefaultProps: IIconButtonProps = {
  icon:'', 
  title: '', 
  onClick: () => {}, 
  loading: false, 
  disabled: false 
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
        width: '100%',
        textTransform:'capitalize',
        padding: '10px 20px',
        justifyContent: 'flex-start',
        borderRadius: "5px",
        backgroundColor: "#f1f1f1",
        border: '1px solid #f1f1f1',
        boxShadow: 'none',
        color: "rgb(51,51,51)",
        fontSize: '14px',
        transition: "0.5s all",
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif`,
        '& > span:first-child > span':{
          '&>div':{
            color: "rgb(51,51,51)",
          }
        },
        '&:hover':{
          backgroundColor: "#f1f1f1",
          boxShadow: 'none',
          color:"#008F2B",
          '& > span:first-child > span':{
            '&>div':{
              color: "#008F2B",
            }
          },
        }
    },
    secondaryButton:{
      padding: '8px 15px',
      backgroundColor: "transparent",
      border: '1px solid #008F2B',
      color: "#008F2B",
      '& > span:first-child > span > div':{
        color: "#008F2B"
      },
      '&:hover':{
        backgroundColor:"#008F2B",
        color: "#fff",
        '& > span:first-child > span > span > svg':{
          color: "#fff"
        },
        '& > span:first-child > span > div':{
          color: "#fff"
        },
      }
    },
    secondaryGreenButton:{
      padding: '8px 15px',
      backgroundColor: "#008F2B",
      border: '1px solid #008F2B',
      color: "#fff",
      '& > span:first-child > span > div':{
        color: "#fff"
      },
      '& > span:first-child > span > span > svg':{
        color: "#fff"
      },
      '&:hover':{
        backgroundColor:"transparent",
        color: "#008F2B",
        '& > span:first-child > span > span > svg':{
          color: "#008F2B"
        },
        '& > span:first-child > span > div':{
          color: "#008F2B"
        },
      }
    },
    disabled:{
      border: '1px solid #f1f1f1',
      '& > span:first-child > span > div':{
        color: "rgba(0, 0, 0, 0.26)"
      },
      '& > span:first-child > span > span > svg':{
        color: "rgba(0, 0, 0, 0.26)"
      },
      '&:hover':{
        '& > span:first-child > span > span > svg':{
          color: "rgba(0, 0, 0, 0.26)"
        },
        '& > span:first-child > span > div':{
          color: "rgba(0, 0, 0, 0.26)"
        },
      }
    },
    maxContent:{
      width:"max-content",
      padding: '8px 15px',
    },
    labelBtnRoot:{
      width: 'max-content',
      textTransform:'capitalize',
      padding: '8px 15px',
      borderRadius: 5,
      boxShadow: 'none',
      fontSize: '14px',
      transition: "0.5s all",
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif`,
      backgroundColor: "transparent",
      border: '1px solid #008F2B',
      color: "#008F2B",
      display: "flex",
      alignItems:"center",
      justifyContent:"flex-start",
      '& > span > svg':{
        width: 20,
        height: 20,
        marginRight: 5
      },
      '&:hover':{
        backgroundColor: "#008F2B",
        boxShadow: 'none',
        color:"#fff",
      }
    },
    disabledLabelBtn:{
      pointerEvents:"none",
      border: "1px solid #888",
      color: "#888",
      '&:hover':{
        backgroundColor: "transparent",
        color: "#888",
      }
    },
    hoverZoomAnimation:{
      '&:hover':{
        animation: 'all 0.5s',
        transform: 'scale(1.1)',
      }
    },
    showIconOnHover:{
      '& > span:first-child > span':{
        display: 'none'
      },
      '&:hover':{
        animation: 'all 0.5s',
        '& > span:first-child > span':{
          display: 'inherit'
        },
      }
    },
    iconButton:{
      color: "#008F2B",
      padding: 2,
      width: 25,
      height: 25,
      border: "1px solid #008F2B",
      transition: "0.5s all",
      '&>span':{
          '&>svg':{
              width: 15,
              height: 15,
              color: "#008F2B"
          }
      },
      '&:hover':{
          backgroundColor: "#008F2B",
          '&>span':{
              '&>svg':{
                  color: "#fff"
              }
          },
      }
    },
    iconButtonLoading:{
      border: "none",
    }
  }),
);

const MuiIconButton: FunctionComponent<IIconButtonProps> = ({icon="", title="", onClick, loading, disabled}) => {
  const classes = useStyles();
  return <Tooltip arrow title={title}>
      <IconButton onClick={onClick} className={clsx(classes.iconButton, loading && classes.iconButtonLoading)} disabled={loading || disabled}>{loading ? <MuiCircularProgress /> : getIcon(icon)}</IconButton>
    </Tooltip>
}


MuiIconButton.defaultProps = iconButtonDefaultProps;

const PrimaryButton: FunctionComponent<IPrimaryButtonProps> = ({ onClick, dataTip,icon,children,disabled,loading,style,maxContent,secondaryButton,secondaryGreenButton,hoverZoomAnimation,endIcon,showIconOnHover}) => {
    const classes = useStyles();
    const getEndIcon = (): any =>{
      if(endIcon && loading){
        return <MuiCircularProgress />
      }else if(endIcon){
        return <Icon onlyIcon={true} icon={endIcon} dataTip={dataTip} onClick={()=>{}} />
      }else if(!icon && loading) {
        return <MuiCircularProgress />
      }else {
        return null;
      }
    }
    const getStartIcon = (): any => {
      if(icon && loading){
        return <MuiCircularProgress />
      }else if(icon){
        return <Icon onlyIcon={true} icon={icon} dataTip={dataTip} onClick={()=>{}} />
      }else {
        return null
      }
    }
  return <Button onClick={onClick} className={clsx(classes.primaryButton,maxContent && classes.maxContent,secondaryButton && classes.secondaryButton,secondaryGreenButton && classes.secondaryGreenButton,disabled && classes.disabled,hoverZoomAnimation && classes.hoverZoomAnimation,showIconOnHover && classes.showIconOnHover)} style={style} disabled={disabled} variant="contained" data-tip={dataTip} startIcon={getStartIcon()} endIcon={getEndIcon()}>
    {children}
  </Button>
}

PrimaryButton.defaultProps = defaultProps;

interface ILabelBtnProps{
  onClick?: (e?: any) => any;
  dataTip?: string;
  icon?: string;
  disabled?: boolean;
  children?: string;
  style?: any;
  htmlFor?: string;
}

const labelBtnDefaultProps: ILabelBtnProps = {
  onClick: () => {},
  dataTip: '',
  icon: '',
  disabled: false,
  children: '',
  style: {},
  htmlFor: "",
}

const LabelBtn: FunctionComponent<ILabelBtnProps> = ({style,onClick,dataTip,icon,disabled,children,htmlFor}) => {
  const classes = useStyles();
  return <InputLabel onClick={onClick} htmlFor={htmlFor} className={clsx(classes.labelBtnRoot,disabled && classes.disabledLabelBtn)} data-tip={dataTip} style={style}>{icon ? <Icon icon={icon} onlyIcon={true} dataTip={dataTip} /> : ""}{children}</InputLabel>
}
LabelBtn.defaultProps = labelBtnDefaultProps;

export { PrimaryButton, LabelBtn, MuiIconButton}