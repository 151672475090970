import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Box
} from '@material-ui/core';
import { PrimaryButton, MuiIconButton } from 'common';
import ConfirmationDialog from 'common/Dialogs/ConfirmationDialog';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { useOrganizationExhibitStore } from 'hooks/useOrganizationExhibitStore';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: 20,
      marginBottom: 30
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700
        }
      }
    },
    actionBtns: {
      '&>*:not(:last-child)': {
        marginRight: 10
      }
    }
  })
);

export default function OrganizationExhibit() {
  const {
    initGetAllOrganizationExhibit,
    getAllData,
    initDestroyOrganizationExhibit,
    deleteData
  } = useOrganizationExhibitStore();
  const classes = useStyle();
  const history = useHistory();
  const [openDeleteExhibitConfirmationDialog, setOpenDeleteExhibitConfirmationDialog] = useState(false);
  const [loadingId, setLoadingId] = useState('');

  useEffect(() => {
    initGetAllOrganizationExhibit({ limit: 20, offset: 0 });
  }, []);
  useEffect(() => {
    if (!deleteData?.loading && deleteData?.status === '1' && loadingId) {
      setLoadingId('');
    }
  }, [deleteData]);
  return (
    <div className="cp">
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <div className="subscription-title">Attachments</div>
            <PrimaryButton
              icon="add"
              children={'Add New Attachment'}
              onClick={() => history.push('/organization-exhibits/create')}
              maxContent
              secondaryGreenButton
            />
          </div>
        </div>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Effective Date</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAllData.loading && !getAllData?.data ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Grid container alignItems="center" justifyContent="center">
                      Loading...&nbsp;
                      <MuiCircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : getAllData?.data?.count ? (
                getAllData?.data?.rows?.map((item: any, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{item.title}</TableCell>
                      <TableCell align="left">{item?.description}</TableCell>
                      <TableCell align="left">{moment(item?.effective_date).format('MM/DD/YYYY')}</TableCell>
                      <TableCell align="center">
                        <Box
                          display="flex"
                          alignItems={'center'}
                          justifyContent="center"
                          className={classes.actionBtns}
                        >
                          <MuiIconButton
                            icon="visibility"
                            title="View Attachment"
                            onClick={() => window.open(item?.url, '_blank')}
                          />
                          <MuiIconButton
                            icon="edit"
                            title="Edit Attachment"
                            onClick={() => history.push(`/organization-exhibits/edit/${item?.id}`)}
                          />
                          <MuiIconButton
                            icon="delete"
                            loading={loadingId === item?.id && deleteData?.loading}
                            title="Delete Attachment"
                            onClick={() => {
                              setOpenDeleteExhibitConfirmationDialog(true);
                              setLoadingId(item?.id);
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    No proof certificates found for your organization. Verify your organization and increase trust with
                    your customers.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ConfirmationDialog
        open={openDeleteExhibitConfirmationDialog}
        handleClose={(value: boolean): any => {
          if (value) {
            initDestroyOrganizationExhibit(loadingId);
          }
          setOpenDeleteExhibitConfirmationDialog(false);
        }}
        title={'Confirmation'}
        message={'Are you sure you want to delete this attachment?'}
      />
    </div>
  );
}
