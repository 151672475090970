import React, { FunctionComponent, CSSProperties } from 'react';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { IntegrantKind, IIntegrantOptionPaneProps } from '../types/common';
import { Location } from './Location';
import { Layer } from '../common';

const locationWrapperStyle: CSSProperties = { padding: 10, width: '100%' };
const IntegrantLocation: FunctionComponent<IIntegrantOptionPaneProps> = ({ isVisible, isDisabled }) => {
  const { currentIntegrant, integrantKind } = useIntegrantGraphStore();
  const { onSaveLocation } = useIntegrantPaneStore();
  return (
    <Layer className='integrantLocation' disabled={isDisabled} scrollY={true}>
      <Location
        locationWrapperStyle={locationWrapperStyle}
        currentLocation={currentIntegrant.location}
        onSaveLocation={onSaveLocation}
        isVisible={isVisible}
        isFormVisible={integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer}
        isTransfer={integrantKind === IntegrantKind.Transfer}
      />
    </Layer>
  );
};

export { IntegrantLocation };
