import React, { FunctionComponent } from 'react';
import { DashboardView } from './DashboardView';
import { Wrapper } from './wrapper';

const Dashboard: FunctionComponent = () => {
  return (
    <Wrapper>
      <DashboardView />
    </Wrapper>
  );
};

export { Dashboard };
