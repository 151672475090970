import { CSSProperties } from 'react';

const primaryColor = '#0089ff';
const errorColor = 'red';
const primaryLightColor = 'rgb(247, 250, 255)';
const defaultFontSize = '14px';
const defaultBoxShadow = 'rgba(50, 50, 93, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px';
const defaultGreyBackgroundColor = '#F9F9F9';
const defaultBorderTransparent = '1px solid transparent';

const defaultBorderColorStyle = {
  borderColor: '#ccc'
};
const defaultBorderStyle: CSSProperties = {
  margin: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  ...defaultBorderColorStyle
};
const defaultParagraphStyle = {
  fontSize: '15px'
};
const primaryBorderStyle = {
  border: '1px solid #999'
};
const defaultButtonStyle: CSSProperties = {
  position: 'relative',
  cursor: 'pointer',
  fontSize: defaultFontSize,
  padding: '10px 20px',
  textAlign: 'left',
  textTransform: 'capitalize',
  border: defaultBorderTransparent
};

const defaultButtonSmallStyle: CSSProperties = {
  ...defaultButtonStyle,
  padding: '5px 20px',
  width: 180
};

const wideDefaultButtonStyle: CSSProperties = { ...defaultButtonStyle, width: '100%' };
const defaultInputStyle: CSSProperties = {
  backgroundColor: 'white',
  fontSize: defaultFontSize,
  border: 0,
  outline: 0
};
const primaryLargeButtonStyle: CSSProperties = {
  ...defaultButtonStyle,
  padding: '30px',
  width: '100%'
};
const primaryButtonStyle: CSSProperties = {
  ...defaultButtonStyle,
  backgroundColor: primaryColor,
  color: 'white'
};

const largeButtonStyle: CSSProperties = {
  padding: 20
};
const defaultPaneInputStyle: CSSProperties = { width: '100%', padding: 10, fontSize: 12 };
const listItemImgStyle: CSSProperties = { height: 30 };
const listItemImgContainer: CSSProperties = {
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  paddingBottom: 10
};

const imgAvatarStyle: CSSProperties = {
  height: 100,
  width: 100,
  borderRadius: '50%'
};
const graphNodeImgStyle: CSSProperties = {
  height: 60,
  width: 60,
  borderRadius: '50%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  zIndex: 0
};
const graphNodeWrapperStyle: CSSProperties = {
  height: 70,
  width: 70,
  borderRadius: '50%',
  backgroundColor: '#ccc',
  position: 'relative'
};
const listItemTitleStyle: CSSProperties = {
  //wordBreak: 'break-word',
  //whiteSpace: 'nowrap',
  // overflow: 'hidden',
  margin: 0,
  textOverflow: 'ellipses'
};
const listItemWrapperStyle: CSSProperties = { padding: '5px 10px', alignItems: 'center', cursor: 'pointer' };
const loadingIconStyle = (isLoading: boolean, obj?: any): CSSProperties => {
  return { color: 'blue', marginRight: 10, opacity: isLoading ? 1 : 0, position: 'absolute', left: 10, top: 12 };
};

const loadingIconButtonStyle = (isLoading: boolean): CSSProperties => {
  return { color: 'blue', marginRight: 10, opacity: isLoading ? 1 : 0, position: 'absolute', left: 10, top: '100px' };
};

const listItemStyle: CSSProperties = {
  padding: 0,
  borderColor: '#ccc',
  marginTop: 10
};

const dropdownWrapperStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  backgroundColor: 'white',
  boxShadow: 'rgba(50, 50, 93, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px'
};

const formButtonStyle: CSSProperties = { ...defaultButtonStyle, marginRight: 5 };

const gridBackgroundStyle: CSSProperties = {
  backgroundSize: '40px 40px',
  backgroundImage: `linear-gradient(to right, #eee 1px, transparent 1px),
linear-gradient(to bottom, #eee 1px, transparent 1px)`
};

const defaultModalStyle: { content: CSSProperties } = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const iconStyle: CSSProperties = { position: 'relative', top: '3px', right: '5px' };

const tabItemStyle: CSSProperties = { padding: 10, margin: 0, cursor: 'pointer' };
const labelStyle: CSSProperties = { ...tabItemStyle, color: '#888', cursor: 'pointer' };
const ttl: CSSProperties = { paddingBottom: 15 };
const ttl1: CSSProperties = { border: '1px solid  rgba(0, 0, 0, 0.1)', marginBottom: 10, borderRadius: 5, padding: 10 };

const labelStylettl: CSSProperties = { color: '#000', fontSize: '0.875rem', paddingBottom: 7 };
const mb10: CSSProperties = { marginBottom: 10 };
const mb5: CSSProperties = { marginBottom: 5 };

const fileInputStyle: CSSProperties = {
  zIndex: -10,
  height: 0,
  overflow: 'hidden',
  width: 0,
  position: 'absolute',
  top: 0
};

export {
  defaultFontSize,
  loadingIconButtonStyle,
  defaultBoxShadow,
  defaultButtonStyle,
  defaultInputStyle,
  primaryLargeButtonStyle,
  defaultBorderStyle,
  defaultBorderColorStyle,
  primaryBorderStyle,
  defaultPaneInputStyle,
  defaultGreyBackgroundColor,
  primaryColor,
  primaryButtonStyle,
  primaryLightColor,
  listItemImgContainer,
  listItemImgStyle,
  listItemTitleStyle,
  listItemWrapperStyle,
  defaultParagraphStyle,
  loadingIconStyle,
  defaultButtonSmallStyle,
  graphNodeImgStyle,
  graphNodeWrapperStyle,
  dropdownWrapperStyle,
  listItemStyle,
  formButtonStyle,
  imgAvatarStyle,
  wideDefaultButtonStyle,
  errorColor,
  largeButtonStyle,
  gridBackgroundStyle,
  defaultModalStyle,
  iconStyle,
  tabItemStyle,
  labelStyle,
  ttl,
  ttl1,
  labelStylettl,
  fileInputStyle,
  mb10,
  mb5
};
