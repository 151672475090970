import React, { useEffect, useState } from 'react';
import { useSuggestIntegrantsService } from '../../hooks/api/useSuggestIntegrantsService';
import { CreateContext } from '../../types/common';
import { isUndefined } from '../../utils';
import { defaults } from './suggestIntegrantChildContextState';
import { ISuggestIntegrantChildState } from './types';

const SuggestIntegrantChildContext = React.createContext<CreateContext<ISuggestIntegrantChildState>>([
  defaults,
  (): ISuggestIntegrantChildState => defaults
]);

const SuggestIntegrantChildProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const { getSuggestedIntegrants, suggestedIntegrants } = useSuggestIntegrantsService();
  const [state, setState] = useState<ISuggestIntegrantChildState>(defaults);
  const resetStore = (): void => {
    setState(s => ({ ...s, isLoading: true, integrants: [] }));
  };

  useEffect(() => {
    if (!isUndefined(suggestedIntegrants)) {
      setState(s => ({ ...s, integrants: suggestedIntegrants }));
    }
  }, [suggestedIntegrants]);

  useEffect(() => {
    if (state.isOpen) {
      getSuggestedIntegrants().then(() => {
        setState(s => ({ ...s, isLoading: false }));
      });
    }
    return (): void => resetStore();
  }, [state.isOpen, getSuggestedIntegrants]);

  return (
    <SuggestIntegrantChildContext.Provider value={[state, setState]}>
      {props.children}
    </SuggestIntegrantChildContext.Provider>
  );
};

export { SuggestIntegrantChildContext, SuggestIntegrantChildProvider };
