import React, { FunctionComponent, useEffect } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PrimaryButton } from 'common';
import { IntegrantTypeFacets } from '../../components/IntegrantTypeFacets';
import { IntegrantTypeDetails } from 'components/IntegrantTypeDetails';
import { CreateIntegrantTypePaneHeader } from 'components/CreateIntegrantTypePaneHeader';
import { useCreateIntegrantTypeStore } from 'hooks/useCreateIntegrantTypeStore';
import IngredientsForm from './IngredientsForm';
import { CreateIntegrantTypePaneFooter } from 'components/CreateIntegrantTypePaneFooter';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    createProductContainer: {
      marginBottom: 50
    }
  })
);

interface ICreateProductForm {
  data: any;
}

export const CreateProductForm: FunctionComponent<ICreateProductForm> = ({ data = {} }) => {
  // data = {
  //   ...data,
  //   ...{
  //     src: '01-raw/2022-10-25/data/label_290.json',
  //     id: 274265,
  //     nhanesId: '',
  //     bundleName: '',
  //     fullName: 'GABA Brain Food',
  //     brandName: 'NS Natural Stacks',
  //     brandIpSymbol: '®',
  //     upcSku: '6 13310 57893 2',
  //     productVersionCode: 'Not Present',
  //     pdf: '274265_document.pdf',
  //     thumbnail: '274265_thumbnail.jpg',
  //     servingsPerContainer: '5',
  //     hasOuterCarton: null,
  //     percentDvFootnote: 'Not Present',
  //     labelRelationships: [],
  //     contacts: [
  //       {
  //         contactId: 24635,
  //         text: 'Formulated & Distributed by',
  //         types: ['Manufacturer', 'Distributor'],
  //         contactDetails: {
  //           src: '01-raw/2022-10-25/data/contact_6.json',
  //           id: 24635,
  //           name: 'NS 360, Inc.',
  //           streetAddress: '4241 24th Ave W',
  //           city: 'Seattle',
  //           state: 'WA',
  //           country: '',
  //           zipCode: '98199',
  //           phoneNumber: '1-855-678-2257',
  //           email: 'info@naturalstacks.com',
  //           webAddress: ''
  //         }
  //       }
  //     ],
  //     netContents: [
  //       {
  //         order: 1,
  //         quantity: 15,
  //         unit: 'Vegetarian Capsule(s)',
  //         display: '15 Vegetarian Capsule(s)'
  //       }
  //     ],
  //     physicalState: {
  //       langualCode: 'E0159',
  //       langualCodeDescription: 'Capsule'
  //     },
  //     servingSizes: [
  //       {
  //         order: 1,
  //         minQuantity: 1,
  //         maxQuantity: 3,
  //         minDailyServings: 1,
  //         maxDailyServings: 1,
  //         unit: 'Capsule(s)',
  //         notes: '',
  //         inSFB: true
  //       }
  //     ],
  //     targetGroups: ['Adult (18 - 50 Years)'],
  //     productType: {
  //       langualCode: 'A1325',
  //       langualCodeDescription: 'Other Combinations'
  //     },
  //     statements: [
  //       {
  //         type: 'FDA Disclaimer Statement',
  //         notes:
  //           'These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.'
  //       },
  //       {
  //         type: 'Suggested/Recommended/Usage/Directions',
  //         notes: 'Suggested Use: Take 1-3 capsules. Do not exceed 3 capsules per day.'
  //       },
  //       {
  //         type: 'Precautions re: Pregnant or Nursing or Prescription Medications',
  //         notes:
  //           'Warning: Consult a healthcare practitioner if you are pregnant/nursing, have or suspect a medical condition, or are taking any medications.'
  //       },
  //       {
  //         type: 'Precautions re: Children',
  //         notes: 'Keep out of reach of children.'
  //       },
  //       {
  //         type: 'Formulation re: Other',
  //         notes: 'Nighttime support for: Calm & relaxation'
  //       },
  //       {
  //         type: 'FDA Statement of Identity',
  //         notes: 'Dietary Supplement'
  //       },
  //       {
  //         type: 'Precautions re: All Other',
  //         notes: 'Do not exceed 3 capsules per day.'
  //       }
  //     ],
  //     claims: [
  //       {
  //         langualCode: 'P0115',
  //         langualCodeDescription: 'All Other'
  //       },
  //       {
  //         langualCode: 'P0265',
  //         langualCodeDescription: 'Structure/Function'
  //       }
  //     ],
  //     events: [
  //       {
  //         date: '2022-09-22',
  //         type: 'Date entered into DSLD'
  //       }
  //     ],
  //     userGroups: [
  //       {
  //         dailyValueTargetGroupName: 'Adults and children 4 or more years of age',
  //         langualCode: 'P0250',
  //         langualCodeDescription: 'Adults and Children 4 years and above'
  //       }
  //     ],
  //     ingredientRows: [
  //       {
  //         order: 1,
  //         ingredientId: 8924,
  //         description: '',
  //         notes: '',
  //         quantity: [
  //           {
  //             servingSizeOrder: 1,
  //             servingSizeQuantity: 3,
  //             operator: '=',
  //             quantity: 633,
  //             unit: 'mg',
  //             dailyValueTargetGroup: [
  //               {
  //                 name: 'Adults and children 4 or more years of age',
  //                 operator: null,
  //                 percent: null,
  //                 footnote: 'Daily Value (DV) Not Established'
  //               }
  //             ],
  //             servingSizeUnit: 'Capsule(s)'
  //           }
  //         ],
  //         nestedRows: [],
  //         name: 'Gamma-Aminobutyric Acid',
  //         category: 'non-nutrient/non-botanical',
  //         ingredientGroup: 'GABA',
  //         uniiCode: null,
  //         alternateNames: ['GABA'],
  //         forms: []
  //       },
  //       {
  //         order: 2,
  //         ingredientId: 7113,
  //         description: '',
  //         notes: '',
  //         quantity: [
  //           {
  //             servingSizeOrder: 1,
  //             servingSizeQuantity: 3,
  //             operator: '=',
  //             quantity: 500,
  //             unit: 'mg',
  //             dailyValueTargetGroup: [
  //               {
  //                 name: 'Adults and children 4 or more years of age',
  //                 operator: null,
  //                 percent: null,
  //                 footnote: 'Daily Value (DV) Not Established'
  //               }
  //             ],
  //             servingSizeUnit: 'Capsule(s)'
  //           }
  //         ],
  //         nestedRows: [],
  //         name: 'L-Citrulline',
  //         category: 'amino acid',
  //         ingredientGroup: 'Citrulline',
  //         uniiCode: null,
  //         alternateNames: [],
  //         forms: []
  //       },
  //       {
  //         order: 3,
  //         ingredientId: 309943,
  //         description: '',
  //         notes: 'standardized to 6% rosmarinic acid',
  //         quantity: [
  //           {
  //             servingSizeOrder: 1,
  //             servingSizeQuantity: 3,
  //             operator: '=',
  //             quantity: 333,
  //             unit: 'mg',
  //             dailyValueTargetGroup: [
  //               {
  //                 name: 'Adults and children 4 or more years of age',
  //                 operator: null,
  //                 percent: null,
  //                 footnote: 'Daily Value (DV) Not Established'
  //               }
  //             ],
  //             servingSizeUnit: 'Capsule(s)'
  //           }
  //         ],
  //         nestedRows: [],
  //         name: 'Rosemary Leaf Extract',
  //         category: 'botanical',
  //         ingredientGroup: 'Rosemary ',
  //         uniiCode: null,
  //         alternateNames: [],
  //         forms: [
  //           {
  //             order: 1,
  //             ingredientId: 302750,
  //             prefix: null,
  //             percent: null,
  //             name: 'Rosmarinic Acid'
  //           }
  //         ]
  //       },
  //       {
  //         order: 4,
  //         ingredientId: 300422,
  //         description: '',
  //         notes: 'min. 95% total polyphenols',
  //         quantity: [
  //           {
  //             servingSizeOrder: 1,
  //             servingSizeQuantity: 3,
  //             operator: '=',
  //             quantity: 33,
  //             unit: 'mg',
  //             dailyValueTargetGroup: [
  //               {
  //                 name: 'Adults and children 4 or more years of age',
  //                 operator: null,
  //                 percent: null,
  //                 footnote: 'Daily Value (DV) Not Established'
  //               }
  //             ],
  //             servingSizeUnit: 'Capsule(s)'
  //           }
  //         ],
  //         nestedRows: [],
  //         name: 'Grape Seed Extract',
  //         category: 'botanical',
  //         ingredientGroup: 'Grape ',
  //         uniiCode: 'C34U15ICXA',
  //         alternateNames: [],
  //         forms: [
  //           {
  //             order: 1,
  //             ingredientId: 296052,
  //             prefix: null,
  //             percent: null,
  //             name: 'Polyphenols'
  //           }
  //         ]
  //       }
  //     ],
  //     otheringredients: {
  //       text: null,
  //       ingredients: [
  //         {
  //           order: 1,
  //           ingredientId: 3721,
  //           name: 'Hypromellose',
  //           category: 'other',
  //           ingredientGroup: 'Hypromellose',
  //           uniiCode: null,
  //           alternateNames: [],
  //           forms: []
  //         },
  //         {
  //           order: 2,
  //           ingredientId: 4843,
  //           name: 'Ascorbyl Palmitate',
  //           category: 'vitamin',
  //           ingredientGroup: 'Vitamin C (ascorbyl palmitate)',
  //           uniiCode: null,
  //           alternateNames: [],
  //           forms: []
  //         },
  //         {
  //           order: 3,
  //           ingredientId: 293311,
  //           name: 'Silica',
  //           category: 'mineral',
  //           ingredientGroup: 'Silicon',
  //           uniiCode: 'ETJ7Z6XBU4',
  //           alternateNames: [],
  //           forms: []
  //         }
  //       ]
  //     },
  //     offMarket: 0,
  //     entryDate: '2022-09-22'
  //   }
  // };

  const classes = useStyle();
  const { slideIndex, onUpdateDetails, setFile, addIngredients } = useCreateIntegrantTypeStore();

  useEffect(() => {
    if (!data?.isLoading && data?.id) {
      setFile(
        `https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/thumbnails/${data.id}.jpg`
      );
      onUpdateDetails('title', data?.fullName);
      let ingredientsArr: any = [];
      if (Boolean(data?.ingredientRows?.length)) {
        ingredientsArr = [
          ...ingredientsArr,
          ...data?.ingredientRows?.map((item: any) => {
            return {
              title: item?.name || '',
              description: item?.description || '',
              currentFile: null,
              id: item?.ingredientId
            };
          })
        ];
      }
      if (Boolean(data?.otheringredients?.ingredients?.length)) {
        ingredientsArr = [
          ...ingredientsArr,
          ...data?.otheringredients?.ingredients?.map((item: any) => {
            return {
              id: item?.ingredientId,
              title: item?.name || '',
              currentFile: null
            };
          })
        ];
      }
      addIngredients(ingredientsArr);
    }
  }, [data?.id, data?.isLoading]);

  return (
    <Box display="flex" flexDirection="column" className={classes.createProductContainer}>
      <Typography variant="h5">Product Info</Typography>
      <CreateIntegrantTypePaneHeader type="importNIHProduct" />
      <IntegrantTypeDetails isVisible={slideIndex === 0} type="importNIHProduct" />
      <IntegrantTypeFacets isVisible={slideIndex === 1} />
      <IngredientsForm isVisible={slideIndex === 2} />
      <CreateIntegrantTypePaneFooter isUpdate={false} type="importNIHProduct" />
    </Box>
  );
};

export default CreateProductForm;
