import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  createStyles,
  Dialog
} from '@material-ui/core';
import { Layer } from 'common';
import { useIndividualSubscriptionDetailStore } from 'hooks/useIndividualSubscriptionDetailStore';
import MuiLinearProgress from 'common/MuiLinearProgress';
import DocToolJWTGenerator from '../verifier-publisher/DocToolJWTGenerator';
import { abbrNum } from 'utils';
import PublisherFreeTrialDocSection from './PublisherFreeTrialDocSection';
import { useIntegrantTypesService } from 'hooks/api/useIntegrantTypesService';
import { useAuthStore } from 'hooks/useAuthStore';
import moment from 'moment';
import { MuiSelect } from 'common/formComponents';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import LineChart from 'components/LineChart';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { filterOption } from '../Verifier';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    dashboardContainer: {
      width: '100%'
    },
    progressBarDiv: {
      margin: '20px 0'
    }
  })
);

interface DocumentChartDataToalDoc {
  label: string;
  total_doc: number;
}

export default function PublisherDashboard() {
  const { dashboardOverview } = useIndividualSubscriptionDetailStore();
  const classes = useStyle();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));
  const { individual } = useAuthStore();
  const { graphSuccessfulUploadData } = useIntegrantTypesService(individual);
  const [totalDocGraphDataLoading, setTotalDocGraphDataLoading] = useState<Boolean>(false);
  const [graphTotalData, setGraphTotalData] = useState<DocumentChartDataToalDoc[]>([]);
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [selectRangeTotalData, setSelectRangeTotalData] = useState('');

  const fetchDataTotalData = async (type: string, customStartDate?: any, customEndDate?: any) => {
    setTotalDocGraphDataLoading(true);
    let startDate: unknown = null;
    let endDate: unknown = null;
    const currentDate = moment();
    if (type === 'last_week') {
      startDate = currentDate.startOf('week');
      endDate = currentDate.clone().endOf('week');
    } else if (type === 'last_2_week') {
      const startOfCurrentWeek = currentDate.startOf('week');
      startDate = startOfCurrentWeek.clone().subtract(2, 'weeks');
      endDate = startOfCurrentWeek
        .clone()
        .subtract(1, 'week')
        .endOf('week');
    } else if (type === 'last_month') {
      const startOfCurrentMonth = currentDate.startOf('month');
      startDate = startOfCurrentMonth.clone().subtract(1, 'month');
      endDate = startOfCurrentMonth.clone().subtract(1, 'day');
    } else if (type === 'last_3_month') {
      const startOfCurrentMonth = currentDate.startOf('month');
      startDate = startOfCurrentMonth.clone().subtract(3, 'months');
      endDate = startOfCurrentMonth
        .clone()
        .subtract(1, 'month')
        .endOf('month');
    } else {
      startDate = customStartDate;
      endDate = customEndDate ? customEndDate : customStartDate;
    }
    const totalDocData = await graphSuccessfulUploadData({
      type: type,
      startDate: startDate,
      endDate: endDate
    });
    setTotalDocGraphDataLoading(false);
    setGraphTotalData(totalDocData.data);
  };

  const handleSelectRageTotalData = (event: any) => {
    setSelectRangeTotalData(event.target.value);
    if (event.target.value !== 'custom') {
      fetchDataTotalData(event.target.value);
    }
    if (event.target.value === 'custom') {
      setOpenDateSelector(true);
    }
  };

  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClose = () => {
    fetchDataTotalData('custom', startDate, endDate);
    setOpenDateSelector(false);
  };

  useEffect(() => {
    setSelectRangeTotalData(filterOption[0].value);
    fetchDataTotalData(filterOption[0].value);
  }, []);
  return (
    <Layer
      direction="column"
      style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0', height: '100%' }}
      width={match ? '100%' : '50%'}
    >
      <Box className={classes.dashboardContainer} display="flex" flexDirection={'column'}>
        <Typography variant="h5">Publisher Dashboard</Typography>
        <Divider />

        {!totalDocGraphDataLoading ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '25px' }}>
              {/* <span>Total uploaded documents</span> */}
              <span>
                <MuiSelect
                  value={selectRangeTotalData}
                  onChange={event => handleSelectRageTotalData(event)}
                  label=""
                  options={filterOption}
                />
                <Dialog
                  // id={id}
                  open={openDateSelector}
                  onClose={handleClose}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </Dialog>
              </span>
            </div>
            <LineChart data={graphTotalData} />{' '}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px'
            }}
          >
            <MuiCircularProgress size={50} />
          </div>
        )}
        {(dashboardOverview?.data?.organization?.ignore_threshold === 0 ||
          !dashboardOverview?.data?.organization?.ignore_threshold) && (
          <Box display="flex" flexDirection={'column'} className={classes.progressBarDiv}>
            <Typography variant="h6"> Your current month document publish limit overview</Typography>
            <MuiLinearProgress
              {...{
                loading: dashboardOverview.loading,
                value:
                  (parseInt(dashboardOverview?.data?.current_num_monthly_hashes || '0') * 100) /
                  parseInt(dashboardOverview?.data?.num_monthly_hashes || '0'),
                label: `${dashboardOverview?.data?.current_num_monthly_hashes || '0'}/${abbrNum(
                  dashboardOverview?.data?.num_monthly_hashes || '0'
                )}`
              }}
            />
          </Box>
        )}
        <DocToolJWTGenerator />
        <PublisherFreeTrialDocSection />
      </Box>
    </Layer>
  );
}
