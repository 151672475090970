import React, { FunctionComponent } from 'react';
import { TransferredView } from './TransferredView';
import { Wrapper } from './wrapper';

const Transfer: FunctionComponent = () => {
  return <Wrapper>
    <TransferredView />
  </Wrapper>;
};

export { Transfer };
