import { ICreateIndividualDTO, IIndividualAuthenticatedDTO } from '../../lib/individual';
import { ICreateMutationDTO, IMutationDTO } from '../../lib/mutation';
import { ICreateTransferDTO, ITransferDetailDTO, ITransferDTO } from '../../lib/transfer';
import { ICreateTransitDTO } from '../../lib/transit';
import { IExhibitDTO } from '../../lib/exhibit';
import { IFacetDTO, ICreateFacetDTO } from '../../lib/facet';
import { IIntegrantDetailDTO, IIntegrantDTO } from '../../lib/integrant';
import {
  IIntegrantTypeDTO,
  ICreateIntegrantTypeDTO,
  IIntegrantTypeDataDTO,
  IIntegrantTypeIngredientsDataDTO
} from '../../lib/integrantType';
import { ILocationDTO } from '../../lib/location';
import { IOrganizationDTO } from '../../lib/organization';

export const location_: ILocationDTO = {
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip: '',
  coordinates: [0, 0],
  country: '',
  id: '',
  description: ''
};

export const integrant_: IIntegrantDTO = {
  id: '',
  primary: false,
  integrant_type_id: '',
  created_on: '',
  updated_on: '',
  tracking_id: '',
  location: location_,
  facets: [],
  exhibits: [],
  mutations: [],
  transfers: [],
  transits: [],
  published: false,
  is_published: false,
  title: '',
  description: '',
  image_thumb_url: '',
  organization_id: '',
  buy_again_url: '',
  inherited_facets: [],
  external_id_slug: '',
  external_id: '',
  type: ''
};

export const integrant_detail_: IIntegrantDetailDTO = {
  ...integrant_,
  mutations: [],
  exhibits: [],
  transits: [],
  transfers: []
};

export const create_individual_: ICreateIndividualDTO = {
  email_address: '',
  password: '',
  name_first: '',
  name_middle: '',
  name_last: '',
  phone: null,
  phone_mobile: ''
};

export const individual_: IIndividualAuthenticatedDTO = {
  id: '',
  email_address: '',
  name_first: '',
  name_middle: '',
  name_last: '',
  phone: '',
  phone_mobile: '',
  roles: [],
  access_token: '',
  organization_id: '',
  active: false,
  statusCode: 0,
  temporary_password: false
};

export const create_integrant_type_: ICreateIntegrantTypeDTO = {
  title: '',
  description: '',
  image_thumb_url: '',
  facets: [],
  external_id: '',
  organization_id: '',
  name: '',
  is_completed: 'false',
  integrant_type_id: '',
  directions: '',
  warnings: '',
  //buy_again_url: '',
  tracking_id: '',
  location_id: '',
  created_by: '',
  updated_by: '',
  product_type: '',
  youtube_video_url: '',
  bunny_video_title: '',
  bunny_video_url: '',
  bunny_video_thumbnail_url: ''
};

export const integrant_type_: IIntegrantTypeDTO = {
  id: '',
  organization_id: '',
  title: '',
  description: '',
  image_url: '',
  youtube_video_url: '',
  bunny_video_title: '',
  bunny_video_url: '',
  bunny_video_thumbnail_url: '',
  facets: [],
  created_on: '',
  updated_on: '',
  external_id: null,
  directions: '',
  warnings: '',
  created_by: '',
  updated_by: '',
  external_id_slug: '',
  is_disabled: false,
  used_as_ingredients: true
  //buy_again_url: ''
};

export const integrant_type_ingredients: IIntegrantTypeIngredientsDataDTO = {
  status: '',
  message: '',
  loading: false,
  data: null
};

export const integrant_type_data: IIntegrantTypeDataDTO = {
  id: '',
  title: '',
  description: '',
  image_url: '',
  directions: '',
  warnings: '',
  facets: [],
  external_id: '',
  organization_id: '',
  created_on: '',
  updated_on: '',
  created_by: '',
  updated_by: '',
  youtube_video_url: '',
  bunny_video_title: '',
  bunny_video_url: '',
  bunny_video_thumbnail_url: ''
  //buy_again_url: ''
};

export const exhibit_: IExhibitDTO = {
  id: '',
  // TODO JI currently needs to be a string of 12 characters
  exhibit_type_id: '',
  title: '',
  description: '',
  url: '',
  exhibit_type: '',
  rank: 0,
  location: location_,
  isOld: false,
  effective_date: new Date()
};

export const create_facet_: ICreateFacetDTO = {
  title: '',
  description: '',
  rank: 0,
  id: ''
};

export const facet_: IFacetDTO = {
  id: '',
  title: '',
  description: '',
  rank: 0,
  isOld: false
};

export const create_mutation_: ICreateMutationDTO = {
  integrant_id: ''
};

export const mutation_: IMutationDTO = {
  id: '',
  integrant_id: ''
};

export const create_transit: ICreateTransitDTO = {
  integrant_id: '',
  title: '',
  description: '',
  oldTransitId: '',
  exhibit: [],
  from_location: location_,
  to_location: location_
};

export const create_transfer_: ICreateTransferDTO = {
  integrant_id: '',
  title: '',
  description: ''
};

export const transfer_: ITransferDTO = {
  id: '',
  integrant_id: '',
  title: '',
  description: ''
};

export const transfer_detail_: ITransferDetailDTO = {
  ...transfer_,
  integrant: integrant_detail_
};

export const organization_: IOrganizationDTO = {
  id: '',
  description: '',
  logo_url: '',
  name: '',
  short_code: '',
  tag_line: '',
  account_id: ''
};
