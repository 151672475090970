import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuthStore } from './../../hooks/useAuthStore';
import { labelMeta, currencyFormat, monthFormat, formAxiosHeader, subscriptionCategoriesLabel } from './../../config';
import { Link } from 'react-router-dom';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { CircularProgress, makeStyles, createStyles, Theme, Box, Grid, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/api-interceptor';
import { abbrNum } from 'utils';
import clsx from 'clsx';

const useStyle = makeStyles((theme: Theme)=> createStyles({
  addOrUpdateContainer: {
      width:'100%',
      minHeight: 'calc(100vh - 70px)',
      '&>div':{
          '&>div':{
              maxWidth: 800,
              width:'100%',
              borderRadius: 16,
              backgroundColor: '#fff',
              boxShadow: '0px 0px 10px 0px #00000026',
              padding: '20px 20px',
          },
          '&>h4,&>p':{
              textAlign:"center"
          },
          '&>h4':{
              marginBottom: 10,
          },
          '&>p':{
              marginBottom: 20
          }
      }
  },
  planDetailUl:{
      '&>div:not(:first-child,:nth-child(2),:nth-child(3),:nth-child(4))':{
          borderBottom: `1px solid rgba(0,0,0,0.1)`
      }
  },
  priceBoxRoot:{
      margin: '20px 0',
  },
  priceBox:{
      border: `2px solid #999`,
      borderRadius: 5,
      padding: 10,
      cursor:"pointer",
      maxWidth: 250,   
      margin: '0 auto',
      '&>h6':{
          fontWeight: 'bold'
      }
  },
  selectedPriceBox:{
    border: `2px solid #008F2B`,
    color: '#008F2B',
    background: '#EAF7ED'
  },
  disabledPriceBox:{
    cursor:"initial",
    border: `2px solid rgba(0,0,0,0.1)`,
    color: "#999",
    background: '#fff'
  },
  selectedPriceDataColor:{
    color: '#008F2B',
  }
}));

const intervalMonth: any = { month: 1, year: 12 }

const UpgradeSubscription = (props: any) => {
  const history = useHistory();
  const { individual: { organization_id, access_token }, selectedSubscription } = useAuthStore();
  const [pageLoading, setPageLoading] = useState(false)
  const [isCalculate, setCalculate] = useState(false)
  const [pageLoadingWait, setPageLoadingWait] = useState(false)
  const [step, setStep] = useState(0)
  const [updateInterval, setUpdateInterval] = useState('')
  const [upgradeAmountObj, setAmountObj] = useState<any>(null)
  const [priceId, setPriceId] = useState('')
  const [subscriptionObj, setsubscriptionObj] = useState<any>(null)
  const params: any = useParams();
  const [promotionCodeLocading, setPromotionCodeLocading] = useState(false)
  const [promotionCodeVal, setPromotionCodeVal] = useState<any>("")
  const [promotionCodeIDVal, setPromotionCodeIDVal] = useState<any>("")
  const classes = useStyle();

  useEffect(() => {
    async function fetchData() {
      return await axiosInstance.post('subscription/list', { organization_id: organization_id },formAxiosHeader(access_token))
    }
    if(access_token){
      if (params.productID !== null) {
        const subscriptionListObj: any = fetchData()
        subscriptionListObj.then(function (subscriptionListObj: any) {
          const selectedplan = subscriptionListObj.plans.filter((item: any) => {
            return item.product_id === params.productID
          })
          setsubscriptionObj({
            currentPlan: subscriptionListObj.currentPlan?.filter((item: any) => item?.subscription_type === selectedplan[0]?.subscription_type)[0],
            selectedPlan: selectedplan[0]
          })
          setPageLoading(true)
        })
      }
    }
  }, [organization_id, access_token])

  const calculateamount = (currentPlan: any) => {
    setPageLoadingWait(true)
    async function fetchData() {
      return await axiosInstance.post('subscription/proration-invoice', { organization_id: organization_id, price: priceId, proration_date: Math.floor(Date.now() / 1000) },formAxiosHeader(access_token, currentPlan?.subscription_type))
    }
    const calculateAmountObj: any = fetchData()
    calculateAmountObj.then(function (amountObj: any) {
      setAmountObj(amountObj)
      setPageLoadingWait(false)
      setCalculate(false)
      setStep(3)
    })

  }

  const confirmSubscription = (currentPlan: any) => {
    setPageLoading(false)
    async function fetchData() {
      return await axiosInstance.post('subscription/update-subscription', { organization_id: organization_id, price: priceId, promotion_code: promotionCodeIDVal },formAxiosHeader(access_token, currentPlan?.subscription_type))
    }
    const updatePlan: any = fetchData()
    updatePlan.then(function (amountObj: any) {
      if (amountObj?.status === 201) {
        setPageLoading(false)
        history.replace("/upgrade-successfully")
      } else {
        alert("Something went wrong please try again")
      }
    })
  }

  const confirmCouponCode = async () => {
    if(promotionCodeVal){
      setPageLoading(true);
      setPromotionCodeLocading(true);
      const promotionCodeObj: any = await axiosInstance.post('subscription/verify-promotion-code', { promotion_code: promotionCodeVal },formAxiosHeader(access_token))
      if(promotionCodeObj) {
        if (promotionCodeObj?.status == true) {
          setPageLoading(true);
          setPromotionCodeLocading(false);
          setPromotionCodeIDVal(promotionCodeObj?.data?.promotion_code_id);
          toast.success(promotionCodeObj.message);
        } else {
          setPageLoading(true);
          setPromotionCodeLocading(false);
          setPromotionCodeIDVal("");
          toast.error(promotionCodeObj.message);
        }
      }
    } else {
      toast.error("Coupon code is a required field.");
    }
  }

  if (!pageLoading || !subscriptionObj) {
    return <div className="subscription-page-load">
      <Loading title={LoadingTitle.Loading} />
    </div>
  }
  const { currentPlan, selectedPlan } = subscriptionObj
  let priceBoxData = selectedPlan?.product_plan?.map((item: any) => {
    return {
          label: selectedPlan?.prouct_name?.split(" - ")?.pop(),
          price: `${currencyFormat(item?.amount_decimal, item?.currency)}${monthFormat(item?.interval_count, item?.interval)}`,
          id: item?.id,
          interval: item?.interval
      }
  })
  if(!priceBoxData?.map((item: any)=> item?.id)?.includes(currentPlan.plan.id)){
    priceBoxData.unshift({
      label: currentPlan?.name?.split(" - ")?.pop(),
          price: `${currencyFormat(currentPlan.plan?.amount_decimal, currentPlan.plan?.currency)}${monthFormat(currentPlan.plan?.interval_count, currentPlan.plan?.interval)}`,
          id: currentPlan.plan.id,
          interval: currentPlan.plan.interval
    })
  }
  return <Box className={classes.addOrUpdateContainer} display="flex" alignItems={"center"} justifyContent="center">
  <Box display={"flex"} flexDirection="column">
      <Typography variant='h4'>Confirm Subscription</Typography>
      <Typography variant='body1'>Please select duration to proceed the next step of subscription.</Typography>
      <Box>
          <Grid container className={classes.planDetailUl} spacing={3}>
              <Grid item xs={12}>
                  <Typography variant='h6' style={{textAlign:'center', marginBottom: 10}}>{subscriptionCategoriesLabel[selectedPlan?.subscription_type]}</Typography>
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1' style={{textAlign:'center', marginBottom: 10}}>{currentPlan?.name?.split(" - ")?.pop()}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1' style={{textAlign:'center', marginBottom: 10}} className={clsx(priceId && classes.selectedPriceDataColor)}>{selectedPlan?.prouct_name?.split(" - ")?.pop()}</Typography>
              </Grid>
              {
                  Object.keys(currentPlan?.metadata).map(key => {
                  return <React.Fragment key={key}>
                          <Grid item xs={6}>
                              <Typography variant='body1'>{labelMeta[key]}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                              <Typography variant='body1' style={{textAlign:'center'}}>{abbrNum(currentPlan.metadata[key])}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                              <Typography className={clsx(priceId && classes.selectedPriceDataColor)} variant='body1' style={{textAlign:'center'}}>{abbrNum(selectedPlan.prouct_metadata[key])}</Typography>
                          </Grid>
                      </React.Fragment>
                  })
              }
          </Grid>
          <Grid container className={classes.priceBoxRoot} spacing={2}>
                  {priceBoxData?.map((item: any, key: number, arr: any)=>{
                      return <Grid item xs={arr.length === 2 ? 6 : arr.length === 3 ? 4 : arr.length === 4 ? 3 : 12} key={key} ><Box onClick={()=>{
                        if(currentPlan?.plan?.id !== item?.id){
                          setPriceId(item.id)
                          setCalculate(true)
                          setStep(0)
                          setUpdateInterval(item.interval)
                        }
                      }} display={"flex"} flexDirection="column" className={clsx(classes.priceBox, currentPlan?.plan?.id === item?.id && classes.disabledPriceBox, priceId === item?.id && classes.selectedPriceBox)}>
                          <Typography variant='h6'>{item?.label}</Typography>
                          <Typography variant='body1'>{item?.price}</Typography>
                      </Box></Grid>
                  })}
          </Grid>
          {isCalculate &&
            <div className="d-flex justify-content-center _pt10">
              {pageLoadingWait ? <Loading title={LoadingTitle.Loading} /> :
                <div className='btn-subscription' onClick={() => calculateamount(currentPlan)}>Calculate amount for this billing cycle</div>
              }
            </div>}
            {step ? <div className='text-center section-updating'>
            <div className='d-flex justify-content-center section-btn-updating'>
              <input placeholder="Coupon Code" value={promotionCodeVal} onChange={(e) => setPromotionCodeVal(e.target.value)} />
              <div style={{marginLeft: "10px"}} className='btn-subscription' onClick={() => confirmCouponCode()}>Add { promotionCodeLocading ? <CircularProgress size={12} style={{color: "#fff"}} /> : "" }</div>
            </div>
            <p className="title">You will be charged {currencyFormat(upgradeAmountObj.total, upgradeAmountObj.currency)} after updating to plan {selectedPlan.prouct_name} ({updateInterval}ly)</p>
            <div className='d-flex justify-content-center section-btn-updating'>
              <Link className='btn-subscription cancel' to="/">Cancel</Link>
              <div className='btn-subscription' onClick={() => confirmSubscription(currentPlan)}>Confirm</div>
            </div>
            <p className='disclaimer-updating'>This amount is calculated based on current usage and remaining usage of selected plan.</p>
          </div> : null}
      </Box>
  </Box>
</Box>
};

export { UpgradeSubscription };
