import { useCallback, useContext } from 'react';
import { ILayerState, LayerPaneContext } from '../context/layerPaneContext';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useSuggestIntegrantChildStore } from './useSuggestIntegrantChildStore';
import { useIntegrantsService } from './api/useIntegrantsService';
import { convertToGraphData } from '../utils';
import { IntegrantKind } from '../types/common';

interface IUseLayerPaneStore {
  selectedParentId: string;
  layers: ILayerState[];
  onSelectLayer: (id: string, parent_id: string) => void;
}

const useLayerPaneStore = (): IUseLayerPaneStore => {
  const [state, setState] = useContext(LayerPaneContext);
  const { data, currentIntegrant, setCurrentIntegrant, setData, setRootIntegrantId, setSelectedIntegrant, setIntegrantKind } = useIntegrantGraphStore();
  const { getDetail } = useIntegrantsService();
  const { onToggleMenu } = useSuggestIntegrantChildStore();
  const { layers, selectedParentId } = state;
  const resetStore = useCallback((): void => {
    onToggleMenu(false);
  }, [onToggleMenu]);

  const onSelectLayer: IUseLayerPaneStore['onSelectLayer'] = async (id, parent_id) => {
    const detailList = await getDetail(id);
    resetStore();
    const graphData = convertToGraphData(detailList);
    setData(graphData);
    setRootIntegrantId(id);
    setSelectedIntegrant(graphData.nodes[0]);
    setIntegrantKind(IntegrantKind.Default);
  };

  return {
    layers,
    onSelectLayer,
    selectedParentId
  };
};

export { useLayerPaneStore };
