import React, { FunctionComponent, useRef } from 'react';
import ReactPlayer from 'react-player';

interface PrimaryVideoPlayerProps {
  url?: string;
  onError?: (e: any) => any;
  light?: string;
}

const PrimaryVideoPlayer: FunctionComponent<PrimaryVideoPlayerProps> = ({
  url = '',
  onError = () => {},
  light = ''
}) => {
  return (
    <div>
      <ReactPlayer url={url} onError={onError} controls playsinline playing={true} light={light} />
    </div>
  );
};

export default PrimaryVideoPlayer;
