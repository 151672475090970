import React, { FunctionComponent } from 'react';
import { BarLoader } from 'react-spinners';
import { Layer } from '../common/Layer';

interface ILoadingProps {
  title: string;
}

const Loading: FunctionComponent<ILoadingProps> = ({ title }) => {
  return (
    <Layer direction="column" fill center>
      <BarLoader />
      <Layer style={{ marginTop: 5, marginBottom: 5 }}>
        <small>{title}</small>
      </Layer>
    </Layer>
  );
};

export { Loading };
