import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import {IAPIResponseDTO} from '../../../lib/common';
import axiosInstance from '../../utils/api-interceptor'

interface IIndividualSubscriptionDetailService {
    getDashboardOverview: (data: any) => Promise<IAPIResponseDTO>;
    buyNewProduct: (data: any) => Promise<IAPIResponseDTO>;
}

const useIndividualSubscriptionDetailService = (): IIndividualSubscriptionDetailService => {
    const { individual } = useAuthStore();

    const getDashboardOverview: IIndividualSubscriptionDetailService["getDashboardOverview"] = async (data: any) => {
        try {
            return await axiosInstance.post("/individual-subscription-detail/dashboard-overview",data, formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
            }
        }
    }
    
    const buyNewProduct: IIndividualSubscriptionDetailService["buyNewProduct"] = async (data: any) => {
        try {
            return await axiosInstance.post("/individual-subscription-detail/buy-new-product",data, formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
            }
        }
    }

  return { getDashboardOverview, buyNewProduct };
};

export { useIndividualSubscriptionDetailService };
