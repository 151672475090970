import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/api-interceptor';
import { formAxiosHeader } from '../../config';
import { Input } from '../../common/Input';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { useAuthStore } from '../../hooks/useAuthStore';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';
import { isEmptyString, isEmptyObj, validateEmail, validatePhoneNo } from './../../utils';
import '../../styles/subscription.css';
import { useToastStore } from '../../hooks/useToastStore';
import { CommonLink, PrimaryButton } from 'common';

const UserAccounts = (props: any) => {
  const { userType } = useParams<any>();
  const [pageLoading, setPageLoading] = useState(false);
  const [isEdit, setEdit] = useState(userType === 'create-user-account');
  const history = useHistory();
  const { isOpenToast } = useToastStore();

  const [userAccount, setUserAccount] = useState<any>({
    name_first: '',
    name_last: '',
    email_address: '',
    phone: null,
    password: ''
  });

  let isSubmitDisable = false;
  const { individual } = useAuthStore();
  const { selectedOrganization } = useOrganizationStore();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id, email_address, name_first, name_last, phone, password } = userAccount;

  const getorganization = async () => {
    await axiosInstance
      .post(
        'integrant/get-organization',
        { organization_id: selectedOrganization.id },
        formAxiosHeader(individual.access_token)
      )
      .then((response: any) => {
        setPageLoading(false);
        const { individuals } = response;
        individuals.forEach((element: any) => {
          if (element.id === userType) {
            setUserAccount(element);
          }
        });
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (userType !== 'create-user-account') {
      getorganization();
    }
  }, [userType]);

  if (pageLoading || (!isEdit && isEmptyObj(userAccount))) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Loading} />
      </div>
    );
  }

  const inputsHandler = async (e: any) => {
    const { name, value } = e.target;
    setUserAccount({ ...userAccount, [name]: value });
  };

  function submitHandler() {
    const obj: any = {
      email_address,
      name_first,
      name_last,
      phone,
      status: 'active',
      name_middle: '',
      organization_id: selectedOrganization.id,
      created_by: selectedOrganization.account_id,
      updated_by: selectedOrganization.account_id
    };
    if (isEdit) {
      obj.password = password;
    } else {
      obj.individual_id = id;
    }
    axiosInstance
      .post('integrant/create-edit-individuals', obj, formAxiosHeader(individual.access_token))
      .then((response: any) => {
        const text = 'User Account has been ' + (isEdit ? 'Created.' : 'Updated.');
        isOpenToast('success', text);
        history.push('/organization');
      })
      .catch(err => {
        return err;
      });
  }

  if (isEdit) {
    isSubmitDisable =
      !isEmptyString(name_first) &&
      !isEmptyString(name_last) &&
      !validatePhoneNo(phone) &&
      !validateEmail(email_address) &&
      !isEmptyString(password);
  } else {
    isSubmitDisable =
      !isEmptyString(name_first) &&
      !isEmptyString(name_last) &&
      !validatePhoneNo(phone) &&
      !validateEmail(email_address);
  }

  return (
    <div className="cp">
      <div className="container">
        <p className="page-title">{isEdit ? 'Add' : 'Edit'} User Account</p>
        <div className="row align-items-center">
          <div className="col-md-6">
            <FormInput
              isFirst={true}
              label={
                <>
                  First Name<span className="asterisk-symbol">*</span>
                </>
              }
              name="name_first"
              value={name_first}
              required={isSubmitted && isEmptyString(name_first)}
              inputsHandler={inputsHandler}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              label={
                <>
                  Last Name<span className="asterisk-symbol">*</span>
                </>
              }
              name="name_last"
              value={name_last}
              required={isSubmitted && isEmptyString(name_last)}
              inputsHandler={inputsHandler}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              label={
                <>
                  Phone<span className="asterisk-symbol">*</span>
                </>
              }
              name="phone"
              type="number"
              value={phone}
              required={isSubmitted && validatePhoneNo(phone)}
              inputsHandler={inputsHandler}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              label={
                <>
                  Email<span className="asterisk-symbol">*</span>
                </>
              }
              name="email_address"
              value={email_address}
              required={isSubmitted && validateEmail(email_address)}
              inputsHandler={inputsHandler}
            />
          </div>
          {isEdit && (
            <div className="col-md-6">
              <FormInput
                type="password"
                label={
                  <>
                    Password<span className="asterisk-symbol">*</span>
                  </>
                }
                name="password"
                value={password}
                required={isSubmitted && isEmptyString(password)}
                inputsHandler={inputsHandler}
              />
            </div>
          )}
        </div>
        <div
          className="btn-strip"
          style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <CommonLink to={'/organization'} children={'Back'} icon="back" />
          <PrimaryButton
            style={{ marginLeft: '10px' }}
            onClick={submitHandler}
            maxContent={true}
            secondaryButton={true}
            disabled={!isSubmitDisable}
            children={'Submit'}
            icon="save"
          />
        </div>
      </div>
    </div>
  );
};

const FormInput = (props: any) => {
  const { type, name, label, value, inputsHandler, isDisable, isFirst, required } = props;
  return (
    <div className={'d-flex organization-strip ' + (isFirst && 'f')}>
      <div className="organization-label">{label}</div>
      <div className="organization-field">
        <Input
          disabled={isDisable}
          value={value}
          name={name}
          required={required}
          type={type ? type : 'text'}
          autoFocus
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
        />
      </div>
    </div>
  );
};

export { UserAccounts };
