import { IIntegrantTypeDTO } from '../../lib/integrantType';
import { useContext, useEffect } from 'react';
import { isUndefined } from 'util';
import { integrant_type_, integrant_type_ingredients } from '../common/models';
import { IntegrantTypeContext } from '../context/products/integrantTypeContext';
import { IIntegrantTypeState } from '../context/products/types';
import { isPopulated } from '../utils';
import { useIntegrantTypesService } from './api/useIntegrantTypesService';
import { useAuthStore } from './useAuthStore';

interface IIntegrantTypeStore extends IIntegrantTypeState {
  onSelectIntegrantType: (integrantTypesData: any) => void;
  setIntegrantTypes: (integrantTypes: IIntegrantTypeDTO[]) => void;
  reset: () => void;
  initIntegrantType: (searchValue?: string) => Promise<void>;
  initIntegrantData: () => Promise<void>;
  initProductDisableStatus: (integrant_type_id: string, is_disabled: boolean) => Promise<void>;
  initGetIntegrantsByIntegrantTypeId: (integrant_type_id: string) => Promise<void>;
  updateProductIngredients: (data: any) => void;
  initGetSurveyList: () => any;
  initGetSurveyQuestionsList: (integrant_type_id: string) => any;
  initGetSurveyInvitationList: (integrant_type_id: string) => any;
  initGetBunnyVideoUploadList: (data: any) => any;
}

const useIntegrantTypeStore = (currentIIntegrantTypeId?: IIntegrantTypeDTO['id']): IIntegrantTypeStore => {
  const [state, setState] = useContext(IntegrantTypeContext);
  const { individual } = useAuthStore();
  const {
    integrantTypes,
    isLoading,
    selectedIntegrantType,
    productIngredientsData,
    surveyList,
    surveyQuestionsList,
    surveyInvitationList,
    getBunnyVideoUploadInfoData
  } = state;
  const {
    getAll,
    getIntegrantData,
    updateProductDisabledStatus,
    getIngredientsByIntegrantTypeId,
    getSurveyList,
    getSurveyQuestionsList,
    getSurveyInvitationList,
    getBunnyVideoUploadInfoAPI
  } = useIntegrantTypesService(individual);

  const onSelectIntegrantType: IIntegrantTypeStore['onSelectIntegrantType'] = integrantTypesData => {
    setState({ ...state, selectedIntegrantType: integrantTypesData });
  };

  const updateProductIngredients: IIntegrantTypeStore['updateProductIngredients'] = data => {
    setState({ ...state, productIngredientsData: { ...state.productIngredientsData, ...data } });
  };

  const reset: IIntegrantTypeStore['reset'] = () => {
    setState({
      selectedIntegrantType: integrant_type_,
      isLoading: true,
      integrantTypes: [],
      productIngredientsData: integrant_type_ingredients,
      surveyList: {
        loading: false,
        status: '',
        message: '',
        data: null
      },
      surveyQuestionsList: {
        loading: false,
        status: '',
        message: '',
        data: null,
        offerData: null
      },
      surveyInvitationList: {
        loading: false,
        status: '',
        message: '',
        data: null
      },
      getBunnyVideoUploadInfoData: {
        loading: false,
        status: '',
        message: '',
        data: null
      }
    });
  };

  const setIntegrantTypes: IIntegrantTypeStore['setIntegrantTypes'] = integrantTypes => {
    setState({ ...state, integrantTypes: integrantTypes });
  };

  const initIntegrantType: IIntegrantTypeStore['initIntegrantType'] = async (searchValue?: string) => {
    const integrantTypes = await getAll(searchValue);
    if (typeof integrantTypes !== 'undefined') {
      setIntegrantTypes(integrantTypes);
    }
  };

  const initIntegrantData: IIntegrantTypeStore['initIntegrantData'] = async () => {
    const integrantTypesData = await getIntegrantData();
    if (typeof integrantTypesData === 'object') {
      onSelectIntegrantType(integrantTypesData);
    }
  };

  const initProductDisableStatus: IIntegrantTypeStore['initProductDisableStatus'] = async (
    integrant_type_id,
    is_disabled
  ) => {
    const data = await updateProductDisabledStatus(integrant_type_id, is_disabled);
    if (data?.status === '1') {
      setState({
        ...state,
        integrantTypes: state?.integrantTypes?.map((item: any) => {
          return item?.id === data?.integrant_type_id ? { ...item, is_disabled: data?.is_disabled } : item;
        })
      });
    }
  };

  const initGetIntegrantsByIntegrantTypeId: IIntegrantTypeStore['initGetIntegrantsByIntegrantTypeId'] = async integrant_type_id => {
    updateProductIngredients({ loading: true });
    const data = await getIngredientsByIntegrantTypeId(integrant_type_id);
    updateProductIngredients({ loading: false, ...data });
  };

  const initGetSurveyList: IIntegrantTypeStore['initGetSurveyList'] = async () => {
    setState({ ...state, surveyList: { ...state.surveyList, loading: true } });
    const data = await getSurveyList();
    setState({ ...state, surveyList: { ...data, loading: false } });
  };

  const initGetSurveyInvitationList: IIntegrantTypeStore['initGetSurveyInvitationList'] = async integrant_type_id => {
    setState(pre => ({ ...pre, surveyInvitationList: { ...pre.surveyInvitationList, loading: true } }));
    const data = await getSurveyInvitationList(integrant_type_id);
    setState(pre => ({ ...pre, surveyInvitationList: { ...data, loading: false } }));
    // setState({...state,surveyInvitationList:{...data,loading: false}})
  };

  const initGetBunnyVideoUploadList: IIntegrantTypeStore['initGetBunnyVideoUploadList'] = async (data: any) => {
    setState(pre => ({ ...pre, getBunnyVideoUploadInfoData: { ...pre.getBunnyVideoUploadInfoData, loading: true } }));
    const res = await getBunnyVideoUploadInfoAPI(data);
    setState(pre => ({ ...pre, getBunnyVideoUploadInfoData: { ...res, loading: false } }));
  };

  const initGetSurveyQuestionsList: IIntegrantTypeStore['initGetSurveyQuestionsList'] = async integrant_type_id => {
    setState({ ...state, surveyQuestionsList: { ...state.surveyQuestionsList, loading: true } });
    let data = await getSurveyQuestionsList(integrant_type_id);
    data['data'] = data.data?.map((item: any) => {
      return {
        ...item,
        options: item.options ? JSON.parse(item.options) : []
      };
    });
    setState({ ...state, surveyQuestionsList: { ...data, loading: false } });
  };

  useEffect(() => {
    if (currentIIntegrantTypeId !== '') {
      if (!isUndefined(currentIIntegrantTypeId) && isPopulated(integrantTypes)) {
        setState(s => ({
          ...s
        }));
      }
    }
  }, [currentIIntegrantTypeId, integrantTypes, setState]);

  return {
    integrantTypes,
    isLoading,
    selectedIntegrantType,
    productIngredientsData,
    surveyList,
    surveyQuestionsList,
    surveyInvitationList,
    getBunnyVideoUploadInfoData,
    onSelectIntegrantType,
    setIntegrantTypes,
    initIntegrantType,
    initIntegrantData,
    reset,
    initProductDisableStatus,
    initGetIntegrantsByIntegrantTypeId,
    updateProductIngredients,
    initGetSurveyList,
    initGetSurveyQuestionsList,
    initGetSurveyInvitationList,
    initGetBunnyVideoUploadList
  };
};

export { useIntegrantTypeStore };
