import React, { CSSProperties, FunctionComponent, useEffect } from 'react';
import { Layer } from '../common';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { primaryColor } from '../styles/default';
import { IntegrantKind } from '../types/common';
import { isEmptyString } from '../utils';
import { IntegrantDetails } from './IntegrantDetails';
import { IntegrantExhibits } from './IntegrantExhibits';
import { IntegrantFacets } from './IntegrantFacets';
import { IntegrantLocation } from './IntegrantLocation';
import { IntegrantOptions } from './IntegrantOptions';
import { IntegrantPaneFooter } from './IntegrantPaneFooter';
import { IntegrantPaneHeader } from './IntegrantPaneHeader';
import { IntegrantAlert } from './IntegrantAlert';
import { ProductFeedbackQuestion } from './ProductFeedbackQuestion';
// import { Prompt } from 'react-router'

const IntegrantPane: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();
  const { onChangeIndex } = useIntegrantPaneStore();
  const { currentIntegrant, integrantKind, currentEdge, firstCurrentIntegrant } = useIntegrantGraphStore();
  const { tabIndex } = useIntegrantPaneStore();
  const isTransfer = false;
  //const isTransfer = typeof currentEdge !== 'undefined' && currentEdge.edge_type !== IntegrantKind.Transfer;

  //let tabIndex = 4

  useEffect(() => {
    if ((integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer) && tabIndex < 3) {
      onChangeIndex(3);
    }
  }, [integrantKind, onChangeIndex, tabIndex]);

  return (
    <>
      {/* <Prompt
        when={firstCurrentIntegrant !== currentIntegrant}
        message='You have unsaved changes, are you sure you want to leave?'
      /> */}
      <Layer
        className="integrantPane"
        direction="column"
        style={{ boxShadow: isDraft ? `-5px 0px 30px -20px ${primaryColor}` : 'none' }}
      >
        <IntegrantPaneHeader />
        {isTransfer && tabIndex !== 0 ? <IntegrantAlert title={'This item is locked'} /> : null}
        <IntegrantOptions isVisible={tabIndex === 0} />
        <IntegrantDetails isDisabled={isTransfer} isVisible={tabIndex === 1 && !isEmptyString(currentIntegrant.id)} />
        <IntegrantFacets isDisabled={isTransfer} isVisible={tabIndex === 2} />
        <IntegrantLocation isDisabled={isTransfer} isVisible={tabIndex === 3} />
        <IntegrantExhibits isDisabled={isTransfer} isVisible={tabIndex === 4} />
        <IntegrantPaneFooter />
      </Layer>
    </>
  );
};

export { IntegrantPane };
