import React, { useCallback, useEffect, useContext } from 'react';
import axiosInstance from '../utils/api-interceptor'
import { formAxiosHeader, slugPatten } from '../config';
import { useAuthStore } from '../hooks/useAuthStore';
import { useOrganizationStore } from '../hooks/useOrganizationStore';

import { ICreateTransferInState, CreateTransferInContext } from '../context/createTransferInContext';
import { getTimeNumber } from 'utils';

interface ICreateTransferInStore extends ICreateTransferInState {
  /*Function*/
  setOpen: (isOpen: boolean) => void;
  selectedProduct: (currItem: any) => void;
  closeProduct: () => any;
  clone: (
    integrant: any,
    tracking_id: string
  ) => any;
}

const useCreateTransferInStore = (): ICreateTransferInStore => {
  const [state, setState] = useContext(CreateTransferInContext);
  const { individual } = useAuthStore();
  const { selectedOrganization } = useOrganizationStore();

  useEffect(() => {
    axiosInstance.post(
      'integrant/transferred-integrants',
      { organization_id: selectedOrganization.id },
      formAxiosHeader(individual.access_token)
    ).then((response: any) => {
      setState({ type: 'setLoading', payload: false });
      setState({ type: 'transferList', payload: response });
    }).catch(err => {
      return err
    })
  }, [axiosInstance]);

  const setOpen: ICreateTransferInStore['setOpen'] = (isOpen: boolean) => {
    setState({ type: 'isOpen', payload: isOpen })
  }

  const selectedProduct: ICreateTransferInStore['selectedProduct'] = (currItem: any) => {
    setState({ type: 'selectedIntegrantId', payload: currItem.id });
    setState({ type: 'selectedIntegrantObj', payload: currItem });
    setOpen(true)
  }

  const closeProduct: ICreateTransferInStore['closeProduct'] = () => {
    setState({ type: 'selectedIntegrantId', payload: '' });
    setState({ type: 'selectedIntegrantObj', payload: {} });
    setOpen(false)
  }

  const clone: ICreateTransferInStore['clone'] = useCallback(
    async (integrant, tracking_id) => {
      const integrantClone = {
        transfer_id: integrant.id,
        integrant_id: integrant.integrant_id,
        external_id: tracking_id,
        external_id_slug: tracking_id.replace(slugPatten, ''),
        organization_id: individual.organization_id
      };
      return await axiosInstance.post('integrant/clone-transferred-integrants', integrantClone, formAxiosHeader(individual.access_token)).then((res: any) => {
        return res
      }).catch(err => {
        return err
      })
      //const updatedIntegrant: any = await request.post(`integrant/clone`, integrantClone);
      // return updatedIntegrant;
    },
    [axiosInstance]
  );

  return {
    ...state,
    clone,
    setOpen,
    selectedProduct,
    closeProduct
  }
};

export { useCreateTransferInStore };
