import { IExhibitDTO } from '../../lib/exhibit';
import { IIntegrantDTO } from '../../lib/integrant';
import { IMutationDTO } from '../../lib/mutation';
import { IOrganizationDTO } from '../../lib/organization';
import { RoleTypes } from '../../lib/auth';

export enum IntegrantKind {
  Transit = 'Transit',
  Mutation = 'Mutation',
  Transfer = 'Transfer',
  Default = 'Default'
}

export enum LoadingTitle {
  Updating = 'Updating',
  Loading = 'Loading',
  Integrant = 'Loading Integrants',
  IntegrantTypes = 'Loading Products',
  Tracking = 'Loading Batches',
  Subscription = 'Loading Subscription Details',
  Plans = 'Loading Plans'
}

export interface IIndividualRole {
  readonly title: string;
  readonly role: RoleTypes;
  readonly organization: IOrganizationDTO;
  readonly organization_id: string;
}

export type IExhibitReturn = { id: IExhibitDTO['id']; url: IExhibitDTO['url'] };
export type IIntegrantDelete = { id: IMutationDTO['id']; integrant_id: IIntegrantDTO['id'] };

export type CreateContext<T> = [T, React.Dispatch<React.SetStateAction<T>>];
export type CreateActions<T, Q> = [T, React.Dispatch<Q>];

export interface IAction<T, P = {}> {
  type: T;
  payload: P;
}

export type IResetAction = IAction<'reset'>;

export interface IIntegrantOptionPaneProps {
  isVisible: boolean;
  isDisabled?: boolean;
  isTransfer?: boolean;
}
