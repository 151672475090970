import { makeStyles, Theme, createStyles, Grid, Typography } from '@material-ui/core';
import { PrimaryButton, Input } from 'common';
import { useOrganizationExhibitStore } from 'hooks/useOrganizationExhibitStore';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory, useParams } from 'react-router-dom';
import { GoFile } from 'react-icons/go';
import { iconStyle, labelStyle } from 'styles/default';
import { TextArea } from 'common/TextArea';
import clsx from 'clsx';
import moment from 'moment';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formDiv: {
      margin: '20px 0',
      '&>*:not(:last-child)': {
        marginBottom: 15
      }
    },
    uploadFileError: {
      color: 'red !important'
    }
  })
);

let createFlag = false;
let updateFlag = false;

export default function CreateorEditOrganizationExhibit() {
  const {
    initCreateOrganizationExhibit,
    createData,
    initGetOneOrganizationExhibit,
    getOneData,
    initUpdateOrganizationExhibit,
    updateData
  } = useOrganizationExhibitStore();
  const classes = useStyle();
  const history = useHistory();
  const { type, id }: any = useParams();
  const [createOrEditAttachmentData, setCreateOrEditAttachmentData] = useState<any>({
    title: '',
    description: '',
    effective_date: new Date(),
    file: null
  });
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (
      createOrEditAttachmentData['title'] &&
      ((type === 'create' && createOrEditAttachmentData['file']?.size) ||
        (type === 'edit' &&
          (Object.keys(createOrEditAttachmentData['file'])?.length > 0 || createOrEditAttachmentData['file']?.size)))
    ) {
      let formData = new FormData();
      createOrEditAttachmentData['title'] && formData.append('title', createOrEditAttachmentData['title']);
      createOrEditAttachmentData['description'] &&
        formData.append('description', createOrEditAttachmentData['description']);
      createOrEditAttachmentData['effective_date'] &&
        formData.append('effective_date', createOrEditAttachmentData['effective_date']);
      createOrEditAttachmentData['file']?.size && formData.append('file', createOrEditAttachmentData['file']);
      if (type === 'create') {
        initCreateOrganizationExhibit(formData);
        createFlag = true;
      } else {
        initUpdateOrganizationExhibit(id, formData);
        updateFlag = true;
      }
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    setCreateOrEditAttachmentData({
      title: '',
      description: '',
      effective_date: new Date(),
      file: null
    });
    setError(false);
  };

  const inputsHandler = (e: any) => {
    const { name, value } = e.target;
    setCreateOrEditAttachmentData((pre: any): any => ({ ...pre, [name]: value }));
    setError(false);
  };

  useEffect(() => {
    if (!createData?.loading && createData.status === '1' && createFlag) {
      handleClose();
      createFlag = false;
      history.push('/organization-exhibits');
    }
  }, [createData]);

  useEffect(() => {
    if (!updateData?.loading && updateData.status === '1' && updateFlag) {
      updateFlag = false;
      history.push('/organization-exhibits');
    }
  }, [updateData]);

  useEffect(() => {
    if (getOneData?.status === '1' && !getOneData?.loading) {
      setCreateOrEditAttachmentData({
        title: getOneData?.data?.title,
        description: getOneData?.data?.description,
        effective_date: new Date(getOneData?.data?.effective_date),
        file: {
          name: getOneData?.data?.url?.split('/')?.pop()
        }
      });
    }
  }, [getOneData]);

  useEffect(() => {
    handleClose();
    if (type === 'edit' && id) {
      initGetOneOrganizationExhibit(id);
    }
  }, []);

  return (
    <div className="cp">
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <div className="subscription-title">{type === 'create' ? 'Add' : 'Edit'} Attachment</div>
            <PrimaryButton
              icon="back"
              children={'Back'}
              onClick={() => {
                history.push('/organization-exhibits');
              }}
              maxContent
              secondaryGreenButton
            />
          </div>
        </div>
        <div>
          <Grid container spacing={2} className={classes.formDiv}>
            <Grid item xs={2}>
              <Typography variant="body1">
                Title<span className="asterisk-symbol">*</span>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Input
                value={createOrEditAttachmentData['title']}
                name={'title'}
                required={!createOrEditAttachmentData['title'] && error}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
              />
              {error && !createOrEditAttachmentData['title'] && <p className="error-message">Please enter a Title</p>}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Description</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextArea
                value={createOrEditAttachmentData['description']}
                name={'description'}
                required={false}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => inputsHandler(event)}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Effective Date<span className="asterisk-symbol">*</span>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <DatePicker
                selected={createOrEditAttachmentData['effective_date']}
                onChange={(date: any) =>
                  setCreateOrEditAttachmentData((pre: any): any => ({ ...pre, effective_date: date }))
                }
                // onChangeRaw={e => e.preventDefault()}
                dateFormat="MM/dd/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="MM/DD/YYYY"
              />
              {error && !createOrEditAttachmentData['effective_date'] && (
                <p className="error-message">Please select Effective Date</p>
              )}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Upload Attachment<span className="asterisk-symbol">*</span>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <label
                style={labelStyle}
                htmlFor="file"
                className={clsx(error && !createOrEditAttachmentData['file'] && classes.uploadFileError)}
              >
                <GoFile style={iconStyle}></GoFile>{' '}
                {createOrEditAttachmentData['file'] ? createOrEditAttachmentData['file']?.name : `Choose a file`}
              </label>
              <input
                type={'file'}
                id="file"
                style={{ display: 'none' }}
                onClick={(e: any): string => (e.target.value = '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  if (e?.target?.files !== null) {
                    let obj = { ...createOrEditAttachmentData, file: e.target?.files[0] };
                    setCreateOrEditAttachmentData(obj);
                  }
                }}
              />
              {error && !createOrEditAttachmentData['file'] && (
                <p className="error-message">Please select attachment file</p>
              )}
            </Grid>
          </Grid>
          <PrimaryButton
            children={'Submit'}
            loading={createData?.loading || updateData?.loading}
            disabled={createData?.loading || updateData?.loading || getOneData.loading}
            secondaryGreenButton
            maxContent
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
