import React, { FunctionComponent, useEffect, useState } from 'react';
import axiosInstance from '../../utils/api-interceptor';
import { formAxiosHeader } from '../../config';
import { useAuthStore } from '../../hooks/useAuthStore';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { FormInput } from './FormInput';
import { isEmptyObj } from './../../utils';
import { useToastStore } from '../../hooks/useToastStore';
import '../../styles/subscription.css';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SketchPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';
import { LabelBtn, PrimaryButton } from 'common/Button/buttons';
import { useHistory } from 'react-router-dom';
import Icon from 'common/Icon';
import { CommonLink } from 'common';
import { Theme, createStyles, makeStyles, Checkbox, FormControlLabel, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useUserStore } from 'hooks/useUserStore';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    domainRoot: {
      display: 'flex',
      alignItems: 'center'
    },
    domainVerified: {
      display: 'flex',
      alignItems: 'center',
      color: '#008F2B'
    },
    domainVerificationSteps: {
      marginTop: 20,
      '&>div': {
        '&>p': {
          '&>span': {
            color: '#546178'
          }
        },
        margin: '10px 0'
      }
    },
    checkbox: {
      color: '#008F2B !important'
    },
    disableSecondLayer: {
      margin: '15px 0px'
    }
  })
);

const acceptedFileTypes = '.pdf, .png, .jpeg, .jpg';
const colors = ['#306B34', '#1C5253', '#F3FFC6', '#C3EB78', '#B6174B'];

const OrganizationInformation = (props: any) => {
  const classes = useStyle();
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [isTXTRecordAdded, setIsTXTRecordAdded] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isSecondLayerIsDisable, setIsSecondLayerIsDisable] = useState(false);
  const [organizationObj, setOrganizationObj] = useState<any>({});
  const [orgData, setOrgData] = useState<any>({});
  const { individual, selectedSubscription, updateDomainInfo } = useAuthStore();
  const { selectedOrganization } = useOrganizationStore();
  const { verifyDomainData, initVerifyDomain } = useUserStore();
  const { isOpenToast } = useToastStore();
  const {
    id,
    individuals,
    subscription_name,
    domain_name,
    name,
    description,
    logo_url,
    num_seats,
    short_code,
    bg_color,
    bg_image,
    tag_line,
    currentFile,
    backgroundCurrentFile,
    imgObj
  } = organizationObj;
  const [toggleColorPicker, setToggleColorPicker] = useState(false);
  const getorganization = async () => {
    await axiosInstance
      .post(
        'integrant/get-organization',
        { organization_id: selectedOrganization.id },
        formAxiosHeader(individual.access_token)
      )
      .then((response: any) => {
        setIsSecondLayerIsDisable(response.hide_org);
        setPageLoading(false);
        setOrganizationObj(response);
        setOrgData(response);
      })
      .catch(err => {
        return err;
      });
  };
  useEffect(() => {
    getorganization();
  }, []);

  useEffect(() => {
    if (verifyDomainData?.status === '1') {
      setOrgData((pre: any) => ({ ...pre, is_valid_domain_name: true }));
      setOrganizationObj((pre: any) => ({ ...pre, is_valid_domain_name: true }));
    }
  }, [verifyDomainData]);

  if (pageLoading || isEmptyObj(organizationObj)) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Loading + ' Your Organization'} />
      </div>
    );
  }

  const inputsHandler = async (e: any) => {
    const { name, value, files } = e.target;
    if (files && typeof files === 'object') {
      const [fileData, theFile]: any = await readFile(files[0]);
      if (typeof fileData === 'object' && isEmptyObj(fileData)) {
        setOrganizationObj({ ...organizationObj, currentFile: fileData, [name]: theFile.result });
      }
    } else {
      setOrganizationObj({ ...organizationObj, [name]: value });
    }
  };

  const inputsHandlerBGI = async (e: any) => {
    const { name, value, files } = e.target;
    if (files && typeof files === 'object') {
      const [fileData, theFile]: any = await readFile(files[0]);
      if (typeof fileData === 'object' && isEmptyObj(fileData)) {
        setOrganizationObj({ ...organizationObj, backgroundCurrentFile: fileData, [name]: theFile.result });
      }
    } else {
      setOrganizationObj({ ...organizationObj, [name]: value });
    }
  };

  function submitHandler() {
    // setEdit(false)
    if (!isEdit) {
      const formDataImage = new FormData();
      formDataImage.append('name', name);
      formDataImage.append('description', description);
      formDataImage.append('tag_line', tag_line);
      if (bg_color && bg_color != 'Select Color' && bg_color != 'null' && bg_color != 'undefined') {
        formDataImage.append('bg_color', bg_color);
      } else {
        formDataImage.append('bg_color', 'null');
      }
      formDataImage.append('hide_org', String(isSecondLayerIsDisable));
      formDataImage.append('organization_id', selectedOrganization.id);
      formDataImage.append('created_by', selectedOrganization.account_id);
      formDataImage.append('updated_by', selectedOrganization.account_id);
      if (typeof currentFile === 'object' && typeof backgroundCurrentFile === 'object') {
        formDataImage.append('type', 'both');
        formDataImage.append('fileName', currentFile);
        formDataImage.append('fileName', backgroundCurrentFile);
      } else if (typeof currentFile === 'object') {
        formDataImage.append('type', 'logo');
        formDataImage.append('fileName', currentFile);
      } else if (typeof backgroundCurrentFile === 'object') {
        formDataImage.append('type', 'bgimage');
        formDataImage.append('fileName', backgroundCurrentFile);
      } else {
        formDataImage.append('fileName', '');
      }
      if (orgData?.domain_name !== domain_name?.trim()) {
        formDataImage.append('domain_name', domain_name?.trim());
      }
      // if (typeof currentFile === 'object') {
      //   formDataImage.append('fileName', currentFile);
      // } else {
      //   formDataImage.append('fileName', '');
      // }
      // if (typeof backgroundCurrentFile === 'object') {
      //   formDataImage.append('fileNameBG', backgroundCurrentFile);
      // } else {
      //   formDataImage.append('fileNameBG', '');
      // }
      axiosInstance
        .post('integrant/edit-organization-info', formDataImage, formAxiosHeader(individual.access_token))
        .then((response: any) => {
          isOpenToast('success', 'Your Organization details has been successfully updated.');
          // setEdit(true)
          setOrgData((pre: any) => ({ ...pre, ...response }));
          if (individual.domain_name !== response?.domain_name) {
            updateDomainInfo({
              domain_name: response?.domain_name,
              is_valid_domain_name: response?.is_valid_domain_name,
              text_record: response?.text_record
            });
          }
        })
        .catch(err => {
          return err;
        });
    }
  }

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const theFile = event.target;
        return resolve([file, theFile]);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const seatsHandler = (index: number, type: string) => {
    const obj = individuals[index];
    if (type === 'delete') {
      axiosInstance
        .post(
          'integrant/delete-individual',
          {
            id: obj.id,
            organization_id: selectedOrganization.id
          },
          formAxiosHeader(individual.access_token)
        )
        .then((response: any) => {
          isOpenToast('success', 'User Account has been successfully Delete.');
          getorganization();
          // setEdit(true)
        })
        .catch(err => {
          return err;
        });
    }
  };

  const handleSketchPickerChange = (e: any) => {
    setOrganizationObj({ ...organizationObj, bg_color: e.hex });
    setToggleColorPicker(false);
  };
  return (
    <div className="cp">
      <ReactTooltip />
      <div className="container">
        <p className="page-title">Organization</p>
        <div className="row align-items-center">
          <div className="col-md-6">
            <FormInput
              isFirst={true}
              label={
                <>
                  Name<span className="asterisk-symbol">*</span>
                </>
              }
              name="name"
              value={name}
              isDisable={isEdit}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label="Sort Code"
              name="short_code"
              value={short_code}
              isDisable={true}
              inputsHandler={inputsHandler}
            />
            <FormInput
              label={
                <>
                  Tag Line<span className="asterisk-symbol">*</span>
                </>
              }
              name="tag_line"
              value={tag_line}
              isDisable={isEdit}
              inputsHandler={inputsHandler}
            />
          </div>
          <div className="col-md-6 text-right">
            <div className="section-logo-organization">
              <img src={logo_url} alt="" className="organization-logo" />
              <LabelBtn
                style={{ marginLeft: 'auto' }}
                disabled={isEdit}
                htmlFor="logo_url"
                children={currentFile ? currentFile.name : `Change Logo`}
                icon="edit"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <FormInput
            label={
              <>
                Description<span className="asterisk-symbol">*</span>
              </>
            }
            type="textarea"
            name="description"
            value={description}
            inputsHandler={inputsHandler}
            isDisable={isEdit}
          />
        </div>
        {selectedSubscription === 'tracer' && (
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6">
              <div className={'d-flex organization-strip '}>
                <div className="organization-label">Product Background Color</div>
                <div className={'organization-field color-simple-select'}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bg_color ? bg_color : 'Select Color'}
                    label="Select Color"
                    name="bg_color"
                    onChange={inputsHandler}
                    disabled={isEdit}
                  >
                    <MenuItem value="Select Color">Select Color</MenuItem>
                    {colors?.map((item: string, key: number) => {
                      return (
                        <MenuItem value={item} key={key}>
                          <span className="span-color-box" style={{ backgroundColor: item }}></span>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="select-color-root">
                  <div
                    data-tip={'Select custom color'}
                    className="select-color-box"
                    onClick={() => setToggleColorPicker(true)}
                    style={{ backgroundColor: bg_color, pointerEvents: isEdit ? 'none' : 'unset' }}
                  ></div>
                  {toggleColorPicker ? (
                    <div className="sketchpicker-bg-div">
                      <div className="sketchpicker-root" onClick={() => setToggleColorPicker(false)} />
                      <SketchPicker onChange={handleSketchPickerChange} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={'d-flex organization-strip '}>
                <div className="organization-label">Product Background Image (1800px * 250px)</div>
                <div className="organization-field">
                  <div>
                    {bg_image && <img src={bg_image} alt="" className="organization-logo justify-content-left" />}
                    <LabelBtn
                      disabled={isEdit}
                      htmlFor="bg_image"
                      children={backgroundCurrentFile ? backgroundCurrentFile.name : `Product Background Image`}
                      icon="edit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={clsx('row', classes.domainRoot)}>
          <div className="col-md-6">
            <FormInput
              label={
                <>
                  Domain Name<span className="asterisk-symbol">*</span>
                </>
              }
              name="domain_name"
              value={domain_name}
              isDisable={isEdit}
              inputsHandler={inputsHandler}
            />
          </div>
          {orgData?.is_valid_domain_name && (
            <div className={clsx('col-md-6', classes.domainVerified)}>
              Verified <VerifiedUserOutlinedIcon style={{ marginLeft: 5 }} />
            </div>
          )}
        </div>
        <div>
          <FormControlLabel
            className={classes.disableSecondLayer}
            control={
              <Checkbox
                checked={isSecondLayerIsDisable}
                onChange={() => setIsSecondLayerIsDisable(!isSecondLayerIsDisable)}
                classes={{ colorSecondary: classes.checkbox }}
              />
            }
            label="Hide organization documents"
            labelPlacement="start"
          />
        </div>
        {!orgData?.is_valid_domain_name && orgData?.domain_name && orgData?.domain_name === domain_name && (
          <div className={classes.domainVerificationSteps}>
            <p>To verify your domain add new DNS TXT record</p>
            <div>
              <p>
                <span>Type: </span> TXT
              </p>
              <p>
                <span>Name: </span>
                {domain_name}&nbsp;&nbsp;&nbsp;<span>OR: </span>@
              </p>
              <p>
                <span>Content: </span>
                {orgData?.text_record}
              </p>
            </div>
            <p>
              After adding the above TXT record to your domain provider click on the below button to verify the domain.
            </p>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ colorSecondary: classes.checkbox }}
                    checked={isTXTRecordAdded}
                    onChange={(e: any) => setIsTXTRecordAdded(e.target.checked)}
                  />
                }
                label="You have added the TXT record to your website's DNS settings?"
                labelPlacement="start"
              />
              {isTXTRecordAdded && (
                <PrimaryButton
                  style={{ marginLeft: '30px' }}
                  onClick={initVerifyDomain}
                  secondaryGreenButton
                  maxContent={true}
                  children={verifyDomainData?.loading ? `Verifying...` : `Verify`}
                  disabled={verifyDomainData?.loading || !domain_name || orgData?.domain_name !== domain_name}
                  loading={verifyDomainData?.loading}
                />
              )}
            </Box>
          </div>
        )}
        <div className="btn-strip">
          <PrimaryButton
            style={{ marginTop: '10px', marginLeft: 'auto' }}
            onClick={submitHandler}
            icon="edit"
            secondaryButton={true}
            maxContent={true}
            children={`Save Changes`}
            disabled={!name || !description || !tag_line || !domain_name}
          />
        </div>
        <div className="section-seats _pt50">
          <div className="d-flex justify-content-between align-items-center page-title">
            <p>User Accounts - Seats</p>
            <CommonLink icon="add" children={'Add User Accounts'} to="/organization/create-user-account" />
          </div>
          <div className="cs-table-parent">
            <div className="cs-table">
              <div className="cs-table-head container-fluid">
                <div className="row no-gutters">
                  <div className="col-2">First Name</div>
                  <div className="col-2">Last Name</div>
                  <div className="col-2">Phone</div>
                  <div className="col-3">Email Address</div>
                  <div className="col-1">Status</div>
                  <div className="col-2">Action</div>
                </div>
              </div>
              <div className="cs-table-body container-fluid">
                {individuals.length > 1 ? (
                  individuals.map((item: any, index: number) => {
                    if (item.id === selectedOrganization.account_id) {
                      return null;
                    }
                    return (
                      <div key={index} className="row no-gutters">
                        <div className="col-2 ac">
                          <div>{item.name_first}</div>
                        </div>
                        <div className="col-2 ac">
                          <div>{item.name_last}</div>
                        </div>
                        <div className="col-2 ac">
                          <div>{item.phone}</div>
                        </div>
                        <div className="col-3 ac">
                          <div>{item.email_address}</div>
                        </div>
                        <div className="col-1 ac">
                          <div>{item.status}</div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex justify-content-center">
                            <Icon
                              style={{ marginRight: '10px' }}
                              onClick={() => history.push(`/organization/${item.id}`)}
                              dataTip="Edit"
                              icon="edit"
                            />
                            <Icon onClick={() => seatsHandler(index, 'delete')} dataTip="Delete" icon="delete" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center _p10">No User Accounts Found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section-seats _pt50 _pb50">
          <p className="page-title">Subscription Detail</p>
          <p>
            Your are currently on the <b>{subscription_name}</b> subscription.
          </p>
          <div className="d-flex">
            <CommonLink
              to={'/subscription'}
              style={{ marginRight: '10px' }}
              children={'Current Subscription & Billing Details'}
            />
            <CommonLink to={'/plans'} children={'Upgrade Your Subscription'} />
          </div>
        </div>
      </div>
      <input accept={acceptedFileTypes} onChange={inputsHandler} type="file" id="logo_url" name="logo_url" hidden />
      <input accept={acceptedFileTypes} onChange={inputsHandlerBGI} type="file" id="bg_image" name="bg_image" hidden />
    </div>
  );
};

export { OrganizationInformation };
