/* eslint-disable import/no-unresolved */
import { ICreateTransferDTO } from '../../lib/transfer';
import { ICreateTransitDTO } from '../../lib/transit';
import React, { CSSProperties, FunctionComponent, ReactNode, useState } from 'react';
import UseAnimations from 'react-useanimations';
import { Layer } from '../common';
import { defaultButtonStyle, defaultPaneInputStyle, loadingIconStyle } from '../styles/default';
import { DropdownContent } from './DropdownContent';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { organization_, location_ } from '../common/models';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { PrimaryButton } from 'common/Button/buttons';

const buttonStyle: CSSProperties = { marginRight: 5, marginTop: 10 };

interface IAddDropdownProps<T> {
  isLoading: boolean;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onAddItem: (item: T) => void;
  placeholderDescription: string;
  placeholderTitle: string;
  renderHeader: () => JSX.Element;
  renderContent?: () => React.ReactNode;
}

const AddDropdown: FunctionComponent<IAddDropdownProps<ICreateTransferDTO | ICreateTransitDTO>> = ({
  isLoading,
  isOpen,
  setOpen,
  onAddItem,
  placeholderTitle,
  placeholderDescription,
  renderHeader,
  renderContent
}) => {

  const {
    selectedTransferOrganization,
    setSelectedOrganization
  } = useCreateTransferStore();

  const { setCurrentTransitLocation, setedit_location } = useCreateTransitStore();


  const [item, setItem] = useState<ICreateTransferDTO | ICreateTransitDTO>({
    title: '',
    description: '',
    integrant_id: ''
  });

  const handleAddItem = (): void => {
    const TransferObj = {
      from_location: location_,
      to_location: location_,
      effective_date: new Date(),
      to_organization_id: selectedTransferOrganization.id
    }
    setedit_location(false);
    setCurrentTransitLocation(TransferObj)
    onAddItem(item);
  };

  const handleCancelItem = (): void => {
    setSelectedOrganization(organization_)
    setOpen(false)
  }

  const renderContentView = (): ReactNode => (
    <>
      <Layer>{renderHeader()}</Layer>
      {typeof renderContent !== 'undefined' ? (
        <>{renderContent()}</>
      ) : (
        <>
          <input
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
              setItem({ ...item, title: evt.currentTarget.value })
            }
            autoFocus
            placeholder={placeholderTitle}
            style={{ ...defaultPaneInputStyle, marginTop: 10 }}
          />
          <input
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
              setItem({ ...item, description: evt.currentTarget.value })
            }
            placeholder={placeholderDescription}
            style={defaultPaneInputStyle}
          />
        </>
      )}
    </>
  );

  const renderOptions = (): ReactNode => {
    let disabledButton = true
    if (item.description !== '' && item.title !== '') {
      disabledButton = false
    }
    return (
      <PrimaryButton maxContent={true}  children={"Save"} loading={isLoading} icon="save" secondaryButton={true} disabled={disabledButton} onClick={handleAddItem} />
    )
  };

  return (
    <DropdownContent
      isOpen={isOpen}
      handleCancel={(): void => handleCancelItem()}
      renderContent={renderContentView}
      renderOptions={renderOptions}
    />
  );
};

export { AddDropdown };
