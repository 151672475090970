import { integrant_ } from '../../common/models';

const defaults = {
  slideIndex: 0,
  isOpen: false,
  isLoading: true,
  selectedIntegrant: integrant_
};

export { defaults };
