import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText } from '@material-ui/core';
import '../../styles/commonComponent.css';

import { makeStyles, createStyles } from '@material-ui/core';
import { fileInputStyle, iconStyle, labelStyle } from 'styles/default';
import { GoFile } from 'react-icons/go';
import { Input } from 'common/Input';
import { useTestingLabStore } from 'hooks/useTestingLabStore';
import { PrimaryButton } from 'common/Button/buttons';

const useStyle = makeStyles(theme =>
  createStyles({
    dialogContent: {
      '&>*:not(:last-child)': {
        marginBottom: 15
      }
    },
    fileDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>img': {
        maxHeight: 150
      },
      '&>label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    }
  })
);

interface ICreateTestingLabDialogProps {
  open: boolean;
  handleClose: () => any;
}

const CreateTestingLabDialog: FunctionComponent<ICreateTestingLabDialogProps> = ({ open, handleClose }) => {
  const classes = useStyle();
  const { postTestingLab, createTestingLab } = useTestingLabStore();
  const [file, setFile] = useState<any>(null);
  const [name, setName] = useState<any>('');
  const [error, setError] = useState<any>({
    name: '',
    file: ''
  });
  const handleDataVerification = () => {
    let errorObj: any = { ...error };
    let hasError: any = false;
    if (!name?.trim()) {
      errorObj['name'] = 'Name is a required field.';
      hasError = true;
    }
    if (!file) {
      errorObj['file'] = 'Organization logo is a required field.';
      hasError = true;
    } else if (file?.type?.split('/')[0] !== 'image') {
      errorObj['file'] =
        'The file type you have uploaded is not valid. Only image file type is valid for organization logo.';
      hasError = true;
    } else if (file?.size > 15 * 1024 * 1024) {
      errorObj['file'] = 'File size must be less than or equal to 15 MB.';
      hasError = true;
    }
    if (hasError) {
      setError(errorObj);
    } else {
      setError({
        name: '',
        file: ''
      });
    }
    return hasError;
  };
  const handleSubmit = () => {
    if (!handleDataVerification()) {
      let formData = new FormData();
      formData.append('name', name);
      formData.append('file', file);
      postTestingLab(formData);
    }
  };
  const handleClear = () => {
    setFile(null);
    setName('');
    setError({
      name: '',
      file: ''
    });
  };
  useEffect(() => {
    if (open) {
      handleClear();
    }
  }, [open]);
  useEffect(() => {
    if (!createTestingLab.loading && createTestingLab.status === '1') {
      handleClear();
      handleClose();
    }
  }, [createTestingLab]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle className="dialog-title">Add New Organization</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <Input
            required={Boolean(error?.name)}
            placeholder="Organization Name*"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setName(e.target.value);
              setError((pre: any) => ({
                ...pre,
                name: ''
              }));
            }}
            value={name}
          />
          {Boolean(error?.name) && <FormHelperText error={true} children={error?.name} />}
        </div>
        <div className={classes.fileDiv}>
          <input
            style={fileInputStyle}
            onChange={e => {
              if (e.target?.files && e.target?.files[0]) {
                setFile(e.target.files[0]);
                setError((pre: any) => ({
                  ...pre,
                  file: ''
                }));
              }
            }}
            type="file"
            id="organization-file"
            accept="image/*"
          />
          <label style={labelStyle} htmlFor="organization-file">
            <GoFile style={iconStyle}></GoFile> {file !== null ? file?.name : `Choose a file*`}
          </label>
          {file && <img alt="" src={URL.createObjectURL(file)} />}
        </div>
        {Boolean(error?.file) && <FormHelperText error={true} children={error?.file} />}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <PrimaryButton children={'Cancel'} maxContent onClick={handleClose} />
        <PrimaryButton
          children={'Submit'}
          loading={createTestingLab.loading}
          disabled={createTestingLab.loading || !name || !file}
          secondaryGreenButton
          maxContent
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateTestingLabDialog;
