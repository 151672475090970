import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import {IAPIResponseDTO} from '../../../lib/common';
import { IGetAllOrganizationExhibitsDTO } from '../../../lib/organization_exhibit';
import axiosInstance from '../../utils/api-interceptor'

interface IOrganizationExhibitService {
    create: (data: any)=> Promise<IAPIResponseDTO>;
    update: (id: string, data: any) => Promise<IAPIResponseDTO>;
    destroy: (id: string) => Promise<IAPIResponseDTO>;
    getAll: (query: IGetAllOrganizationExhibitsDTO) => Promise<IAPIResponseDTO>;
    getOne: (id: string) => Promise<IAPIResponseDTO>;
}

const useOrganizationExhibitService = (): IOrganizationExhibitService => {
    const { individual } = useAuthStore();

    const create: IOrganizationExhibitService["create"] = async (data: any) => {
        try {
            return await axiosInstance.post("/organization-exhibit",data, formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
                data: null
            }
        }
    }

    const update: IOrganizationExhibitService["update"] = async (id: string, data: any) => {
        try {
            return await axiosInstance.patch(`/organization-exhibit/${id}`,data,formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
                data: null
            }
        }
    }

    const destroy: IOrganizationExhibitService["destroy"] = async (id: string) => {
        try {
            return await axiosInstance.delete(`/organization-exhibit/${id}`,formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
                data: null
            }
        }
    }

    const getAll: IOrganizationExhibitService["getAll"] = async (query: IGetAllOrganizationExhibitsDTO): Promise<IAPIResponseDTO> => {
        try {
            return await axiosInstance.get(`/organization-exhibit`,{...formAxiosHeader(individual.access_token), params: query});
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
            }
        }
    }

    const getOne: IOrganizationExhibitService["getOne"] = async (id: string): Promise<IAPIResponseDTO> => {
        try {
            return await axiosInstance.get(`/organization-exhibit/${id}`,formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
            }
        }
    }

  return { create, update, destroy, getAll, getOne };
};

export { useOrganizationExhibitService };
