import { ICreateIntegrantResponseDTO, IIntegrantDTO } from '../../lib/integrant';
import { IExhibitDTO, ICreateExhibitDTO } from '../../lib/exhibit';
import { IFacetDTO, ICreateFacetDTO } from '../../lib/facet';
import useFetch from 'use-http';
import { ILocationDTO } from '../../lib/location';

import { useCallback, useContext } from 'react';
import { isClientId, formAxiosHeader } from '../config';
import { IntegrantPaneContext } from '../context/integrantPaneContext';
import { IntegrantKind } from '../types/common';
import {
  generateClientID,
  isEmptyString,
  isUndefined,
  convertToGraphData,
  validURL,
  getTimeNumber,
  replaceSpecialCharAndWhiteSpace,
  getCoordinates
} from '../utils';
import { convertAddressToLatLng } from '../utils/google-client';
import { useExhibitsService } from './api/useExhibitsService';
import { useIntegrantsService } from './api/useIntegrantsService';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useLayerPaneStore } from './useLayerPaneStore';
import { useRouter } from './useRouter';
import { useHistory } from 'react-router-dom';
import { url } from './../config';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { useAuthStore } from '../hooks/useAuthStore';
import { location_ } from '../common/models';

import axiosInstance from '../utils/api-interceptor';
import { useToastStore } from '../hooks/useToastStore';

interface IIntegrantPaneStoreReturn {
  tabIndex: number;
  isLoading: boolean;
  isRemoveLoading: boolean;
  isEditableLocation: boolean;
  onChangeIndex: (i: number) => void;
  resetTabs: () => void;
  onSave: (integrant: IIntegrantDTO) => Promise<void>;
  onUpdate: (integrant: Partial<IIntegrantDTO> & { id: string }) => Promise<IIntegrantDTO | undefined>;
  onTrackingUpdate: (integrant: Partial<IIntegrantDTO> & { id: string }) => Promise<IIntegrantDTO | undefined>;
  onUpdateFacet: (facet: IFacetDTO) => Promise<void>;
  onPublish: (isPublished: boolean) => Promise<void>;
  onUpdateLocation: (location: ILocationDTO) => Promise<void>;
  onSaveLocation: (location: ILocationDTO) => Promise<void>;
  onRemoveFacet: (id: IFacetDTO['id'], isDelete?: boolean) => Promise<void>;
  onUpdateExhibit: (exhibit: IExhibitDTO, file?: File, location?: any, productLabel?: File) => Promise<void>;
  onUpdateExhibitLocation: (currentLocation: ILocationDTO, exhibitId: string) => Promise<void>;
  onRemoveExhibit: (id: IExhibitDTO['id'], isDelete?: boolean) => Promise<void>;
  onRemove: (integrant_id: IIntegrantDTO['id'], mutation_id: IIntegrantDTO['id']) => Promise<void>;
  currentBatch: (currentIntegrant: any, external_id_slug: any) => Promise<void>;
  setEditableLocation: (isEditable: boolean) => void;
  onAddIntegrant: (integrant: IIntegrantDTO) => Promise<ICreateIntegrantResponseDTO>;
  onBuyNowUpdate: (partialIntegrant: any) => Promise<IIntegrantDTO | undefined>;
  checkCurrentBatch: (currentIntegrant: any, external_id_slug: any) => Promise<void>;
}

const useIntegrantPaneStore = (): IIntegrantPaneStoreReturn => {
  const {
    data,
    setData,
    setCurrentIntegrant,
    currentIntegrant,
    setRootIntegrantId,
    batch_id,
    integrantKind,
    setIntegrantKind,
    batchViewPopup
  } = useIntegrantGraphStore();
  const { request, response, loading } = useFetch(url);
  const [state, setState] = useContext(IntegrantPaneContext);
  const {
    post,
    put,
    postTransit,
    editTransfer,
    postTransfer,
    editTransit,
    postFacet,
    updateFacet,
    updateExhibit,
    postExhibit,
    deleteFacet,
    deleteIntegrant,
    deleteExhibit,
    getDetail,
    editIntegrant,
    updateStaticBatchInformation,
    checkStaticBatchInformation
  } = useIntegrantsService();
  const { individual } = useAuthStore();
  const useExhibits = useExhibitsService();
  const { tabIndex, isLoading, isRemoveLoading, isEditableLocation } = state;
  const { selectedParentId } = useLayerPaneStore();
  const { selectedOrganization } = useOrganizationStore();
  const { currentTransit, from_location, to_location, effective_date, to_organization_id } = useCreateTransitStore();

  const { selectedTransferOrganization, currentTransfer } = useCreateTransferStore();
  const { navigate } = useRouter();
  const { isOpenToast } = useToastStore();

  const onChangeIndex: IIntegrantPaneStoreReturn['onChangeIndex'] = async i => {
    if (state.tabIndex !== i) {
      if (state.tabIndex == 1 && currentIntegrant.tracking_id !== currentIntegrant?.external_id) {
        const checkCurrentBatchData: any = await checkCurrentBatch(currentIntegrant, currentIntegrant.tracking_id);
        if (checkCurrentBatchData) {
          isOpenToast('isError', 'Tracking ID Already Exists!');
        } else {
          setState(s => ({ ...s, tabIndex: i }));
        }
      } else {
        setState(s => ({ ...s, tabIndex: i }));
      }
    }
  };

  const resetStore = useCallback((): void => {
    setState({
      tabIndex: 0,
      isLoading: false,
      isRemoveLoading: false,
      isSaveLoading: false,
      isEditableLocation: false
    });
  }, [setState]);

  const updateClientIdToServerId = (serverId: IIntegrantDTO['id'], newIntegrant: ICreateIntegrantResponseDTO): void => {
    const edgeId: string | undefined =
      newIntegrant.origination_mutation?.id !== ''
        ? newIntegrant.origination_mutation?.id
        : newIntegrant.origination_transit?.id;

    const updateIntegrant = (): void => {
      setCurrentIntegrant(
        {
          ...newIntegrant,
          x: currentIntegrant.x,
          y: currentIntegrant.y,
          edge_type: IntegrantKind.Default,
          external_id_slug: ''
        },
        serverId,
        edgeId,
        selectedParentId
      );
    };
    setIntegrantKind(IntegrantKind.Default);
    if (data.nodes.length === 1) {
      setRootIntegrantId(newIntegrant.id);
      updateIntegrant();
      navigate(`/batches/${newIntegrant.id}`);
    } else {
      updateIntegrant();
    }
  };

  const onAddIntegrant = async (integrant: IIntegrantDTO): Promise<ICreateIntegrantResponseDTO> => {
    return await post(integrant, selectedParentId, batch_id);
  };

  const onSaveIntegrant = async (
    integrant: Partial<IIntegrantDTO> & { id: string }
  ): Promise<IIntegrantDTO | undefined> => {
    const { type } = integrant;
    if (typeof type !== undefined && type === 'batch') {
      const { integrant_type_id, tracking_id, buy_again_url, facets, exhibits } = integrant;
      const { account_id } = selectedOrganization;
      const loc: any = integrant.location;
      const linksObj = {
        child_integrant_id: integrant_type_id,
        parent_integrant_id: selectedParentId,
        created_by: account_id,
        updated_by: account_id
      };
      const integrantObj: any = {
        exhibits,
        tracking_id,
        buy_again_url,
        integrant_id: integrant_type_id
      };

      if (typeof facets === 'object' && facets.length !== 0) {
        integrantObj.facets = JSON.stringify(facets);
      }

      if (loc !== null && typeof loc === 'object' && loc.id && loc.id !== '') {
        integrantObj.location_id = loc.id;
      }

      await axiosInstance.post('/links', linksObj, formAxiosHeader(individual.access_token));
      return await axiosInstance
        .post('/integrant/edit', integrantObj, formAxiosHeader(individual.access_token))
        .then((response: any) => {
          return response;
        })
        .catch(err => {
          return err;
        });
    } else {
      const updatedIntegrantID = await put(selectedParentId, integrant);
      if (typeof updatedIntegrantID !== 'undefined' && !updatedIntegrantID.hasOwnProperty('statusCode')) {
        const updatedIntegrant = await getDetail(updatedIntegrantID);
        const { integrantInfo } = updatedIntegrant;
        integrantInfo.exhibits = [];
        setCurrentIntegrant({ ...integrantInfo, x: 0, y: 0, edge_type: IntegrantKind.Default });
        setState(s => ({ ...s, isLoading: false }));
      }
      return updatedIntegrantID;
    }
  };

  const locationUpdate = async (location: any, organization_id?: string): Promise<any | undefined> => {
    const { account_id } = selectedOrganization;
    const { id } = location;
    const updatedAddress = await returnLocationWithCoordinates(location);
    const { coordinates, line_1, line_2, zip, city, state, country, description } = updatedAddress;
    const updatedLocationObj = {
      description: description ? description : '',
      id,
      line_1,
      line_2,
      zip,
      city,
      state,
      country,
      coordinates: getCoordinates(coordinates),
      organization_id:
        typeof organization_id === 'string' && organization_id !== '' ? organization_id : selectedOrganization.id,
      created_by: account_id,
      updated_by: account_id
    };
    return await axiosInstance
      .post('location/save', updatedLocationObj, formAxiosHeader(individual.access_token))
      .then((locationResponse: any) => {
        return locationResponse.id;
      })
      .catch(err => {
        return err;
      });
    // const locationResponse = await request.post('location/save', updatedLocationObj);
    // return locationResponse.id
  };

  const onSaveTransit = async (integrant: Partial<IIntegrantDTO>): Promise<any | undefined> => {
    const { account_id } = selectedOrganization;
    const { exhibits } = integrant;
    const { description, integrant_id, title, oldTransitId } = currentTransit;
    let updatedExhibitsObj: any = [];
    const from_location_id = await locationUpdate(from_location);
    const to_location_id = await locationUpdate(to_location);
    const dateFormat =
      effective_date.getMonth() + 1 + '/' + effective_date.getDate() + '/' + effective_date.getFullYear();
    if (exhibits) {
      exhibits.forEach(item => {
        if (item.description === '') {
          const { title, url } = item;
          updatedExhibitsObj.push({ title, url });
        }
      });
    }

    if (typeof oldTransitId === 'string') {
      const transitData = {
        id: oldTransitId,
        from_location_id: from_location_id,
        to_location_id: to_location_id,
        effective_date: new Date(effective_date),
        updated_by: account_id,
        transitExhibits: updatedExhibitsObj
      };
      await editTransit(transitData);
    } else {
      const transitData = {
        integrant_id,
        title,
        description,
        from_location_id: from_location_id,
        to_location_id: to_location_id,
        effective_date: new Date(effective_date),
        created_by: account_id,
        updated_by: account_id,
        transitExhibits: updatedExhibitsObj
      };
      await postTransit(transitData);
    }
  };

  const onSaveTransfer = async (integrant: Partial<IIntegrantDTO>): Promise<any | undefined> => {
    const { account_id, id } = selectedOrganization;
    const { exhibits } = integrant;
    const { description, integrant_id, title, oldTransitId } = currentTransit;
    let updatedExhibitsObj: any = [];
    const from_location_id = await locationUpdate(from_location);
    const to_location_id = await locationUpdate(to_location, to_organization_id);
    // const dateFormat = effective_date.getMonth() + 1 + '/' + effective_date.getDate() + '/' + effective_date.getFullYear()
    if (exhibits) {
      exhibits.forEach(item => {
        if (item.description === '') {
          const { title, url } = item;
          updatedExhibitsObj.push({ title, url });
        }
      });
    }
    if (typeof oldTransitId === 'string' && oldTransitId !== '') {
      const transferData = {
        id: oldTransitId,
        effective_date: new Date(effective_date),
        to_organization_id,
        from_location_id: from_location_id,
        to_location_id: to_location_id,
        transferExhibits: updatedExhibitsObj,
        updated_by: account_id
      };
      await editTransfer(transferData);
    } else {
      const transferData = {
        integrant_id,
        title,
        description,
        from_organization_id: id,
        to_organization_id,
        effective_date: new Date(effective_date),
        from_location_id: from_location_id,
        to_location_id: to_location_id,
        transferExhibits: updatedExhibitsObj,
        created_by: account_id,
        updated_by: account_id
      };
      await postTransfer(transferData);
    }
  };

  const onSave: IIntegrantPaneStoreReturn['onSave'] = async integrant => {
    function isReload(integrant_id: any) {
      if (selectedParentId === '') {
        if (integrant_id) {
          window.location.replace('/batches/' + integrant_id);
        } else {
          window.location.replace('/');
        }
      } else {
        window.location.reload();
      }
      batchViewPopup(false);
    }
    setState(s => ({ ...s, isLoading: true }));
    if (integrantKind === IntegrantKind.Transit) {
      await onSaveTransit(integrant);
      isReload('');
    } else if (integrantKind === IntegrantKind.Transfer) {
      await onSaveTransfer(integrant);
      isReload('');
    } else {
      const data = await onSaveIntegrant(integrant);
      await axiosInstance
        .post(
          'integrant/update-product-label',
          { id: integrant.integrant_type_id, intigrant_id: data },
          formAxiosHeader(individual.access_token)
        )
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return err;
        });
      if (typeof data !== 'undefined' && data.hasOwnProperty('statusCode')) {
        setState(s => ({ ...s, isLoading: false }));
      } else {
        isReload(data);
      }
    }
    //window.location.reload();
  };

  const onUpdate: IIntegrantPaneStoreReturn['onUpdate'] = async partialIntegrant => {
    setState(s => ({ ...s, isLoading: true }));
    return await onSaveIntegrant(partialIntegrant);
  };

  const onTrackingUpdate: IIntegrantPaneStoreReturn['onUpdate'] = async (partialIntegrant: any) => {
    const { id, tracking_id } = partialIntegrant;
    if (tracking_id === '') {
      return false;
    }
    let updatedObj: any = {
      integrant_id: id,
      external_id: tracking_id,
      external_id_slug: replaceSpecialCharAndWhiteSpace(tracking_id)
    };
    return await editIntegrant(updatedObj);
  };

  const onBuyNowUpdate: IIntegrantPaneStoreReturn['onBuyNowUpdate'] = async (partialIntegrant: any) => {
    const { id, buy_again_url } = partialIntegrant;
    if (validURL(buy_again_url)) {
      return false;
    }
    let updatedObj: any = {
      integrant_id: id,
      buy_again_url
    };
    return await editIntegrant(updatedObj);
  };

  const onRemove: IIntegrantPaneStoreReturn['onRemove'] = async (integrant_id, mutation_id) => {
    setState(s => ({ ...s, isRemoveLoading: true }));
    await deleteIntegrant(integrant_id, mutation_id);
    getDetail(batch_id).then(detailList => {
      setCurrentIntegrant(data.nodes[0]);
      setData(convertToGraphData(detailList));
    });
    resetStore();
  };

  const currentBatch: IIntegrantPaneStoreReturn['currentBatch'] = async (currentIntegrant, batchNo) => {
    const { integrant_type_id, id } = currentIntegrant;
    const updatedObj = {
      integrant_type_id,
      integrant_id: id,
      external_id_slug: replaceSpecialCharAndWhiteSpace(batchNo),
      external_id: batchNo
    };
    const responseBatch = await updateStaticBatchInformation(updatedObj);
    return responseBatch;
  };

  const checkCurrentBatch: IIntegrantPaneStoreReturn['checkCurrentBatch'] = async (currentIntegrant, batchNo) => {
    const { integrant_type_id, id, organization_id } = currentIntegrant;
    const checkObj = {
      integrant_type_id,
      integrant_id: id,
      external_id: batchNo,
      organization_id: organization_id
    };
    const checkBatch = await checkStaticBatchInformation(checkObj);
    return checkBatch;
  };

  const updateFacets = (facets: IFacetDTO[], facet: IFacetDTO, key: keyof IFacetDTO): IFacetDTO[] =>
    facets.map(f => (f[key] === facet[key] ? facet : f));

  const updateExhibits = (exhibits: IExhibitDTO[], exhibit: IExhibitDTO, key: keyof IExhibitDTO): IExhibitDTO[] =>
    exhibits.map(f => (f[key] === exhibit[key] ? exhibit : f));

  const onUpdateFacet: IIntegrantPaneStoreReturn['onUpdateFacet'] = async (facet: IFacetDTO) => {
    if (isEmptyString(facet.id)) {
      setCurrentIntegrant({
        ...currentIntegrant,
        facets: [...currentIntegrant.facets, { ...facet, id: generateClientID() }]
      });
    } else {
      if (isClientId(currentIntegrant.id)) {
        setCurrentIntegrant({ ...currentIntegrant, facets: updateFacets(currentIntegrant.facets, facet, 'id') });
      } else {
        if (isClientId(facet.id)) {
          let oldFacet = currentIntegrant.facets.find(f => f.id === facet.id);
          if (
            oldFacet?.title === facet.title &&
            oldFacet?.description === facet.description &&
            oldFacet?.group_id === facet?.group_id
          ) {
            return;
          }
        }
        const createFacet: ICreateFacetDTO = {
          title: facet.title,
          description: facet.description,
          rank: facet.rank
        };
        const updatedFacet: any = isClientId(facet.id)
          ? await postFacet(currentIntegrant, updateFacets(currentIntegrant.facets, facet, 'id'))
          : await updateFacet(currentIntegrant, facet);
        if (!isUndefined(updatedFacet)) {
          const facetExists = currentIntegrant.facets.filter(f => f.id === updatedFacet.id).length > 0;
          setCurrentIntegrant({
            ...currentIntegrant,
            facets: facetExists ? updateFacets(currentIntegrant.facets, updatedFacet, 'id') : updatedFacet
          });
        }
      }
    }
  };

  const onRemoveFacet: IIntegrantPaneStoreReturn['onRemoveFacet'] = async (id: string, isDelete = false) => {
    if (isDelete) {
      await deleteFacet(currentIntegrant.id, id, currentIntegrant);
    }

    setCurrentIntegrant({
      ...currentIntegrant,
      facets: currentIntegrant.facets.filter((facet: IFacetDTO) => facet.id !== id)
    });
  };

  const onUpdateExhibit: IIntegrantPaneStoreReturn['onUpdateExhibit'] = async (
    selectedExhibit,
    file,
    location,
    productLabel
  ) => {
    if (isEmptyString(selectedExhibit.id)) {
      setCurrentIntegrant({
        ...currentIntegrant,
        exhibits: [...currentIntegrant.exhibits, { ...selectedExhibit, id: generateClientID() }]
      });
    } else if (!isEmptyString(selectedExhibit.id)) {
      const createExhibit: ICreateExhibitDTO = {
        exhibit_type_id: selectedExhibit.exhibit_type_id,
        exhibit_type: selectedExhibit.exhibit_type,
        effective_date: selectedExhibit.effective_date,
        title: selectedExhibit.title,
        description: selectedExhibit.description,
        rank: selectedExhibit.rank,
        location: selectedExhibit.location,
        url: selectedExhibit.url,
        exihibit_id: '',
        created_by: '',
        updated_by: '',
        testing_lab_id: selectedExhibit?.testing_lab_id || ''
      };
      let product_label_url = null;
      if (productLabel) {
        product_label_url = await useExhibits.postExhibit(currentIntegrant.id, productLabel, createExhibit);
      }
      // if (!requestExhibit.id) {
      //   return false;
      // }
      const requestExhibit: IExhibitDTO | any =
        typeof file !== 'undefined'
          ? isClientId(currentIntegrant.id)
            ? await useExhibits.postExhibit(currentIntegrant.id, file, createExhibit)
            : await postExhibit(currentIntegrant, file, createExhibit, location, product_label_url)
          : selectedExhibit.url
          ? isClientId(currentIntegrant.id)
            ? selectedExhibit.url
            : await postExhibit(currentIntegrant, null, createExhibit, location)
          : await updateExhibit(currentIntegrant.id, selectedExhibit);
      if (requestExhibit) {
        const { id, title } = selectedExhibit;
        const updatedExhibit = {
          ...selectedExhibit,
          url: typeof requestExhibit === 'string' ? requestExhibit : '',
          id: typeof id === 'string' ? id : title
        };
        if (!isUndefined(updatedExhibit)) {
          const exhibitExists = currentIntegrant.exhibits.filter(f => f.id === updatedExhibit.id).length > 0;
          setCurrentIntegrant({
            ...currentIntegrant,
            exhibits: exhibitExists
              ? updateExhibits(currentIntegrant.exhibits, updatedExhibit, 'id')
              : [...currentIntegrant.exhibits.filter(f => f.id !== selectedExhibit.id), updatedExhibit]
          });
        }
      } else {
        setCurrentIntegrant({
          ...currentIntegrant,
          exhibits: [...currentIntegrant.exhibits.filter(f => f.id !== selectedExhibit.id)]
        });
      }
    }
  };

  const returnLocationWithCoordinates = async (location: ILocationDTO): Promise<ILocationDTO> => {
    const address = `${location.line_1}, ${location.city}, ${location.state} ${location.country} ${location.zip}`;
    const coordinates = await convertAddressToLatLng(address);
    return typeof coordinates !== 'undefined'
      ? {
          ...location,
          coordinates
        }
      : location;
  };

  const onUpdateExhibitLocation: IIntegrantPaneStoreReturn['onUpdateExhibitLocation'] = async (location, exhibitId) => {
    const { account_id } = selectedOrganization;
    const updatedAddress = await returnLocationWithCoordinates(location);
    const { id } = location;
    const { coordinates, line_1, line_2, zip, city, state, country, description } = updatedAddress;
    const updatedLocationObj = {
      description: description ? description : '',
      id,
      line_1,
      line_2,
      zip,
      city,
      state,
      country,
      coordinates: getCoordinates(coordinates),
      organization_id: selectedOrganization.id,
      created_by: account_id,
      updated_by: account_id
    };
    setState(s => ({ ...s, isLoading: true }));
    await axiosInstance
      .post('location/save', updatedLocationObj, formAxiosHeader(individual.access_token))
      .then((locationResponse: any) => {
        const exhibitsLocObj = currentIntegrant.exhibits;
        exhibitsLocObj &&
          exhibitsLocObj.filter((item: any) => {
            if (item.id === exhibitId) {
              item.location = locationResponse;
            }
            return item;
          });
        setCurrentIntegrant({
          ...currentIntegrant,
          exhibits: exhibitsLocObj
        });

        if (isClientId(currentIntegrant.id)) {
          onUpdateLocation(locationResponse);
        } else {
          // onSaveIntegrant({
          //   location: locationResponse,
          //   id: currentIntegrant.id
          // });
        }
      })
      .catch(err => {
        return err;
      });
    // const locationResponse = await request.post('location/save', updatedLocationObj);
    // setState(s => ({ ...s, isLoading: true }));
  };

  const onRemoveExhibit: IIntegrantPaneStoreReturn['onRemoveExhibit'] = async (id, isDelete = false) => {
    // if (isDelete) {
    //   await deleteExhibit(currentIntegrant.id, id);
    // }
    setCurrentIntegrant({
      ...currentIntegrant,
      exhibits: currentIntegrant.exhibits.filter((exhibit: IExhibitDTO) => exhibit.id !== id)
    });
  };

  const resetTabs = (): void => {
    setState(s => ({ ...s, tabIndex: 0 }));
  };

  const onUpdateLocation: IIntegrantPaneStoreReturn['onUpdateLocation'] = async location => {
    setState(s => ({ ...s, isLoading: false }));
    setCurrentIntegrant({ ...currentIntegrant, location });
  };

  const onSaveLocation: IIntegrantPaneStoreReturn['onSaveLocation'] = async location => {
    const { account_id } = selectedOrganization;
    const { id } = location;
    const updatedAddress = await returnLocationWithCoordinates(location);
    const { coordinates, line_1, line_2, zip, city, state, country, description } = updatedAddress;
    const updatedLocationObj = {
      description: description ? description : '',
      id,
      line_1,
      line_2,
      zip,
      city,
      state,
      country,
      coordinates: getCoordinates(coordinates),
      organization_id: selectedOrganization.id,
      created_by: account_id,
      updated_by: account_id
    };
    setState(s => ({ ...s, isLoading: true }));
    await axiosInstance
      .post('location/save', updatedLocationObj, formAxiosHeader(individual.access_token))
      .then((locationResponse: any) => {
        if (isClientId(currentIntegrant.id)) {
          return onUpdateLocation(locationResponse);
        } else {
          onUpdateLocation(locationResponse);
          editIntegrant({ integrant_id: currentIntegrant.id, location_id: locationResponse.id });
        }
      })
      .catch(err => {
        return err;
      });

    //await request.post('location2/save', updatedLocationObj);
    // setState(s => ({ ...s, isLoading: true }));
    // if (isClientId(currentIntegrant.id)) {
    //   onUpdateLocation(locationResponse);
    // } else {
    //   onUpdateLocation(locationResponse);
    //   editIntegrant({ integrant_id: currentIntegrant.id, location_id: locationResponse.id })
    // }
  };

  const onPublish: IIntegrantPaneStoreReturn['onPublish'] = async published => {
    await editIntegrant({ integrant_id: currentIntegrant.id, is_published: published });
    //await put(currentIntegrant.id, { published: published });
    setCurrentIntegrant({ ...currentIntegrant, is_published: published });
  };

  const setEditableLocation: IIntegrantPaneStoreReturn['setEditableLocation'] = async isEditableLocation => {
    setState({ ...state, isEditableLocation });
  };

  return {
    resetTabs,
    onUpdateExhibit,
    tabIndex,
    isLoading,
    isRemoveLoading,
    onChangeIndex,
    onAddIntegrant,
    onSave,
    onRemove,
    onUpdateFacet,
    onRemoveFacet,
    onRemoveExhibit,
    onUpdateLocation,
    onSaveLocation,
    onPublish,
    isEditableLocation,
    setEditableLocation,
    onUpdate,
    onUpdateExhibitLocation,
    onTrackingUpdate,
    onBuyNowUpdate,
    currentBatch,
    checkCurrentBatch
  };
};

export { useIntegrantPaneStore };
