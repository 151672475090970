import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../../hooks/useAuthStore';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';
import '../../styles/widget.css';
import { Input } from '../../common/Input';

const Widget = (props: any) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [copyText, setCopyText] = useState('Copy');
  const { selectedOrganization } = useOrganizationStore();
  const [title, setTitle] = useState('Blockchain Proof');
  const [widgetType, setWidgetType] = useState('default');

  if (!selectedOrganization?.id) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Loading + ' Widget'} />
      </div>
    );
  }

  let urlTextCopy: any = '';
  let clientAppURL: any = '';
  if (window.location.origin === 'https://healthloqproducer-dev.azurewebsites.net') {
    clientAppURL = 'https://healthloqclientapp-dev.azurewebsites.net';
    urlTextCopy = `${clientAppURL}/assets/healthloq-widget/healthloq-widget-dev.js`;
  } else if (window.location.origin === 'https://healthloqproducer-qa.azurewebsites.net') {
    clientAppURL = 'https://healthloqclientapp-qa.azurewebsites.net';
    urlTextCopy = `${clientAppURL}/assets/healthloq-widget/healthloq-widget-qa.js`;
  } else if (window.location.origin === 'https://partner.healthloq.com') {
    clientAppURL = 'https://www.healthloq.com';
    urlTextCopy = `${clientAppURL}/assets/healthloq-widget/healthloq-widget-live.js`;
  } else {
    clientAppURL = 'http://localhost:3000';
    urlTextCopy = `${clientAppURL}/assets/healthloq-widget/healthloq-widget-dev.js`;
  }

  function copyData() {
    setCopyText('Copied');
    navigator.clipboard.writeText(
      `<div id="healthloq-widget-blockchain-proof" data-organization-id="${
        selectedOrganization.id
      }" data-type="integrant" data-icon-title="${title}" ${
        widgetType === 'inline' ? `data-custom-icon-classname="healthloq-widget-blockchain-proof"` : ''
      }></div><link href="${clientAppURL}/assets/healthloq-widget/healthloq-widget.css" rel="stylesheet" /><script src="${urlTextCopy}"></script>`
    );
    setTimeout(() => {
      setCopyText('Copy');
    }, 5000);
  }

  return (
    <div className="cp">
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center profile-heading">
            <div className="subscription-title">Widget</div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6 col-xs-12">
            <label>Title</label>
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setTitle(event.target.value)}
              value={title}
            />
          </div>
          <div className="col-md-6 col-xs-12">
            <label>Widget Type</label>
            <select value={widgetType} onChange={(evt: any): void => setWidgetType(evt.target.value)}>
              <option value={'default'}>Default Widget</option>
              <option value={'inline'}>Inline Widget</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center _pt15">
          <div className="col-md-12">
            <div className="widget-wrapper">
              <div className="widget-copy">
                {`<div id="healthloq-widget-blockchain-proof" data-organization-id="${
                  selectedOrganization.id
                }" data-type="integrant" data-icon-title="${title}" ${
                  widgetType === 'inline' ? `data-custom-icon-classname="healthloq-widget-blockchain-proof"` : ''
                }></div>`}
                <br />
                {`<link href="${clientAppURL}/assets/healthloq-widget/healthloq-widget.css" rel="stylesheet" />`}
                <br />
                {`<script src="${urlTextCopy}"></script>`}
              </div>
              <div className="btn-strip _pt15 widget-wrapper-btn" onClick={copyData}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.09144 0C6.52619 0 5.25914 1.26507 5.25771 2.83031L5.25631 3.25572H4.8323C3.26642 3.25572 2 4.52217 2 6.08802V17.1663C2 18.7322 3.26646 20 4.8323 20H12.7352C14.3004 20 15.5675 18.7322 15.5675 17.1663V16.7666L15.9408 16.7207C17.3364 16.5463 18.4193 15.3556 18.4193 13.9099C18.4193 13.691 18.2484 13.5201 18.0295 13.5201C17.8105 13.5201 17.641 13.689 17.641 13.9099C17.641 15.0388 16.7152 15.9645 15.5864 15.9645L8.09182 15.9673C7.54555 15.9673 7.02566 15.7498 6.6406 15.3654C6.25414 14.9803 6.03726 14.4584 6.03726 13.9127V2.83449C6.03726 1.70501 6.96303 0.779927 8.09182 0.779927H15.5864C16.7159 0.779927 17.6424 1.70569 17.6424 2.83449V9.84135C17.6438 10.0596 17.8106 10.2284 18.0295 10.2284C18.2492 10.2284 18.4194 10.0595 18.4194 9.83996V2.83309C18.4194 1.26721 17.1529 0.000794812 15.5871 0.000794812L8.09144 0ZM4.83373 4.03485H5.25914V13.9121C5.25914 14.6724 5.55112 15.3792 6.08839 15.9165C6.62564 16.4538 7.33189 16.7458 8.09148 16.7458L14.7901 16.7444V17.1698C14.7895 18.2978 13.863 19.223 12.7342 19.223H4.83286C3.70339 19.223 2.77687 18.2978 2.77687 17.1684V6.09016C2.77687 4.96068 3.70339 4.03417 4.83286 4.03417L4.83373 4.03485Z"
                    fill="black"
                    fill-opacity="0.5"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p>
            <strong>Step: 1</strong>&nbsp; Add the above script into the <strong>body</strong> of the html file.
          </p>
          {widgetType === 'inline' && (
            <p>
              <strong>Step: 2</strong>&nbsp; Add class <b>healthloq-widget-blockchain-proof</b> into your html element.
              &nbsp;<strong>e.g.</strong>&nbsp;
              <span className="widget-wrapper px-2">
                {`<button class="healthloq-widget-blockchain-proof"></button>`}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { Widget };
