import React, { FunctionComponent, useEffect, CSSProperties, useState } from 'react';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { ListView } from './ListView';
import { IOrganizationDTO } from '../../lib/organization';
import { listItemStyle, listItemImgStyle, listItemImgContainer, iconStyle } from '../styles/default';
import { Img, Layer } from '../common';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import ReactModal from 'react-modal';
import { AddOrganization } from './../route/add-organization';
import { useAuthStore } from '../hooks/useAuthStore';
import axiosInstance from '../utils/api-interceptor';
import { formAxiosHeader } from '../config';
import { useToastStore } from '../hooks/useToastStore';
import Icon from '../common/Icon';

interface IAddTransferSelectOrganizationProps {
  onListItemSelect: ((item: IOrganizationDTO) => void) | undefined;
  setOpen: (isOpen: boolean) => void;
}

const AddTransferSelectOrganization: FunctionComponent<IAddTransferSelectOrganizationProps> = ({
  onListItemSelect,
  setOpen
}) => {
  const { individual } = useAuthStore();
  const [apiLoading, setPageLoading] = useState(false)
  const { isOpenToast } = useToastStore();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { selectedOrganization } = useOrganizationStore();
  const { isDraft } = useCreateBatchStore();
  const { initGetOrganizations, organizations } = useCreateTransferStore();

  useEffect(() => {
    if (organizations.length === 0) {
      initGetOrganizations();
    }
  }, [organizations, initGetOrganizations]);

  const addOrganization = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const handleSubmit = async (organizationObj: any) => {
    const { organization_id } = individual
    setPageLoading(true)
    await axiosInstance.post(
      'integrant/get-organization',
      { organization_id },
      formAxiosHeader(individual.access_token)
    ).then((response: any) => {
      const { name } = response
      organizationObj.curOrganization = name
      axiosInstance.post(
        '/integrant/transfer-to-organization',
        organizationObj,
        formAxiosHeader(individual.access_token)
      ).then((response: any) => {
        setPageLoading(false)
        isOpenToast('success', "Add organization successful");
        closeModal()
        setOpen(false)
      }).catch(err => {
        setPageLoading(false)
        return err
      })
    }).catch(err => {
      return err
    })
  }

  return isDraft ? null : (
    <>
      <ListView
        onListItemSelect={onListItemSelect}
        renderListItem={(item: IOrganizationDTO): React.ReactNode => {
          return (
            <Layer key={item.id} style={listItemImgContainer}>
              {item.name}
              <Img alt='' style={listItemImgStyle} height={26} src={item.logo_url} />
            </Layer>
          );
        }}
        dataSource={organizations.filter(org => org.id !== selectedOrganization.id)}
        listItemStyle={(): CSSProperties => listItemStyle}
      />
      <div className="list-new-organization d-flex align-items-center justify-content-between" onClick={() => addOrganization()}>
        <div>Add New Organization</div>
        <Icon icon='add' onClick={()=>{}} dataTip='' />
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="popup-add-organization"
        ariaHideApp={false}
      >
        <AddOrganization
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          apiLoading={apiLoading}
        />
      </ReactModal>
    </>
  );
};

export { AddTransferSelectOrganization };
