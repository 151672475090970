import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem
} from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Layer } from 'common';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { useNIHService } from 'hooks/api/useNIHService';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { concatArrayOfObjectStrings, getOnlyDigit } from 'utils';
import CreateProductForm from './CreateProductForm';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    searchProductFromNIHSection: {
      margin: '20px 0'
    },
    textfieldRoot: {
      border: `1px solid #008F2B`,
      borderRadius: 4,
      width: '100%',
      height: 40,
      '&>div': {
        paddingLeft: 10,
        height: '100%',
        '&>input': {
          padding: 0
        }
      }
    },
    selectBox: {
      border: `1px solid #008F2B`,
      borderRadius: 4,
      width: '100%',
      height: 40,
      '&>div': {
        paddingLeft: 15
      }
    },
    productQuickInfo: {
      '&>h6': {
        fontWeight: 'bold',
        marginBottom: 10
      },
      '&>p': {
        '&>span': {
          fontWeight: 'bold',
          marginRight: 5
        }
      }
    },
    productImg: {
      display: 'flex'
    },
    autocompleteOption: {
      padding: '20px 10px',
      '&:not(:last-child)': {
        borderBottom: `1px solid rgba(0,0,0,0.1)`
      }
    }
  })
);

const formatNIHProductResponse = (arr: any = []) =>
  arr.map((item: any) => ({
    id: item['_id'],
    brandName: item['_source']?.brandName,
    fullName: item['_source']?.fullName,
    netContents: item['_source']?.netContents,
    events: item['_source']?.events
  }));

export default function ProductContainer() {
  const classes = useStyle();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));
  const { searchProduct, getLabelById } = useNIHService();

  //Import product from NIH Database
  const [searchProductData, setSearchProductData] = useState({
    q: '',
    method: 'by_letter',
    size: 10,
    from: 0,
    isLoading: false
  });
  const [productList, setProductList] = useState([]);
  const [activeProductInfo, setActiveProductInfo] = useState({
    isLoading: false,
    id: 0,
    fullName: ''
  });
  const { debounceData } = useDebounce(searchProductData.q, 1000);

  const handleSearchProduct = (key: string, value: string | number) => {
    setSearchProductData((pre: any) => ({
      ...pre,
      [key]: value,
      isLoading: key === 'q' ? Boolean(value) : pre.isLoading
    }));
    if (key === 'q' && !Boolean(value)) {
      setProductList([]);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await searchProduct(searchProductData);
      setSearchProductData((pre: any) => ({ ...pre, isLoading: false }));
      setProductList(formatNIHProductResponse(products?.hits) || []);
    };
    if (debounceData?.trim()) {
      fetchProducts();
    }
  }, [debounceData]);
  useEffect(() => {
    const fetchProduct = async () => {
      const product = await getLabelById(activeProductInfo?.id);
      setActiveProductInfo((pre: any) => ({ ...pre, isLoading: false, ...product }));
    };
    if (activeProductInfo?.id) {
      fetchProduct();
    }
  }, [activeProductInfo?.id]);
  return (
    <Layer fill direction="column" width={'100%'}>
      <Layer
        direction="column"
        style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0' }}
        width={match ? '100%' : '50%'}
      >
        <Box display={'flex'} flexDirection="column">
          <Typography variant="h5">Import Product and Ingredients from NIH</Typography>
          <Grid container className={classes.searchProductFromNIHSection} spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                variant="standard"
                disableUnderline
                className={classes.selectBox}
                value={searchProductData.method}
                onChange={(e: any) => handleSearchProduct('method', e.target.value)}
              >
                <MenuItem value="by_letter">By Letter</MenuItem>
                <MenuItem value="by_keyword">By Keyword</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                className={classes.textfieldRoot}
                placeholder="From"
                value={searchProductData.from}
                type="number"
                onChange={e => handleSearchProduct('from', getOnlyDigit(e.target.value))}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                className={classes.textfieldRoot}
                placeholder="Size"
                value={searchProductData.size}
                type="number"
                onChange={e => handleSearchProduct('size', getOnlyDigit(e.target.value))}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={function(params: AutocompleteRenderInputParams): React.ReactNode {
                  return (
                    <TextField
                      {...params}
                      placeholder="Search product or label from NIH Database"
                      variant="standard"
                      InputProps={{ disableUnderline: true, ...params.InputProps }}
                      autoFocus
                      className={classes.textfieldRoot}
                      name="nih-product-search-textfield"
                    />
                  );
                }}
                loading={searchProductData.isLoading}
                loadingText={
                  <Box display={'flex'} alignItems="center" justifyContent={'center'}>
                    <Typography variant="body1">Loading...</Typography>
                    <MuiCircularProgress size={20} style={{ marginLeft: 5 }} />
                  </Box>
                }
                freeSolo
                onInputChange={(e: any, value: string) => handleSearchProduct('q', value?.trim())}
                options={productList}
                onChange={(e: any, value: any) =>
                  setActiveProductInfo((pre: any) => ({ ...pre, id: value?.id || 0, isLoading: Boolean(value?.id) }))
                }
                getOptionLabel={(option: any): string => option?.fullName || ''}
                noOptionsText={<Typography variant="body1">0 labels found.</Typography>}
                filterOptions={(option: any) => option}
                classes={{ option: classes.autocompleteOption }}
                renderOption={(option: any): React.ReactNode => {
                  return (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={8}>
                        <Box display={'flex'} flexDirection="column" className={classes.productQuickInfo}>
                          <Typography variant="h6">{option.fullName}</Typography>
                          <Typography variant="body1">
                            <span>Brand:</span>
                            {option.brandName}
                          </Typography>
                          <Typography variant="body1">
                            <span>Net Contents:</span>
                            {concatArrayOfObjectStrings(option.netContents, 'display')}
                          </Typography>
                          {option?.events?.map((item: any, key: number) => {
                            return (
                              <Typography variant="body1" key={key}>
                                <span>{item?.type}:</span>
                                {item?.date}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} className={classes.productImg}>
                        <Box display={'flex'} alignItems="center" justifyContent={'center'}>
                          <img
                            src={`https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/thumbnails/${option.id}.jpg`}
                            alt={option.fullName || ''}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Grid>
          </Grid>
          {activeProductInfo?.isLoading ? (
            <Box display={'flex'} alignItems="center" justifyContent={'center'}>
              <Typography variant="body1">Please wait while we are fetching the product detail...</Typography>
              <MuiCircularProgress size={20} style={{ marginLeft: 5 }} />
            </Box>
          ) : activeProductInfo.id && activeProductInfo?.fullName ? (
            <CreateProductForm data={activeProductInfo} />
          ) : null}
        </Box>
      </Layer>
    </Layer>
  );
}
