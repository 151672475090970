import React, { FunctionComponent } from 'react';
import { Grid, Typography,makeStyles,Theme,createStyles } from '@material-ui/core';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    tipsContainer:{
        flexDirection:"column",
    },
    tipsHeader:{
        height: 48,
        background:"#FBFBFB",
        padding: 12,
        display:"flex",
        alignItems:"center",
        '&>h6':{
            fontSize:"14px",
            lineHeight:"16px",
            fontWeight:700,
            color:"#000",
            textTransform:"capitalize",
        }
    },
    tipsContent:{
        padding:"15px 10px",
        display:"flex",
        flexDirection:"column",
        border:'1px solid #DEDEDE',
        marginBottom: 10,
        '&>p':{
            fontSize:"12px",
            lineHeight:"14px",
            fontWeight: 400,
            color:"#B0B0B0",
            margin: '5px 0px',
        }
    }
}))

export const Tips: FunctionComponent = () => {
    const classes = useStyle();
  return (
    <Grid item>
        <Grid container className={classes.tipsContainer}>
            <Grid item className={classes.tipsHeader}><Typography variant="h6">Tips</Typography></Grid>
            <Grid item className={classes.tipsContent}>
                <Typography variant="body1">- Make every question count.</Typography>
                <Typography variant="body1">- Keep it short and simple.</Typography>
                <Typography variant="body1">- Ask direct questions.</Typography>
                <Typography variant="body1">- Ask one question at a time.</Typography>
                <Typography variant="body1">- Avoid leading and biased questions.</Typography>
                <Typography variant="body1">- Speak your respondent's language.</Typography>
                <Typography variant="body1">- Use response scales whenever possible.</Typography>
                <Typography variant="body1">- Avoid using grids or matrices for responses.</Typography>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Tips;