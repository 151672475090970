import React, { CSSProperties, FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Layer } from '../common';
import { titles, titlesTooltips } from '../config';
import { useTransitsService } from '../hooks/api/useTransitsService';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { AddDropdown } from './AddDropdown';
import { IntegrantKind } from '../types/common';
import { PrimaryButton } from 'common/Button/buttons';

const transitWrapper: CSSProperties = { position: 'relative', zIndex: 3, marginBottom: 10 };

const AddTransit: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();

  const { setOpen, isOpen, onAddTransit } = useCreateTransitStore();
  const { isLoading } = useTransitsService();

  return isDraft ? null : (
    <>
      <ReactTooltip />
      <Layer style={transitWrapper} direction="row">
        <PrimaryButton disabled={isLoading} loading={isLoading} onClick={(): void => setOpen(isOpen ? false : true)} icon='locationOn' dataTip={titlesTooltips.transit} children={!isOpen ? `Add a change of ${titles.transit}` : 'Close'} />

        <AddDropdown
          isLoading={isLoading}
          isOpen={isOpen}
          setOpen={setOpen}
          onAddItem={(item) => onAddTransit(item, IntegrantKind.Transit)}
          placeholderTitle={'Title... e.g. Moved to safe storage facility.'}
          placeholderDescription={'Description... e.g. The trip duration was 86 minutes.'}
          renderHeader={(): JSX.Element => (
            <>
              {titles.transitPlural} allow for the transition of {titles.transitPlural.toLowerCase()}
            </>
          )}
        />
      </Layer>
    </>
  );
};

export { AddTransit };
