import { IExhibitDTO } from '../../lib/exhibit';
import { IFacetDTO } from '../../lib/facet';
import { IIntegrantTypeDTO } from '../../lib/integrantType';
import { useContext, useEffect } from 'react';
import { IIntegrantNode } from '../components/GraphNode';
import { defaultNodeAxis } from '../config';
import { GraphPaneContext } from '../context/integrantGraph/graphPaneContext';
import { IEdge, IGraphData, IIntegrantGraphProviderState } from '../context/integrantGraph/types';
import { IntegrantKind } from '../types/common';
import { generateClientID, generateInheritedFacetsRank, isEmptyString, handleUpdateRank } from '../utils';
import { useIntegrantTypeStore } from './useIntegrantTypeStore';
import { defaults } from '../context/integrantGraph/integrantGraphContextState';

interface IIntegrantGraphStore extends IIntegrantGraphProviderState {
  onSelectIntegrantType: (integrantType: IIntegrantTypeDTO, sourceID: IEdge['source']) => void;
  onAddIntegrant: (integrant: IIntegrantNode, sourceID: IEdge['source'], integrantKind: IntegrantKind) => void;
  onCancelIntegrant: () => void;
  setData: (updatedData: IGraphData) => void;
  setFacets: (facets: IFacetDTO[]) => IFacetDTO[];
  setExhibits: (exhibits: IExhibitDTO[]) => IExhibitDTO[];
  setCurrentIntegrant: (
    updatedData: IIntegrantNode,
    clientId?: string,
    edgeId?: string,
    selectedParentId?: string
  ) => void;
  setSelectedIntegrant: (updatedData: IIntegrantNode) => void;
  setFirstSelectedIntegrant: (updatedData: IIntegrantNode) => void;
  setCurrentGraphData: (
    updatedData: IIntegrantNode,
    clientId?: string,
    edgeId?: string,
    selectedParentId?: string
  ) => void;
  setRootIntegrantId: (val: string) => void;
  setCurrentEdge: (updatedData: IIntegrantNode) => void;
  setIntegrantKind: (integrantKind: IntegrantKind) => void;
  setTree: (tree: any[], currentIntegrant: any) => void;
  batchViewPopup: (val: any) => void;
  reset: (data?: any) => void;
}

const useIntegrantGraphStore = (integrant_id?: string): IIntegrantGraphStore => {
  const [state, setState] = useContext(GraphPaneContext);
  const { selectedIntegrantType } = useIntegrantTypeStore();
  const { data, isLoading, currentIntegrant, currentParentId, batch_id, detail, currentEdge, integrantKind, integrantsData, firstCurrentIntegrant, batchViewPopupVal } = state;
  useEffect(() => {
    if (integrant_id === '' && state.currentIntegrant.id === '') {
      const organization_id: string =
        selectedIntegrantType.organization_id !== null ? selectedIntegrantType.organization_id : '';
      if (!isEmptyString(organization_id) && selectedIntegrantType.organization_id !== null) {
        const mergeSelectedProductAsIntegrant: IIntegrantNode = {
          ...state.currentIntegrant,
          ...selectedIntegrantType,
          primary: true,
          integrant_type_id: selectedIntegrantType.id,
          // facets: generateInheritedFacetsRank(selectedIntegrantType.facets),
          id: generateClientID(),
          organization_id: selectedIntegrantType.organization_id
        };
        setState(s => ({
          ...s,
          isLoading: false,
          data: {
            nodes: [{ ...mergeSelectedProductAsIntegrant, x: defaultNodeAxis, y: 200 }],
            edges: []
          },
          currentIntegrant: mergeSelectedProductAsIntegrant
        }));
      }
    }
  }, [selectedIntegrantType, state.currentIntegrant, integrant_id, setState, currentIntegrant.edge_type]);

  const reset: IIntegrantGraphStore['reset'] = (data = {}): void =>{
    setState({...defaults,...data})
  }

  const setCurrentEdge: IIntegrantGraphStore['setCurrentEdge'] = (updatedIntegrant): void => {
    setState(s => ({ ...s, currentEdge: data.edges.filter(e => e.target === updatedIntegrant.id)[0] }));
  };

  const setTree: IIntegrantGraphStore['setTree'] = (tree, currentIntegrant): void => {
    function childList(childListObj: any) {
      const listObj01: any = {};
      listObj01.children = [];
      listObj01.id = childListObj.source;
      listObj01.image_thumb_url = childListObj.sourceInfo.image;
      listObj01.title = childListObj.sourceInfo.title;
      listObj01.parent_id = childListObj.target;
      if (childListObj.nodes) {
        childListObj.nodes.forEach((nodesList: any) => {
          listObj01.children.push(childList(nodesList))
        });
      }
      return listObj01
    }

    function graphChildList(childListObj: any, sourceId: any) {
      graphList.nodes.push({
        id: childListObj.source,
        title: childListObj.sourceInfo.title,
        image_thumb_url: childListObj.sourceInfo.image,
        x: defaultNodeAxis,
        y: 200
      })
      graphList.links.push({
        edge_type: 'Default',
        source: sourceId,
        target: childListObj.source
      })
      if (childListObj.nodes) {
        childListObj.nodes.forEach((nodesList: any) => {
          graphChildList(nodesList, childListObj.source)
        });
      }
    }

    const updatedList: any = []
    const graphList: any = {
      nodes: [],
      links: []
    }

    if (tree && tree.length) {
      const { integrantInfo: { title, integrantsType } } = currentIntegrant
      tree.forEach(list => {
        const listObj: any = {};
        listObj.children = [];
        listObj.title = title;
        listObj.id = list.source;
        listObj.parent_id = list.source;
        listObj.image_thumb_url = integrantsType ? integrantsType.image_url : '';
        graphList.nodes.push({
          id: list.source,
          title: title,
          image_thumb_url: integrantsType ? integrantsType.image_url : '',
          x: 0,
          y: 0
        })
        graphList.links.push({
          edge_type: 'Default',
          source: list.source,
          target: list.source
        })
        updatedList.push(listObj);
        if (list.nodes) {
          list.nodes.forEach((nodesList: any) => {
            graphChildList(nodesList, list.source)
            listObj.children.push(childList(nodesList))
          });
        }
      });
    }
    setState(s => (
      {
        ...s,
        integrantsData: { integrantsGraph: graphList, integrantsTree: updatedList }
      }
    ));
  };

  const onSelectIntegrantType: IIntegrantGraphStore['onSelectIntegrantType'] = (
    integrantType: IIntegrantTypeDTO,
    currentParentId
  ) => {
    let facetsBeauty01: any = integrantType.facets
    let facetsBeauty = [];
    let facetsAddId = [];
    if (integrantType.facets) {
      facetsBeauty = JSON.parse(facetsBeauty01);
      facetsAddId = facetsBeauty.filter((item: any) => {
        item.id = generateClientID();
        item.isOld = true;
        return item;
      });
    }
    const updatedIntegrant: IIntegrantNode = {
      ...currentIntegrant,
      ...integrantType,
      ...{
        id: generateClientID(),
        integrant_type_id: integrantType.id,
        x: 0,
        y: 0,
        edge_type: IntegrantKind.Default,
        organization_id: currentIntegrant.organization_id,
        exhibits: [],
        location: null,
        facets: facetsAddId
      }
    };

    const updatedData: IGraphData = {
      nodes: [...state.data.nodes, { ...updatedIntegrant }],
      edges: [
        ...state.data.edges,
        { source: currentParentId, target: updatedIntegrant.id, edge_id: '', edge_type: integrantKind }
      ]
    };

    const integrantsTreeList = state.integrantsData.integrantsTree

    function updatedList(item: any) {
      if (item.parent_id === currentParentId) {
        item.children.push({
          children: [],
          title: "",
          id: updatedIntegrant.id,
          parent_id: currentParentId,
          image_thumb_url: ""
        })
      }
      return item
    }

    integrantsTreeList.filter(item => {
      let updatedItem = updatedList(item)
      return updatedItem
    })

    const updatedIntegrantsData = {
      integrantsGraph: state.integrantsData.integrantsGraph,
      integrantsTree: integrantsTreeList
    }

    setState(s => ({ ...s, integrantsData: updatedIntegrantsData, data: updatedData, currentIntegrant: updatedIntegrant, currentParentId }));

    setCurrentEdge(updatedIntegrant);
  };

  const onAddIntegrant: IIntegrantGraphStore['onAddIntegrant'] = (
    integrant,
    currentParentId,
    integrantKind = IntegrantKind.Default
  ) => {
    const updatedIntegrant: IIntegrantNode = {
      ...integrant,
      ...{ id: generateClientID(), integrant_type_id: integrant.integrant_type_id },
      x: 0,
      y: 0
    };

    const updatedData: IGraphData = {
      nodes: [...state.data.nodes, updatedIntegrant],
      edges: [
        ...state.data.edges,
        { source: currentParentId, target: updatedIntegrant.id, edge_id: '', edge_type: integrantKind }
      ]
    };

    setState(s => ({ ...s, data: updatedData, currentIntegrant: updatedIntegrant, currentParentId, integrantKind }));
    setCurrentEdge(updatedIntegrant);
  };

  const setData: IIntegrantGraphStore['setData'] = (updatedData): void => setState(s => ({ ...s, data: updatedData }));

  const setNodesAndEdges = (
    id: string,
    nodes: IIntegrantNode[],
    edges: IEdge[],
    updatedIntegrant: IIntegrantNode
  ): void => {
    const foundIndex = nodes.findIndex(x => x.id === id);
    nodes[foundIndex] = updatedIntegrant;
    setData({ ...data, nodes: nodes, edges: edges });
  };

  const setCurrentGraphData: IIntegrantGraphStore['setCurrentGraphData'] = (
    updatedIntegrant,
    clientId,
    edgeId,
    selectedParentId
  ) => {
    const clientOrServerId = typeof clientId !== 'undefined' ? clientId : updatedIntegrant.id;
    if (typeof clientId !== 'undefined') {
      const updatedNodes = data.nodes;
      const updatedEdges = data.edges.map(edge =>
        edge.target === clientOrServerId
          ? {
            source: typeof selectedParentId !== 'undefined' ? selectedParentId : '',
            target: updatedIntegrant.id,
            edge_id: edgeId,
            edge_type: IntegrantKind.Default
          }
          : { ...edge }
      );
      setNodesAndEdges(clientOrServerId, updatedNodes, updatedEdges, updatedIntegrant);
    } else {
      setNodesAndEdges(updatedIntegrant.id, data.nodes, data.edges, updatedIntegrant);
    }
    setCurrentEdge(updatedIntegrant);
  };

  const setCurrentIntegrant: IIntegrantGraphStore['setCurrentIntegrant'] = (
    updatedIntegrant,
    clientId,
    edgeId,
    selectedParentId
  ): void => {
    setState(s => ({ ...s, currentIntegrant: updatedIntegrant }));
    setCurrentGraphData(updatedIntegrant, clientId, edgeId, selectedParentId);
  };

  const setSelectedIntegrant: IIntegrantGraphStore['setSelectedIntegrant'] = (updatedIntegrant): void => {
    setState(s => ({ ...s, currentIntegrant: updatedIntegrant }));
  };

  const setFirstSelectedIntegrant: IIntegrantGraphStore['setSelectedIntegrant'] = (updatedIntegrant): void => {
    setState(s => ({ ...s, firstCurrentIntegrant: updatedIntegrant }));
  };

  const setFacets: IIntegrantGraphStore['setFacets'] = (facets): IFacetDTO[] => {
    const updatedFacetsRank = handleUpdateRank(facets);
    setCurrentIntegrant({
      ...currentIntegrant,
      facets: updatedFacetsRank
    });
    return updatedFacetsRank;
  };

  const setExhibits: IIntegrantGraphStore['setExhibits'] = (exhibits): IExhibitDTO[] => {
    const updatedExhibitsRank = handleUpdateRank(exhibits);
    setCurrentIntegrant({ ...currentIntegrant, exhibits: updatedExhibitsRank });
    return updatedExhibitsRank;
  };

  const setRootIntegrantId: IIntegrantGraphStore['setRootIntegrantId'] = val => {
    setState(s => ({ ...s, batch_id: val }));
  };

  const setIntegrantKind: IIntegrantGraphStore['setIntegrantKind'] = integrantKind => {
    setState(s => ({ ...s, integrantKind: integrantKind }));
  };

  const onCancelIntegrant: IIntegrantGraphStore['onCancelIntegrant'] = () => {
    const updatedNodes = state.data.nodes.filter(n => n.id !== currentIntegrant.id);
    const updatedEdges = state.data.edges.filter(e => e.target !== currentIntegrant.id);
    const updatedData: IGraphData = {
      nodes: updatedNodes,
      edges: updatedEdges
    };
    const foundIndex = updatedNodes.findIndex(x => x.id === currentParentId);
    setCurrentIntegrant(updatedData.nodes[foundIndex]);
    setIntegrantKind(IntegrantKind.Default);
    setData(updatedData);
  };

  const batchViewPopup: IIntegrantGraphStore['batchViewPopup'] = val => {
    setState(s => ({ ...s, batchViewPopupVal: val }));
  };

  return {
    data,
    isLoading,
    currentIntegrant,
    currentParentId,
    batch_id,
    detail,
    currentEdge,
    integrantKind,
    integrantsData,
    firstCurrentIntegrant,
    batchViewPopupVal,
    onSelectIntegrantType,
    setCurrentEdge,
    setCurrentIntegrant,
    onCancelIntegrant,
    onAddIntegrant,
    setData,
    setRootIntegrantId,
    setFacets,
    setExhibits,
    setIntegrantKind,
    setCurrentGraphData,
    setSelectedIntegrant,
    setFirstSelectedIntegrant,
    setTree,
    batchViewPopup,
    reset
  };
};

export { useIntegrantGraphStore };
