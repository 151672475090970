import { useContext, useEffect } from 'react';
import { OrganizationExhibitContext,IOrganizationExhibitProviderState } from '../context/organizationExhibitContext';
import { IGetAllOrganizationExhibitsDTO } from '../../lib/organization_exhibit';
import { useOrganizationExhibitService } from './api/useOrganizationExhibitService';

interface IOrganizationExxhibitStore extends IOrganizationExhibitProviderState {
  initCreateOrganizationExhibit: (data: any)=> void;
  initUpdateOrganizationExhibit: (id: string, data: any) => void;
  initDestroyOrganizationExhibit: (id: string) => void;
  initGetAllOrganizationExhibit: (query: IGetAllOrganizationExhibitsDTO) => void;
  initGetOneOrganizationExhibit: (id: string) => void;
}

const useOrganizationExhibitStore = (): IOrganizationExxhibitStore => {
  const [state, setState] = useContext(OrganizationExhibitContext);
  const { create, update, destroy, getAll, getOne } = useOrganizationExhibitService();
  const {createData, updateData, deleteData, getAllData, getOneData} = state;

  const initCreateOrganizationExhibit: IOrganizationExxhibitStore["initCreateOrganizationExhibit"] = async (data: any) => {
    setState( pre => ({...pre, createData: {...pre.createData, loading: true}}));
    const response = await create(data)
    setState( pre => ({...pre, createData: {...pre.createData, ...response, loading: false}}));
  }

  const initUpdateOrganizationExhibit: IOrganizationExxhibitStore["initUpdateOrganizationExhibit"] = async (id: string, data: any) => {
    setState( pre => ({...pre, updateData: {...pre.updateData, loading: true}}));
    const response = await update(id, data)
    setState( pre => ({...pre, updateData: {...pre.updateData, ...response, loading: false}}));
  }

  const initDestroyOrganizationExhibit: IOrganizationExxhibitStore["initDestroyOrganizationExhibit"] = async (id: string) => {
    setState( pre => ({...pre, deleteData: {...pre.deleteData, loading: true}}));
    const response = await destroy(id)
    setState( pre => ({...pre, deleteData: {...pre.deleteData, ...response, loading: false}, getAllData:{...pre.getAllData, data: {...pre?.getAllData?.data, rows: response?.status === "1" ?  pre?.getAllData?.data?.rows?.filter((item: any)=> item?.id !== id) : pre?.getAllData?.data?.rows, count: response?.status === "1" ? pre?.getAllData?.data?.count - 1 :  pre?.getAllData?.data?.count}}}));
  }

  const initGetAllOrganizationExhibit: IOrganizationExxhibitStore["initGetAllOrganizationExhibit"] = async (query: IGetAllOrganizationExhibitsDTO) => {
    setState( pre => ({...pre, getAllData: {...pre.getAllData, loading: true}}));
    const response = await getAll(query)
    setState( pre => ({...pre, getAllData: {...pre.getAllData, ...response, loading: false}}));
  }

  const initGetOneOrganizationExhibit: IOrganizationExxhibitStore["initGetOneOrganizationExhibit"] = async (id: string) => {
    setState( pre => ({...pre, getOneData: {...pre.getOneData, loading: true, status: ""}}));
    const response = await getOne(id)
    setState( pre => ({...pre, getOneData: {...pre.getOneData, ...response, loading: false}}));
  }

  return {
    createData, updateData, deleteData, getAllData, getOneData,
    initCreateOrganizationExhibit,
    initUpdateOrganizationExhibit,
    initDestroyOrganizationExhibit,
    initGetAllOrganizationExhibit,
    initGetOneOrganizationExhibit
  };
};

export { useOrganizationExhibitStore };
