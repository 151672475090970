import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { GoTrashcan } from 'react-icons/go';
import ReactTooltip from 'react-tooltip';
import UseAnimations from 'react-useanimations';
import { Input, Layer } from '../common';
import { isClientId, titlesTooltips } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { defaultButtonStyle, formButtonStyle, loadingIconStyle, primaryButtonStyle } from '../styles/default';
import { isEmptyString, isInheritedRank } from '../utils';
import Icon from '../common/Icon';
import { PrimaryButton } from 'common/Button/buttons';

interface IListItemProps<T> {
  isSaveDisabled: boolean;
  item: T;
  handleSave: (currentItem: T) => Promise<void>;
  handleRemove: (currentItemId: string) => Promise<void>;
  handleCancel: (currentItem: T) => Promise<void>;
}

interface IEditListTransitItemProps<T> extends IListItemProps<T> {
  renderViewOnlyItem: (item: T) => ReactNode;
  renderTopForm: (currentItem: T) => ReactNode;
  renderBottomForm?: (currentItem: T) => ReactNode;
}

interface IEditableListItemProps<T> extends IListItemProps<T> {
  setEditable: (isEditable: boolean) => void;
  renderTopForm: (currentItem: T) => ReactNode;
  renderBottomForm?: (currentItem: T) => ReactNode;
}

const itemStyle: CSSProperties = { borderBottom: '1px solid #ccc', paddingTop: 10, paddingBottom: 10 };
const editButtonStyle: CSSProperties = { paddingTop: 3, paddingLeft: 7, marginRight: 5, cursor: 'pointer' };
const editableItemStyle: CSSProperties = { width: '100%', paddingRight: 10, paddingLeft: 10 };

const EditableListItem = <T extends { id: string; title: string; }>(
  props: IEditableListItemProps<T> & { children?: ReactNode }
): JSX.Element => {
  const {
    item,
    handleRemove,
    handleSave,
    setEditable,
    renderTopForm,
    handleCancel,
    isSaveDisabled,
    renderBottomForm
  } = props;
  const { isDraft } = useCreateBatchStore();
  const [currentItem, setItem] = useState(item);
  const [isLoading, setLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const { id, title } = currentItem;

  const onChange = (key: keyof T, value: string): void => {
    setItem({ ...currentItem, [key]: value });
  };

  const onSave = async (): Promise<void> => {
    setLoading(true);
    await handleSave(currentItem);
    setLoading(false);
    setEditable(false);
  };

  const onCancel = async (): Promise<void> => {
    if (isClientId(currentItem.id)) {
      await handleCancel(currentItem);
    }
    setEditable(false);
  };

  const onDelete = async (): Promise<void> => {
    setDeleteLoading(true);
    await handleRemove(id);
    setDeleteLoading(false);
    setEditable(false);
  };

  const isCurrentItemEmpty = isEmptyString(title);
  const isButtonDisabled = isDeleteLoading || isLoading;

  const renderSaveButton = (isVisible: boolean): JSX.Element | null => {
    return isVisible ? (
        <PrimaryButton maxContent={true} style={{marginRight: 10}} disabled={isCurrentItemEmpty || isButtonDisabled || isSaveDisabled}
          onClick={onSave} icon='save' secondaryButton={true} loading={isLoading} children={"Save"} />
    ) : null;
  };
  const isServerId = !isClientId(currentItem.id);
  return (
    <div style={editableItemStyle}>
      {renderTopForm(currentItem)}
      <Input
        disabled={isServerId}
        required={isEmptyString(title)}
        autoFocus={!isServerId}
        placeholder="Title"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange('title', e.target.value)}
        defaultValue={title}
      />
      {typeof renderBottomForm !== 'undefined' ? renderBottomForm(currentItem) : null}
      <div style={{ marginTop: 10 }}>
        {renderSaveButton(!isCurrentItemEmpty && !isSaveDisabled)}
        <PrimaryButton icon='close' maxContent={true} children={"Cancel"} disabled={isButtonDisabled} onClick={onCancel} />
        {!isDraft ? (
          <PrimaryButton maxContent={true} style={{marginLeft: 10}} disabled={isButtonDisabled} onClick={onDelete} icon='delete' children={"Delete"} loading={isDeleteLoading} />
        ) : null}
      </div>
    </div>
  );
};

const EditListTransitItem = <T extends { id: string; title: string; description: string; rank: number; isOld: boolean }>(
  props: IEditListTransitItemProps<T> & { children?: ReactNode }
): JSX.Element => {
  const [isEditable, setEditable] = useState(false);
  const {
    item,
    handleRemove,
    handleSave,
    renderViewOnlyItem,
    renderTopForm,
    handleCancel,
    isSaveDisabled,
    renderBottomForm
  } = props;
  const { title, isOld } = props.item;

  useEffect(() => {
    if (isEmptyString(title)) {
      setEditable(true);
    }
  }, [item, title]);

  useEffect(() => {
    return (): void => {
      setEditable(false);
    };
  }, []);
  if (item.description) {
    return <></>
  } else {
    return (
      <Layer direction="row">
        {!isEditable ? (
          <>
            <ReactTooltip />
            {item.isOld ? (
              <div style={editButtonStyle}>
                <Icon icon='archive' dataTip={titlesTooltips.inherited_facet} />
              </div>
            ) : (
              <div style={editButtonStyle}>
                <Icon icon='edit' onClick={(): void => setEditable(!isEditable)} />
              </div>
            )}
          </>
        ) : null}
        {isEditable ? (
          <EditableListItem<T>
            handleRemove={handleRemove}
            handleSave={handleSave}
            handleCancel={handleCancel}
            setEditable={setEditable}
            item={item}
            renderTopForm={renderTopForm}
            renderBottomForm={renderBottomForm}
            isSaveDisabled={isSaveDisabled}
          />
        ) : (
          renderViewOnlyItem(item)
        )}
      </Layer>
    );
  }
};

export { EditListTransitItem };
