import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import {IAPIResponseDTO} from '../../../lib/common';
import axiosInstance from './../../utils/api-interceptor'

interface ISurveyService {
    getSurveySubmissions:(integrant_type_id: string)=>Promise<IAPIResponseDTO>;
}

const useSurveyService = (): ISurveyService => {
    const { individual } = useAuthStore();

    const getSurveySubmissions: ISurveyService["getSurveySubmissions"] = async (integrant_type_id) => {
        try{
            return await axiosInstance.post("/surveyquestionanswers/lists",{integrant_type_id},formAxiosHeader(individual.access_token));
        }catch(err){
            return {
                status: "0",
                message: "Something went wrong! Please try after sometime."
            }
        }
    }

  return { getSurveySubmissions };
};

export { useSurveyService };
