import React from 'react';
import { Input } from '../../common/Input';
import { TextArea } from '../../common/TextArea';
import { isEmptyString } from './../../utils';

const FormInput = (props: any) => {
  const { name, label, value, inputsHandler, isDisable, type, isFirst, required, error = '' } = props;
  return (
    <div className={'d-flex organization-strip ' + (isFirst && 'f')}>
      <div className="organization-label">{label}</div>
      <div className="organization-field">
        {type === 'textarea' ? (
          <>
            <TextArea
              disabled={isDisable}
              value={value}
              name={name}
              required={required && Boolean(error)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => inputsHandler(event)}
            />
            {required && Boolean(error) && <p className="error-message">{error}</p>}
          </>
        ) : (
          <>
            <Input
              disabled={isDisable}
              value={value}
              name={name}
              required={required && Boolean(error)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {required && Boolean(error) && <p className="error-message">{error}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export { FormInput };
