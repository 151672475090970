import React, { CSSProperties, FunctionComponent } from 'react';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';

const layerStyle: CSSProperties = {
  padding: 20
};

const SelectedProductInfo: FunctionComponent = () => {
  const { selectedIntegrantType } = useIntegrantTypeStore();
  const product = selectedIntegrantType;
  const { title, description } = product;

  return (
    <div>
      <div style={layerStyle}>
        <strong style={{ marginBottom: 20, display: 'block' }}>{title}</strong>
        <small>{description}</small>
      </div>
    </div>
  );
};

export { SelectedProductInfo };
