import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Tooltip
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { ExpandMore, Delete } from '@material-ui/icons';
import { CreateIntegrantTypePaneHeader } from 'components/CreateIntegrantTypePaneHeader';
import { IntegrantTypeDetails } from 'components/IntegrantTypeDetails';
import { IntegrantTypeFacets } from 'components/IntegrantTypeFacets';
import { useCreateIntegrantTypeStore } from 'hooks/useCreateIntegrantTypeStore';
import ConfirmationDialog from 'common/Dialogs/ConfirmationDialog';

interface IIngredientsForm {
  isVisible?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    heading: {},
    ingredientsContainer: {
      margin: '20px 0'
    },
    accordionDetails: {
      width: '100%',
      height: 'auto',
      overflow: 'hidden'
    },
    expandedAccordion: {
      '&>div:first-child': {
        backgroundColor: '#f1f1f1'
      }
    },
    accordionHeader: {
      width: 'calc(100% - 5px)',
      '&>button': {
        padding: 5
      }
    }
  })
);

export const IngredientsForm: FunctionComponent<IIngredientsForm> = ({ isVisible = false }) => {
  const classes = useStyle();
  const { ingredients, onRemoveIngredients } = useCreateIntegrantTypeStore();
  const [openIngredientDeleteConfDialog, setOpenIngredientDeleteConfDialog] = useState({
    id: 0,
    title: ''
  });
  if (!isVisible) return <></>;
  return (
    <Box className={classes.ingredientsContainer}>
      {ingredients?.length > 0 ? (
        ingredients?.map((item: any, key: number) => {
          return (
            <Accordion key={key} classes={{ expanded: classes.expandedAccordion }}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Box
                  display={'flex'}
                  alignItems="center"
                  justifyContent={'space-between'}
                  className={classes.accordionHeader}
                >
                  <Typography variant="body1">{item?.title}</Typography>
                  <Tooltip arrow title={`Remove ${item?.title} from ingredients list.`}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setOpenIngredientDeleteConfDialog(item);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.accordionDetails}>
                  <CreateIntegrantTypePaneHeader ingredient={item} />
                  <IntegrantTypeDetails isVisible={item?.slideIndex === 0} ingredient={item} />
                  <IntegrantTypeFacets isVisible={item?.slideIndex === 1} ingredient={item} />
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Box>
          <Typography variant="body1">Ingredients information not available.</Typography>
        </Box>
      )}
      <ConfirmationDialog
        open={Boolean(openIngredientDeleteConfDialog.id)}
        handleClose={(value: any) => {
          if (value) {
            onRemoveIngredients(openIngredientDeleteConfDialog.id);
          }
          setOpenIngredientDeleteConfDialog({
            id: 0,
            title: ''
          });
        }}
        title="Confirmation"
        message={`Are you sure you want to remove ${openIngredientDeleteConfDialog.title} ingredient from the list?`}
      />
    </Box>
  );
};

export default IngredientsForm;
