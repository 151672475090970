import axios from 'axios';
import { url } from '../config';
import { toast } from 'react-toastify';
import { thresholdUX } from '../components/Threshold';

const errorHandler = (error: any) => {
  const statusCode = error.response ? error.response.status : null;
  const errorString = error.response
    ? error.response.data
      ? error.response.data.message
      : 'Oops, something went wrong Please try again later.'
    : null;
  let test = '';
  switch (statusCode) {
    case 404:
      test = 'The requested resource does not exist or has been deleted';
      break;
    case 401:
      test = 'Please login to access this resource';
      break;
    case 500:
      test = errorString || 'Oops, something went wrong Please try again later.';
      break;
    default:
      test = errorString;
  }
  if (statusCode === 403) {
    if (errorString === 'Please enter new password' && error?.response?.data?.error === 'Forbidden') {
      toast.error(
        'You have requested new password so please try to login with a temporary password you would have received in email from HealthLoq.'
      );
    } else if (errorString === 'Forbidden resource') {
    } else {
      thresholdUX(errorString);
    }
  } else if (statusCode === 401) {
    test = '';
    localStorage.removeItem('otpVerified');
    document.cookie = `hl-individual=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    window.location.href = '/login';
    toast.error('Your login session is expired. please login again for continue your work.');
  } else {
    toast.error(test);
  }
};

const axiosInstance = axios.create({
  baseURL: url
});

axiosInstance.interceptors.response.use(
  response => {
    if (response && response.data) {
      return response.data;
    } else {
      return response;
    }
  },
  error => {
    errorHandler(error);
    return Promise.reject({ ...error.response.data });
  }
);

export default axiosInstance;
