import React, { CSSProperties, FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Layer } from '../common';
import { titles, titlesTooltips } from '../config';
import { errorColor, tabItemStyle } from '../styles/default';
import { HR } from './HR';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';

interface ICreateIntegrantTypePaneHeaderProps {
  type?: string;
  ingredient?: any;
}

const CreateIntegrantTypePaneHeader: FunctionComponent<ICreateIntegrantTypePaneHeaderProps> = ({
  type = '',
  ingredient = null
}) => {
  const { gotoSlideIndex, slideIndex, ingredients } = useCreateIntegrantTypeStore();

  const isTabSelected = (n: number, isError: boolean): CSSProperties =>
    n === (ingredient ? ingredient?.slideIndex || 0 : slideIndex)
      ? tabItemStyle
      : { ...tabItemStyle, color: isError ? errorColor : '#888' };
  return (
    <>
      <Layer>
        <ReactTooltip />

        <h5
          data-tip={titlesTooltips.details}
          onClick={(): void => gotoSlideIndex(0, false, ingredient)}
          style={isTabSelected(0, false)}
        >
          Details
        </h5>
        <h5
          data-tip={titlesTooltips.facets}
          onClick={(): void => gotoSlideIndex(1, false, ingredient)}
          style={isTabSelected(1, false)}
        >
          {titles.facetsPlural}
        </h5>
        {type === 'importNIHProduct' && (
          <h5
            data-tip={titlesTooltips.options}
            onClick={(): void => gotoSlideIndex(2, false, ingredient)}
            style={isTabSelected(2, false)}
          >
            {titles.subIntegrant}
          </h5>
        )}
      </Layer>
      <HR />
    </>
  );
};

export { CreateIntegrantTypePaneHeader };
