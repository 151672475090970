import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { titles } from '../config';

export const ErrorFallback: React.ComponentType<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return typeof error !== 'undefined' ? (
    <div role="alert">
      <p>{titles.error}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  ) : null;
};
