import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import { IAPIResponseDTO } from '../../lib/common';

export interface IUserProviderState {
  userDataById: IAPIResponseDTO;
  userFilteredCount: IAPIResponseDTO;
  sendSurveyInvitationData: IAPIResponseDTO;
  myConnections: IAPIResponseDTO;
  verifyDomainData: IAPIResponseDTO;
}

export const defaults = {
  userDataById: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  userFilteredCount: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  sendSurveyInvitationData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  myConnections: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  verifyDomainData: {
    status: '',
    message: '',
    loading: false,
    data: null
  }
};

const UserContext = React.createContext<CreateContext<IUserProviderState>>([
  defaults,
  (): IUserProviderState => defaults
]);

const UserProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<IUserProviderState>(defaults);
  return <UserContext.Provider value={[state, setState]}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
