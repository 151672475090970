import React from "react"
import { Link } from "react-router-dom"

const PageNotFound = () => (
    <>
        <div className="page-404 d-flex align-items-center justify-content-center">
            <div className="text-center">
                {/* <img src={Icon404} alt="" className="image-404" /> */}
                <p className="text-unfortunately">Unfortunately the page you are looking for has been moved or deleted</p>
                <Link to="/" className="btn btn-success">Go Back Home</Link>
            </div>
        </div>
    </>
    )
export default PageNotFound
