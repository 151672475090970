import { createStyles, FormHelperText, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { LazyLoading, MuiIconButton, PrimaryButton } from 'common';
import ConfirmationDialog from 'common/Dialogs/ConfirmationDialog';
import { useDocumentHashStore } from 'hooks/useDocumentHashStore';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GoFile } from 'react-icons/go';
import ReactTooltip from 'react-tooltip';
import { iconStyle, labelStyle } from '../../../styles/default';
import { useIntersection } from 'hooks/useIntersection';
import PrimaryTable from 'common/Tables/PrimaryTable';
import UpdateDocumentEffectiveDateDialog from 'common/Dialogs/UpdateDocumentEffectiveDateDialog';

interface IPublisherFreeTrialDocSectionProps { }

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    publisherFreeTrialDocSection: {
      margin: '30px 0 20px',
      display: 'flex',
      flexDirection: 'column'
    },
    inputFile: {
      margin: '10px 0 30px',
      display: 'flex',
      flexDirection: 'column',
      '&>div': {
        width: 'max-content'
      }
    },
    documentInfoUl: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      height: 400
    },
    documentInfo: {
      border: `2px solid rgba(0,0,0,0.1)`,
      borderRadius: 10,
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>div': {
        '&>p': {
          '&:not(:last-child)': {
            marginBottom: 5
          },
          '&>span': {
            fontWeight: 'bold',
            marginRight: 5
          }
        },
        '&:nth-child(2)': {
          '&>div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            '&>button': {
              minWidth: 175
              // '&:not(:last-child)': {
              //   marginBottom: 10
              // }
            },
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
              '&>button': {
                // '&:not(:last-child)': {
                //   marginBottom: '0px !important'
                // }
              }
            }
          }
        }
      }
    }
  })
);

let createFreeTrialDocumentFlag: boolean = false;

export const PublisherFreeTrialDocSection: FunctionComponent<IPublisherFreeTrialDocSectionProps> = () => {
  const classes = useStyle();
  const {
    initGetFreeTrialDocuments,
    getFreeTrialDocumentsData,
    createFreeTrialDocumentData,
    initCreateFreeTrialDocument,
    initDeleteFreeTrialDocument,
    deleteFreeTrialDocumentData
  } = useDocumentHashStore();

  const [files, setFiles] = useState<any>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [openUpdateEffectiveDateDialog, setOpenUpdateEffectiveDateDialog] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<any>('');
  const [setTarget, entry] = useIntersection({
    threshold: 0.1
  });

  const handleSubmit = () => {
    const formData = new FormData();
    for (let file of files) {
      formData.append('files[]', file);
    }
    initCreateFreeTrialDocument(formData);
    createFreeTrialDocumentFlag = true;
  };

  const handleCloseConfirmationDialog = (value: boolean) => {
    if (value) {
      initDeleteFreeTrialDocument(openConfirmationDialog);
    }
    setOpenConfirmationDialog('');
  };

  useEffect(() => {
    const { isIntersecting }: any = entry;
    if (
      isIntersecting &&
      !getFreeTrialDocumentsData?.loading &&
      (getFreeTrialDocumentsData?.count || 0) > getFreeTrialDocumentsData?.data?.length
    ) {
      initGetFreeTrialDocuments({
        limit: 10,
        offset: getFreeTrialDocumentsData?.data?.length
      });
    }
  }, [entry, getFreeTrialDocumentsData]);

  useEffect(() => {
    initGetFreeTrialDocuments({
      limit: 10,
      offset: 0
    });
  }, []);
  useEffect(() => {
    if (!createFreeTrialDocumentData.loading && createFreeTrialDocumentFlag) {
      if (createFreeTrialDocumentData?.status === '1') {
        setFiles([]);
      } else {
        setError(createFreeTrialDocumentData?.message);
      }
      createFreeTrialDocumentFlag = false;
    }
  }, [createFreeTrialDocumentData, createFreeTrialDocumentFlag]);
  return (
    <div className={classes.publisherFreeTrialDocSection}>
      <ReactTooltip />
      <Typography variant="h6">Upload a documents to the blockchain for authentication</Typography>
      <div className={classes.inputFile}>
        <label style={labelStyle} htmlFor="publisher-free-trial-document-file-input">
          <GoFile style={iconStyle}></GoFile>
          {files?.length ? `${files?.length} files selected` : 'Choose a files*'}
        </label>
        <input
          hidden
          id="publisher-free-trial-document-file-input"
          type={'file'}
          onChange={e => {
            if (e.target.files?.length) {
              if (e.target.files?.length > 5) {
                setError('You can only upload 5 documents at a time');
              } else {
                setFiles(e.target.files);
                setError('');
              }
            }
          }}
          multiple
        />
        {Boolean(error) && <FormHelperText error={true}>{error}</FormHelperText>}
        <div data-tip={'Upload document'}>
          <PrimaryButton
            children={'Upload'}
            maxContent
            secondaryGreenButton
            disabled={createFreeTrialDocumentData.loading || !files?.length}
            loading={createFreeTrialDocumentData.loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <PrimaryTable
        tableHeading="Uploaded Documents"
        isLoading={getFreeTrialDocumentsData.loading || false}
        data={getFreeTrialDocumentsData?.data?.map((item: any) => {
          return {
            ...item,
            created_on: moment(item?.created_on).format('MM/DD/YYYY hh:mm A'),
            effective_date: moment(item?.effective_date).format('MM/DD/YYYY'),
            action: (
              <>
                <MuiIconButton
                  icon="delete"
                  loading={deleteFreeTrialDocumentData?.loading && item?.id === deleteFreeTrialDocumentData?.data}
                  title="Delete Document"
                  onClick={() => {
                    setOpenConfirmationDialog(item?.id);
                  }}
                />
              </>
            )
          };
        })}
        selected={selected}
        setSelected={setSelected}
        showCheckbox
        getBulkActionInfo={
          <>
            <PrimaryButton
              maxContent
              secondaryGreenButton
              children={'Edit Effective Date'}
              icon="edit"
              onClick={() => setOpenUpdateEffectiveDateDialog(true)}
            />
          </>
        }
        totalCount={getFreeTrialDocumentsData.count || 0}
        columns={[
          {
            id: 'file_name',
            label: 'File Name',
            numeric: false,
            sortable: true
          },
          {
            id: 'file_size',
            label: 'File Size',
            numeric: true,
            sortable: true
          },
          {
            id: 'effective_date',
            label: 'Effective Date',
            numeric: false,
            sortable: true
          },
          {
            id: 'created_on',
            label: 'Created',
            numeric: false,
            sortable: true
          },
          {
            id: 'action',
            label: 'Action',
            numeric: false,
            sortable: false
          }
        ]}
        defaultOrderBy="created_on"
      />
      <UpdateDocumentEffectiveDateDialog
        open={openUpdateEffectiveDateDialog}
        handleClose={() => setOpenUpdateEffectiveDateDialog(false)}
        selectedDocuments={selected}
        setSelected={setSelected}
      />
      <ConfirmationDialog
        open={Boolean(openConfirmationDialog)}
        handleClose={handleCloseConfirmationDialog}
        title="Confirmation"
        message="Are you sure you want to delete this document from the blockchain?"
      />
    </div>
  );
};

export default PublisherFreeTrialDocSection;
