import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import { IAPIResponseDTO } from '../../lib/common';

interface IIngredientComparisionDTO {
  integrant_type_id: string;
  integrant_id: string;
  integrant_type_name: string;
  external_id_slug: string;
  exhibit_id: string;
  exhibit_url: string;
  nihLabelId: number;
  nihSearchLabelName: string;
}

export interface INIHProviderState {
  productAndBatchesList: IAPIResponseDTO;
  integrantDetailData: IAPIResponseDTO;
  nihLabelsList: IAPIResponseDTO;
  nihLabelDetail: IAPIResponseDTO;
  documentAiCoaData: IAPIResponseDTO;
  ingredientComparisionData: IIngredientComparisionDTO;
}

export const defaults = {
  productAndBatchesList: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  integrantDetailData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  nihLabelsList: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  nihLabelDetail: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  documentAiCoaData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  ingredientComparisionData: {
    integrant_type_id: '',
    integrant_id: '',
    integrant_type_name: '',
    external_id_slug: '',
    exhibit_id: '',
    exhibit_url: '',
    nihLabelId: 0,
    nihSearchLabelName: ''
  }
};

const NIHContext = React.createContext<CreateContext<INIHProviderState>>([defaults, (): INIHProviderState => defaults]);

const NIHProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<INIHProviderState>(defaults);
  return <NIHContext.Provider value={[state, setState]}>{props.children}</NIHContext.Provider>;
};

export { NIHContext, NIHProvider };
