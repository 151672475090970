import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, Select, InputLabel, MenuItem, InputAdornment, createStyles } from '@material-ui/core';
import { ISelectOptionDTO } from '../../lib/common';
import clsx from 'clsx';
import MuiCircularProgress from './MuiCircularProgress';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      display: 'flex',
      alignItems: 'center',
      '&>label': {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        color: '#000',
        width: 200,
        textAlign: 'right',
        paddingRight: 30,
        boxSizing: 'border-box'
      },
      '&>div': {
        width: 'calc(100% - 200px)',
        maxWidth: 'max-content',
        minWidth: 200,
        border: '1px solid #DEDEDE',
        backgroundColor: '#fff',
        '&>div': {
          padding: '5px 24px 5px 8px',
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 400,
          color: '#949494'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '&>label': {
          width: '100%',
          textAlign: 'left',
          paddingRight: 0,
          marginBottom: 10
        },
        '&>div': {
          width: '100%',
          maxWidth: '100%'
        }
      }
    }
  })
);

interface IMuiSelectProps {
  label: string;
  options: (number | string | ISelectOptionDTO)[];
  value: string;
  id?: string;
  className?: string;
  visibleOptions?: number;
  onChange: (e: any) => void;
  loading?: boolean;
  sx?: any;
}

const muiSelectDefaultProps: IMuiSelectProps = {
  label: '',
  options: [],
  value: '',
  id: '',
  className: '',
  visibleOptions: 5,
  onChange: () => {},
  loading: false,
  sx: ''
};

export const MuiSelect: FunctionComponent<IMuiSelectProps> = ({
  loading,
  label,
  options,
  value,
  id,
  className,
  visibleOptions = 5,
  onChange,
  sx
}) => {
  const classes = useStyle();
  return (
    <div className={clsx(classes.selectRoot, className)}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        value={value}
        id={id}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 36 * visibleOptions
            }
          }
        }}
        disableUnderline={true}
        onChange={onChange}
        endAdornment={
          loading && (
            <InputAdornment position="end">
              <MuiCircularProgress />
            </InputAdornment>
          )
        }
      >
        {options.map((item: string | number | ISelectOptionDTO, key: number) => {
          if (typeof item === 'object') {
            return (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={key} value={item}>
                {item}
              </MenuItem>
            );
          }
        })}
      </Select>
    </div>
  );
};

MuiSelect.defaultProps = muiSelectDefaultProps;
