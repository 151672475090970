import React from 'react';
import {makeStyles, Theme, Box, LinearProgress, Typography, createStyles} from '@material-ui/core';

const useStyle = makeStyles((theme: Theme)=>createStyles({
    primaryBgColor: {
        backgroundColor: "#008F2B"
    }  
}))

export default function MuiLinearProgress(props: any) {
    const classes = useStyle();
    return props.loading ? <LinearProgress classes={{barColorPrimary: classes.primaryBgColor}} /> : 
     ( <Box display="flex" alignItems="center" justifyContent={"space-between"}>
          <LinearProgress classes={{barColorPrimary: classes.primaryBgColor}} style={{width:'100%',marginRight: 10}} variant="determinate" value={props.value} />
          <Typography variant="body2" color="textSecondary">{props.label}</Typography>
      </Box>)
    
}
