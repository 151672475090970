import { IIntegrantTypeDTO } from '../../lib/integrantType';
import React, { CSSProperties, FunctionComponent } from 'react';
import { titles } from '../config';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';
import { primaryColor } from '../styles/default';
import { ListView } from './ListView';
import { ProductListItem } from './ProductListItem';

const ProductsList: FunctionComponent = () => {
  const {
    integrantTypes,
    isLoading,
    onSelectIntegrantType,
    initIntegrantType,
    selectedIntegrantType
  } = useIntegrantTypeStore();

  const handleSelectProduct = (product: IIntegrantTypeDTO): void => {
    onSelectIntegrantType(product);
  };

  const createProductsList = (product: IIntegrantTypeDTO): React.ReactNode => {
    return (
      <ProductListItem onSelectProduct={(): void => handleSelectProduct(product)} key={product.id} product={product} />
    );
  };

  const isSelected = (product: IIntegrantTypeDTO): boolean =>
    Boolean(selectedIntegrantType) && selectedIntegrantType.id === product.id;

  const liveSearch = (searchValue: string): void => {
    initIntegrantType(searchValue);
  };

  return (
    <ListView<IIntegrantTypeDTO>
      scrollY={integrantTypes.length > 3}
      onListItemSelect={(): void => {}}
      dataSource={integrantTypes}
      renderListItem={createProductsList}
      isLoading={isLoading}
      searchPlaceholder={`Search by ${titles.integrantType} Name`}
      // filter={(integrantType, searchResult): boolean =>
      //   integrantType.title.toLowerCase().includes(searchResult.toLowerCase())
      // }
      isLiveSearch={true}
      liveSearch={liveSearch}
      listItemStyle={(product): CSSProperties => {
        return {
          borderBottom: isSelected(product) ? `1px solid ${primaryColor}` : `1px solid #ccc`,
          marginBottom: 10
        };
      }}
    />
  );
};

export { ProductsList };
