import React,{FunctionComponent, useState,useEffect,useRef} from 'react';
import { makeStyles, createStyles,Theme,Typography,Grid,TextField,InputLabel } from '@material-ui/core';
import { PrimaryButton } from 'common';
import clsx from 'clsx';
import { MuiSelect } from 'common/formComponents';
import PotentialReach  from './PotentialReach';
import SurveyFormActionButtons from './SurveyFormActionButtons';
import Tips from './Tips';
import {IAudienceSurveyFormFieldsDTO} from '../../../lib/survey';
import SurveySuccessPage from './SurveySuccessPage';
import QuestionsAnswers from './QuestionAnswers';
import SummaryLeft from './SummaryLeft';
import SummaryRight from './SummaryRight';
import { useUserStore } from 'hooks/useUserStore';
import {IEmptyObjectDTO} from '../../../lib/common';
import { IntegrantTypesWrapper } from 'wrappers/IntegrantTypesWrapper';
import {useLocation,useHistory,useParams} from 'react-router-dom';

const defaultAudienceFormFields = [
    {
      key:"gender",
      label:"Gender",
      inputType: "dropdown",
      options:["Male","Female"],
      value: "",
      error: ""
    },
    {
      key:"race",
      label:"Race",
      inputType: "dropdown",
      options: [
        "White",
        "Black or African American",
        "American Indian and Alaska Native",
        "Asian",
        "Native Hawaiian and Other Pacific Islander",
      ],
      value: "",
      error: ""
    },
    {
      key:"age",
      label:"Age (in years)",
      inputType:"dropdown",
      value: "",
      error: "",
      options: [
        "15 - 20",
        "21 - 25",
        "26 - 30",
        "31 - 35",
        "36 - 40",
        "41 - 45",
        "46 - 50",
        "51 - 55",
        "56 - 60",
        "61 - 65",
        "66 - 70",
        "71 - 75",
        "76 - 80",
        "81 - 85",
      ],
    },
    {
      key:"marital_status",
      label:"Marital Status",
      inputType: "dropdown",
      options:["Married", "Single"],
      value: "",
      error: ""
    },
    {
      key:"family_and_dependents",
      label:"Family and Dependents",
      inputType: "dropdown",
      options: [...Array(20)].map((e, i) => String(i + 1)),
      value: "",
      error: ""
    },
    {
      key:"education_level",
      label:"Education Level",
      inputType: "dropdown",
      options:[
      "No Formal Schooling",
      "Some High School or Less",
      "High School/GED",
      "Some College",
      "Associate's Degree",
      "Bachelor's Degree",
      "Some Graduate School",
      "Master's Degree",
      "Doctoral Degree"
      ],
      value: "",
      error: ""
    },
    {
      key:"general_wellness",
      label:"General Wellness",
      inputType: "dropdown",
      options: [
      "Healthy",
      "Somewhat Healthy",
      "Somewhat Unhealthy",
      "Unhealthy",
      ],
      value: "",
      error: ""
    },
    {
      key:"height",
      label:"Height (in inchs)",
      inputType: "dropdown",
      options: [...Array(41)].map((el, i) => String(i + 45)),
      value: "",
      error: ""
    },
    {
      key:"weight",
      label:"Weight (in pounds)",
      inputType: "textfield",
    //   options: [...Array(211)].map((el, i) => String(i + 65)),
      value: "",
      error: ""
    },
];

const useStyle = makeStyles((theme:Theme)=>createStyles({
    surveyContainer:{
        width:"100%",
        height: '100%',
        maxWidth: 900,
        margin: '30px auto',
        [theme.breakpoints.down("sm")]:{
            '&>div':{
                '&:nth-child(1)':{
                    boxSizing: 'border-box',
                    padding: '0px 20px',
                },
                '&:nth-child(2)':{
                    '&>div':{
                        '&:nth-child(2)':{
                            boxSizing: 'border-box',
                            padding: '0px 20px',   
                        }
                    }
                }
            }
        }
    },
    surveyFormContainer:{
        paddingBottom: 30
    },
    createSurveyBtnDiv:{
        marginBottom: 20
    },
    surveyHeaderLinks:{
        width:'100%',
        backgroundColor:"#fff",
        boxShadow: "0px 10px 30px 0px #0000001A",
        boxSizing:"border-box",
        padding:"15px 30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        marginBottom:45,
        overflow: 'auto',
        '&>p':{
            textTransform:"capitalize",
            fontSize:"18px",
            fontWeight:400,
            lineHeight:"21px",
            color:"#AAA",
            cursor:"pointer",
        },
    },
    activeLink:{
        color:"#008F2B !important"
    },
    surveyFormHeader:{
        position:"relative",
        justifyContent:"space-between",
        '&>p':{
            padding: '0px 10px',
            zIndex: 1,
            backgroundColor:"#fff",
            cursor:"initial",
        }
    },
    surveyHeaderLine:{
        position:"absolute",
        left: 30,
        right: 30,
        top: "auto",
        height: 2,
        width:"calc(100% - 60px)",
        backgroundColor:"#E6E6E6"
    },
    surveyForm:{
        display:"flex",
        flexDirection:"column",
        '&>div':{
          minHeight: 48,
          padding: 10,
          boxSizing:'border-box',
          background: '#fff',
          border:'1px solid #DEDEDE',
          '&:nth-child(even)':{
            background: "#FBFBFB"
          }
        }
    },
    suveyFormRightSide:{
        flexDirection:"column",
        justifyContent:"space-between",
        height: '100%',
        [theme.breakpoints.down('sm')]:{
            marginTop: 15
        }
    },
    textfieldDiv:{
        display:"flex",
        alignItems:"center",
        '&>label':{
            fontSize:"14px",
            lineHeight:"16px",
            fontWeight: 400,
            color:"#000",
            width: 200,
            textAlign:'right',
            paddingRight: 30,
            boxSizing:"border-box",
        },
        '&>div':{
            width:"calc(100% - 200px)",
            maxWidth:"max-content",
            minWidth: 200,
            border: "1px solid #DEDEDE",
            backgroundColor: "#fff",
            '&>div':{
                '&>input':{
                    padding: "5px 24px 5px 8px",
                    fontSize:"14px",
                    lineHeight:"16px",
                    fontWeight: 400,
                    color:"#949494",
                }
            }
        },
        [theme.breakpoints.down("xs")]:{
            flexDirection:"column",
            '&>label':{
                width: '100%',
                textAlign:'left',
                paddingRight: 0,
                marginBottom: 10
            },
            '&>div':{
                width:"100%",
                maxWidth: '100%'
            },
        }
    }
}))

const CreateSurvey: FunctionComponent = () => {
    const location = useLocation();
    const history = useHistory();
    const surveyFormHeaderLinkRef: any = useRef({})
    const {tabIndex} = useParams();
    const surveyFormStep = parseInt(tabIndex);
    const classes = useStyle();
    const integrantTypeId = window.sessionStorage.getItem("integrant_type_id");
    const audienceFormFieldsData = window.sessionStorage.getItem("audienceFormFields");
    const [audienceFormFields, setAudienceFormFields] = useState<IAudienceSurveyFormFieldsDTO[]>(audienceFormFieldsData ? JSON.parse(audienceFormFieldsData) : defaultAudienceFormFields)
    const [integrant_type_id, setIntegrant_type_id] = useState(integrantTypeId ? window.atob(integrantTypeId) : "")
    const {getFilterUsersCount,resetFilterUsersCount} = useUserStore()
    const handleAudienceChange = (key: string,value: string) => {
        let data: IAudienceSurveyFormFieldsDTO[] = [...audienceFormFields];
        data = data?.map((item: IAudienceSurveyFormFieldsDTO)=>{
            return item?.key === key ? {...item,value} : item
        });
        setAudienceFormFields(data);
        window.sessionStorage.setItem("audienceFormFields",JSON.stringify(data))
    }
    const handleSurveyChange = (id: string)=>{
        setIntegrant_type_id(id);
        window.sessionStorage.setItem("integrant_type_id",window.btoa(id))
    }
    const handleBack = () => {
        window.sessionStorage.removeItem("audienceFormFields");
        window.sessionStorage.removeItem("integrant_type_id");
        setIntegrant_type_id("");
        setAudienceFormFields(defaultAudienceFormFields);
        resetFilterUsersCount();
        history.push("/survey");
    }
    useEffect(()=>{
        if(surveyFormHeaderLinkRef.current){
            const type: any = surveyFormStep === 0 ? "audience" : surveyFormStep === 1 ? "questions_survey" : surveyFormStep === 2 ? "summary" : surveyFormStep === 3 ? "confirm" : "";
            surveyFormHeaderLinkRef.current[type].scrollIntoView({ behavior: "smooth",block: "end",
            inline: "center" });
        }
    },[surveyFormStep])
    useEffect(()=>{
        let query: IEmptyObjectDTO = {};
        for(let item of audienceFormFields){
            if(Boolean(item?.value)){
                query[item.key] = item.value;
            }
        }
        getFilterUsersCount(query);
    },[audienceFormFields])
    return <IntegrantTypesWrapper>
        <div className={classes.surveyContainer}>
            <Grid container alignItems="center" justifyContent={'flex-start'} className={classes.createSurveyBtnDiv}>
                <PrimaryButton children="Back" secondaryButton={true} maxContent={true} icon="back" onClick={handleBack} /> 
            </Grid>
            <Grid container className={classes.surveyFormContainer}>
                    <div className={clsx(classes.surveyHeaderLinks,classes.surveyFormHeader)}>
                        <span className={classes.surveyHeaderLine} />
                        <Typography ref={el => surveyFormHeaderLinkRef.current = { ...surveyFormHeaderLinkRef.current, audience: el }} variant="body1" className={surveyFormStep === 0 ? classes.activeLink : ""} >Audience</Typography>
                        <Typography variant="body1" className={surveyFormStep === 1 ? classes.activeLink : ""} ref={el => surveyFormHeaderLinkRef.current = { ...surveyFormHeaderLinkRef.current, questions_survey: el }} >Questions/Survey</Typography>
                        <Typography variant="body1" className={surveyFormStep === 2 ? classes.activeLink : ""} ref={el => surveyFormHeaderLinkRef.current = { ...surveyFormHeaderLinkRef.current, summary: el }} >Summary</Typography>
                        <Typography variant="body1" className={surveyFormStep === 3 ? classes.activeLink : ""} ref={el => surveyFormHeaderLinkRef.current = { ...surveyFormHeaderLinkRef.current, confirm: el }} >Confirm</Typography>
                    </div>
                    <Grid container>
                        {surveyFormStep === 3 ? <SurveySuccessPage /> : <>
                            <Grid item md={8} sm={12} xs={12}>
                                {surveyFormStep === 0 && 
                                    <Grid container className={classes.surveyForm}>
                                    {audienceFormFields?.map((item: any,key: number) => {
                                        if(item?.inputType === "dropdown"){
                                        return <MuiSelect key={key} onChange={(e: any): void =>handleAudienceChange(item?.key,e.target.value)} value={item?.value} label={item?.label} options={item?.options} />
                                        }
                                        return <div className={classes.textfieldDiv}>
                                            <InputLabel>{item?.label}</InputLabel>
                                            <TextField key={key} onChange={(e: any): void =>handleAudienceChange(item?.key,e.target.value)} InputProps={{disableUnderline: true}} value={item?.value} />
                                        </div>;
                                    })}
                                    </Grid>
                                }
                                {surveyFormStep === 1 && (
                                    <QuestionsAnswers {...{integrant_type_id,handleSurveyChange}} />
                                )}
                                {surveyFormStep === 2 && (
                                    <SummaryLeft {...{integrant_type_id}} />
                                )}
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <Grid container className={classes.suveyFormRightSide}>
                                    {surveyFormStep === 0 && <PotentialReach />}
                                    {surveyFormStep === 1 && <Tips />}
                                    {surveyFormStep === 2 && <SummaryRight {...{audienceFormFields}} />}
                                    <SurveyFormActionButtons {...{surveyFormStep,integrant_type_id,audienceFormFields}} />
                                </Grid>
                            </Grid>
                        </>}
                        
                    </Grid>
                </Grid>
        </div>
    </IntegrantTypesWrapper>
}

export default CreateSurvey