import { ICreateTransferDTO } from '../../lib/transfer';
import { IOrganizationDTO } from '../../lib/organization';
import React, { useState, useReducer } from 'react';
import { create_transfer_, organization_ } from '../common/models';
import { CreateContext } from '../types/common';
import { CreateActions, IAction, IResetAction } from '../types/common';

export interface ICreateTransferInState {
  isLoading: boolean,
  isOpen: boolean,
  transferList: any[],
  selectedIntegrantId: string
  selectedIntegrantObj: any
}

const defaults: ICreateTransferInState = {
  isLoading: true,
  isOpen: false,
  transferList: [],
  selectedIntegrantId: '',
  selectedIntegrantObj: {}
}

export type ISetLoadingAction = IAction<'setLoading', boolean>;
export type ISetTransferListAction = IAction<'transferList', any>;
export type ISetIntegrantIDAction = IAction<'selectedIntegrantId', string>;
export type ISetIntegrantObjAction = IAction<'selectedIntegrantObj', any>;
export type IIsOpenAction = IAction<'isOpen', boolean>;

type IActions = ISetLoadingAction | ISetTransferListAction | IResetAction | ISetIntegrantIDAction | ISetIntegrantObjAction | IIsOpenAction;

const CreateTransferInContext = React.createContext<CreateActions<ICreateTransferInState, IActions>>([
  defaults,
  (): React.Dispatch<IActions> => (): ICreateTransferInState => defaults
]);


const reducer = (state: ICreateTransferInState, action: IActions): ICreateTransferInState => {
  switch (action.type) {
    case 'reset':
      return defaults;
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'transferList':
      return { ...state, transferList: action.payload };
    case 'selectedIntegrantId':
      return { ...state, selectedIntegrantId: action.payload };
    case 'selectedIntegrantObj':
      return { ...state, selectedIntegrantObj: action.payload };
    case 'isOpen':
      return { ...state, isOpen: action.payload };
    default:
      return state;
  }
};

const CreateTransferInProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useReducer(reducer, defaults);
  return <CreateTransferInContext.Provider value={[state, setState]}>{props.children}</CreateTransferInContext.Provider>;
};

export { CreateTransferInContext, CreateTransferInProvider };
