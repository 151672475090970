import { ICreateIndividualDTO, IIndividualAuthenticatedDTO, IIndividualDetailDTO } from '../../../lib/individual';
import { ILoginDTO, IRecoverPasswordDTO, IResetPasswordDTO } from '../../../lib/auth';
import { useCallback, useState } from 'react';
import useFetch from 'use-http';
import { individual_ } from '../../common/models';
import { formAxiosHeader, url } from '../../config';
import axiosInstance from './../../utils/api-interceptor';

export interface IAuthService {
  individual: IIndividualAuthenticatedDTO;
  loading: boolean;
  post: (userCredentials: ILoginDTO) => Promise<IIndividualAuthenticatedDTO>;
  recover: (email_address: IRecoverPasswordDTO['email_address']) => void;
  reset: (reset: IResetPasswordDTO) => Promise<boolean | { message: string }>;
  register: (userCredentials: ICreateIndividualDTO) => Promise<IIndividualDetailDTO | undefined>;
  changePassword: (obj: any) => Promise<boolean | { message: string }>;
  verifyOTP: (obj: any, token: string) => Promise<any>;
  resendOTP: (obj?: any) => Promise<any>;
}

const useAuthService = (): IAuthService => {
  const authURL = `${url}auth`;
  const [individual, setIndividual] = useState<IIndividualAuthenticatedDTO>(individual_);
  const { request, response, loading } = useFetch(authURL);
  const post: IAuthService['post'] = useCallback(
    async userCredentials => {
      return await axiosInstance
        .post('auth/login', userCredentials)
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return err;
        });
      // const currentIndividual: IIndividualAuthenticatedDTO = await request.post('login', userCredentials);
      // setIndividual(currentIndividual);
      // return currentIndividual;
    },
    [axiosInstance]
  );

  const recover: IAuthService['recover'] = useCallback(
    async email_address => {
      return await axiosInstance
        .post('auth/forgot-password', { email_address: email_address })
        .then(res => {
          return res;
        })
        .catch(err => {
          return err;
        });
    },
    [axiosInstance]
  );

  const changePassword: IAuthService['changePassword'] = useCallback(
    async changePasswordObj => {
      return await axiosInstance
        .post('auth/change-password', changePasswordObj)
        .then(res => {
          return res;
        })
        .catch(err => {
          return err;
        });
      // const resetResponse = await request.post('change-password', changePasswordObj);
      // if (response.ok) {
      //   return resetResponse
      // } else {
      //   return response.text().then(function (text) {
      //     const beautyobj = JSON.parse(text)
      //     return beautyobj
      //   });
      // };
    },
    [axiosInstance]
  );

  /*******OLD Code**********/

  const reset: IAuthService['reset'] = useCallback(
    async reset => {
      const resetResponse = await request.post('reset-password', reset);
      if (response.ok) return resetResponse;
    },
    [request, response.ok]
  );

  const register: IAuthService['register'] = useCallback(
    async userCredentials => {
      const registerResponse = await request.post('register', userCredentials);
      if (response.ok) return registerResponse;
    },
    [request, response.ok]
  );

  const verifyOTP: IAuthService['verifyOTP'] = async (obj: any, token: string) => {
    return await axiosInstance
      .post('auth/verify-otp', obj, formAxiosHeader(token))
      .then((response: any) => {
        return response;
      })
      .catch(err => {
        return err;
      });
  };

  const resendOTP: IAuthService['resendOTP'] = async (obj?: any) => {
    return await axiosInstance
      .post('auth/resend-otp', {}, formAxiosHeader(obj.access_token))
      .then((response: any) => {
        return response;
      })
      .catch(err => {
        return err;
      });
  };

  return {
    post,
    recover,
    reset,
    individual,
    loading,
    register,
    changePassword,
    verifyOTP,
    resendOTP
  };
};

export { useAuthService };
