import React from 'react';
import ProductContainer from './ProductContainer';
import { Wrapper } from './wrapper';

export default function ImportProductsFromNIH() {
  return (
    <Wrapper>
      <ProductContainer />
    </Wrapper>
  );
}
