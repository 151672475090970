import { PrimaryButton } from 'common/Button/buttons';
import React, { FunctionComponent, useState } from 'react';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { isEmptyString } from '../utils';

const PublishButton: FunctionComponent = () => {
  const { onPublish } = useIntegrantPaneStore();
  const { isDraft } = useCreateBatchStore();
  const { currentIntegrant } = useIntegrantGraphStore();
  const isPublished = currentIntegrant.is_published;
  const [isPublishLoading, setPublishLoading] = useState(false);

  const handlePublish = async (): Promise<void> => {
    setPublishLoading(true);
    await onPublish(isPublished ? false : true);
    setPublishLoading(false);
  };

  return !isDraft ? (
      <PrimaryButton icon='world' disabled={isEmptyString(currentIntegrant.tracking_id) || isPublishLoading}
      onClick={(): Promise<void> => handlePublish()} secondaryButton={true} loading={isPublishLoading}  children={isPublished ? `Unpublish` : `Publish Online`} />
  ) : null;
};

export { PublishButton };
