import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
  TextField
} from '@material-ui/core';
import '../../styles/commonComponent.css';
import { PrimaryButton } from 'common/Button/buttons';
import { useDocumentHashStore } from 'hooks/useDocumentHashStore';
import moment from 'moment';

const useStyle = makeStyles(theme =>
  createStyles({
    dialogContent: {
      '&>div': {
        padding: '20px 0'
      }
    },
    fileDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>img': {
        maxHeight: 150
      },
      '&>label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    },
    inputLabelRoot: {
      marginBottom: 5
    },
    datePickerRoot: {
      zIndex: 1301
    }
  })
);

interface IUpdateDocumentEffectiveDateDialogProps {
  open: boolean;
  handleClose: () => any;
  selectedDocuments?: string[];
  setSelected: (pre: string[]) => string[] | void;
}

const UpdateDocumentEffectiveDateDialog: FunctionComponent<IUpdateDocumentEffectiveDateDialogProps> = ({
  open,
  handleClose,
  selectedDocuments = [],
  setSelected = () => {}
}) => {
  const classes = useStyle();
  const [effectiveDate, setEffectiveDate] = useState<string>('');
  const { initUpdateFreeTrialDocument, updateFreeTrialDocumentData } = useDocumentHashStore();

  const handleSubmit = async () => {
    await initUpdateFreeTrialDocument({
      effective_date: effectiveDate,
      documentIds: selectedDocuments
    });
    setSelected([]);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      setEffectiveDate('');
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className="dialog-title">Update Document Effective Date</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <TextField
            id="date"
            label="Effective Date"
            type="date"
            required
            InputLabelProps={{
              shrink: true
            }}
            value={effectiveDate}
            onChange={(e: any) => setEffectiveDate(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <PrimaryButton
          children={'Cancel'}
          disabled={updateFreeTrialDocumentData.loading}
          maxContent
          onClick={handleClose}
        />
        <PrimaryButton
          children={'Submit'}
          loading={updateFreeTrialDocumentData.loading}
          disabled={updateFreeTrialDocumentData.loading || !effectiveDate || !moment(effectiveDate).isValid()}
          secondaryGreenButton
          maxContent
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDocumentEffectiveDateDialog;
