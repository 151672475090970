import React,{ FunctionComponent, useState } from 'react';
import {useUserStore} from '../../hooks/useUserStore';
import {Drawer,makeStyles,Theme,Grid,Avatar,Typography, CircularProgress, Tooltip, createStyles} from '@material-ui/core';
import Icon from 'common/Icon';

const useStyles = makeStyles((theme:Theme)=>createStyles({
    drawerRoot:{
        width: 350,
        padding: 20,
        boxSizing:'border-box',
        display:"flex",
        flexDirection:"column"
    },
    userProfilePic:{
        width: 150,
        height: 150,
        margin: '0 auto',
    },
    userName:{
        margin: "10px auto 20px",
        fontWeight: 600,
        fontSize: "24px",
        color: "#008F2B"
    },
    userInfo:{
        marginBottom:10,
        display:"flex",
        alignItems:'flex-start',
        '&>span':{
            '&:first-child':{
                fontWeight: 600,
                marginRight: 5,
            },
            '&:last-child':{
                display:"flex",
                '&>div':{
                    marginLeft: 10
                }
            }
        }
    },
    mailTo:{
        textDecoration:'none',
        color:"initial",
        '&:hover':{
            color:"#008F2B",
            textDecoration:'underline'
        }
    }
}))

interface IUserDrawerProps {
    open: boolean;
    handleClose: () => void;
}
const defaultProps: IUserDrawerProps = {    
    open: false,
    handleClose: () => {}
}

const UserDrawer: FunctionComponent<IUserDrawerProps> = ({open,handleClose})=> {
  const { userDataById: {data,loading} } = useUserStore()
  const classes = useStyles();
  const [copyIconTooltip, setCopyIconTooltip] = useState("copy")
  const handleCopyClick = (str: string)=>{
    navigator.clipboard.writeText(str);
    setCopyIconTooltip("copied");
    setTimeout(()=>{
        setCopyIconTooltip("copy")
    },5000)
  }
  return (
    <Drawer anchor={"right"} open={open} classes={{paper:classes.drawerRoot}} onClose={handleClose}>
        {loading ? 
        <Grid container alignItems='center' justifyContent='center' style={{height:"inherit"}}><CircularProgress size={20} style={{color:"#008F2B"}} /></Grid>:
        <>
            <Grid container alignItems='center' justifyContent='flex-end'><Icon icon="close" onClick={handleClose} /></Grid>
            <Avatar src={data?.profile_picture} variant="circle" className={classes.userProfilePic} />
            <Typography variant='h2' className={classes.userName}>
                {data?.name} 
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Address:</span>
                {data?.address ? data?.address : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Email:</span>
                {data?.email ? <span><span><a href={`mailto:${data?.email}`} className={classes.mailTo}>{data?.email}</a></span><Tooltip arrow placement='top' title={copyIconTooltip}><Icon icon="copy" onClick={()=>handleCopyClick(data?.email)} /></Tooltip></span> : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Phone No:</span>
                {data?.phone ?  
                <span><span>{data?.phone}</span><Tooltip arrow placement='top' title={"copy"}><Icon icon="copy" onClick={()=>handleCopyClick(data?.phone)} /></Tooltip>
                </span> : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>gender:</span>
                {data?.gender ? data?.gender : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Race:</span>
                {data?.race ? data?.race : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Age:</span>
                {data?.age ? data?.age + " years" : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Marital Status:</span>
                {data?.marital_status ? data?.marital_status : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Family and Dependents:</span>
                {data?.family_and_dependents ? data?.family_and_dependents : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Education Level:</span>
                {data?.education_level ? data?.education_level : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>General Wellness:</span>
                {data?.general_wellness ? data?.general_wellness : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Height:</span>
                {data?.height ? data?.height + " inch" : "*****"}
            </Typography>
            <Typography variant="body1" className={classes.userInfo}>
                <span>Weight:</span>
                {data?.weight ? data?.weight + " pound" : "*****"}
            </Typography>
        </>}
    </Drawer>
  )
}

UserDrawer.defaultProps = defaultProps;

export default UserDrawer
