import React, { CSSProperties, FunctionComponent, ReactNode, useState, useEffect } from 'react';
import { GoPlus, GoTrashcan } from 'react-icons/go';
import { Button, Layer, Input } from '../common';
import {
  iconStyle,
  wideDefaultButtonStyle,
  defaultButtonStyle,
  loadingIconStyle,
  formButtonStyle,
  primaryButtonStyle
} from '../styles/default';
import { IIntegrantOptionPaneProps } from '../types/common';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import ConfirmationDialog from '../common/Dialogs/ConfirmationDialog';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Chip, IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import ReactTooltip from 'react-tooltip';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { useNotificationStore } from '../hooks/useNotificationStore';
import { CircularProgress, Theme, createStyles } from '@material-ui/core';
import Icon from '../common/Icon';
import { PrimaryButton } from 'common/Button/buttons';
import moment from 'moment';
import { useUserStore } from 'hooks/useUserStore';
import { UserDrawer } from 'common/Drawers';
import { ProductFeedbackQuestion } from '../../src/components/ProductFeedbackQuestion';
import { useParams } from 'react-router-dom';
import { useIntersection } from 'hooks/useIntersection';
import { LazyLoading } from 'common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      },
      '&>div': {
        margin: '0 0 0 0 !important'
      }
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      // fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
      '&>span:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }
    },
    rootSummary: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56
      }
    },
    content: {
      '&$expanded': {
        margin: '12px 0'
      }
    }
  })
);

const rootChip: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: '5px',
  margin: '10px 0px 10px 0px !important'
};
const chip: CSSProperties = { margin: '0px 5px 0px 0px !important' };

interface ICategoryProps {
  isVisible?: boolean;
  isDisabled?: boolean;
}

interface IsurveyQuestion {
  id?: string;
  input_type?: string;
  question?: string;
  options?: any;
}

interface IOffer {
  offer_id?: string;
  offer?: string;
}

const buttonStyle: CSSProperties = { marginRight: 5, marginTop: 10 };

const ProductOffers: FunctionComponent<ICategoryProps> = ({ isVisible, isDisabled }): JSX.Element => {
  const [newQuestion, setNewQuestion] = useState(false);
  const [newSurveyQuestionArray, setNewSurveyQuestionArray] = useState<any[]>([]);
  const [newSurveyQuestion, setNewSurveyQuestion] = useState({});
  const [saveBtnShow, setSaveBtnShow] = useState(false);
  const [batchLoading, setBatchLoading] = useState(false);
  const [surveyQuestion, setSurveyQuestion] = useState<IsurveyQuestion>({
    id: '',
    input_type: '',
    question: '',
    options: []
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [surveyQuestionId, setSurveyQuestionId] = useState('');
  const [selectionValue, setSelectionValue] = useState('');
  const [selectionValueEdit, setSelectionValueEdit] = useState('');
  const classes = useStyles();
  const { slideIndex } = useCreateIntegrantTypeStore();
  const [newOffer, setNewOffer] = useState<IOffer>({ offer_id: '', offer: '' });
  const { getUserById } = useUserStore();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const { initReadNotification } = useNotificationStore();
  const { integrantTypeId } = useParams();

  const {
    getCategoryData,
    categoryList,
    getSurveyQuestionStoreData,
    getSurveyQuestionData,
    surveyQuestionList,
    surveyQuestionLoading,
    getSurveyQuestionUpdateData,
    surveyQuestionDeleteData,
    getSurveyQuestionAnswerData,
    surveyQuestionAnswerList,
    getOfferStoreData,
    getOfferData,
    offerList
  } = useIntegrantsStore();
  useEffect(() => {
    // getCategoryData();
    if (slideIndex == 0) {
      getSurveyQuestionData();
    }
    if (slideIndex == 1) {
      getSurveyQuestionAnswerData();
    }
    if (slideIndex == 2) {
      getOfferData();
    }
    if (slideIndex == 3) {
      initReadNotification({ type: 'feedback', action_id: integrantTypeId });
    }
  }, [slideIndex]);

  useEffect(() => {
    setNewOffer({ offer_id: offerList?.id, offer: offerList?.offer });
  }, [offerList]);

  const editableItemStyle: CSSProperties = { width: '100%', paddingRight: 10, paddingLeft: 10 };
  // const onAddNewSurveyQuestion = (): void => {
  //   setNewSurveyQuestionView(true);
  // };

  const handeleQuestion = (): void => {
    setSurveyQuestion({ id: '', input_type: '', question: '', options: [] });
    setNewSurveyQuestionArray([...newSurveyQuestionArray, { question: '', type: '', options: [] }]);
    if (newSurveyQuestionArray.length > 0) {
      setSaveBtnShow(true);
    }
  };

  const handeleOffer = (key: string, value: any): void => {
    setNewSurveyQuestion({ ...newSurveyQuestion, [key]: value });
  };

  const handeleSurveyQuestions = (key: string, value: any, index: any): void => {
    let tempArray = [...newSurveyQuestionArray];
    tempArray[index][key] = value;
    setNewSurveyQuestionArray(tempArray);
    if (!saveBtnShow) {
      setSaveBtnShow(true);
    }
  };

  const handeleSurveyQuestionSave = async (): Promise<any> => {
    setBatchLoading(true);
    await getSurveyQuestionStoreData({ ...newSurveyQuestion, survey_question: newSurveyQuestionArray });
    setNewSurveyQuestionArray([]);
    setSaveBtnShow(false);
    setBatchLoading(false);
    getSurveyQuestionData();
  };

  const handeleSurveyQuestionValidation = (): boolean => {
    let validationData = newSurveyQuestionArray.length > 0 ? true : false;
    for (let value of newSurveyQuestionArray) {
      if (!value.question) {
        validationData = false;
        break;
      } else if (!value.type) {
        validationData = false;
        break;
      }
    }
    return validationData;
  };

  const surveyQuestionEdit = (item: any): void => {
    setNewSurveyQuestionArray([]);
    setSurveyQuestion({
      id: item?.id,
      input_type: item?.input_type,
      question: item?.question,
      options: item?.options
    });
  };

  const handeleSurveyQuestionUpdate = async (): Promise<any> => {
    setUpdateLoading(true);
    await getSurveyQuestionUpdateData(surveyQuestion);
    setSurveyQuestion({ id: '', input_type: '', question: '', options: [] });
    setSaveBtnShow(false);
    setUpdateLoading(false);
    getSurveyQuestionData();
  };

  const handleCloseConfirmationDialog = async (value: boolean): Promise<any> => {
    setOpenConfirmationDialog(false);
    if (value) {
      await surveyQuestionDeleteData(surveyQuestionId);
      setSurveyQuestionId('');
      getSurveyQuestionData();
    }
  };

  const handleDelete = (mainI: any, subI: any) => () => {
    // setNewSurveyQuestionArray(pre=>{...pre,options:pre.options.filter((item,i)=>i!== index)})
    setNewSurveyQuestionArray(pre =>
      pre.map((el: any, i: any) => {
        return i === mainI ? { ...el, options: el.options.filter((ele: any, subJ: any) => subJ !== subI) } : el;
      })
    );
  };

  const handleEditDelete = (subI: any) => () => {
    setSurveyQuestion({ ...surveyQuestion, options: surveyQuestion.options.filter((ele: any, i: any) => i !== subI) });
  };

  const [expanded, setExpanded] = React.useState<number>(0);
  const handleChange = (panel: number) => {
    if (expanded === panel) {
      setExpanded(0);
    } else {
      setExpanded(panel);
    }
  };

  const handeleOfferSave = async (): Promise<any> => {
    setBatchLoading(true);
    await getOfferStoreData(newOffer);
    setNewOffer({ offer_id: '', offer: '' });
    setSaveBtnShow(false);
    setBatchLoading(false);
    getOfferData();
  };

  const surveySubmissionPageLimit = 10;
  const [surveySubmissionPageOffset, setSurveySubmissionPageOffset] = useState(surveySubmissionPageLimit);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1
  });

  useEffect(() => {
    const { isIntersecting }: any = entry;
    if (isIntersecting && surveyQuestionAnswerList?.length - surveySubmissionPageOffset > 0) {
      setSurveySubmissionPageOffset(pre => pre + surveySubmissionPageLimit);
    }
  }, [entry, surveySubmissionPageOffset]);

  return (
    <Layer className="integrantExhibits" disabled={isDisabled} direction="column" fill style={{ overflowY: 'auto' }}>
      <>
        <ReactTooltip />
        {slideIndex == 0 ? (
          <>
            <div style={{ padding: 10, width: '100%' }}>
              <div>
                <div className="title_transit">Details</div>
                <div style={{ overflow: 'auto' }}>
                  <div className="table_transit">
                    <div className="head_transit row_transit row">
                      <div className="col-5">Question</div>
                      <div className="col-2">Type</div>
                      <div className="col-3">Options</div>
                      <div className="col-2">Action</div>
                    </div>
                    <div className="body_transit">
                      {surveyQuestionLoading ? (
                        <div className="col-12" style={{ textAlign: 'center', height: '24px' }}>
                          <CircularProgress size={20} />
                        </div>
                      ) : surveyQuestionList?.length > 0 ? (
                        surveyQuestionList?.map((surveyQuestion: any) => {
                          return (
                            <div className="row_transit row">
                              <div className="col-5">{surveyQuestion?.question}</div>
                              <div className="col-2">{surveyQuestion?.input_type}</div>
                              <div className="col-3">
                                {surveyQuestion.options.length > 0
                                  ? surveyQuestion.options?.map((option: any, i: any) => {
                                      return (
                                        <span>
                                          {option}
                                          {surveyQuestion.options.length - 1 != i ? ', ' : ''}
                                        </span>
                                      );
                                    })
                                  : '---'}
                              </div>
                              <div className="col-2">
                                <div className="product-offers-icons">
                                  <Icon
                                    icon="edit"
                                    dataTip={'Edit'}
                                    onClick={(): void => surveyQuestionEdit(surveyQuestion)}
                                  />
                                  <Icon
                                    icon="delete"
                                    dataTip={'Delete'}
                                    onClick={(): void => {
                                      setOpenConfirmationDialog(true);
                                      setSurveyQuestionId(surveyQuestion?.id);
                                    }}
                                  />
                                </div>
                                {/* <span onClick={(e: any) => surveyQuestionEdit(surveyQuestion)} title="Edit" style={{ cursor: "pointer" }}><GoPencil /></span>
                                  <span> | </span>
                                  <span onClick={() => { setOpenConfirmationDialog(true); setSurveyQuestionId(surveyQuestion?.id) }} title="Delete" style={{ cursor: "pointer" }}><GoTrashcan /></span> */}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12" style={{ textAlign: 'center' }}>
                          You have not created any survey questions yet. Start by creating a new survey question to
                          collect valuable insights from your audience about this product.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ minHeight: 10 }}></div>
              </div>
            </div>

            <div>
              <div style={{ padding: 10, width: '100%' }}>
                <PrimaryButton icon="add" onClick={handeleQuestion} disabled={newQuestion} children="Add New" />
              </div>
              <div style={editableItemStyle}>
                {newSurveyQuestionArray.map((item, key) => {
                  return (
                    <div style={{ marginBottom: '20px' }} key={key}>
                      <Input
                        placeholder="Question"
                        value={item.question}
                        onChange={(e: any) => {
                          handeleSurveyQuestions('question', e?.target?.value, key);
                        }}
                      />
                      <select
                        onChange={(e: any) => {
                          handeleSurveyQuestions('type', e?.target?.value, key);
                        }}
                      >
                        <option value="">Select Input Type</option>
                        <option value="Text">Text</option>
                        <option value="Selection">Selection</option>
                        <option value="Score">Score</option>
                      </select>
                      {item.type === 'Selection' ? (
                        <>
                          <div style={{ display: 'flex' }}>
                            <Input
                              placeholder="Option"
                              style={{ width: '85%' }}
                              value={selectionValue}
                              onChange={(e: any) => setSelectionValue(e?.target?.value)}
                            />
                            <div style={{ width: '15%' }}>
                              <PrimaryButton
                                onClick={() => {
                                  handeleSurveyQuestions('options', [...item.options, selectionValue], key);
                                  setSelectionValue('');
                                }}
                                disabled={newQuestion}
                                icon="add"
                                children="Add"
                              />
                            </div>
                          </div>
                          <Paper component="ul" style={rootChip}>
                            {item.options.map((chipdata: any, keyChip: any) => {
                              return <Chip onDelete={handleDelete(key, keyChip)} label={chipdata} style={chip} />;
                            })}
                          </Paper>
                        </>
                      ) : (
                        ''
                      )}
                      <PrimaryButton
                        maxContent={true}
                        style={{ marginTop: 10 }}
                        children={'Delete'}
                        onClick={(e: any) => {
                          setNewSurveyQuestionArray(pre => pre.filter((v, i) => i != key));
                        }}
                        icon="delete"
                      />
                    </div>
                  );
                })}
                {handeleSurveyQuestionValidation() ? (
                  <PrimaryButton
                    maxContent={true}
                    secondaryButton={true}
                    children={'Save'}
                    onClick={handeleSurveyQuestionSave}
                    loading={batchLoading}
                    icon="save"
                  />
                ) : (
                  ''
                )}
              </div>
              {surveyQuestion?.id ? (
                <div style={editableItemStyle}>
                  <div style={{ marginBottom: '20px' }}>
                    <Input
                      placeholder="Question"
                      value={surveyQuestion?.question}
                      onChange={(e: any) => {
                        setSurveyQuestion({ ...surveyQuestion, question: e?.target?.value });
                      }}
                    />
                    <select
                      onChange={(e: any) => {
                        setSurveyQuestion({ ...surveyQuestion, input_type: e?.target?.value });
                      }}
                      value={surveyQuestion?.input_type}
                    >
                      <option value="">Select Input Type</option>
                      <option value="Text">Text</option>
                      <option value="Selection">Selection</option>
                      <option value="Score">Score</option>
                    </select>
                    {surveyQuestion?.input_type === 'Selection' ? (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Input
                            placeholder="Option"
                            style={{ width: '85%' }}
                            value={selectionValueEdit}
                            onChange={(e: any) => setSelectionValueEdit(e?.target?.value)}
                          />
                          <div style={{ width: '15%' }}>
                            <PrimaryButton
                              onClick={() => {
                                setSurveyQuestion({
                                  ...surveyQuestion,
                                  options: [...surveyQuestion.options, selectionValueEdit]
                                });
                                setSelectionValueEdit('');
                              }}
                              children="Add"
                              icon="add"
                            />
                          </div>
                        </div>
                        <Paper component="ul" style={rootChip}>
                          {surveyQuestion.options.map((chipdata: any, keyChip: any) => {
                            return <Chip onDelete={handleEditDelete(keyChip)} label={chipdata} style={chip} />;
                          })}
                        </Paper>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  {surveyQuestion?.question && surveyQuestion?.input_type ? (
                    <PrimaryButton
                      maxContent={true}
                      secondaryButton={true}
                      children={'Update'}
                      onClick={handeleSurveyQuestionUpdate}
                      icon="edit"
                      loading={updateLoading}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
        {slideIndex == 1 ? (
          <div style={{ padding: 10, width: '100%' }}>
            <div>
              {/* <div className="title_transit">Answer Submission</div> */}

              {surveyQuestionLoading ? (
                <div className="col-12" style={{ textAlign: 'center' }}>
                  <CircularProgress size={20} />
                </div>
              ) : surveyQuestionAnswerList?.length > 0 ? (
                surveyQuestionAnswerList?.slice(0, surveySubmissionPageOffset)?.map((surveyQuestion: any) => {
                  return (
                    <div className={classes.root}>
                      <Accordion
                        square
                        expanded={expanded === moment(surveyQuestion.created_on).valueOf()}
                        onChange={e => handleChange(moment(surveyQuestion.created_on).valueOf())}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes.rootSummary}
                        >
                          <Typography className={classes.heading}>
                            <span>
                              <span>{surveyQuestion.user.name}</span>
                              <Icon
                                icon="info"
                                onlyIcon={true}
                                dataTip="View user detail"
                                onClick={e => {
                                  e.stopPropagation();
                                  getUserById(surveyQuestion.user.id);
                                  setToggleDrawer(true);
                                }}
                                style={{ marginLeft: '5px' }}
                              />
                            </span>
                            <span>{moment(surveyQuestion.created_on).format('MM/DD/YYYY hh:mm A')}</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography>
                              <b>Batch:</b> {surveyQuestion?.integrant?.external_id}
                            </Typography>
                            <Typography>
                              <b>Offer:</b> {surveyQuestion?.offer ? surveyQuestion?.offer : ''}
                            </Typography>
                            <div className="table_transit body_transit">
                              <div className="head_transit row_transit row">
                                <div className="col-6">Question</div>
                                <div className="col-6">Answer</div>
                              </div>
                              <div>
                                {surveyQuestion?.questionAnswers?.length > 0
                                  ? surveyQuestion.questionAnswers?.map((answer_data: any) => {
                                      return (
                                        <div className="row_transit row">
                                          <div className="col-6">{answer_data.question}</div>
                                          <div className="col-6">{answer_data.answer}</div>
                                        </div>
                                      );
                                    })
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <div className="col-12" style={{ textAlign: 'center' }}>
                  No survey submissions found.
                </div>
              )}
              {(!surveyQuestionLoading && surveyQuestionAnswerList?.length - surveySubmissionPageOffset) > 0 ? (
                <LazyLoading padding="20px 0px" setRef={setTarget} />
              ) : null}
              <div style={{ minHeight: 10 }}></div>
            </div>
            <UserDrawer open={toggleDrawer} handleClose={() => setToggleDrawer(false)} />
          </div>
        ) : (
          ''
        )}
        {slideIndex == 2 ? (
          <>
            <div style={{ padding: 10, width: '100%' }}>
              <div style={editableItemStyle}>
                <div style={{ marginBottom: '20px' }}>
                  <Input
                    placeholder="Offer"
                    value={newOffer.offer}
                    onChange={(e: any) => {
                      setNewOffer({ ...newOffer, offer: e?.target?.value });
                    }}
                  />
                </div>
                {newOffer?.offer && newOffer?.offer_id ? (
                  <PrimaryButton
                    maxContent={true}
                    secondaryButton={true}
                    children={'Update'}
                    onClick={handeleOfferSave}
                    loading={batchLoading}
                    icon="save"
                  />
                ) : newOffer?.offer ? (
                  <PrimaryButton
                    maxContent={true}
                    secondaryButton={true}
                    children={'Save'}
                    onClick={handeleOfferSave}
                    loading={batchLoading}
                    icon="save"
                  />
                ) : (
                  ' '
                )}
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        {slideIndex == 3 ? (
          <>
            <div style={{ padding: 10, width: '100%' }}>
              <ProductFeedbackQuestion />
            </div>
          </>
        ) : (
          ''
        )}
        <ConfirmationDialog
          open={openConfirmationDialog}
          handleClose={handleCloseConfirmationDialog}
          title="Confirmation"
          message="Are you sure you want to delete this survey question?"
        />
      </>
    </Layer>
  );
};

export { ProductOffers };
