import React, { FunctionComponent } from 'react';
import { Layer } from '../common';
import { CreateIntegrantTypePaneHeader } from './CreateIntegrantTypePaneHeader';
import { CreateIntegrantTypePaneFooter } from './CreateIntegrantTypePaneFooter';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { IntegrantTypeDetails } from './IntegrantTypeDetails';
import { IntegrantTypeFacets } from './IntegrantTypeFacets';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const CreateIntegrantTypePane: FunctionComponent = () => {
  const { slideIndex, isOpen, integrantType } = useCreateIntegrantTypeStore();
  const location = useLocation();
  const isUpdate = location.pathname.split('/')[1] === 'update-product';
  return true ? (
    <Layer fill direction="column" >
      {isUpdate && integrantType.title === '' ? 
      <div className='integrant-type-form-loader'>
        <CircularProgress size={20} />
      </div> : '' }
      <Layer direction={'column'} className='createIntegrantTypePane' style={{ maxWidth: 800, margin: '0 auto', width: '100%' }}>
        <CreateIntegrantTypePaneHeader />
        <IntegrantTypeDetails isVisible={slideIndex === 0} />
        <IntegrantTypeFacets isVisible={slideIndex === 1} />
        <CreateIntegrantTypePaneFooter isUpdate={isUpdate} />
      </Layer>
    </Layer>
  ) : null;
};

export { CreateIntegrantTypePane };
