import { ICreateIntegrantResponseDTO, IIntegrantDTO } from '../../lib/integrant';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import 'react-toggle/style.css';
import { titles } from '../config';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import { useRouter } from '../hooks/useRouter';
import {
  defaultBorderStyle,
  defaultInputStyle,
  defaultModalStyle,
  formButtonStyle,
  primaryButtonStyle,
  primaryColor
} from '../styles/default';
import Icon from '../common/Icon';
import { PrimaryButton } from 'common/Button/buttons';
import { useIntegrantTypeStore } from 'hooks/useIntegrantTypeStore';
import { useIntegrantsService } from 'hooks/api/useIntegrantsService';
import {useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';

const linkStyle: CSSProperties = { textDecoration: 'none', color: primaryColor, cursor: 'pointer' };

interface ICloneBatch {
  integrant: any;
}

const cloneButtonStyle: CSSProperties = { ...formButtonStyle, ...primaryButtonStyle };

const cloneButtonDisableStyle: CSSProperties = { ...formButtonStyle, ...primaryButtonStyle, opacity: 0.5 };

const CloneBatch: FunctionComponent<ICloneBatch> = ({ integrant }) => {
  const { onCloneIntegrant,setIntegrants,setLoading } = useIntegrantsStore();
  const {initialize} = useIntegrantsService();
  const {selectedIntegrantType} = useIntegrantTypeStore()
  const [isCloneLoading, setCloneLoading] = useState(false);
  const [cloned, setCloned] = useState<ICreateIntegrantResponseDTO>();
  const [tracking, setTracking] = useState('');
  const { navigate } = useRouter();
  const history = useHistory();
  const handleOnClone = async (): Promise<void> => {
    setCloneLoading(true);
    const clonedIntegrant = await onCloneIntegrant(integrant.id, tracking);
    if (typeof clonedIntegrant !== 'undefined' && !clonedIntegrant.hasOwnProperty('statusCode')) {
      handleAfterClose();
      hideModal()
      toast.success(`Clone of ${integrant.external_id} has been created successfully.`)
      history.push(`/batches/${clonedIntegrant}`)      
    }
    setCloneLoading(false);
    // if (clonedIntegrant && integrantInfo.id !== '') {
    //   setCloned(clonedIntegrant);
    //   setTracking('');
    // }
  };

  const handleAfterClose = (): void => {
    setCloned(undefined);
    setTracking('');
    setCloneLoading(false);
  };

  const onkeypress = function (keyCode: number): boolean {
    const key = keyCode;
    return key !== 32 ? true : false;
  };
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} onAfterClose={handleAfterClose} style={defaultModalStyle} isOpen>
        <div className='cloneTransferIn-div'>
          <strong style={{ marginBottom: 20, display: 'block' }}>
            You are about to clone batch {integrant.external_id}.
          </strong>
          <small>
            Please enter a new {titles.tracking}{' '}
            number:
          </small>
          <div style={{ marginTop: 20, marginBottom: 20, display: 'block' }}>
            <input
              pattern="[^\s]+"
              onKeyDownCapture={(e: React.KeyboardEvent<HTMLInputElement>): boolean => onkeypress(e.keyCode)}
              autoFocus
              value={tracking}
              onChange={(evt): void => setTracking(evt.currentTarget.value.split(' ').join(''))}
              style={{ ...defaultInputStyle, padding: 10, width: '100%', ...defaultBorderStyle }}
              placeholder={titles.tracking}
            />
          </div>

          <PrimaryButton onClick={handleOnClone} loading={isCloneLoading} maxContent={true} secondaryButton={true} icon='clone' children={`Clone ${typeof cloned !== 'undefined' ? `Again` : ``}`} disabled={!tracking} />
          {/* {typeof cloned !== 'undefined' ? (
          <button
            onClick={(): void => navigate(`batches/${cloned.id}`)}
            disabled={isCloneLoading}
            style={cloneButtonStyle}
          >
            View Cloned {cloned.tracking_id}
          </button>
        ) : null} */}
          <PrimaryButton style={{marginLeft: 10}} icon='close' disabled={isCloneLoading} onClick={hideModal} maxContent={true}  children={"Cancel"} />
        </div>
      </ReactModal>
    ),
    [isCloneLoading, tracking, cloned]
  );

  return (
    <Icon icon='copy' dataTip='Clone' onClick={showModal} />
  );
};

export { CloneBatch };
