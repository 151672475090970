import { IFacetDTO } from '../../lib/facet';
import React, { FunctionComponent, ReactNode } from 'react';
import { Layer } from '../common';
import { facet_ } from '../common/models';
import { titles } from '../config';
import { reduceRank } from '../utils';
import { DraggableList } from './DraggableList';
import { FacetListItem } from './FacetListItem';
import { ResponderProvided } from 'react-beautiful-dnd';
import { PrimaryButton } from 'common/Button/buttons';

interface IFacetsListProps {
  facets: IFacetDTO[];
  isVisible: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleDragEnd?: (result: IFacetDTO[], provided?: ResponderProvided | undefined) => void;
  onRemoveFacet: (facetID: string, isClientFacet?: boolean) => Promise<void>;
  onUpdateFacet: (facet: IFacetDTO) => Promise<void>;
  className?: string;
  type?: string;
}

const FacetsList: FunctionComponent<IFacetsListProps> = ({
  facets,
  isVisible,
  isDisabled,
  handleDragEnd,
  onRemoveFacet,
  onUpdateFacet,
  isLoading = false,
  className = '',
  type = 'facet'
}): JSX.Element => {
  const renderFacets = (): React.ReactNode => (
    <DraggableList<IFacetDTO>
      renderItem={(facet: IFacetDTO): ReactNode => (
        <FacetListItem
          onUpdateFacet={onUpdateFacet}
          onRemoveFacet={onRemoveFacet}
          onDeleteFacet={onRemoveFacet}
          index={facet.id}
          key={facet.id}
          facet={facet}
          type={type}
        />
      )}
      onDragEnd={handleDragEnd}
      items={facets.sort((a, b) => (a.rank > b.rank ? 1 : -1))}
      loading={isLoading}
    />
  );

  const onAddNewFacet = (): void => {
    const highestRank = reduceRank(facets);
    onUpdateFacet({ ...facet_, rank: highestRank });
  };

  return isVisible ? (
    <Layer className={className} disabled={isDisabled} direction="column" fill scrollY>
      <div style={{ padding: 10, width: '100%' }}>
        <PrimaryButton icon="add" onClick={onAddNewFacet} children={`Add New ${titles.facets}`} />
      </div>
      {renderFacets()}
    </Layer>
  ) : (
    <div />
  );
};

export { FacetsList };
