import React, { CSSProperties, FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Layer } from '../common';
import { titles, titlesTooltips } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { AddDropdown } from './AddDropdown';
import { AddTransferSelectOrganization } from './AddTransferSelectOrganization';
import { IOrganizationDTO } from '../../lib/organization';
import { IntegrantKind } from '../types/common';
import { PrimaryButton } from 'common/Button/buttons';

const transferWrapper: CSSProperties = { position: 'relative', zIndex: 2, marginBottom: 10 };

const AddTransfer: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();
  const {
    isOpen,
    isLoading,
    setOpen,
    onAddTransfer,
    selectedTransferOrganization,
    setSelectedOrganization
  } = useCreateTransferStore();

  const {
    onAddTransit
  } = useCreateTransitStore()

  const selectedOrganizationExists = selectedTransferOrganization.id === '';

  return isDraft ? null : (
    <>
      <ReactTooltip />
      <Layer style={transferWrapper} direction="row">
        <PrimaryButton icon='link' onClick={(): void => setOpen(!isOpen)}
            disabled={isLoading} dataTip={titlesTooltips.transfer} loading={isLoading} children={!isOpen ? `Transfer to another ${titles.organization.toLowerCase()}` : 'Close'} />
        <AddDropdown
          isLoading={isLoading}
          isOpen={isOpen}
          setOpen={setOpen}
          onAddItem={(item) => onAddTransit(item, IntegrantKind.Transfer)}
          placeholderTitle={'Title... e.g. Ownership changed.'}
          placeholderDescription={'Description... e.g. Transferred to original farmer.'}
          renderHeader={(): JSX.Element => (
            <>
              {titles.transferPlural} this item to another {titles.organization.toLowerCase()}.
            </>
          )}
          renderContent={
            selectedOrganizationExists
              ? (): React.ReactNode => (
                <AddTransferSelectOrganization setOpen={setOpen}
                  onListItemSelect={(selectedOrg: IOrganizationDTO): void => setSelectedOrganization(selectedOrg)}
                />
              )
              : undefined
          }
        />
      </Layer>
    </>
  );
};

export { AddTransfer };
