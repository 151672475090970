import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, Theme, Grid, Typography, createStyles } from '@material-ui/core';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { useIntersection } from 'hooks/useIntersection';
import { LazyLoading } from 'common';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    surveyListContainer: {
      width: '100%',
      '&>div': {
        '&:nth-child(even)': {
          background: '#FBFBFB'
        }
      }
    },
    surveyLI: {
      padding: 15,
      border: '1px solid #DEDEDE',
      '&>h6': {
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 700,
        color: '#000',
        flex: 1
      },
      '&>p': {
        cursor: 'pointer',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 700,
        color: '#A5A5A5'
      },
      [theme.breakpoints.down('sm')]: {
        '&>h6': {
          fontSize: '14px'
        },
        '&>p': {
          cursor: 'pointer',
          fontSize: '14px'
        }
      }
    }
  })
);

interface ISurveyListProps {
  handleViewSurveyDetail: (id: string) => void;
}

let surveyListFlag = false;

export const SurveyList: FunctionComponent<ISurveyListProps> = ({ handleViewSurveyDetail }) => {
  const classes = useStyle();
  const { surveyList, initGetSurveyList } = useIntegrantTypeStore();
  const [rows, setRows] = useState([]);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1
  });
  let limit = 10;
  useEffect(() => {
    const { intersectionRatio }: any = entry;
    let offsetStatus = surveyList?.data?.length - rows.length > 0;
    if (intersectionRatio && intersectionRatio >= 0.1 && offsetStatus) {
      setRows(rows.concat(surveyList.data?.slice(rows.length, rows.length + limit) || []));
    }
  }, [entry]);
  useEffect(() => {
    if (!surveyList.loading && surveyList.data?.length && surveyListFlag) {
      surveyListFlag = false;
      setRows(surveyList.data?.slice(0, limit));
    }
  }, [surveyList]);
  useEffect(() => {
    initGetSurveyList();
    surveyListFlag = true;
  }, []);
  return (
    <div className={classes.surveyListContainer}>
      {surveyList.loading && rows.length === 0 ? (
        <Grid container justifyContent="center" alignItems="center">
          <MuiCircularProgress />
        </Grid>
      ) : rows?.length > 0 ? (
        <>
          {rows.map((item: any, key: number) => {
            return (
              <Grid key={key} container justifyContent="space-between" alignItems="center" className={classes.surveyLI}>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="body1" onClick={() => handleViewSurveyDetail(item?.id)}>
                  View
                </Typography>
              </Grid>
            );
          })}
          {surveyList.data.length - rows.length > 0 ? <LazyLoading setRef={setTarget} /> : null}
        </>
      ) : (
        <Typography variant="body1">
          No surveys found. Create a new survey to start collecting valuable insights from your audience!
        </Typography>
      )}
    </div>
  );
};

export default SurveyList;
