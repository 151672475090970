import { useContext } from 'react';
import { IIntegrantDTO } from '../../lib/integrant';
import { SuggestIntegrantCreatedAlreadyContext } from '../context/suggestIntegrantCreatedAlready/suggestIntegrantCreatedAlreadyContext';
import { ISuggestIntegrantCreatedAlreadyState } from '../context/suggestIntegrantCreatedAlready/types';

interface ISuggestIntegrantCreatedAlready extends ISuggestIntegrantCreatedAlreadyState {
  onToggleMenu: (isOpen: boolean) => void;
  onSelectIntegrant: (integrant: IIntegrantDTO) => void;
  onGotoSlideIndex: (n: number) => void;
}

const useSuggestIntegrantCreatedAlreadyStore = (): ISuggestIntegrantCreatedAlready => {
  const [state, setState] = useContext(SuggestIntegrantCreatedAlreadyContext);
  const { isLoading, isOpen, slideIndex, selectedIntegrant } = state;

  const onToggleMenu: ISuggestIntegrantCreatedAlready['onToggleMenu'] = (isOpen): void => {
    setState({ ...state, isOpen });
  };

  const onSelectIntegrant: ISuggestIntegrantCreatedAlready['onSelectIntegrant'] = (integrant: IIntegrantDTO): void => {
    setState({ ...state, selectedIntegrant: integrant });
  };

  const onGotoSlideIndex = (n: number): void => {
    setState({ ...state, slideIndex: n });
  };
  return {
    isLoading,
    isOpen,
    onToggleMenu,
    slideIndex,
    onSelectIntegrant,
    selectedIntegrant,
    onGotoSlideIndex
  };
};

export { useSuggestIntegrantCreatedAlreadyStore };
