import { getLocalStorageItems } from 'utils';
import { IIntegrantDTO } from '../lib/integrant';
import { IOrganizationDTO } from '../lib/organization';
//eslint-disable-next-line
declare global {
  interface Window {
    MyConfig: any;
  }
}

const myConfig: any = window.MyConfig;
const getEnvUrls = () => {
  //eslint-disable-next-line
  const {
    REACT_APP_PRODUCER_URL_API,
    REACT_APP_PRODUCER_URL_UI,
    REACT_APP_STRIPEPUBLISHABLEKEY,
    REACT_APP_PRODUCER_URL_CLIENT
  } = myConfig;
  return {
    apiURL:
      REACT_APP_PRODUCER_URL_API !== '#{PRODUCER_URL_API}#'
        ? REACT_APP_PRODUCER_URL_API
        : process.env.REACT_APP_PRODUCER_URL_API,
    baseURL:
      REACT_APP_PRODUCER_URL_UI !== '#{PRODUCER_URL_UI}#'
        ? REACT_APP_PRODUCER_URL_UI
        : process.env.REACT_APP_PRODUCER_URL_UI,
    stripePublishableKey:
      REACT_APP_STRIPEPUBLISHABLEKEY !== '#{STRIPEPUBLISHABLEKEY}#'
        ? REACT_APP_STRIPEPUBLISHABLEKEY
        : process.env.REACT_APP_STRIPEPUBLISHABLEKEY,
    clientURL:
      REACT_APP_PRODUCER_URL_CLIENT !== '#{PRODUCER_URL_CLIENT}#'
        ? REACT_APP_PRODUCER_URL_CLIENT
        : process.env.REACT_APP_PRODUCER_URL_CLIENT
  };
};

const { apiURL, baseURL, stripePublishableKey, clientURL } = getEnvUrls();

const updatedUrl = apiURL;
const url = `${updatedUrl}/`;

const clientIdLength = 10;
const isClientId = (str: string): boolean => {
  if (str) {
    return str.length === clientIdLength;
  } else return false;
};

const cookieDuration = 1000 * 60 * 360;
const rememberMeCookieDuration = 1000 * 60 * 60 * 24 * 7;
const defaultNodeAxis = 200;
const defaultGraphDimensions = 600;

const returnQRPath = (short_code: IOrganizationDTO['short_code'], tracking_id: IIntegrantDTO['tracking_id']): string =>
  `https://healthloq.com/p/${short_code}/b/${tracking_id}`;

const subscriptionCategoriesLabel: {
  [key: string]: string;
} = {
  tracer: 'Product Origin',
  publisher: 'Document Protection',
  verifier: 'Document Verification'
};

const titles = {
  organization: 'Organization',
  integrantType: 'Product',
  integrant: 'Integrant',
  subIntegrant: 'Ingredient',
  integrantsPlural: 'Goods',
  exhibits: 'Attachment',
  exhibitsPlural: 'Attachments',
  facets: 'Trait',
  facetsPlural: 'Traits',
  transit: 'Transit',
  transitPlural: 'Transits',
  transfer: 'Transfer',
  transferPlural: 'Transfers',
  transferAction: 'Transferring',
  mutation: 'Link',
  directParent: 'Parent',
  directChild: 'Child',
  tracking: 'Batch',
  trackingPlural: 'Batches',
  error: 'Looks like something went wrong, please try again!'
};

const titlesTooltips = {
  options: `Add an ${titles.subIntegrant.toLowerCase()} for this item.`,
  details: `View/Set the tracking (batch id) number for this item.`,
  facets: `Add unique ${titles.facets}, e.g Color: Red, Size: 0.3 oz.`,
  exhibits: `Add attachments, documents, e.g Certificate of Authenticity.`,
  location: `Set a location for where this item originated.`,
  subIntegrant: `Add a Direct ${titles.subIntegrant} for this item.`,
  transit: `Did this item change location? `,
  transfer: `Change ownership of this item.`,
  inherited_facet: `This ${titles.facets} is inherited and cannot be edited.`,
  batch_tip: `Clicking this button will mark this batch as the default batch so that the static QR Code will link to this particular batch.`,
  productEdit: 'Edit product details',
  used_as_ingredients: 'This ingredient is already been used in another product so cannot be disabled.',
  deleteAttachment: 'Delete Attachment'
};

const titlesAlert = {
  transit: `This item has changed location.`,
  transfer: `This item has been transferred to another ${titles.organization}`,
  mutation: `This item is derived from another product/ingredient. `,
  emptyBatches: 'Looks like you have no batches in your organization',
  attachments: `All attachments added to our secure file archive will be archived for 7 years regardless of deletion.`
};

const d3Config = {
  automaticRearrangeAfterDropNode: false,
  collapsible: false,
  directed: true,
  height: defaultGraphDimensions,
  linkHighlightBehavior: false,
  nodeHighlightBehavior: false,
  panAndZoom: false,
  staticGraphWithDragAndDrop: true,
  width: defaultGraphDimensions,
  node: {
    mouseCursor: 'inherit',
    opacity: 1,
    renderLabel: false,
    size: 800,
    strokeColor: 'none',
    strokeWidth: 1.5,
    svg: '',
    symbolType: 'circle'
  },
  link: {
    color: 'lightgray',
    fontColor: 'black',
    fontSize: 8,
    fontWeight: 'normal',
    highlightColor: 'rgb(0, 137, 255)',
    highlightFontSize: 8,
    highlightFontWeight: 'normal',
    mouseCursor: 'pointer',
    opacity: 1,
    renderLabel: false,
    semanticStrokeWidth: true,
    strokeWidth: 3,
    markerHeight: 6,
    markerWidth: 6
  }
};

const acceptedFileTypes = '.pdf, .png, .jpeg, .jpg, .mp4, .web, .mov, .avi, .mkv, .webm';
const acceptedIMGFileTypes = '.pdf, .png, .jpeg, .jpg, .mp4, .web, .mov, .avi, .mkv, .webm';
const acceptedVideoFileTypes = '.mp4, .mov, .mkv, .mov, .avi, .webm';
const acceptedVideoFileRegex = /\.(mp4|mov|avi|wmv|flv)$/i;

const formDataConfig = (formData: FormData, access_token: string): RequestInit => {
  return {
    method: 'POST',
    body: formData,
    headers: new Headers({
      Authorization: `Bearer ${access_token}`
    })
  };
};

const formDataConfigType = (formData: any, access_token: string): RequestInit => {
  return {
    method: 'POST',
    body: formData,
    headers: new Headers({
      Authorization: `Bearer ${access_token}`,
      'Content-type': 'application/json'
    })
  };
};

const formAxiosHeader = (access_token: string, selectedsubscriptionType: any = ''): any => {
  const { selectedSubscription } = getLocalStorageItems();
  return {
    headers: {
      Authorization: `Bearer ${access_token}`,
      selectedsubscription: selectedsubscriptionType || selectedSubscription
    }
  };
};

const formDataConfigTypeJson = (formData: any, access_token: string): RequestInit => {
  return {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: new Headers({
      Authorization: `Bearer ${access_token}`,
      'Content-type': 'application/json'
    })
  };
};

const requestDataConfig = (access_token: string): RequestInit => {
  return {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${access_token}`
    })
  };
};

const requestDataConfigGet = (formData: any, access_token: string): RequestInit => {
  return {
    method: 'GET',
    body: JSON.stringify(formData),
    headers: new Headers({
      Authorization: `Bearer ${access_token}`,
      'Content-type': 'application/json'
    })
  };
};

const registrationStepArr = [
  'Pre Registration',
  'Service Agreement',
  'Basic Organization Information',
  'Payment Options'
];

const labelMeta: any = {
  num_batches: 'Batches (per month)',
  num_products_on_chain: 'Products on Chain (total)',
  num_seats: 'Seats (user accounts)',
  num_uploaded_documents: 'Uploaded Documents (per month)',
  num_uploaded_document: 'Uploaded Documents (per month)',
  num_daily_hashes: 'Daily Hashes (per day)',
  num_monthly_hashes: 'Monthly Hashes'
};

const dateConverter = (date: any) => {
  if (date) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const d = new Date(date * 1000);
    return month[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
  }
  return '';
};
const currencyFormat = (amount: number, currency: string) => {
  return (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0
  });
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const monthFormat = (interval_count: number, interval: string) => {
  if (interval_count === 1) {
    return '\xa0per ' + interval;
  }
  return '\xa0every ' + interval_count + ' ' + interval + 's';
};

const sortByKey = (array: any, key: any) => {
  return array.sort(function(a: any, b: any) {
    const x = a[key];
    const y = b[key];
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  });
};

const sortList = (list: any) => {
  const dayList: any = [];
  const weekList: any = [];
  const monthList: any = [];
  const yearList: any = [];
  const otherList: any = [];
  if (list) {
    list.forEach((item: any, index: any) => {
      switch (item.interval) {
        case 'month':
          monthList.push(item);
          break;
        case 'week':
          weekList.push(item);
          break;
        case 'day':
          dayList.push(item);
          break;
        case 'year':
          yearList.push(item);
          break;
        default:
          otherList.push(item);
      }
    });
  }
  return [
    ...sortByKey(dayList, 'interval_count'),
    ...sortByKey(weekList, 'interval_count'),
    ...sortByKey(monthList, 'interval_count'),
    ...sortByKey(yearList, 'interval_count'),
    ...sortByKey(otherList, 'interval_count')
  ];
};

const slugPatten = /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g;

export {
  d3Config,
  isClientId,
  titles,
  titlesAlert,
  url,
  baseURL,
  apiURL,
  updatedUrl,
  acceptedFileTypes,
  acceptedIMGFileTypes,
  cookieDuration,
  rememberMeCookieDuration,
  stripePublishableKey,
  formDataConfig,
  formDataConfigType,
  defaultGraphDimensions,
  defaultNodeAxis,
  titlesTooltips,
  registrationStepArr,
  labelMeta,
  clientURL,
  dateConverter,
  currencyFormat,
  requestDataConfig,
  requestDataConfigGet,
  returnQRPath,
  formDataConfigTypeJson,
  formAxiosHeader,
  slugPatten,
  monthFormat,
  sortByKey,
  sortList,
  subscriptionCategoriesLabel,
  acceptedVideoFileTypes,
  acceptedVideoFileRegex
};
