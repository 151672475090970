import { useEffect, useState } from 'react';
import { IntegrantKind } from '../types/common';
import { convertToGraphData } from '../utils';
import { useIndividualRolesService } from './api/useIndividualRolesService';
import { useIntegrantsService } from './api/useIntegrantsService';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';

const useRequestDetailIntegrants = (batch_id = ''): void => {
  const {
    currentIntegrant,
    data,
    setData,
    setIntegrantKind,
    setSelectedIntegrant,
    setRootIntegrantId,
    setFirstSelectedIntegrant,
    setTree,
    reset
  } = useIntegrantGraphStore(batch_id);
  const { getDetail, getLevel } = useIntegrantsService();
  const { initializeIndividualRoles } = useIndividualRolesService();
  const [init, setInit] = useState(false);

  useEffect(() => {
    let detailList = {};
    if (batch_id !== '' && typeof batch_id !== 'undefined' && (data.nodes.length === 0 || data.nodes[0].id !== batch_id) && !init) {
      reset();
      getDetail(batch_id)
        .then(detailListData => {
          detailList = detailListData;
          return getLevel(batch_id);
        })
        .then(tree => {
          const graphData = convertToGraphData(detailList);
          setTree(tree, detailList);
          setData(graphData);
          setRootIntegrantId(graphData.nodes[0].id);
          setSelectedIntegrant(graphData.nodes[0]);
          setFirstSelectedIntegrant(graphData.nodes[0]);
          setIntegrantKind(IntegrantKind.Default);
        });
      setInit(true);
    } else {
      setInit(true);
    }
  }, [
    init,
    batch_id,
    getDetail,
    setData,
    data.nodes,
    setSelectedIntegrant,
    setFirstSelectedIntegrant,
    setIntegrantKind,
    currentIntegrant,
    initializeIndividualRoles,
    setRootIntegrantId
  ]);
};

export { useRequestDetailIntegrants };
