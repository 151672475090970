import React, { FunctionComponent, useEffect } from 'react';
import { PrimaryButton } from 'common';
import { Grid,makeStyles,Theme, createStyles } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import { useUserStore } from 'hooks/useUserStore';
import { IEmptyObjectDTO } from '../../../lib/common';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    btnContainer:{
        padding: "0px 10px",
        boxSizing: 'border-box'
    }
}))

interface ISurveyFormActionButtons {
    surveyFormStep: number;
    integrant_type_id: string;
    audienceFormFields: any;
}

let sendSurveyInvitationFlag = false;

export const SurveyFormActionButtons:FunctionComponent<ISurveyFormActionButtons> = ({surveyFormStep,integrant_type_id,audienceFormFields}) => {
    const classes = useStyle();
    const history = useHistory();
    const {initSendSurveyInvitation,sendSurveyInvitationData,resetFilterUsersCount} = useUserStore();
    const isDisabled = ()=>{
        if(surveyFormStep === 1){
            return !Boolean(integrant_type_id)
        }else if(surveyFormStep === 2){
            return sendSurveyInvitationData.loading
        }
        return false
    }
    const handleNext = ()=>{
        if(surveyFormStep < 2){
            history.push(`/create-audience/${surveyFormStep + 1}`)
        }else{
            let params: IEmptyObjectDTO = {integrant_type_id};
            let query: IEmptyObjectDTO = {};
            for(let item of audienceFormFields){
                if(Boolean(item.value)){
                    query[item.key] = item.value
                }
            }
            if(Object.keys(query).length){
                params["query"] = query;
            }
            initSendSurveyInvitation(params);
            sendSurveyInvitationFlag = true;
        }
    }
    useEffect(()=>{
        if(!sendSurveyInvitationData.loading && sendSurveyInvitationData.status === "1" && sendSurveyInvitationFlag){
            sendSurveyInvitationFlag = false;
            window.sessionStorage.removeItem("audienceFormFields");
            window.sessionStorage.removeItem("integrant_type_id");
            resetFilterUsersCount();
            history.push(`/create-audience/${surveyFormStep + 1}`);
        }
    },[sendSurveyInvitationData])
  return (
    <Grid item>
        <Grid container justifyContent='space-between' className={classes.btnContainer}>
            <Grid item>
                <PrimaryButton icon="back" showIconOnHover={true} hoverZoomAnimation={true} children="Previous" onClick={()=> history.push(`/create-audience/${surveyFormStep - 1}`)} secondaryButton={true} maxContent={true} disabled={surveyFormStep === 0} />
            </Grid>
            <Grid item>
                <PrimaryButton endIcon="next" showIconOnHover={!sendSurveyInvitationData.loading} hoverZoomAnimation={true} loading={sendSurveyInvitationData.loading} children={surveyFormStep < 2 ? "Next" : "Submit"} disabled={isDisabled()} onClick={handleNext} secondaryGreenButton={true} maxContent={true} />
            </Grid>
        </Grid>
    </Grid>
  )
}

export default SurveyFormActionButtons
