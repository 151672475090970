import { IIntegrantDetailDTO } from '../../lib/integrant';
import { isUndefined } from '.';
import { integrant_ } from '../common/models';
import { IIntegrantNode } from '../components/GraphNode';
import { IEdge, IGraphData } from '../context/integrantGraph/types';
import { IntegrantKind } from '../types/common';

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
//eslint-disable-next-line
export function* process(
  key: 'mutations' | 'transits' | 'transfers',
  integrant: any
): IterableIterator<[IIntegrantNode, IEdge]> {
  const { sourceLinks } = integrant;
  if (sourceLinks && sourceLinks.length === 0) {
    return;
  }

  // for (let i = 0; i < integrant[key].length; i++) {
  //   const currentEdgeType = capitalizeFirstLetter(key.slice(0, -1)) as IntegrantKind;
  //   const currentIntegrant = integrant[key][i];
  //   const currentGraphData: IEdge = {
  //     target: currentIntegrant.integrant.id,
  //     source: integrant.id,
  //     edge_id: currentIntegrant.id,
  //     edge_type: currentEdgeType
  //   };
  //   yield [{ ...integrant_, ...currentIntegrant.integrant, x: 0, y: 0, edge_type: currentEdgeType }, currentGraphData];
  //   if (currentIntegrant.integrant['mutations'].length > 0) {
  //     yield* process('mutations', currentIntegrant.integrant);
  //   } else if (currentIntegrant.integrant['transits'].length > 0) {
  //     yield* process('transits', currentIntegrant.integrant);
  //   }
  //}
}

export const convertToGraphData = (integrantDetailList: any): IGraphData => {
  const graphData: IGraphData = {
    nodes: [
      {
        ...integrant_,
        ...integrantDetailList.integrantInfo,
        mutations: [],
        transits: [],
        x: 0,
        y: 0,
        edge_type: IntegrantKind.Default
      }
    ],
    edges: []
  };

  const awaitMutations = process('mutations', integrantDetailList);
  const awaitTransits = process('transits', integrantDetailList);
  const awaitTransfers = process('transfers', integrantDetailList);

  let mutation = awaitMutations.next();
  let transit = awaitTransits.next();
  let transfer = awaitTransfers.next();

  const push = (integrant: IIntegrantNode, edge: IEdge): void => {
    graphData.edges.push(edge);
    graphData.nodes.push(integrant);
  };

  while (mutation.done === false || transit.done === false || transfer.done === false) {
    if (transfer.done === false) {
      push(transfer.value[0], transfer.value[1]);
      transfer = awaitTransfers.next();
    }
    if (transit.done === false) {
      push(transit.value[0], transit.value[1]);
      transit = awaitTransits.next();
    }
    if (mutation.done === false) {
      push(mutation.value[0], mutation.value[1]);
      mutation = awaitMutations.next();
    }
  }

  return graphData;
};
