import React, { FunctionComponent, CSSProperties } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../common/Button';
import { errorColor, largeButtonStyle, primaryColor } from '../styles/default';

interface INextButtonProps {
  buttonStyle: CSSProperties;
  isVisible?: boolean;
  isButtonEnabled: boolean;
  errorMessage: string;
  onChangeIndex: (index: number) => void;
  index: number;
  type?: string;
}

const NextButton: FunctionComponent<INextButtonProps> = ({
  buttonStyle,
  isVisible = false,
  isButtonEnabled = true,
  errorMessage,
  index,
  onChangeIndex,
  type = ''
}) => {
  return isVisible === true ? (
    <Button
      disabled={isButtonEnabled}
      onClick={(): void => {
        onChangeIndex(index + 1);
        if (isButtonEnabled && errorMessage) {
          toast.error(errorMessage);
        }
      }}
      center
      style={{
        ...buttonStyle,
        ...largeButtonStyle,
        width: '100%',
        backgroundColor: '#008F2B'
      }}
    >
      {(index === 0 && type !== 'importNIHProduct') || !isButtonEnabled ? `Next` : errorMessage}
    </Button>
  ) : null;
};

export { NextButton };
