import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  Box,
  Checkbox,
  FormControlLabel,
  createStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Divider
} from '@material-ui/core';
import { useAuthStore } from 'hooks/useAuthStore';
import { Layer, PrimaryButton } from 'common';
import { useUserStore } from 'hooks/useUserStore';
import { useRouter } from 'hooks/useRouter';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    domainVerificationSteps: {
      marginTop: 20,
      '&>div': {
        '&>p': {
          '&>span': {
            color: '#546178'
          }
        },
        margin: '10px 0'
      }
    },
    checkbox: {
      color: '#008F2B !important'
    }
  })
);

export default function DomainVerification(props: any) {
  const classes = useStyle();
  const {
    individual: { domain_name, is_valid_domain_name, text_record }
  } = useAuthStore();
  const { navigate } = useRouter();
  const { verifyDomainData, initVerifyDomain } = useUserStore();
  const [isTXTRecordAdded, setIsTXTRecordAdded] = useState(false);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));
  return (
    <Layer
      direction="column"
      style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0' }}
      width={match ? '100%' : '50%'}
    >
      <Box display="flex" flexDirection={'column'} width={'100%'}>
        <Typography variant="h5">Verify Your Domain to Unlock Exclusive Features!</Typography>
        <Divider />
        <Box display={'flex'} flexDirection="column" className={classes.domainVerificationSteps}>
          {domain_name ? (
            <>
              <p>To verify your domain add new DNS TXT record</p>
              <div>
                <p>
                  <span>Type: </span> TXT
                </p>
                <p>
                  <span>Name: </span>
                  {domain_name}&nbsp;&nbsp;&nbsp;<span>OR: </span>@
                </p>
                <p>
                  <span>Content: </span>
                  {text_record}
                </p>
              </div>
              <p>
                After adding the above TXT record to your domain provider click on the below button to verify the
                domain.
              </p>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{ colorSecondary: classes.checkbox }}
                      checked={isTXTRecordAdded}
                      onChange={(e: any) => setIsTXTRecordAdded(e.target.checked)}
                    />
                  }
                  label="You have added the TXT record to your website's DNS settings?"
                  labelPlacement="start"
                />
                {isTXTRecordAdded && (
                  <PrimaryButton
                    style={{ marginLeft: '30px' }}
                    onClick={initVerifyDomain}
                    secondaryGreenButton
                    maxContent={true}
                    children={verifyDomainData?.loading ? `Verifying...` : `Verify`}
                    disabled={verifyDomainData?.loading}
                    loading={verifyDomainData?.loading}
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              <p>Please add and verify your domain name to unlock exclusive features by clicking the below button</p>
              <PrimaryButton
                style={{ marginTop: '10px' }}
                onClick={() => navigate('/organization')}
                secondaryGreenButton
                maxContent={true}
                children={`Verify Domain`}
              />
            </>
          )}
        </Box>
      </Box>
    </Layer>
  );
}
