import { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'util';
import { isClientId, titles } from '../config';
import { CreateBatchContext, ICreateBatchState } from '../context/createBatchContext';
import { isEmptyString, validURL } from '../utils';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useIntegrantPaneStore } from './useIntegrantPaneStore';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { useRequestDetailIntegrants } from './useRequestDetailIntegrants';
import { IntegrantKind } from '../types/common';


interface ICreateBatchStore extends ICreateBatchState {
  setDraftAlertVisible: (isVisible: boolean) => void;
  //isDetailsDisabled: boolean;
  isFacetsDisabled: boolean;
  isExhibitsDisabled: boolean;
  isLocationDisabled: boolean;
  isSaveIntegrantDisabled: boolean;
  isDetailsBuyAgainUrl: boolean;
  isDetailsTrackingId: boolean;
  saveButtonMessage: string;
  onTogglePrimary: (primary: boolean) => void;
}

const useCreateBatchStore = (batchId?: string): ICreateBatchStore => {
  const [state, setState] = useContext(CreateBatchContext);
  const { currentIntegrant, integrantKind, setCurrentIntegrant } = useIntegrantGraphStore(batchId);
  const { tabIndex, isEditableLocation } = useIntegrantPaneStore();
  const { from_location, to_location, effective_date, to_organization_id } = useCreateTransitStore();
  const { isDraftAlertVisible, isDraft, batchValue } = state;
  const [saveButtonMessage, setSaveButtonMessage] = useState('');

  useRequestDetailIntegrants(batchId);

  const setDraftAlertVisible: ICreateBatchStore['setDraftAlertVisible'] = bool => {
    setState(s => ({ ...s, isDraftAlertVisible: bool, isDraft: bool }));
  };

  useEffect(() => {
    if (!isUndefined(currentIntegrant) && isClientId(currentIntegrant.id)) {
      if (tabIndex === 0) {
        setState(s => ({ ...s, isDraft: true, isDraftAlertVisible: true }));
      } else {
        setState(s => ({ ...s, isDraft: true, isDraftAlertVisible: false }));
      }
    } else {
      setState(s => ({ ...s, isDraft: false, isDraftAlertVisible: false }));
    }
  }, [currentIntegrant, tabIndex, setState]);

  const isDetailsTrackingId: ICreateBatchStore['isDetailsTrackingId'] = isEmptyString(currentIntegrant.tracking_id);
  const isDetailsBuyAgainUrl: ICreateBatchStore['isDetailsBuyAgainUrl'] = validURL(currentIntegrant.buy_again_url);
  const isFacetsDisabled: ICreateBatchStore['isFacetsDisabled'] =
    currentIntegrant.facets.filter(i => isEmptyString(i.description) || isEmptyString(i.title)).length > 0;
  let isExhibitsDisabled: ICreateBatchStore['isExhibitsDisabled'] = false
  let isLocationDisabled: ICreateBatchStore['isLocationDisabled'] = false
  let isSaveIntegrantDisabled = true
  if (integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer) {
    const toLocationDisabled = to_location !== null && typeof to_location === 'object' &&
      isEmptyString(to_location.city) &&
      isEmptyString(to_location.state) &&
      isEmptyString(to_location.zip) &&
      isEmptyString(to_location.country)
    const fromLocationDisabled = from_location !== null && typeof from_location === 'object' &&
      isEmptyString(from_location.city) &&
      isEmptyString(from_location.state) &&
      isEmptyString(from_location.zip) &&
      isEmptyString(from_location.country)
    isLocationDisabled = true
    isExhibitsDisabled = currentIntegrant.exhibits.filter(i => isEmptyString(i.title)).length > 0;
    if (!fromLocationDisabled && !toLocationDisabled) {
      isLocationDisabled = false
    }

    if (!isExhibitsDisabled && !fromLocationDisabled && !toLocationDisabled) {
      isSaveIntegrantDisabled = false
    }
  } else {
    isExhibitsDisabled = currentIntegrant.exhibits.filter(i => isEmptyString(i.description) || isEmptyString(i.title)).length > 0;
    isLocationDisabled = isEditableLocation &&
      currentIntegrant.location !== null &&
      isEmptyString(currentIntegrant.location.line_1) &&
      isEmptyString(currentIntegrant.location.city) &&
      isEmptyString(currentIntegrant.location.state) &&
      isEmptyString(currentIntegrant.location.zip) &&
      isEmptyString(currentIntegrant.location.country);
    isSaveIntegrantDisabled = isDetailsTrackingId || isDetailsBuyAgainUrl || isFacetsDisabled || isExhibitsDisabled;
  }

  useEffect(() => {
    switch (true) {
      case isDetailsTrackingId:
      case isDetailsBuyAgainUrl:
        if (integrantKind !== IntegrantKind.Transfer) {
          setSaveButtonMessage('You Need Tracking & Buy Again URL  Filled Out.');
        } else {
          setSaveButtonMessage('Save');
        }
        break;
      case isFacetsDisabled:
        setSaveButtonMessage(`You Need ${titles.facetsPlural} Filled Out.`);
        break;
      case isLocationDisabled:
        setSaveButtonMessage(integrantKind === IntegrantKind.Transfer ? 'You Need Location & To Organization Filled Out.' : 'You Need Location Filled Out.');
        break;
      case isExhibitsDisabled:
        setSaveButtonMessage(`You Need ${titles.exhibitsPlural} Filled Out.`);
        break;
      default:
        setSaveButtonMessage('Save');
        break;
    }
  }, [isDetailsTrackingId, isFacetsDisabled, isExhibitsDisabled, isLocationDisabled, isDetailsBuyAgainUrl]);

  const onTogglePrimary: ICreateBatchStore['onTogglePrimary'] = primary => {
    setCurrentIntegrant({ ...currentIntegrant, primary });
  };

  return {
    isDraft,
    isLoading: state.isLoading,
    isDraftAlertVisible,
    isDetailsBuyAgainUrl,
    isDetailsTrackingId,
    isFacetsDisabled,
    isExhibitsDisabled,
    isLocationDisabled,
    isSaveIntegrantDisabled,
    saveButtonMessage,
    setDraftAlertVisible,
    onTogglePrimary,
    batchValue
  };
};

export { useCreateBatchStore };
