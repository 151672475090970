import React, { CSSProperties, FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Layer } from '../common';
import { titles, titlesTooltips } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useSuggestIntegrantChildStore } from '../hooks/useSuggestIntegrantChildStore';
import { SuggestIntegrantTypeDropdown } from './SuggestIntegrantTypeDropdown';
import { PrimaryButton } from 'common/Button/buttons';

const integrantWrapper: CSSProperties = { position: 'relative', zIndex: 5, marginBottom: 10 };

const AddIntegrant: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();
  const { currentIntegrant } = useIntegrantGraphStore();
  const { onToggleMenu, isOpen } = useSuggestIntegrantChildStore();

  return isDraft ? null : (
    <>
      <ReactTooltip />
      <Layer style={integrantWrapper} direction="column">
        <PrimaryButton
          icon="add"
          dataTip={titlesTooltips.subIntegrant}
          children={!isOpen ? `Add ${titles.subIntegrant} for ${currentIntegrant.title}` : 'Close'}
          onClick={(): void => onToggleMenu(!isOpen)}
        />
        <SuggestIntegrantTypeDropdown
          source={currentIntegrant.id}
          helperText="If you can't find the ingredient you are looking for, please ensure you have created an ingredient with batch first."
        />
      </Layer>
    </>
  );
};

export { AddIntegrant };
