import { ICreateTransitDTO, ITransitLocationDTO } from '../../lib/transit';
import { useContext } from 'react';
import { CreateTransitContext, ICreateTransitState } from '../context/createTransitContext';
import { IntegrantKind } from '../types/common';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useTransitsService } from './api/useTransitsService';
import { useCreateTransferStore } from './useCreateTransferStore';
import { location_ } from 'common/models';

interface ICreateTransitStore extends ICreateTransitState {
  setCurrentTransit: (transit: ICreateTransitDTO, CurrentTransitLocation?: any, updatedExhibitList?: any) => void;
  setCurrentTransitLocation: (currentLocationObj: ITransitLocationDTO) => void;
  setOpen: (isOpen: boolean) => void;
  getTransit: (id: string) => void;
  onAddTransit: (transit: ICreateTransitDTO, isType: any) => Promise<void>;
  setedit_location: (edit_location: boolean) => void;
}

const useCreateTransitStore = (): ICreateTransitStore => {
  const [state, setState] = useContext(CreateTransitContext);
  const { onAddIntegrant, currentIntegrant } = useIntegrantGraphStore();
  const { getTransitList } = useTransitsService()
  const { isOpen, currentTransit, isLoading, from_location, to_location, effective_date, to_organization_id, edit_location } = state;
  const setCurrentTransit: ICreateTransitStore['setCurrentTransit'] = (transit, CurrentTransitLocation?: any, updatedExhibitList?: any) => {
    setState((s: ICreateTransitState) => ({ ...s, currentTransit: transit, ...CurrentTransitLocation }));
  };

  const setCurrentTransitLocation: ICreateTransitStore['setCurrentTransitLocation'] = async (currentLocationObj: ITransitLocationDTO) => {
    setState((s: ICreateTransitState) => ({
      ...s, ...currentLocationObj
    }));
  };

  const setedit_location: ICreateTransitStore['setedit_location'] = async (edit_location: boolean) => {
    setState((s: ICreateTransitState) => ({
      ...s, edit_location
    }));
  };

  const onAddTransit: ICreateTransitStore['onAddTransit'] = async (transit: ICreateTransitDTO, isType: any) => {
    setState((s: ICreateTransitState) => ({ ...s, isLoading: true }));
    setCurrentTransit({ ...transit, integrant_id: currentIntegrant.id });
    onAddIntegrant(currentIntegrant, currentIntegrant.id, isType);
    setState((s: ICreateTransitState) => ({ ...s, isLoading: false, isOpen: false }));
  };

  const setOpen: ICreateTransitStore['setOpen'] = bool => {
    setState((s: ICreateTransitState) => ({ ...s, isOpen: bool }));
  };

  const getTransit: ICreateTransitStore['getTransit'] = async (id: string) => {
    let transitList = await getTransitList(id)
    return transitList
  }

  return {
    setCurrentTransit,
    setCurrentTransitLocation,
    isOpen,
    currentTransit,
    setOpen,
    onAddTransit,
    getTransit,
    isLoading,
    from_location,
    to_location,
    effective_date,
    to_organization_id,
    edit_location,
    setedit_location
  };
};

export { useCreateTransitStore };
