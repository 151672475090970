import React, { useState } from 'react';
import { Input } from '../../common/Input';
import UseAnimations from 'react-useanimations';
import { isEmptyString, validateEmail } from '../../utils';
import Icon from 'common/Icon';
import { PrimaryButton } from 'common/Button/buttons';

const AddOrganization = (props: any) => {
    const [organizationObj, setOrganization] = useState({
        organization_name: '',
        organization_email_address: ''
    })
    const { organization_email_address, organization_name } = organizationObj
    const { apiLoading, handleSubmit, closeModal } = props
    return (
        <div className='section-organization'>
            <div className='pao-title'>
                <p className='txt-pao-title'>Add Organization</p>
                <Icon onClick={() => closeModal()} icon='close'  />
            </div>
            <div className='row'>
                <div className='col-3 d-flex align-items-center'>
                    Org. Name
                </div>
                <div className='col'>
                    <Input
                        disabled={apiLoading}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            setOrganization({ ...organizationObj, organization_name: event.currentTarget.value })
                        }
                        placeholder="e.g. Natural Stacks"
                        value={organization_name}
                        required={isEmptyString(organization_name)}
                    />
                </div>
            </div>
            <div className='row _pt15'>
                <div className='col-3 d-flex align-items-center'>
                    Org. Email
                </div>
                <div className='col'>
                    <Input
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            setOrganization({ ...organizationObj, organization_email_address: event.currentTarget.value })
                        }
                        disabled={apiLoading}
                        value={organization_email_address}
                        type="email"
                        placeholder="e.g. Dwight.Schrute@healthloq.com"
                        required={validateEmail(organization_email_address)}
                    />
                </div>
            </div>
            <div className='row _pt15'>
                <div className='offset-3 col-9'>
                    <PrimaryButton loading={apiLoading} maxContent={true} secondaryButton={true}  disabled={
                        isEmptyString(organization_name) ||
                        validateEmail(organization_email_address)}
                    onClick={() => handleSubmit(organizationObj)} children={"Submit"} icon='save'  />
                </div>
            </div>
        </div>
    )
}

export { AddOrganization }