import React, { FunctionComponent } from 'react';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { defaultButtonStyle, primaryButtonStyle } from '../styles/default';
import { NextButton } from './NextButton';
import { validURL } from '../utils';

interface IIntegrantNextButtonProps {
  isVisible?: boolean;
}

const IntegrantNextButton: FunctionComponent<IIntegrantNextButtonProps> = ({ isVisible = false }) => {
  const { onChangeIndex, tabIndex } = useIntegrantPaneStore();
  const { isDraft, isFacetsDisabled, isLocationDisabled } = useCreateBatchStore();
  const buttonStyle = isDraft ? primaryButtonStyle : defaultButtonStyle;
  const { saveButtonMessage } = useCreateBatchStore();
  const { currentIntegrant } = useIntegrantGraphStore();

  const isDetailDisable = currentIntegrant.tracking_id !== '' && !validURL(currentIntegrant.buy_again_url);

  const isButtonEnabled =
    (tabIndex === 1 && !isDetailDisable) ||
    (tabIndex === 2 && isFacetsDisabled) ||
    (tabIndex === 3 && isLocationDisabled);

  return (
    <NextButton
      buttonStyle={buttonStyle}
      isVisible={isVisible}
      errorMessage={saveButtonMessage}
      onChangeIndex={onChangeIndex}
      index={tabIndex}
      isButtonEnabled={isButtonEnabled}
    />
  );
};

export { IntegrantNextButton };
