import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    reactQuillRoot: {
      '&>div:nth-child(2)': {
        backgroundColor: theme.palette.common.white
      }
    }
  })
);

interface props {
  data: string;
  onChange: (content: string) => any;
}

const ReactQuillEditor: FunctionComponent<props> = ({ data, onChange }) => {
  const classes = useStyle();
  return (
    <ReactQuill
      className={classes.reactQuillRoot}
      theme="snow"
      value={data}
      onChange={(str: any) => onChange(str || '')}
    />
  );
};

export default ReactQuillEditor;
