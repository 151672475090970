import { IIntegrantTypeDTO } from '../../lib/integrantType';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Img, Layer } from '../common';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';
import { useHistory } from 'react-router-dom';
import {
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Grid
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { titlesTooltips } from '../config';
import Icon from '../common/Icon';

interface IProductListItemProps {
  product: IIntegrantTypeDTO;
  onSelectProduct: () => void;
}

const productInfoStyle: CSSProperties = {
  marginLeft: 0
};

const productImageStyle: CSSProperties = {
  borderRadius: '50%',
  height: 50,
  width: 50,
  marginRight: 10,
  cursor: 'pointer'
};

export const titleStyle: CSSProperties = {
  textOverflow: 'ellipses',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  margin: 0
};

const ProductListItem: FunctionComponent<IProductListItemProps> = ({ product, onSelectProduct }) => {
  const { title, description, image_url, id, is_disabled, used_as_ingredients } = product;
  const history = useHistory();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const {
    initProductDisableStatus,
    initGetIntegrantsByIntegrantTypeId,
    productIngredientsData,
    updateProductIngredients
  } = useIntegrantTypeStore();
  const handleSwitchChange = (e: any): void => {
    if (e.target.checked) {
      initProductDisableStatus(id, false);
    } else {
      // initGetIntegrantsByIntegrantTypeId(id);
      setOpenConfirmDialog(true);
    }
  };
  const handleClose = (value: boolean): void => {
    // updateProductIngredients({status: '', message: '', data: null})
    if (value) {
      initProductDisableStatus(id, true);
    }
    setOpenConfirmDialog(false);
  };
  return (
    <Layer direction="row">
      <ReactTooltip />
      <Img style={productImageStyle} onClick={(): void => onSelectProduct()} height={50} alt={title} src={image_url} />
      <div style={productInfoStyle} className="home-page-product-info-style">
        <div className="home-page-product-list-heading">
          <div>
            <h5 className="home-page-product-title" onClick={(): void => onSelectProduct()}>
              {title}
            </h5>
            <small className="home-page-product-description" onClick={(): void => onSelectProduct()}>
              {description}
            </small>
          </div>
          <div className="home-page-product-list-heading-icons">
            <Icon
              icon="edit"
              onClick={() => history.push(`/update-product/${id}`)}
              dataTip={titlesTooltips.productEdit}
            />
            <Icon
              icon="surveyQuestion"
              onClick={() => history.push(`/survey-questions/${id}/0`)}
              dataTip={'Survey Questions'}
            />
            <div
              className="home-page-product-switch"
              data-tip={used_as_ingredients ? titlesTooltips.used_as_ingredients : ''}
            >
              <Switch
                disabled={used_as_ingredients}
                checked={!is_disabled}
                className={`${!is_disabled ? 'checked' : ''} ${used_as_ingredients ? 'disabled' : ''}`}
                onChange={handleSwitchChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openConfirmDialog} onClose={(): void => handleClose(false)}>
        <DialogTitle className="product-ingredients-dialog-heading">Confirmation</DialogTitle>
        <DialogContent>Please confirm you would like to disable this product?</DialogContent>
        <DialogActions className="product-ingredients-dialog-footer">
          <Button onClick={(): void => handleClose(false)}>No</Button>
          <Button onClick={(): void => handleClose(true)}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Layer>
  );
};

export { ProductListItem };
