import { IOrganizationDTO } from '../../lib/organization';
import React, { useReducer } from 'react';
import { organization_ } from '../common/models';
import { CreateActions, IAction, IResetAction } from '../types/common';

export interface IAppProviderState {
  isLoading: boolean;
  selectedOrganization: IOrganizationDTO;
}

type ISetLoadingAction = IAction<'setLoading', boolean>;
type ISetOrganizationIdAction = IAction<'setOrganization', IOrganizationDTO>;
type IActions = ISetLoadingAction | ISetOrganizationIdAction | IResetAction;

//

const defaults: IAppProviderState = {
  isLoading: true,
  selectedOrganization: organization_
};

const AppContext = React.createContext<CreateActions<IAppProviderState, IActions>>([
  defaults,
  (): React.Dispatch<IActions> => (): IAppProviderState => defaults
]);

const reducer = (state: IAppProviderState, action: IActions): IAppProviderState => {
  switch (action.type) {
    case 'reset':
      return defaults;
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setOrganization':
      return { ...state, selectedOrganization: action.payload };
    default:
      return state;
  }
};

const AppProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaults);

  return <AppContext.Provider value={[state, dispatch]}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
