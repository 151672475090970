import React, { FunctionComponent, CSSProperties } from 'react';
import { Layer } from '../.././common/Layer';
import { CreateIntegrantTypePane } from '../../components/CreateIntegrantTypePane';
import { useCreateIntegrantTypeStore } from '../../hooks/useCreateIntegrantTypeStore';
import { rightContentWrapperStyle } from '../../utils';

const CreateIntegrantTypeSlide: FunctionComponent = () => {
  const { isOpen } = useCreateIntegrantTypeStore();

  const createIntegrantTypeSlideStyle: CSSProperties = {
    ...rightContentWrapperStyle(isOpen ? '1' : ''),
    position: 'fixed',
    top: 0,
    zIndex: 100
  };

  return (
    <Layer fill direction="column" style={createIntegrantTypeSlideStyle} width={'100%'}>
      <Layer fill direction={'column'} style={{ padding: 20 }}>
        <CreateIntegrantTypePane />
      </Layer>
    </Layer>
  );
};

export { CreateIntegrantTypeSlide };
