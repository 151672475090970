import { url } from "config"
import { useAuthStore } from "hooks/useAuthStore";
import React, { useState } from "react"

function ForbiddenSubStances() {

    const [result, setResult] = useState(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null)
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0])
        }
    }
    const handleClick = () => {
        const formData = new FormData()
        if (file) {
            setLoading(true)
            setResult(null)
            formData.append("file", file)
            fetch(`${url}document-ai/dcoument-forbidden-substances`, {
                method: "POST",
                body: formData
            }).then((res) => res.json()).then((res) => {
                if (res.statusCode > 300) {
                    setResult(res?.message)
                } else {
                    setResult(res?.data)
                }
                setLoading(false)
            }).catch((error) => { console.log(error); setLoading(true); })
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", margin: "100px" }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "50px" }}>
                <input type="file" disabled={loading} onChange={handleFileChange} />
                <button disabled={loading} onClick={handleClick}>Find substance</button>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px"
                }}
            >
                {loading ? "Loading..." : result}
            </div>
        </div>
    )
}


export default ForbiddenSubStances