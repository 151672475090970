import React, { FunctionComponent } from 'react';

import { TransferInSlide } from './TransferInSlide'
import { ViewTransferSlide } from './ViewTransferSlide'

import { titles } from '../../config';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading'
import { useCreateTransferInStore } from '../../hooks/useCreateTransferInStore';

const TransferredView: FunctionComponent = () => {
  const {
    isLoading,
  } = useCreateTransferInStore()

  if (isLoading) {
    return <Loading title={LoadingTitle.Loading + ' ' + titles.trackingPlural + ' from other ' + titles.organization} />
  }

  return (
    <div className='transferred-section'>
      <TransferInSlide />
      <ViewTransferSlide />
    </div>
  );
};

export { TransferredView };
