import { ICreateIntegrantResponseDTO, IIntegrantDTO } from '../../lib/integrant';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import 'react-toggle/style.css';
import UseAnimations from 'react-useanimations';
import { titles } from '../config';
import { useCreateTransferInStore } from '../hooks/useCreateTransferInStore';
import { useRouter } from '../hooks/useRouter';
import {
  defaultBorderStyle,
  defaultInputStyle,
  defaultModalStyle,
  formButtonStyle,
  loadingIconStyle,
  primaryButtonStyle,
} from '../styles/default';
import { useToastStore } from '../hooks/useToastStore'
import Icon from 'common/Icon';
import {Grid} from '@material-ui/core';
import { PrimaryButton } from 'common/Button/buttons';

interface ICloneBatch {
  integrant: any;
}

const cloneButtonStyle: CSSProperties = { ...formButtonStyle, ...primaryButtonStyle };

const cloneButtonDisableStyle: CSSProperties = { ...formButtonStyle, ...primaryButtonStyle, opacity: 0.5 };

const CloneTransferIn: FunctionComponent<ICloneBatch> = ({ integrant }) => {
  const { clone } = useCreateTransferInStore();
  const { isOpenToast } = useToastStore();
  const [isCloneLoading, setCloneLoading] = useState(false);
  const [cloned, setCloned] = useState<ICreateIntegrantResponseDTO>();
  const [tracking, setTracking] = useState('');
  const { navigate } = useRouter();
  const handleOnClone = async (): Promise<void> => {
    setCloneLoading(true);
    const clonedIntegrant: any = await clone(integrant, tracking);
    if (typeof clonedIntegrant !== 'undefined' && !clonedIntegrant.hasOwnProperty('statusCode')) {
      isOpenToast('success', "Clone Successful");
      hideModal()
    }
    setCloneLoading(false);
    // if (clonedIntegrant && integrantInfo.id !== '') {
    //   setCloned(clonedIntegrant);
    //   setTracking('');
    // }
  };

  const handleAfterClose = (): void => {
    setCloned(undefined);
    setTracking('');
    setCloneLoading(false);
  };

  const onkeypress = function (keyCode: number): boolean {
    const key = keyCode;
    return key !== 32 ? true : false;
  };
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} onAfterClose={handleAfterClose} style={defaultModalStyle} isOpen>
        <div className='cloneTransferIn-div'>
          <strong style={{ marginBottom: 20, display: 'block' }}>
            You are about to clone batch {integrant.external_id}.
          </strong>
          <small>
            Please enter a new {titles.tracking}{' '}
            number:
          </small>
          <div style={{ marginTop: 20, marginBottom: 20, display: 'block' }}>
            <input
              pattern="[^\s]+"
              onKeyDownCapture={(e: React.KeyboardEvent<HTMLInputElement>): boolean => onkeypress(e.keyCode)}
              autoFocus
              value={tracking}
              onChange={(evt): void => setTracking(evt.currentTarget.value.split(' ').join(''))}
              style={{ ...defaultInputStyle, padding: 10, width: '100%', ...defaultBorderStyle }}
              placeholder={titles.tracking}
            />
          </div>

          <PrimaryButton loading={isCloneLoading} icon='clone' onClick={handleOnClone}  children={`Clone ${typeof cloned !== 'undefined' ? `Again` : ``}`} disabled={!tracking} maxContent={true} />
          {/* {typeof cloned !== 'undefined' ? (
          <button
            onClick={(): void => navigate(`batches/${cloned.id}`)}
            disabled={isCloneLoading}
            style={cloneButtonStyle}
          >
            View Cloned {cloned.tracking_id}
          </button>
        ) : null} */}
          <PrimaryButton style={{marginLeft: 10}} disabled={isCloneLoading} icon='close' onClick={hideModal} children={'Cancel'} maxContent={true}  />
        </div>
      </ReactModal>
    ),
    [isCloneLoading, tracking, cloned]
  );

  return (
    <Grid style={{marginTop:"10px"}}>
      <Icon icon='copy' onClick={showModal} dataTip='Clone' />
    </Grid>
  );
};

export { CloneTransferIn };
