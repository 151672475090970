import React, { useEffect, useState, useRef, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import UseAnimations from 'react-useanimations';
import Slider from 'react-slick';
import { Link, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import {
  labelMeta,
  updatedUrl,
  currencyFormat,
  monthFormat,
  sortList,
  formAxiosHeader,
  subscriptionCategoriesLabel
} from './../../config';
import { useAuthStore } from './../../hooks/useAuthStore';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/subscription.css';
import { getParams, abbrNum } from '../../utils';
import {
  Tab,
  Tabs,
  makeStyles,
  Theme,
  Divider,
  Box,
  Grid,
  Typography,
  Button,
  createStyles,
  Tooltip
} from '@material-ui/core';
import axiosInstance from 'utils/api-interceptor';
import { HeadingIcon } from 'static/icons';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tabsRoot: {
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: '#008F2B',
        border: '1px solid #008F2B',
        borderRadius: '20px 20px 0 0'
      },
      '& .MuiTab-root': {
        border: '1px solid #E4E4E4',
        borderRadius: '20px 20px 0 0'
      },
      '&>div>span': {
        backgroundColor: '#008F2B !important'
      }
    },
    tabRoot: {
      textTransform: 'capitalize',
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 400
    },
    primaryColor: {
      color: '#008F2B'
    },
    primaryBgColor: {
      backgroundColor: '#008F2B'
    },
    tabsHr: {
      margin: '0 70px 30px 70px',
      width: '100%'
    },
    cardContainer: {
      // width: '100%',
      maxWidth: 369,
      minWidth: 369,
      minHeight: 421,
      height: 'auto',
      borderRadius: 8,
      border: '1px solid #E4E4E4',
      padding: '20px 20px',
      margin: 'auto',
      [theme.breakpoints.down(768)]: {
        minWidth: 'unset',
        margin: '0 auto'
      }
    },
    cardHeader: {
      marginBottom: 10,
      '&>h3': {
        fontWeight: 700,
        fontSize: 26,
        lineHeight: '34px',
        color: '#000',
        [theme.breakpoints.down(768)]: {
          fontSize: 20,
          lineHeight: '28px'
        }
      }
    },
    cardBody: {
      padding: '20px 0',
      flex: 1,
      '&>div': {
        '&:not(:last-child)': {
          marginBottom: 15
        },
        '&>p': {
          color: '#000',
          fontSize: 16,
          lineHeight: '22px',
          '&:nth-child(1)': {
            fontWeight: 400
          },
          '&:nth-child(2)': {
            fontWeight: 700
          }
        }
      }
    },
    actionBtn: {
      marginBottom: 10,
      justifyContent: 'start',
      '&>a': {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      '& button': {
        backgroundColor: '#008F2B',
        borderRadius: 34,
        padding: '10px 20px',
        fontSize: 16,
        fontWeight: 500,
        color: '#fff',
        textTransform: 'capitalize !important',
        minWidth: 'max-content',
        maxWidth: 'max-content',
        width: '100%',
        textAlign: 'center',
        '&:hover': {
          backgroundColor: '#008F2B40',
          color: '#008F2B'
        },
        [theme.breakpoints.down(768)]: {
          padding: '6px 8px',
          fontSize: 14,
          lineHeight: '18px'
        },
        '&.MuiButton-root.Mui-disabled': {
          color: '#fff'
        }
      }
    },
    cardFooter: {
      marginTop: 15,
      '&>div': {
        '&>p': {
          fontWeight: 700,
          '&:nth-child(1)': {
            color: '#049E2C',
            fontSize: 31,
            lineHeight: '140%',
            fontWeight: 700
          },
          '&:nth-child(2)': {
            fontSize: 16,
            lineHeight: '140%',
            color: '#000',
            fontWeight: 700
          }
        }
      }
    },
    cardCount: {
      background: '#008F2B',
      width: 35,
      height: 30,
      borderRadius: 50,
      color: `${theme.palette.common.white} !important`,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardButton: {
      padding: '18px 0px'
    },
    divider: {
      background: '#008F2B',
      margin: '0 12px'
    }
  })
);

var temp = {
  ///fade: true,
  slidesToScroll: 1,
  dots: false,
  infinite: true,
  speed: 500,
  centerMode: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        dots: true,
        arrows: false
      }
    }
  ],
  className: 'subscription-slick variable-width'
};

var settings1 = {
  ///fade: true,
  slidesToScroll: 1,
  dots: false,
  speed: 500,
  centerMode: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        dots: true,
        arrows: false
      }
    }
  ],
  className: 'subscription-slick variable-width'
};

let settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        dots: true
      }
    }
  ]
};

function isUnlimited(value: any) {
  if (value === -1) {
    return 'Unlimited';
  }
  return value;
}
const Subscription = (props: any) => {
  const history = useHistory();
  const classes = useStyle();
  const sliderRef1: any = useRef();
  const sliderRef2: any = useRef();
  const sliderRef3: any = useRef();
  const {
    individual,
    individual: { organization_id },
    selectedSubscription,
    isLoggedIn
  } = useAuthStore();
  const authState = useAuthStore();
  let activeTab: any = getParams('tab');
  activeTab && localStorage.setItem('activeSubscriptionTab', activeTab || '0');
  const [activeSubscriptionTab, setActiveSubscriptionTab] = useState(1);
  const [subscriptionList, getSubscriptionList] = useState<any>([]);
  //const [subscriptionListObj, getSubscriptionListObj] = useState<any>([])
  const [currentPlan, getCurrentPlan] = useState<any>({});
  const [currentPlanList, setCurrentPlanList] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const { request } = useFetch(updatedUrl);
  const userData = sessionStorage.getItem('userData');
  // if (props.isLoggedIn && (userData === null)) {
  //   history.replace("/");
  // }
  const { token }: any = useParams();
  if (token) {
    localStorage.setItem('refer_token', token);
  }

  useEffect(() => {
    async function fetchData() {
      return await axiosInstance.post(
        'subscription/list',
        { organization_id: organization_id },
        formAxiosHeader(individual.access_token)
      );
    }
    const subscriptionListObj: any = fetchData();
    subscriptionListObj.then(function(subscriptionListObj: any) {
      if (subscriptionListObj) {
        if (subscriptionListObj.plans && subscriptionListObj.plans.length) {
          if (subscriptionListObj.currentPlan.length) {
            setCurrentPlanList(subscriptionListObj.currentPlan);
            getCurrentPlan(
              subscriptionListObj.currentPlan?.filter(
                (item: any) => item?.subscription_type === selectedSubscription
              )[0]
            );
            getSubscriptionList(subscriptionListObj.plans);
          } else {
            getSubscriptionList(subscriptionListObj.plans);
          }
          activeTab = localStorage.getItem('activeSubscriptionTab');
          if (activeTab) {
            setActiveSubscriptionTab(parseInt(activeTab || '0'));
          }
          if (!activeTab && selectedSubscription) {
            setActiveSubscriptionTab(Object.keys(subscriptionCategoriesLabel)?.indexOf(selectedSubscription));
          }
          setTimeout(() => {
            setPageLoading(true);
            localStorage.removeItem('activeSubscriptionTab');
          }, 4000);
        }
      }
    });
  }, [individual]);

  useEffect(() => {
    if (currentPlanList?.length > 0) {
      getCurrentPlan(currentPlanList?.filter((item: any) => item?.subscription_type === selectedSubscription)[0]);
    }
  }, [selectedSubscription]);

  useEffect(() => {
    if (currentPlanList?.length > 0) {
      const currentPlanData = currentPlanList?.filter(
        (item: any) => item?.subscription_type === Object.keys(subscriptionCategoriesLabel)[activeSubscriptionTab]
      )[0];
      if (currentPlanData) {
        getCurrentPlan(currentPlanData);
      }
    }
  }, [activeSubscriptionTab]);

  useEffect(() => {
    const email = getParams('email');
    let organization_name = getParams('organization_name');
    if (organization_name && organization_name !== '') {
      organization_name = organization_name.replace('+', ' ');
    }
    const organization_id = getParams('organization_id');
    if (organization_id) {
      const obj = {
        email,
        organization_name: organization_name,
        organization_id
      };
      sessionStorage.setItem('refererObj', JSON.stringify(obj));
    }
  }, [getParams]);

  if (!pageLoading) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Plans} />
      </div>
    );
  }

  let title = 'Transform your organization with HealthLoq Blockchain Platforms.';
  let subTitle = 'Find the right plan for your organization';

  if (typeof currentPlan.name === 'string') {
    title = 'Manage Your Subscription';
    if (authState?.subscriptionList?.includes(Object.keys(subscriptionCategoriesLabel)[activeSubscriptionTab])) {
      subTitle = `Current Subscription: <strong>${currentPlan.name} - (${currencyFormat(
        currentPlan.plan.amount,
        currentPlan.plan.currency
      )} per ${currentPlan.plan.interval})`;
    }
  }

  const SubscriptionCard: FunctionComponent<{
    item: any;
    type: string;
  }> = ({ item, type }) => {
    if (typeof item === 'undefined') {
      return <></>;
    }
    const { prouct_metadata, prouct_name, product_id, product_plan, active, trial_period_days, description } = item;
    const metaData = Object.keys(prouct_metadata);
    let priceList = sortList(product_plan);
    if (!active) {
      return <></>;
    }
    const currentPlanPriceIds = currentPlanList?.map((item: any) => item?.plan?.id);
    const isActivePlan =
      authState.subscriptionList.includes(type) &&
      item?.product_plan?.filter((a: any) => currentPlanPriceIds?.includes(a?.id))?.length === 1;
    return (
      <Box className={classes.cardContainer} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Box className={classes.cardHeader} display="flex" flexDirection={'column'}>
          <Typography variant="h3">{prouct_name}</Typography>
          {description && <Typography variant="body2">{description}</Typography>}
        </Box>
        <Box className={classes.cardBody} display="flex" flexDirection={'column'} flexGrow={1}>
          {metaData.length
            ? metaData.map((a: any, index) => {
                if (['is_active', 'num_daily_hashes'].includes(a)) {
                  return null;
                }
                return (
                  <Box key={index} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="body1">{labelMeta[a]}</Typography>
                    <Typography className={classes.cardCount} variant="body1">
                      {prouct_metadata[a] === -1 ? isUnlimited(prouct_metadata[a]) : abbrNum(prouct_metadata[a])}
                    </Typography>
                  </Box>
                );
              })
            : null}
        </Box>
        <Box display={'flex'} alignItems="center" justifyContent={'center'} className={classes.actionBtn}>
          {typeof currentPlan?.name === 'string' ? (
            authState.subscriptionList.includes(type) ? (
              isActivePlan ? (
                item?.product_plan?.length === 1 ? (
                  <Button disabled>YOUR CURRENT PLAN</Button>
                ) : (
                  <Link to={'/plans/upgrade/' + product_id}>
                    <Button>YOUR CURRENT PLAN</Button>
                  </Link>
                )
              ) : (
                <Link to={'/plans/upgrade/' + product_id}>
                  <Button>UPDATE PLAN</Button>
                </Link>
              )
            ) : (
              <Link to={'/plans/buyNew/' + product_id}>
                <Button>ADD PLAN</Button>
              </Link>
            )
          ) : isLoggedIn && selectedSubscription ? (
            <Link to={'/plans/buyNew/' + product_id}>
              <Button>ADD PLAN</Button>
            </Link>
          ) : (
            <Link to={{ pathname: '/registration', state: { item } }}>
              <Button className={classes.cardButton}>
                {userData === null ? 'SIGN UP NOW' : 'COMPLETE YOUR REGISTRATION'}
              </Button>
            </Link>
          )}
        </Box>
        <Divider style={{ background: '#008F2B', opacity: 0.2 }} />
        <Box className={classes.cardFooter} display="flex" justifyContent={'space-around'} alignItems="center">
          {priceList &&
            priceList.map((a: any, index: any) => {
              const { id, interval_count, interval, currency, amount_decimal, trial_period_days } = a;
              const isLastItem = index === priceList.length - 1;
              if (
                ![
                  'price_1MQtdUBMLJ4xo25JaLYhaW7Y',
                  'price_1MPrhsBMLJ4xo25JmVrtLJEB',
                  'price_1MPrh7BMLJ4xo25JR1gVO8IE'
                ].includes(id)
              ) {
                return (
                  <>
                    <Box
                      key={index}
                      display="flex"
                      flexDirection={'row'}
                      alignItems="center"
                      flexWrap={'wrap'}
                      justifyContent={'center'}
                    >
                      <Typography variant="body1">{currencyFormat(amount_decimal, currency)}</Typography>
                      <Typography variant="body2">
                        {monthFormat(interval_count, interval)}{' '}
                        {trial_period_days && ' + Free ' + trial_period_days + ' days'}
                      </Typography>
                    </Box>
                    {!isLastItem && <Divider orientation="vertical" flexItem className={classes.divider} />}
                  </>
                );
              }
            })}
        </Box>
      </Box>
    );
  };

  return (
    <div className="subscription-page">
      <div className="header-plans">
        <div className="title01-subscription">{title}</div>
        <div className="title02-subscription" dangerouslySetInnerHTML={{ __html: subTitle }}></div>
        <HeadingIcon />
      </div>
      <div className="subscription-carousel">
        {/* <div> */}
        <h2 className="subscription-main-heading">
          Choose Your Plan <HeadingIcon />
        </h2>
        {/* </div> */}
        <Tabs
          value={activeSubscriptionTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e: any, newValue: number) => setActiveSubscriptionTab(newValue)}
          className={classes.tabsRoot}
          variant="scrollable"
          scrollButtons="off"
        >
          {Object.entries(subscriptionCategoriesLabel)?.map((item: any, key: number) => {
            const isDisabled: boolean =
              subscriptionList?.filter((planItem: any) => planItem?.subscription_type === item[0])?.length === 0;
            return (
              <Tooltip arrow title={isDisabled ? 'Upcoming' : ''} key={key}>
                <Tab disabled={isDisabled} label={item[1]} className={classes.tabRoot} />
              </Tooltip>
            );
          })}
        </Tabs>
        {/* <Divider /> */}
        <div>
          {activeSubscriptionTab === 0 && (
            <Slider ref={sliderRef1} {...settings} className={'full-package-slider'}>
              {subscriptionList
                ?.filter((item: any) => item?.subscription_type === 'tracer')
                ?.sort((a: any, b: any) => a?.sort_order - b?.sort_order)
                ?.map((item: any, index: number) => {
                  return <SubscriptionCard item={item} key={index} type="tracer" />;
                })}
            </Slider>
          )}
          {activeSubscriptionTab === 1 && (
            <Slider ref={sliderRef2} {...settings} className={'full-package-slider'}>
              {subscriptionList
                ?.filter((item: any) => item?.subscription_type === 'publisher')
                ?.sort((a: any, b: any) => a?.sort_order - b?.sort_order)
                ?.map((item: any, index: number) => {
                  return <SubscriptionCard item={item} key={index} type="publisher" />;
                })}
            </Slider>
          )}
          {activeSubscriptionTab === 2 && (
            <Slider ref={sliderRef3} {...settings} className={'full-package-slider'}>
              {subscriptionList
                ?.filter((item: any) => item?.subscription_type === 'verifier')
                ?.sort((a: any, b: any) => a?.sort_order - b?.sort_order)
                ?.map((item: any, index: number) => {
                  return <SubscriptionCard item={item} key={index} type="verifier" />;
                })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export { Subscription };
