import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { useAuthStore } from '../../hooks/useAuthStore';
import { defaultBoxShadow, defaultGreyBackgroundColor } from '../../styles/default';
import { isEmptyString, validateEmail } from '../../utils';
import { useToastStore } from '../../hooks/useToastStore';
import { PrimaryButton } from 'common/Button/buttons';
import { IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const labelSpacingStyle: CSSProperties = { marginBottom: 20, display: 'block' };
const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  maxWidth: 600,
  width: '95%',
  padding: 20,
  backgroundColor: 'white'
};
const defaults = {
  email_address: '',
  password: ''
};
const Login: FunctionComponent = () => {
  const [credentials, setCredentials] = useState(defaults);
  const { login, isLoading, isError } = useAuthStore();
  const { isOpenToast } = useToastStore();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (): Promise<void> => {
    if (isEmptyString(credentials.email_address) || isEmptyString(credentials.password)) {
      isOpenToast('warn', 'Username or Password cannot be empty.');
    } else if (validateEmail(credentials.email_address)) {
      isOpenToast('warn', 'Please enter a valid email address.');
    } else {
      await login(credentials);
    }
  };

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor, height: 'calc(100vh - 70px)' }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <h2>Welcome to HealthLoq</h2>
        <p>Build, Edit And Publish Batches.</p>
        <br />

        <label style={labelSpacingStyle} className="col-md-12">
          Email
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, email_address: event.currentTarget.value })
            }
            value={credentials.email_address}
            type="email"
            autoFocus
            placeholder="e.g. Dwight.Schrute@healthloq.com"
          ></Input>
        </label>
        <label style={labelSpacingStyle} className="col-md-12">
          Password
          <div style={{ position: 'relative', width: '100%' }}>
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setCredentials({ ...credentials, password: event.currentTarget.value })
              }
              value={credentials.password}
              type={showPassword ? 'text' : 'password'}
            ></Input>
            <IconButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                fontSize: '14px'
              }}
            >
              {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </div>
        </label>
        {/* <p className="loginError">{isError && `Incorrect Username or Password`}</p> */}
        <PrimaryButton
          children={'Login'}
          icon="signin"
          secondaryButton={true}
          maxContent={true}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleLogin}
        />
        {/* <p>
          Need an account? <Link to="/register">Register</Link>
        </p> */}
        <p>
          Reset password? <Link to="/recover">Reset</Link>
        </p>
      </Layer>
    </Layer>
  );
};

export { Login };
