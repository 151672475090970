import React, { FunctionComponent, useEffect, useState } from 'react';
import unknown from '../../static/unknown.png';
import { isEmptyString } from '../../utils';

interface IImgProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  onClick?: () => void;
  required?: boolean;
  alt?: string;
  src: string;
}

const noSourceFoundImageAvatar = unknown;

const Img: FunctionComponent<IImgProps> = ({ alt = '', ...props }) => {
  const [SRC, setSRC] = useState(props.src);
  const handleError = (): void => {
    setSRC(noSourceFoundImageAvatar);
  };

  useEffect(() => {
    if (props.src !== SRC) {
      setSRC(props.src);
    }
  }, [props.src, SRC]);

  return !isEmptyString(SRC) ? <img alt={alt} {...props} src={SRC} onError={handleError} style={props.style} /> : null;
};

export { Img };
