import React, { CSSProperties, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Img, Layer } from '../common';
import logo from '../static/healthloq-logo.png';
import { defaultBoxShadow, defaultGreyBackgroundColor } from '../styles/default';
import { HeaderAuthenticated } from './HeaderAuthenticated';
import { clientURL } from './../config';
import "../styles/home.css";
import '../styles/header.css';
const headerStyle: CSSProperties = {
  padding: '10px',
  boxShadow: defaultBoxShadow,
  zIndex: 5,
  backgroundColor: defaultGreyBackgroundColor,
};
const Header: FunctionComponent = () => {
  return (
    <div className="main-header">
      <div className="container">
        <div className="navbar-root-div">
          <a href={clientURL} target="_blank" rel="noopener noreferrer" className="brand-logo">
            <Img alt="" height={50} width={50} src={logo} />
          </a>
          <HeaderAuthenticated />
        </div>
      </div>
    </div >
  );
};

export { Header };
