import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import UseAnimations from 'react-useanimations';
import { Img, Layer } from '../common';
import { ILayerState } from '../context/layerPaneContext';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { useLayerPaneStore } from '../hooks/useLayerPaneStore';
import {
  defaultGreyBackgroundColor,
  listItemImgStyle,
  listItemTitleStyle,
  listItemWrapperStyle,
  primaryColor
} from '../styles/default';
import { ListView } from './ListView';

const listItemStyle = (selectedLayerId: string, layer: ILayerState, isDraft: boolean): CSSProperties => {
  return {
    padding: 0,
    border: selectedLayerId === layer.id ? `1px solid ${primaryColor}` : '1px solid #ccc',
    backgroundColor: selectedLayerId === layer.id ? 'white' : '#f9f9f9',
    borderRadius: 0,
    pointerEvents: isDraft ? 'none' : 'initial'
  };
};

const LayerPane: FunctionComponent = () => {
  const { isDraft } = useCreateBatchStore();
  const { onSelectLayer, layers } = useLayerPaneStore();
  const { onChangeIndex } = useIntegrantPaneStore();
  const { currentIntegrant, integrantsData: { integrantsTree } } = useIntegrantGraphStore();
  const isDisabled = (item: ILayerState): boolean => isDraft && item.id !== currentIntegrant.id;

  const handleLayerSelect = (integrantId: string, parentId: string): void => {
    onChangeIndex(0);
    onSelectLayer(integrantId, parentId);
  };

  const renderLayers = (
    dataSource: ILayerState[],
    renderListItem: (layer: ILayerState) => ReactNode,
    renderDetailTemplate: (layer: ILayerState) => ReactNode
  ): React.ReactNode => {
    return (
      <ListView<ILayerState>
        listItemStyle={(layer: ILayerState): CSSProperties => listItemStyle(currentIntegrant.id, layer, isDraft)}
        renderListItem={renderListItem}
        detailTemplate={renderDetailTemplate}
        dataSource={integrantsTree.length ? integrantsTree : dataSource}
        isDisabled={(item: ILayerState): boolean => isDisabled(item)}
      />
    );
  };

  const renderLayer = (layer: any): React.ReactNode => {
    return (
      <Layer
        style={{ ...listItemWrapperStyle }}
        key={layer.id}
        align="left"
        onClick={(): void => handleLayerSelect(layer.id, layer.parent_id)}
        className="renderLayer"
      >
        {(layer.image_thumb_url !== '' && typeof layer.image_thumb_url !== 'undefined') ? (
          <Img alt={layer.title} style={listItemImgStyle} height={26} src={layer.image_thumb_url} />
        ) : null}
        {isDraft && layer.id === currentIntegrant.id ? (
          <UseAnimations play animationKey="activity" size={26} style={{ padding: 0, color: primaryColor }} />
        ) : (
          <Layer direction="column" style={{ cursor: 'pointer' }}>
            <h5 style={listItemTitleStyle}>{layer.title}</h5>
          </Layer>
        )}
      </Layer>
    );
  };

  const renderDetailTemplate = (item: ILayerState): ReactNode => {
    const detailTemplateStyle: CSSProperties = {
      backgroundColor: 'transparent',
      paddingLeft: 15,
      marginLeft: 0,
      marginTop: 4,
      marginBottom: 4,
    };
    return item.children.map((items: any) => {
      return (
        <div className='renderDetailTemplate' style={detailTemplateStyle} key={items.id}>
          {renderLayer(items)}
          {renderDetailTemplate(items)}
        </div>
      );
    });
  };

  return <div className='layerpane'>{renderLayers(layers, renderLayer, renderDetailTemplate)}</div>;
};

export { LayerPane };
