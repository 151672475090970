import React, { FunctionComponent } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { Router } from 'react-router-dom';
import { Layer } from '../common/Layer';
import { Header } from '../components/Header';
import { Toast } from '../components/Toast';
import { AppProvider } from '../context/appContext';
import { AuthProvider } from '../context/authContext';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();

const View: FunctionComponent = ({ children }) => {
  return (
    <ModalProvider>
      <Layer className="view-layer" fill direction="column" scrollY>
        <Header />
        <Layer className="view-child-layer">{children}</Layer>
      </Layer>
    </ModalProvider>
  );
};

const Layout: FunctionComponent = ({ children }) => {
  return (
    <Router history={customHistory}>
      <Toast />
      <AppProvider>
        <AuthProvider>
          <View>{children}</View>
        </AuthProvider>
      </AppProvider>
    </Router>
  );
};

export { Layout };
