import React, { FunctionComponent } from 'react';
import { IntegrantTypesWrapper } from '../../wrappers/IntegrantTypesWrapper';
import { CreateIntegrantTypeProvider } from '../../../src/context/createIntegrantTypeContext';

const Wrapper: FunctionComponent = ({ children }) => {
  return (
    <IntegrantTypesWrapper>
      <CreateIntegrantTypeProvider>
        <>{children}</>
      </CreateIntegrantTypeProvider>
    </IntegrantTypesWrapper>
  );
};

export { Wrapper };
