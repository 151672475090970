import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { isUndefined } from 'util';
import { Layer } from '../common';
import { defaultBorderStyle, defaultInputStyle } from '../styles/default';
import { LoadingTitle } from '../types/common';
import { isNull } from '../utils';
import { Loading } from './Loading';
import { IntegrantAlert } from './IntegrantAlert';

interface IListViewProps<T extends { id: string }> {
  isLoading?: boolean;
  emptyTitle?: string;
  dataSource: any;
  renderListItem: (item: T) => ReactNode;
  onListItemSelect?: (item: T) => void;
  listItemStyle?: (item: T) => CSSProperties;
  detailTemplate?: (item: T) => ReactNode;
  isDisabled?: (item: T) => boolean;
  style?: CSSProperties;
  searchPlaceholder?: string;
  filter?: (item: T, searchValue: string) => boolean;
  scrollY?: boolean;
  isLiveSearch?: boolean;
  liveSearch?: (searchValue: string) => void;
  helperText?: string;
}

const listViewWrapperStyle: CSSProperties = { width: '100%' };

const ListView = <T extends { id: string }>(props: IListViewProps<T> & { children?: ReactNode }): JSX.Element => {
  const {
    renderListItem,
    dataSource,
    onListItemSelect,
    isLoading,
    detailTemplate,
    isDisabled,
    style,
    searchPlaceholder,
    filter,
    scrollY = false,
    emptyTitle,
    liveSearch,
    isLiveSearch,
    helperText = ''
  } = props;
  const [list, setFilteredList] = useState(dataSource);
  const [searchValue, setSearchValue] = useState('');

  const listItemStyle = (): CSSProperties => {
    return {
      borderRadius: '3px',
      padding: '20px 10px'
    };
  };

  const isDisabledStyle = (item: T): CSSProperties => {
    const isItemDisabled = !isUndefined(isDisabled) && isDisabled(item);
    return {
      opacity: isItemDisabled ? '0.4' : 1,
      pointerEvents: isItemDisabled ? 'none' : 'initial'
    };
  };

  const createListItem = (item: T): React.ReactNode => {
    const createListItemStyle = {
      width: '100%',
      ...listItemStyle(),
      ...(!isUndefined(props.listItemStyle) ? props.listItemStyle(item) : {})
    };

    const renderDetailTemplate = (item: T): ReactNode => {
      if (!isUndefined(detailTemplate)) {
        return detailTemplate(item);
      }
    };
    return (
      <div className="renderDetailTemplate" style={{ width: '100%' }} key={item.id}>
        <div
          onClick={!isUndefined(onListItemSelect) ? (): void => onListItemSelect(item) : (): null => null}
          style={{
            ...isDisabledStyle(item),
            ...createListItemStyle
          }}
          className="createListItemStyle-child"
        >
          {renderListItem(item)}
        </div>
        {renderDetailTemplate(item)}
      </div>
    );
  };

  const onFilterList = (value: string): void => {
    if (typeof searchPlaceholder !== 'undefined') {
      setSearchValue(value);
      if (isLiveSearch) {
        if (!isUndefined(liveSearch)) {
          liveSearch(value);
        }
      } else if (typeof filter !== 'undefined') {
        setFilteredList(dataSource.filter((i: T) => filter(i, searchValue)));
      }
    }
  };

  useEffect(() => {
    if (dataSource.length !== list.length && searchValue === '') {
      setFilteredList(dataSource);
    }
  }, [dataSource, list.length, searchValue]);

  const isPopulated = dataSource.length > 0;

  let renderList = [];

  if (isLiveSearch) {
    renderList = dataSource.map(createListItem);
  } else {
    renderList = searchValue !== '' ? list.map(createListItem) : dataSource.map(createListItem);
  }

  return (
    <Layer className="listView" fill={scrollY} direction="column" style={{ ...style, ...listViewWrapperStyle }}>
      {typeof searchPlaceholder !== 'undefined' ? (
        <input
          autoFocus
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => onFilterList(event.currentTarget.value)}
          style={{ ...defaultInputStyle, padding: 10, width: '100%', marginBottom: 10, ...defaultBorderStyle }}
          placeholder={searchPlaceholder}
        />
      ) : null}
      {Boolean(helperText) && <p className="listView-helper-text">{helperText}</p>}
      {isPopulated ? (
        <Layer className="renderlist-div" direction="column" fill={scrollY} scrollY={scrollY}>
          {isLoading === true || isNull(dataSource) ? <Loading title={LoadingTitle.Loading} /> : renderList}
        </Layer>
      ) : typeof emptyTitle !== 'undefined' ? (
        <IntegrantAlert title={emptyTitle} />
      ) : searchValue !== '' ? (
        <IntegrantAlert title="No Record Found" />
      ) : null}
    </Layer>
  );
};

export { ListView };
