import React, { FunctionComponent } from 'react';
import { Button } from '../common';
import { defaultButtonStyle, largeButtonStyle } from '../styles/default';

const IntegrantCancelButton: FunctionComponent<{ isVisible: boolean; onCancel: () => void; isLoading?: boolean, btnLable?:string }> = ({
  onCancel,
  isVisible,
  isLoading,
  btnLable
}) => {
  const buttonStyle = defaultButtonStyle;

  return isVisible ? (
    <>
      <Button
        disabled={isLoading}
        onClick={onCancel}
        center
        style={{ ...buttonStyle, ...largeButtonStyle, width: '100%' }}
      >
        {btnLable ? btnLable : "Cancel"}
      </Button>
    </>
  ) : null;
};

export { IntegrantCancelButton };
