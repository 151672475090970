import React,{FunctionComponent, useState} from 'react';
import { makeStyles,Theme,Typography,Grid,Paper,Tabs,Tab , createStyles} from '@material-ui/core';
import { PrimaryButton } from 'common';
import clsx from 'clsx';
import { IntegrantTypesWrapper } from 'wrappers/IntegrantTypesWrapper';
import {useHistory,useParams} from 'react-router-dom';
import SurveyList from './SurveyList';
import SurveyDetail from './SurveyDetail';
import SurveySubmission from './SurveySubmission';
import { IEmptyObjectDTO } from '../../../lib/common';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    surveyContainer:{
        width:"100%",
        height: '100%',
        maxWidth: 900,
        margin: '30px auto',
        [theme.breakpoints.down("sm")]:{
            '&>div':{
                '&:nth-child(1)':{
                    boxSizing: 'border-box',
                    padding: '0px 20px',
                },
                '&:nth-child(2)':{
                    '&>div':{
                        '&:nth-child(2)':{
                            boxSizing: 'border-box',
                            padding: '0px 20px',   
                        }
                    }
                }
            }
        }
    },
    createSurveyBtnDiv:{
        marginBottom: 20
    },
    surveyHeaderLinks:{
        width:'100%',
        backgroundColor:"#fff",
        boxShadow: "0px 10px 30px 0px #0000001A",
        boxSizing:"border-box",
        padding:"15px 30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        marginBottom:45,
        overflow: 'auto',
        '&>p':{
            textTransform:"capitalize",
            fontSize:"18px",
            fontWeight:400,
            lineHeight:"21px",
            color:"#AAA",
            cursor:"pointer",
        }
    },
    surveyListHeader:{
        '&>p':{
            '&:not(:first-child)':{
                marginLeft: 50
            }
        }
    },
    activeLink:{
        color:"#008F2B !important"
    },
    hidden:{
        display:'none'
    },
    tabs:{
        '&>div':{
            '&>span':{
                backgroundColor: '#008F2B'
            },
            '&>div':{
                '&>button':{
                    textTransform:'capitalize',
                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                    maxWidth: '50%',
                    width: '50%'
                }
            }
        }
    },
    activeTab:{
        color:"#008F2B !important",
        border: '1px solid rgba(0,0,0,0.3)',
        borderBottom: 'none !important'
    },
    tabsRoot:{
        marginBottom: 30,
        width:'100%'
    },
}))

interface IParamsObjDTO extends IEmptyObjectDTO{
    integrant_type_id: string;
    activeLink: number;
}

const SurveyContainer: FunctionComponent = () => {
    const classes = useStyle();
    const history = useHistory();
    const {integrantTypeId} = useParams();
    const [activeLink, setActiveLink] = useState(0);
    const handleViewSurveyDetail = (id: string) => {
        history.push(`/survey/${id}`)
    }
    let paramsObj: IParamsObjDTO = {
        integrant_type_id: integrantTypeId,
        activeLink
    }
    return <IntegrantTypesWrapper>
        <div className={classes.surveyContainer}>
            <Grid container alignItems="center" justifyContent={integrantTypeId ? 'space-between' :'flex-end'} className={classes.createSurveyBtnDiv}>
                {integrantTypeId ? <PrimaryButton children="Back" secondaryButton={true} maxContent={true} icon="back" onClick={()=>history.push('/survey')} />  : null}
                <PrimaryButton icon="add" children={"Create Audience"} secondaryButton={true} maxContent={true} onClick={()=>history.push(`/create-audience/0`)} /> 
            </Grid>
            <Grid container>
                {integrantTypeId ? <>
                    <div className={classes.tabsRoot}>
                        <Tabs
                            value={activeLink}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e: any,num: number) => setActiveLink(num)}
                            className={classes.tabs}
                            classes={{indicator: classes.hidden}}
                        >
                            <Tab className={clsx(activeLink === 0 && classes.activeTab)} value={0} label="Audience Detail" />
                            <Tab className={clsx(activeLink === 1 && classes.activeTab)} value={1} label="Audience Submission" />
                        </Tabs>
                </div>
                {activeLink === 0  ? <SurveyDetail {...paramsObj} /> : <SurveySubmission {...paramsObj} />}
                </> : <>
                    <div className={clsx(classes.surveyHeaderLinks,classes.surveyListHeader)}>
                        <Typography  variant="body1" className={classes.activeLink}>Survey</Typography>
                    </div>
                    <SurveyList {...{handleViewSurveyDetail}} />
                </>}
            </Grid>
        </div>
    </IntegrantTypesWrapper>
}

export default SurveyContainer