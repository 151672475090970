import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Toolbar,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core';
import { MuiSelect } from '../../common/formComponents';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import MuiCircularProgress from 'common/MuiCircularProgress';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    questionAnswersContainer: {
      flexDirection: 'column'
    },
    surveyForm: {
      display: 'flex',
      flexDirection: 'column',
      '&>div': {
        minHeight: 48,
        padding: 10,
        boxSizing: 'border-box',
        background: '#fff',
        border: '1px solid #DEDEDE',
        '&:nth-child(even)': {
          background: '#FBFBFB'
        }
      }
    },
    questionDetail: {
      marginTop: 20,
      width: '100%',
      '&>div': {
        width: '100%'
      }
    },
    questionAnswersHeading: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 700,
      color: '#000'
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700
        }
      }
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    }
  })
);

interface IQuestionsAnswersProps {
  integrant_type_id: string;
  handleSurveyChange: (id: string) => void;
}

let questionListFlag = false;

export const QuestionsAnswers: FunctionComponent<IQuestionsAnswersProps> = ({
  integrant_type_id,
  handleSurveyChange
}) => {
  const classes = useStyle();
  const { surveyList, initGetSurveyList, surveyQuestionsList, initGetSurveyQuestionsList } = useIntegrantTypeStore();
  useEffect(() => {
    initGetSurveyList();
    questionListFlag = true;
  }, []);
  useEffect(() => {
    if (surveyList.status === '1' && !surveyList.loading && questionListFlag) {
      if (integrant_type_id) {
        initGetSurveyQuestionsList(integrant_type_id);
        questionListFlag = false;
      }
    }
  }, [surveyList]);
  return (
    <Grid container className={classes.questionAnswersContainer}>
      <Grid item className={classes.surveyForm}>
        <MuiSelect
          value={integrant_type_id}
          onChange={e => {
            handleSurveyChange(e.target.value);
            initGetSurveyQuestionsList(e.target.value);
          }}
          label={'Select Survey Template'}
          options={surveyList?.data?.map((item: any) => {
            return {
              value: item.id,
              label: item.title
            };
          })}
          loading={surveyList.loading && !surveyList.data}
        />
      </Grid>
      {integrant_type_id && (
        <Grid item className={classes.questionDetail}>
          <Paper>
            <Toolbar>
              <Typography variant="h6" component="div" className={classes.questionAnswersHeading}>
                Survey Questions Detail
              </Typography>
            </Toolbar>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {surveyQuestionsList.loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Grid container alignItems="center" justifyContent="center">
                          Loading...&nbsp;
                          <MuiCircularProgress />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : surveyQuestionsList?.data?.length ? (
                    surveyQuestionsList?.data?.map((item: any, key: number) => {
                      return (
                        <TableRow key={key}>
                          <TableCell align="left">{item.question}</TableCell>
                          <TableCell align="right">{item.input_type}</TableCell>
                          <TableCell align="right">{item.options.length ? item.options.join(',') : '-'}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        You have not created any survey questions yet.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionsAnswers;
