import { IIntegrantTypeDTO } from '../../../lib/integrantType';
import { useCallback, useState } from 'react';
import useFetch from 'use-http';
import { url, formDataConfigType } from '../../config';
import { useOrganizationStore } from '../useOrganizationStore';
import { useAuthStore } from '../useAuthStore';

interface IIntegrantsService {
  suggestedIntegrants: IIntegrantTypeDTO[];
  getSuggestedIntegrants: () => Promise<void>;
}

const useSuggestIntegrantsService = (searchValue?: string): IIntegrantsService => {
  const { selectedOrganization } = useOrganizationStore();
  const { individual } = useAuthStore();
  const [suggestedIntegrants, setIntegrants] = useState<IIntegrantTypeDTO[]>([]);

  const formData = {
    offset: 0,
    limit: 10,
    searchText: searchValue ? searchValue : '',
    organization_id: selectedOrganization.id
  };

  // const [request] = useFetch<IIntegrantTypeDTO[]>(`${url}integrant-types?organization_id=${selectedOrganization.id}`);

  const resetStore = (): void => {
    setIntegrants([]);
  };

  const getSuggestedIntegrants: IIntegrantsService['getSuggestedIntegrants'] = useCallback(async () => {
    resetStore();
    const initialIntegrants: IIntegrantTypeDTO[] = await fetch(
      `${url}integrant-type/search`,
      formDataConfigType(JSON.stringify(formData), individual.access_token)
    ).then(async response => await response.json());
    setIntegrants(initialIntegrants);
  }, []);

  return {
    suggestedIntegrants,
    getSuggestedIntegrants
  };
};

export { useSuggestIntegrantsService };
