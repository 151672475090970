import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import UseAnimations from 'react-useanimations';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { isClientId, titlesTooltips } from '../config';
import {
  defaultBorderStyle,
  defaultInputStyle,
  defaultModalStyle,
  formButtonStyle,
  loadingIconStyle,
  primaryButtonStyle,
} from '../styles/default';
import { useToastStore } from '../hooks/useToastStore';
import { PrimaryButton } from 'common/Button/buttons';
import {Grid} from '@material-ui/core';

const batchButtonStyle: CSSProperties = { ...formButtonStyle, ...primaryButtonStyle };

const CurrentBatch: FunctionComponent = () => {
  const { isRemoveLoading, currentBatch, checkCurrentBatch } = useIntegrantPaneStore();
  const { isDraft } = useCreateBatchStore();
  const { currentIntegrant, currentIntegrant: { id, integrantsType } } = useIntegrantGraphStore();
  let isRemove = false
  if (typeof integrantsType === 'object' && integrantsType.current_integrant_id && integrantsType.current_integrant_id === id) {
    isRemove = true
  }

  const [isBatchLoading, setBatchLoading] = useState(false);
  const [external_id_slug, setExternalIdSlug] = useState('');
  const [external_id, setExternalId] = useState('');
  const { isOpenToast } = useToastStore();

  const handleOnBatch = async (): Promise<void> => {
    setBatchLoading(true);
    const checkbatchdIntegrant: any = await checkCurrentBatch(currentIntegrant, external_id);
    if(checkbatchdIntegrant){
      isOpenToast('isError', "Static Batch Number Already Exists!");
      setBatchLoading(false);
    } else {
      const batchdIntegrant = await currentBatch(currentIntegrant, external_id_slug);
      if (typeof batchdIntegrant === 'string') {
        window.location.reload();
        setBatchLoading(false);
      }
    }
    // if (batchdIntegrant && integrantInfo.id !== '') {
    //   setBatchd(batchdIntegrant);
    //   setExternalIdSlug('');
    // }
  };

  const handleAfterClose = (): void => {
    setExternalIdSlug('');
    setBatchLoading(false);
  };

  const onkeypress = function (keyCode: number): boolean {
    const key = keyCode;
    return key !== 32 ? true : false;
  };


  const batchCheck = async () => {
    const { integrantsType: { external_id } } = currentIntegrant
    if (external_id !== '' && !isRemove) {
      setBatchLoading(true);
      const batchdIntegrant = await currentBatch(currentIntegrant, external_id);
      if (typeof batchdIntegrant === 'string') {
        window.location.reload();
        setBatchLoading(false);
      }
    } else {
      showModal()
    }
  }

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} onAfterClose={handleAfterClose} style={defaultModalStyle} isOpen>
        <div style={{ minWidth: 700 }}>
          <strong style={{ marginBottom: 20, display: 'block' }}>
            Set this batch to the static QR Code.
          </strong>
          <small>
            Please enter a new static batch No.:
          </small>
          <div style={{ marginTop: 20, marginBottom: 20, display: 'block' }}>
            <input
              pattern="[^\s]+"
              onKeyDownCapture={(e: React.KeyboardEvent<HTMLInputElement>): boolean => onkeypress(e.keyCode)}
              autoFocus
              value={external_id_slug}
              onChange={(evt): void => {
                setExternalIdSlug(evt.currentTarget.value.split(' ').join(''))
                setExternalId(evt.currentTarget.value)
              }}
              style={{ ...defaultInputStyle, padding: 10, width: '100%', ...defaultBorderStyle }}
              placeholder="Static Batch No."
            />
          </div>
          <div style={{ display: 'flex' }}>
            {external_id_slug !== '' && 
            <PrimaryButton loading={isBatchLoading} style={{marginRight:"10px"}} icon='save' onClick={handleOnBatch} secondaryButton={true}  children={'Save'} maxContent={true} />
            }
            <PrimaryButton disabled={isBatchLoading} icon='close' onClick={hideModal} children={'Cancel'} maxContent={true}  />
          </div>
        </div>
      </ReactModal >
    ),
    [isBatchLoading, external_id_slug]
  );

  return !isDraft ? (
    <Grid style={{marginBottom:"10px"}}>
            <PrimaryButton disabled={isRemove}
        onClick={batchCheck} dataTip={titlesTooltips.batch_tip} icon='cloudDownload' children={isRemove ? "Current Batch" : "Set this batch to the static QR Code"} />
    </Grid>
  ) : null
};

export { CurrentBatch };
