import { IFacetDTO } from '../../lib/facet';
import React, { FunctionComponent, ReactNode } from 'react';
import { titlesTooltips } from '../config';
import { EditListItem } from './EditListItem';

interface IFacetListItemProps {
  facet: IFacetDTO;
  index: string | number;
  onUpdateFacet: (facet: IFacetDTO) => Promise<void>;
  onRemoveFacet: (facetID: string, isClientFacet: boolean) => Promise<void>;
  onDeleteFacet: (facetID: string) => Promise<void>;
  type?: string;
}

const FacetListItem: FunctionComponent<IFacetListItemProps> = ({
  facet,
  onRemoveFacet,
  onUpdateFacet,
  onDeleteFacet,
  type = 'facet'
}) => {
  const handleSave = async (currentFacet: IFacetDTO): Promise<void> => {
    await onUpdateFacet(currentFacet);
  };

  const handleRemove = async (currentItemId: string): Promise<void> => {
    await onRemoveFacet(currentItemId, true);
  };

  const handleDelete = async (currentItemId: string): Promise<void> => {
    await onDeleteFacet(currentItemId);
  };

  const handleCancel = async (currentFacet: IFacetDTO): Promise<void> => {
    await onRemoveFacet(currentFacet.id, false);
  };

  const renderViewOnlyItem = (): ReactNode => (
    <div>
      <div>
        <small data-tip={titlesTooltips.inherited_facet}>{facet.title}</small>
      </div>
      <div>
        <small dangerouslySetInnerHTML={{ __html: facet.description }} />
      </div>
      <div>
        <small>Group: {facet?.group_id ? facet?.group_id : 'None'}</small>
      </div>
    </div>
  );

  return (
    <EditListItem<IFacetDTO>
      type={type}
      item={facet}
      handleRemove={handleRemove}
      handleSave={handleSave}
      handleCancel={handleCancel}
      renderTopForm={(): ReactNode => <></>}
      renderViewOnlyItem={renderViewOnlyItem}
      isSaveDisabled={false}
      handleDeleteTrait={handleDelete}
    />
  );
};

export { FacetListItem };
