import { IIntegrantDTO } from '../../lib/integrant';
import { IMutationDTO } from '../../lib/mutation';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Layer } from '../common';
import { titles } from '../config';
import { useIntegrantsService } from '../hooks/api/useIntegrantsService';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { useSuggestIntegrantCreatedAlreadyStore } from '../hooks/useSuggestIntegrantCreatedAlreadyStore';
import { defaultButtonSmallStyle, defaultButtonStyle, dropdownWrapperStyle, listItemStyle } from '../styles/default';
import { IntegrantKind } from '../types/common';
import { ListView } from './ListView';
import { ListItem } from './ListItem';
import { PrimaryButton } from 'common/Button/buttons';

const SuggestIntegrantCreatedAlready: FunctionComponent = () => {
  const { currentIntegrant, data, setData } = useIntegrantGraphStore();
  const { isOpen, onToggleMenu, onSelectIntegrant, selectedIntegrant } = useSuggestIntegrantCreatedAlreadyStore();
  const { resetTabs } = useIntegrantPaneStore();
  const { postMutation } = useIntegrantsService();

  const [slideIndex, setSlideIndex] = useState(0);

  const onGotoSlideIndex = (n: number): void => {
    setSlideIndex(n);
  };

  const createIntegrantList = (integrant: IIntegrantDTO): React.ReactNode => {
    return <ListItem title={integrant.title} photoURL={integrant.image_thumb_url} external_id_slug={integrant.external_id_slug} />;
  };

  const handleLink = (isReversed = false): void => {
    const parentId = isReversed ? selectedIntegrant.id : currentIntegrant.id;
    const childId = isReversed ? currentIntegrant.id : selectedIntegrant.id;
    postMutation(parentId, childId).then((mutation: IMutationDTO) => {
      setData({
        ...data,
        edges: [
          ...data.edges,
          { source: parentId, target: childId, edge_id: mutation.id, edge_type: IntegrantKind.Mutation }
        ]
      });
    });
  };

  return isOpen ? (
    <>
      {slideIndex === 0 ? (
        <ListView<IIntegrantDTO>
          onListItemSelect={(integrant: IIntegrantDTO): void => {
            resetTabs();
            onToggleMenu(false);
            onGotoSlideIndex(1);
            onSelectIntegrant(integrant);
          }}
          dataSource={data.nodes.filter(f => currentIntegrant.id !== f.id)}
          renderListItem={createIntegrantList}
          style={dropdownWrapperStyle}
          listItemStyle={(): CSSProperties => listItemStyle}
        />
      ) : (
        <Layer direction="column" style={{ ...dropdownWrapperStyle, padding: 10 }}>
          <p>
            <small>
              Is {currentIntegrant.title} a direct <strong>{titles.directParent}</strong> or{' '}
              <strong>{titles.directChild}</strong> of {selectedIntegrant.title}?
            </small>
          </p>
          <button
            style={{ ...defaultButtonSmallStyle, marginTop: 10, marginBottom: 10 }}
            onClick={(): void => handleLink()}
          >
            {titles.directParent}
          </button>
          <button style={{ ...defaultButtonSmallStyle, marginBottom: 10 }} onClick={(): void => handleLink(true)}>
            {titles.directChild}
          </button>
          <PrimaryButton icon='close' onClick={onToggleMenu} children={'Cancel'} maxContent={true}  />
        </Layer>
      )}
    </>
  ) : (
    <div />
  );
};

export { SuggestIntegrantCreatedAlready };
