import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import {IAPIResponseDTO} from '../../lib/common'


export interface IOrganizationExhibitProviderState {
  createData: IAPIResponseDTO,
  updateData: IAPIResponseDTO,
  deleteData: IAPIResponseDTO,
  getAllData: IAPIResponseDTO,
  getOneData: IAPIResponseDTO
}

export const defaults = { 
    createData:{
      status:"",
      message:"",
      loading: false,
      data: null
    },
    updateData:{
      status:"",
      message:"",
      loading: false,
      data: null
    },
    deleteData:{
      status:"",
      message:"",
      loading: false,
      data: null
    },
    getAllData:{
      status:"",
      message:"",
      loading: false,
      data: null
    },
    getOneData:{
      status:"",
      message:"",
      loading: false,
      data: null
    }
};

const OrganizationExhibitContext = React.createContext<CreateContext<IOrganizationExhibitProviderState>>([
  defaults,
  (): IOrganizationExhibitProviderState => defaults
]);

const OrganizationExhibitProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<IOrganizationExhibitProviderState>(defaults);
  return <OrganizationExhibitContext.Provider value={[state, setState]}>{props.children}</OrganizationExhibitContext.Provider>;
};

export { OrganizationExhibitContext, OrganizationExhibitProvider };
