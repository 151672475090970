import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { IFacetDTO } from '../../lib/facet';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { FacetsList } from './FacetsList';
import { useIntegrantTypeStore } from 'hooks/useIntegrantTypeStore';
import { Upload } from 'tus-js-client';
import { fileInputStyle, iconStyle, labelStyle } from 'styles/default';
import { acceptedVideoFileRegex, acceptedVideoFileTypes } from 'config';
import { Box, Divider, FormHelperText, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { GoFile } from 'react-icons/go';
import { Icon, Input, PrimaryButton } from 'common';
import { validURL } from 'utils';
import PrimaryVideoPlayer from 'common/VideoPlayers/PrimaryVideoPlayer';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import LinearProgress from '@material-ui/core/LinearProgress';

interface IIntegrantTypeFacetsProps {
  isVisible: boolean;
  ingredient?: any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      '&>option[value=""][disabled]': {
        display: 'none'
      },
      '&>option': {
        color: '#000'
      }
    },
    selectPlaceholderStyle: {
      color: '#666'
    },
    selectErrorStyle: {
      border: '1px solid red'
    },
    productImage: {
      height: 'max-content',
      maxHeight: 200,
      width: '100%',
      '&>img': {
        width: '100%',
        height: 'max-content',
        maxHeight: 200,
        objectFit: 'contain'
      }
    },
    replaceImageLabel: {
      marginTop: 10
    },
    videoPreview: {
      width: '100%',
      marginTop: 15,
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    orDivider: {
      margin: '10px 0',
      '&>span': {
        padding: '0 10px'
      },
      '&>hr': {
        width: '40%'
      }
    },
    linearProgressBarColorPrimary: {
      backgroundColor: '#008F2B'
    }
  })
);

const IntegrantTypeFacets: FunctionComponent<IIntegrantTypeFacetsProps> = ({
  isVisible,
  ingredient = null
}): JSX.Element => {
  const classes = useStyle();
  const history = useHistory();
  const {
    onAddFacet,
    facets,
    onRemoveFacet,
    setBunnyVideoFile,
    bunny_video_file,
    integrantType,
    onUpdateDetails,
    createOrUpdateIntegrantTypeData,
    onClearData,
    isDeleteBunnyVideo,
    onRemoveBunnyVideo
  } = useCreateIntegrantTypeStore();
  const { initGetBunnyVideoUploadList, getBunnyVideoUploadInfoData } = useIntegrantTypeStore();
  let bunnyVideoInputId = 'bunny-video';
  const [bunnyVideoUploadError, setBunnyVideoUploadError] = useState('');
  const [bunnyVideoUploadProcess, setBunnyVideoUploadProcess] = useState<any>(null);
  const [videoLinkError, setVideoLinkError] = useState(false);

  const handleBunnyVideoUploadFiles = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      if (acceptedVideoFileRegex.test(event?.target?.files?.[0]?.name))
        setBunnyVideoFile(event.target.files[0], ingredient);
      else {
        setBunnyVideoUploadError('Invalid video extension. Valid extensions are .mp4, .mov, .mkv, .mov, .avi, .webm');
      }
    }
  };

  useEffect(() => {
    if (createOrUpdateIntegrantTypeData && Object.keys(createOrUpdateIntegrantTypeData).length) {
      if (integrantType?.bunny_video_title && bunny_video_file) {
        initGetBunnyVideoUploadList({
          title: integrantType?.bunny_video_title,
          integrant_type_id: createOrUpdateIntegrantTypeData?.id
        });
      } else {
        console.log('useEffect createOrUpdateIntegrantTypeData');
        onClearData();
        history.push('/');
      }
    }
  }, [createOrUpdateIntegrantTypeData]);

  useEffect(() => {
    if (!getBunnyVideoUploadInfoData?.loading && getBunnyVideoUploadInfoData?.data && bunny_video_file) {
      let upload = new Upload(bunny_video_file, {
        endpoint: 'https://video.bunnycdn.com/tusupload',
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: getBunnyVideoUploadInfoData?.data?.AuthorizationSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: getBunnyVideoUploadInfoData?.data?.AuthorizationExpire, // Expiration time as in the signature,
          VideoId: getBunnyVideoUploadInfoData?.data?.VideoId, // The guid of a previously created video object through the Create Video API call
          LibraryId: getBunnyVideoUploadInfoData?.data?.LibraryId
        },
        metadata: {
          filetype: bunny_video_file.type,
          title: integrantType?.bunny_video_title,
          collection: getBunnyVideoUploadInfoData?.data?.collection
        },
        onError: function(error: any) {
          setBunnyVideoUploadError(error?.message);
          toast.error(
            'Something went wrong! Video not uploaded. please try after sometime. you are able to upload video on edit product.'
          );
          console.log('useEffect getBunnyVideoUploadInfoData onError');
          onClearData();
          history.push('/');
        },
        onProgress: function(bytesUploaded: number, bytesTotal: number) {
          setBunnyVideoUploadProcess((bytesUploaded / bytesTotal) * 100);
        },
        onSuccess: function() {
          console.log('useEffect getBunnyVideoUploadInfoData onSuccess');
          setBunnyVideoUploadProcess(null);
          console.log('upload', upload);
          onClearData();
          history.push('/');
        }
      });
      upload.start();
    }
  }, [getBunnyVideoUploadInfoData]);
  const renderBunnyVideoFileUpload = (): ReactNode => (
    <>
      <input
        style={{ ...fileInputStyle }}
        accept={acceptedVideoFileTypes}
        onChange={handleBunnyVideoUploadFiles}
        type="file"
        id={bunnyVideoInputId}
        onClick={(e: any) => (e.target.value = null)}
      />
      <Box
        style={{ margin: '10px 0 10px' }}
        onClick={() => {
          if (!integrantType?.bunny_video_title && !integrantType.youtube_video_url) {
            setBunnyVideoUploadError('Video title is required field');
          }
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent="space-between"
      >
        <label
          htmlFor={bunnyVideoInputId}
          className={clsx('integrant-type-image-label')}
          style={{
            pointerEvents: !integrantType?.bunny_video_title || integrantType.youtube_video_url ? 'none' : 'unset'
          }}
        >
          <div>
            <GoFile style={iconStyle}></GoFile> {bunny_video_file !== null ? bunny_video_file.name : `Choose a file`}
          </div>
        </label>
        {Boolean(bunny_video_file) && (
          <Icon icon="close" style={{ marginRight: 10 }} onClick={() => setBunnyVideoFile(null)} />
        )}
      </Box>
      {bunnyVideoUploadProcess !== null && (
        <Box width={'100%'} marginY={1}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={bunnyVideoUploadProcess}
                classes={{ barColorPrimary: classes.linearProgressBarColorPrimary }}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2">{`${Math.round(bunnyVideoUploadProcess)}%`}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {bunny_video_file !== null ? (
        <PrimaryVideoPlayer url={URL.createObjectURL(bunny_video_file)} onError={(e: any) => setVideoLinkError(true)} />
      ) : (
        Boolean(integrantType?.bunny_video_url) && (
          <PrimaryVideoPlayer
            url={integrantType?.bunny_video_url}
            light={integrantType?.bunny_video_thumbnail_url}
            onError={(e: any) => setVideoLinkError(true)}
          />
        )
      )}
      {Boolean(integrantType?.bunny_video_url) && !isDeleteBunnyVideo && (
        <Box width={'100%'} marginY={1.5}>
          <PrimaryButton icon="delete" children={'Remove Video'} onClick={onRemoveBunnyVideo} />
        </Box>
      )}
    </>
  );

  return (
    <>
      <FacetsList
        facets={ingredient ? ingredient?.facets : facets}
        isVisible={isVisible}
        onRemoveFacet={ingredient ? (facetID: string) => onRemoveFacet(facetID, ingredient) : onRemoveFacet}
        onUpdateFacet={ingredient ? (facet: IFacetDTO) => onAddFacet(facet, ingredient) : onAddFacet}
        type="integrantTypeFacets"
      />
      {isVisible && !ingredient && (
        <>
          <h5 style={labelStyle}>Video Link</h5>
          <Input
            value={integrantType.youtube_video_url}
            placeholder="Video link"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              onUpdateDetails('youtube_video_url', evt.currentTarget.value, ingredient);
              setVideoLinkError(false);
            }}
            disabled={
              Boolean(integrantType?.bunny_video_title) ||
              Boolean(integrantType?.bunny_video_url) ||
              Boolean(bunny_video_file)
            }
          />
          {integrantType?.youtube_video_url && (validURL(integrantType?.youtube_video_url) || videoLinkError) && (
            <FormHelperText error>Invalid Link.</FormHelperText>
          )}
          {integrantType?.youtube_video_url && !validURL(integrantType?.youtube_video_url) && (
            <div className={classes.videoPreview}>
              <PrimaryVideoPlayer
                url={integrantType?.youtube_video_url}
                onError={(e: any) => setVideoLinkError(true)}
              />
            </div>
          )}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
            className={classes.orDivider}
          >
            <Divider />
            <span>OR</span>
            <Divider />
          </Box>
          <h5 style={labelStyle}>Upload Video</h5>
          <Input
            value={integrantType.bunny_video_title}
            placeholder="Video title"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              onUpdateDetails('bunny_video_title', evt.currentTarget.value, ingredient);
              setBunnyVideoUploadError('');
            }}
            disabled={Boolean(integrantType?.youtube_video_url)}
          />
          {Boolean(bunnyVideoUploadError) ? <FormHelperText error>{bunnyVideoUploadError}</FormHelperText> : null}
          {renderBunnyVideoFileUpload()}
        </>
      )}
    </>
  );
};

export { IntegrantTypeFacets };
