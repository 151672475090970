import { IFacetDTO } from '../../lib/facet';
import React, { FunctionComponent } from 'react';
import { isClientId } from '../config';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { IIntegrantOptionPaneProps } from '../types/common';
import { useIntegrantsService } from '../hooks/api/useIntegrantsService';
import { FacetsList } from './FacetsList';

const IntegrantFacets: FunctionComponent<IIntegrantOptionPaneProps> = ({ isVisible, isDisabled }): JSX.Element => {
  const { currentIntegrant, setFacets } = useIntegrantGraphStore();
  const { facets } = currentIntegrant;
  const { onUpdateFacet, onRemoveFacet, isLoading } = useIntegrantPaneStore();
  const { updateFacetsBulk } = useIntegrantsService();

  const handleDragEnd = async (updatedFacets: IFacetDTO[]): Promise<void> => {
    const facets = setFacets(updatedFacets);
    if (!isClientId(currentIntegrant.id)) {
      await updateFacetsBulk(currentIntegrant.id, facets);
    }
  };

  return (
    <FacetsList
      facets={facets}
      isVisible={isVisible}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onRemoveFacet={onRemoveFacet}
      onUpdateFacet={onUpdateFacet}
      handleDragEnd={handleDragEnd}
      className="integrantFacets"
      type="integrantFacets"
    />
  );
};

export { IntegrantFacets };
