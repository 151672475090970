import React, { FunctionComponent } from 'react';
import { GraphPaneProvider } from '../context/integrantGraph/graphPaneContext';
import { IntegrantPaneProvider } from '../context/integrantPaneContext';
import { LayerPaneProvider } from '../context/layerPaneContext';

const IntegrantPanesWrapper: FunctionComponent = ({ children }) => {
  return (
    // <GraphPaneProvider>
    <LayerPaneProvider>
      <IntegrantPaneProvider>
        <>{children}</>
      </IntegrantPaneProvider>
    </LayerPaneProvider>
    // </GraphPaneProvider>
  );
};

export { IntegrantPanesWrapper };
