import React, { CSSProperties, FunctionComponent } from 'react';
import { errorColor } from '../../styles/default';
import { IBaseProps } from '../Layer';

interface IButtonProps extends IBaseProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  required?: boolean;
}

const Button: FunctionComponent<IButtonProps> = ({
  direction = 'row',
  display = 'flex',
  children,
  fill = false,
  height = 'auto',
  width = 'auto',
  center = false,
  style,
  onClick,
  disabled = false,
  required = false
}) => {
  const buttonStyle: CSSProperties = {
    display: display,
    flexDirection: direction,
    height: fill ? '' : height,
    width: fill ? '100%' : width,
    justifyContent: center ? 'center' : 'normal',
    alignItems: center ? 'center' : 'normal',
    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? 'none' : 'inherit',
    cursor: disabled ? 'wait' : 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: required ? errorColor : '#ccc',
    color: required ? 'white' : '#333',
    ...style,
    backgroundColor: required ? errorColor : style?.backgroundColor
  };
  return (
    <button onClick={onClick} style={buttonStyle}>
      {children}
    </button>
  );
};

export { Button };

