import { ExhibitId, IExhibitDTO, ExhibitType } from '../../lib/exhibit';
import { ILocationDTO } from '../../lib/location';
import React, { CSSProperties, FunctionComponent, ReactNode, useState } from 'react';
import { GoFile } from 'react-icons/go';
import { acceptedFileTypes, isClientId, url } from '../config';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { defaultBorderStyle, errorColor, formButtonStyle, iconStyle, fileInputStyle } from '../styles/default';
import { AddressView } from './AddressView';
import { EditListItem } from './EditListItem';
import { Location } from './Location';
import { useToastStore } from '../hooks/useToastStore';
import { Divider, makeStyles, createStyles } from '@material-ui/core';
import { Input } from 'common';
import { verifyEnvironment } from '../utils/index';
import SelectTestingLab from './SelectTestingLab';
import clsx from 'clsx';

const useStyle = makeStyles(theme =>
  createStyles({
    exhibitAttachmentRoot: {
      display: 'flex',
      flexDirection: 'column',
      '&>div': {
        '&:last-child': {
          marginBottom: 10
        }
      }
    },
    orDivider: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '10px 0',
      '&>span': {
        margin: '0px 10px'
      },
      '&>hr': {
        width: '30%'
      }
    },
    compareProductContainer: {
      display: 'flex',
      marginBottom: 10
    },
    compareProductButton: {
      width: '100%',
      maxWidth: '250px'
    }
  })
);
// compare-label
const exhibitOption = ['Quality', 'Sustainability', 'Diversity'];

interface IExhibitListItemProps {
  exhibit: IExhibitDTO;
}

const exhibitItemStyle: CSSProperties = { marginTop: 5, marginBottom: 0 };

const ExhibitListItem: FunctionComponent<IExhibitListItemProps> = ({ exhibit }) => {
  const classes = useStyle();
  const { onUpdateExhibit, onRemoveExhibit, onUpdateExhibitLocation } = useIntegrantPaneStore();
  const [currentFile, setFile] = useState<File | null>(null);
  const [productLableFile, setProductLableFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [location, setLocation] = useState<ILocationDTO | null>(null);
  const [testing_lab_id, setTesting_lab_id] = useState<string>('');
  const [exhibitType, setexhibitType] = useState<ExhibitType | string>('Quality');
  const { isOpenToast } = useToastStore();
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const handleUploadFile = () => {
  //   const formData = new FormData();
  //   if (!currentFile && !fileUrl) {
  //     isOpenToast('Please upload product document');
  //   }
  //   if (!productLableFile) {
  //     isOpenToast('Please upload label document');
  //   }
  //   if (currentFile) {
  //     formData.append('coa_product', currentFile);
  //   }
  //   if (productLableFile) {
  //     formData.append('nih_product', productLableFile);
  //   }
  //   if (fileUrl) {
  //     formData.append('url', fileUrl);
  //   }
  //   setIsLoading(true);
  //   fetch(`${url}document-ai/compare-label`, {
  //     method: 'POST',
  //     body: formData
  //   })
  //     .then(res => res.json())
  //     .then(res => {
  //       if (res.statusCode > 300) {
  //         isOpenToast('Something went wrong!');
  //       } else {
  //         setResult(res?.data);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch(err => {
  //       isOpenToast('Something went wrong!');
  //       setIsLoading(false);
  //     });
  // };

  const labelStyle: CSSProperties =
    !verifyEnvironment('live') && !verifyEnvironment('qa')
      ? {
          ...formButtonStyle,
          display: 'inline-block',
          borderWidth: '1px',
          borderStyle: 'solid',
          // borderColor: currentFile === null && fileUrl === '' ? errorColor : '#ccc',
          borderColor: '#ccc',
          pointerEvents: fileUrl ? 'none' : 'initial'
        }
      : {
          ...formButtonStyle,
          display: 'inline-block',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#ccc'
          // borderColor: currentFile === null ? errorColor : '#ccc'
        };

  const labelStyleLabel: CSSProperties = {
    ...formButtonStyle,
    display: 'inline-block',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc'
    // borderColor: currentFile === null ? errorColor : '#ccc'
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>, type: string): void => {
    if (event.target && event.target.files !== null && Object.keys(event.target.files).length) {
      if (event.target.files[0].size >= 31457280) {
        // 1048576 = 1 MB
        isOpenToast('isError', 'You can upload maximum 30MB');
      } else {
        if (type === 'product_lable') {
          setProductLableFile(event.target.files[0]);
        } else {
          setFile(event.target.files[0]);
        }
      }
    }
  };

  const onChange = (value: string): void => {
    setexhibitType(value);
  };

  const handleSave = async (currentExhibit: IExhibitDTO): Promise<void> => {
    const updatedExhibit = location !== null ? { ...currentExhibit, location } : currentExhibit;
    updatedExhibit.exhibit_type = exhibitType;
    if (!verifyEnvironment('live') && !verifyEnvironment('qa')) {
      if (!currentFile) {
        updatedExhibit.url = fileUrl;
      }
    }
    updatedExhibit.testing_lab_id = testing_lab_id;
    await onUpdateExhibit(
      updatedExhibit,
      currentFile !== null ? currentFile : undefined,
      location,
      productLableFile ? productLableFile : undefined
    );
  };

  const handleSaveLocation = async (location: ILocationDTO): Promise<void> => {
    setLocation(location);
    //await onUpdateExhibitLocation(location, exhibit.id);
  };

  const handleRemove = async (currentExhibitId: string): Promise<void> => {
    await onRemoveExhibit(currentExhibitId, true);
  };

  const handleCancel = async (currentExhibit: IExhibitDTO): Promise<void> => {
    await onRemoveExhibit(currentExhibit.id, false);
  };

  const renderTopForm = (): ReactNode =>
    isClientId(exhibit.id) ? (
      <>
        {!verifyEnvironment('live') && !verifyEnvironment('qa') ? (
          <>
            <div className={classes.exhibitAttachmentRoot}>
              <div>
                <input
                  style={fileInputStyle}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFiles(event, 'product')}
                  type="file"
                  id="file"
                />
                <label style={labelStyle} htmlFor="file">
                  <GoFile style={iconStyle}></GoFile> {currentFile !== null ? currentFile.name : `Choose a file *`}
                </label>
              </div>
              <div className={classes.orDivider}>
                <Divider />
                <span>OR</span>
                <Divider />
              </div>
              <div>
                <Input
                  // required={!(currentFile || fileUrl)}
                  disabled={Boolean(currentFile)}
                  placeholder="Attachment URL"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setFileUrl(e.target.value?.trim())}
                  defaultValue={fileUrl}
                />
              </div>
            </div>{' '}
            <div className={classes.compareProductContainer}>
              {/* <input
                style={fileInputStyle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFiles(event, 'product_lable')}
                type="file"
                id="product_lable"
              /> */}
              {/* <label style={labelStyleLabel} htmlFor="product_lable">
                <GoFile style={iconStyle}></GoFile>{' '}
                {productLableFile !== null ? productLableFile.name : `Choose product label *`}
              </label> */}
              {/* <button
                className={clsx('mui-btn-root', classes.compareProductButton)}
                onClick={handleUploadFile}
                disabled={(!currentFile && !fileUrl) || !productLableFile || isLoading}
              >
                {isLoading ? 'Loading...' : 'Compare Product Label'}
              </button> */}
            </div>
            {result && (
              <div style={{ margin: '10px' }}>
                <b>Result :</b> {result}
              </div>
            )}
            <div>
              <select
                value={exhibitType}
                name="exhibit_type"
                onChange={(e): void => {
                  const { value } = e.target;
                  onChange(value);
                }}
              >
                {/* <option value="">Select Exhibit</option> */}
                {exhibitOption.map((item: string) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <input
                  style={fileInputStyle}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFiles(event, 'product')}
                  type="file"
                  id="file"
                />
                <input
                  style={fileInputStyle}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFiles(event, 'product_lable')}
                  type="file"
                  id="product_lable"
                />

                {/* <label style={labelStyle} htmlFor="file">
                  <GoFile style={iconStyle}></GoFile> {currentFile !== null ? currentFile.name : `Choose a file`}
                </label>
                <label style={labelStyleLabel} htmlFor="product_lable">
                  <GoFile style={iconStyle}></GoFile>{' '}
                  {currentFile !== null ? currentFile.name : `Choose product label *`}
                </label> */}
                {/* <button className="mui-btn-root" onClick={() => console.log('========')}>
                  {isLoading ? 'Loading... ' : 'Compare Product Label'}
                </button> */}
              </div>
              <div>
                <select
                  value={exhibitType}
                  name="exhibit_type"
                  onChange={(e): void => {
                    const { value } = e.target;
                    onChange(value);
                  }}
                >
                  {/* <option value="">Select Exhibit</option> */}
                  {exhibitOption.map((item: string) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
      </>
    ) : null;

  const renderBottomForm = (): ReactNode => {
    return (
      <>
        <Location
          onSaveLocation={handleSaveLocation}
          isVisible={true}
          isSavebutton={true}
          currentLocation={exhibit.location}
          locationWrapperStyle={{ padding: 20, marginTop: 20, ...defaultBorderStyle, border: '1px solid #ccc' }}
        />
        <SelectTestingLab value={testing_lab_id} handleTestingLabId={(id: string) => setTesting_lab_id(id)} />
      </>
    );
  };
  const renderViewOnlyItem = (): ReactNode => {
    const { effective_date, exhibit_type } = exhibit;
    return (
      <div>
        <div>
          <h5 style={exhibitItemStyle}>
            <a rel="noopener noreferrer" target="_blank" href={exhibit.url}>
              {exhibit.title}
            </a>
          </h5>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '0.83em' }}>Description: </span>
          <small>{exhibit.description}</small>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '0.83em' }}>Exhibit Type: </span>
          <small>{exhibit_type}</small>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '0.83em' }}>Effective Date: </span>
          <small>
            {effective_date
              ? new Date(effective_date).getMonth() +
                1 +
                '/' +
                new Date(effective_date).getDate() +
                '/' +
                new Date(effective_date).getFullYear()
              : 'N/A'}
          </small>
        </div>
        {exhibit.location !== null ? <AddressView location={exhibit.location} /> : null}
      </div>
    );
  };

  return (
    <EditListItem<IExhibitDTO>
      type="exhibit"
      item={exhibit}
      handleRemove={handleRemove}
      handleDeleteTrait={handleRemove}
      handleSave={handleSave}
      handleCancel={handleCancel}
      renderTopForm={renderTopForm}
      renderBottomForm={renderBottomForm}
      renderViewOnlyItem={renderViewOnlyItem}
      isSaveDisabled={isClientId(exhibit.id) && currentFile === null && fileUrl === ''}
    />
  );
};

export { ExhibitListItem };
