import React, {FunctionComponent} from 'react';
import {Grid,Typography,makeStyles,Theme,createStyles} from '@material-ui/core';
import successEmoji from '../../static/SuccessEmoji.png';
import { PrimaryButton } from 'common';
import {useHistory} from 'react-router-dom';

const useStyle = makeStyles((theme:Theme)=>createStyles({
    surveySuccessPageContainer:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        '&>img':{
            width: 140,
            height: 140,
            margin: '80px 0px'
        }
    },
    surveySuccessPageHeading: {
        fontSize: '34px',
        lineHeaign:"40px",
        fontWeight: 700,
        color:"#000"
    } 
}))

export const SurveySuccessPage: FunctionComponent = ()=> {
    const classes = useStyle();
    const history = useHistory();
    return <Grid item md={12} className={classes.surveySuccessPageContainer}>
        <Typography variant="h1" className={classes.surveySuccessPageHeading}>Survey Sent to Customers</Typography>
        <img src={successEmoji} alt="Success emoji" />
        <PrimaryButton children={"Done"} onClick={()=>history.push("/survey")} secondaryGreenButton={true} maxContent={true}  />
    </Grid>
}

export default SurveySuccessPage