import { useContext } from 'react';
import { IndividualSubscriptionDetailContext,IIndividualSubscriptionDetailProviderState } from '../context/individualSubscriptionDetailContext';
import { useIndividualSubscriptionDetailService } from './api/useIndividualSubscriptionDetailService';
import { useAuthStore } from './useAuthStore';

interface IIndividualSubscriptionDetailStore extends IIndividualSubscriptionDetailProviderState {
  initGetDashboardOverview: (data: any) => any;
  initBuyNewProduct: (data: any) => any;
  setQuerySearch: (search: any) => any;
}

const useIndividualSubscriptionDetailStore = (): IIndividualSubscriptionDetailStore => {
  const [state, setState] = useContext(IndividualSubscriptionDetailContext);
  const { getDashboardOverview, buyNewProduct } = useIndividualSubscriptionDetailService();
  const { dashboardOverview, buyNewProductDetail, querySearch } = state;
  const { addNewSubscription } = useAuthStore();

  const initGetDashboardOverview: IIndividualSubscriptionDetailStore["initGetDashboardOverview"] = async (data: any) => {
    setState({...state,dashboardOverview:{...state.dashboardOverview,loading: true, status: ""}});
    const response = await getDashboardOverview(data);
    setState({...state,dashboardOverview:{...state.dashboardOverview,loading: false, ...response}});
  }

  const initBuyNewProduct: IIndividualSubscriptionDetailStore["initBuyNewProduct"] = async (data: any) => {
    setState({...state,buyNewProductDetail:{...state.buyNewProductDetail,loading: true, status: ""}});
    const response = await buyNewProduct(data);
    setState({...state,buyNewProductDetail:{...state.buyNewProductDetail,loading: false, ...response}});
    if(response?.data?.token){
      addNewSubscription(response?.data)
    }
  }

  const setQuerySearch: IIndividualSubscriptionDetailStore["setQuerySearch"]=async (search: any)=>{
    setState({...state, querySearch: search})
  }

  return {
    dashboardOverview,
    initGetDashboardOverview,
    buyNewProductDetail,
    initBuyNewProduct,
    querySearch,
    setQuerySearch
  };
};

export { useIndividualSubscriptionDetailStore };
