import { IIntegrantDTO } from '../../lib/integrant';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layer } from '../common';
import { titles } from '../config';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import { LoadingTitle } from '../types/common';
import { isEmptyString } from '../utils';
import { CloneBatch } from './CloneBatch';
import { ListView } from './ListView';
import { Loading } from './Loading';
import Icon from '../common/Icon';
import ReactTooltip from 'react-tooltip';

const infoStyle: CSSProperties = {
  width: '100%'
};

const selectBatchesWrapperStyle: CSSProperties = {
  width: '100%',
  margin: '0 auto',
  paddingTop: 20,
  paddingBottom: 40,
  overflow: 'hidden'
};

const titleStyle: CSSProperties = {
  textOverflow: 'ellipses',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  margin: 0
};

interface INtegrantType {
  integrantTypeIdProps: any;
}

const SelectBatches: FunctionComponent<INtegrantType> = ({ integrantTypeIdProps }) => {
  const { integrants, isLoading, batchListUpdate } = useIntegrantsStore();
  const [isfilteringPublished, setFilteringPublished] = useState(false);

  const renderListItem = (integrant: any): React.ReactNode => {
    return (
      <Layer direction="column" style={infoStyle}>
        <h5 style={titleStyle} className="home-page-product-title">Batch: {integrant.external_id}</h5>
        <small>Updated On: {new Date(integrant.updated_on).toLocaleString()}</small>
        <Layer direction="row" className='batch-list-action-buttons'>
          <Link to={`batches/${integrant.id}`}>
            <Icon icon='edit' dataTip='Edit' />
          </Link>
          <CloneBatch integrant={integrant} />
        </Layer>
      </Layer>
    );
  };

  const filteredByPublished =
    typeof integrants.filter !== 'undefined'
      ? integrants.filter((i, index) => {
        return (isfilteringPublished ? i.is_published === true : true) && !isEmptyString(i.tracking_id);
      })
      : [];

  const liveSearch = (searchValue: string): void => {
    batchListUpdate(searchValue, integrantTypeIdProps);
  };

  return !isLoading ? (
    <Layer fill style={selectBatchesWrapperStyle}>
      <ReactTooltip />
      <Layer direction="column" fill style={{ padding: '0px 10px' }}>
        <ListView<IIntegrantDTO>
          scrollY={true}
          dataSource={filteredByPublished.slice().sort((a, b) => {
            return new Date(b.created_on).getTime() - new Date(a.created_on).getTime();
          })}
          renderListItem={renderListItem}
          searchPlaceholder={`Search by ${titles.tracking}`}
          filter={(i, value): boolean =>
            typeof i.tracking_id !== 'undefined' && i.tracking_id.toLowerCase().includes(value.toLowerCase())
          }
          isLiveSearch={true}
          liveSearch={liveSearch}
          emptyTitle={`There are no ${titles.trackingPlural} for this ${titles.integrantType}`}
          listItemStyle={(): CSSProperties => {
            return {
              padding: 20,
              borderBottom: '1px solid #ccc'
            };
          }}
        />
      </Layer>
    </Layer>
  ) : (
    <Loading title={LoadingTitle.Tracking}></Loading>
  );
};

export { SelectBatches };