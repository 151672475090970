import { PrimaryButton } from 'common/Button/buttons';
import React, { CSSProperties, FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { Layer } from '../common';
import { defaultButtonStyle, dropdownWrapperStyle } from '../styles/default';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const transitButtonStyle: CSSProperties = { marginRight: 5, marginTop: 10 };

interface IDropdownContentProps {
  isOpen: boolean;
  renderContent: () => ReactNode;
  renderOptions?: () => ReactNode;
  handleCancel?: () => void;
  style?: CSSProperties;
  type?: string;
}
const dropdownContentStyle: CSSProperties = {
  ...dropdownWrapperStyle,
  width: '100%',
  padding: 10,
  maxHeight: 400,
  overflow: 'auto'
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: {
      marginTop: 10,
      '&>button': {
        '&:nth-child(1)': {
          marginRight: 10
        }
      }
    }
  })
);
const DropdownContent: FunctionComponent<IDropdownContentProps> = ({
  renderContent,
  renderOptions,
  handleCancel,
  isOpen,
  style,
  type
}) => {
  const classes = useStyles();
  const history = useHistory();
  const targetElRef: any = useRef(null);
  useEffect(() => {
    const handleClick = (e: any): any => {
      if (isOpen && handleCancel && targetElRef && targetElRef.current && !targetElRef.current?.contains(e.target)) {
        handleCancel();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [targetElRef, isOpen]);
  return isOpen ? (
    <Layer direction="column" setRef={targetElRef} style={{ ...dropdownContentStyle, ...style }}>
      <div>{renderContent()}</div>
      <Layer direction="row" className={classes.btnRoot}>
        {typeof handleCancel !== 'undefined' ? (
          <PrimaryButton maxContent={true} children={'Cancel'} icon="close" onClick={handleCancel} />
        ) : null}
        {type === 'integrantSuggestions' ? (
          <PrimaryButton
            maxContent={true}
            secondaryGreenButton
            children={'Add Ingredient'}
            icon="add"
            onClick={() => history.push('/create-new-product')}
          />
        ) : null}
        {typeof renderOptions !== 'undefined' ? renderOptions() : null}
      </Layer>
    </Layer>
  ) : (
    <div />
  );
};

export { DropdownContent };
