import React, { useEffect, useState } from 'react';
import { useUserStore } from 'hooks/useUserStore';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Grid
} from '@material-ui/core';
import MuiCircularProgress from 'common/MuiCircularProgress';
import { useIntersection } from 'hooks/useIntersection';
import { LazyLoading } from 'common';
import { UserDrawer } from 'common/Drawers';

const useStyle = makeStyles(theme =>
  createStyles({
    connectionContainer: {
      width: '100%',
      padding: '50px 0 30px',
      '&>div': {
        width: '100%',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        '&>h3': {
          marginBottom: 20
        }
      }
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    },
    tableContainer: {
      marginTop: 20,
      marginBottom: 30
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700
        }
      }
    },
    actionBtn: {
      fontWeight: 'bold',
      cursor: 'pointer'
    }
  })
);

export default function ConnectionPage() {
  const classes = useStyle();
  const { myConnections, initGetMyConnections, getUserById } = useUserStore();
  const [setTarget, entry] = useIntersection({
    threshold: 0.1
  });
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  useEffect(() => {
    const { isIntersecting }: any = entry;
    if (isIntersecting && myConnections?.data?.count - myConnections?.data?.rows?.length > 0) {
      initGetMyConnections({ limit: 100, offset: myConnections?.data?.rows?.length });
    }
  }, [entry]);
  useEffect(() => {
    initGetMyConnections({ limit: 100, offset: 0 });
  }, []);

  return (
    <Box className={classes.connectionContainer}>
      <Box>
        <Typography variant="h3">My Connections</Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myConnections.loading && !myConnections?.data?.rows ? (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <Grid container alignItems="center" justifyContent="center">
                      Loading...&nbsp;
                      <MuiCircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : myConnections?.data?.rows?.length ? (
                myConnections?.data?.rows?.map((item: any, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{item?.producer_connection_user?.name}</TableCell>
                      <TableCell
                        align="right"
                        className={classes.actionBtn}
                        onClick={() => {
                          getUserById(item?.user_id);
                          setOpenUserDrawer(true);
                        }}
                      >
                        View
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    You Don't Have Any Connections Yet.
                  </TableCell>
                </TableRow>
              )}
              {myConnections?.data?.count - myConnections?.data?.rows?.length > 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <LazyLoading setRef={setTarget} />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <UserDrawer open={openUserDrawer} handleClose={() => setOpenUserDrawer(false)} />
    </Box>
  );
}
