import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js';
interface DocumentChartData {
  label: string;
  total_doc: number;
  no_of_verify_doc?: number;
  no_of_unverify_doc?: number;
}

interface ChartData {
  data: DocumentChartData[];
}
const LineChart: React.FC<ChartData> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart>();

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const hasSuccessAndFailure = data.some(
          (val: DocumentChartData) => val.no_of_verify_doc !== undefined && val.no_of_unverify_doc !== undefined
        );
        const filteredData = data.map((val: DocumentChartData) => ({
          label: val.label,
          totalUploaded: val.total_doc,
          successfullyUploaded: val.no_of_verify_doc !== undefined ? val.no_of_verify_doc : null,
          failedUploaded: val.no_of_unverify_doc !== undefined ? val.no_of_unverify_doc : null
        }));

        const chartConfig: ChartConfiguration = {
          type: 'line',
          data: {
            labels: filteredData.map(val => val.label),
            datasets: [
              {
                label: 'Total Tested',
                data: filteredData.map(val => val.totalUploaded),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                fill: false
              },
              {
                label: 'Successfully Verified',
                data: filteredData.map(val => val.successfullyUploaded),
                borderColor: 'rgb(54, 162, 235)',
                tension: 0.1,
                fill: false
              },
              {
                label: 'Failed Verified',
                data: filteredData.map(val => val.failedUploaded),
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
                fill: false
              }
            ]
          },
          options: {
            plugins: {
              legend: {
                display: hasSuccessAndFailure ? true : false
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Number of documents'
                },
                ticks: {
                  stepSize: hasSuccessAndFailure ? 1 : 500
                }
              }
            }
          }
        };

        // Destroy previous chart instance if it exists
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }

        // Create new chart instance
        chartInstance.current = new Chart(ctx, chartConfig);
      }
    }
  }, [data]);

  return (
    <div style={{ margin: '35px 0px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default LineChart;
