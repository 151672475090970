import { IIndividualAuthenticatedDTO } from '../../lib/individual';
import React, { useReducer } from 'react';
import { individual_ } from '../common/models';
import {IAPIResponseDTO} from '../../lib/common'
import { CreateActions, IAction, IIndividualRole, IResetAction } from '../types/common';

export interface IAuthProviderState {
  isLoggedIn: boolean;
  isLoading: boolean;
  isError: boolean;
  individual: IIndividualAuthenticatedDTO;
  individualRoles: IIndividualRole[];
  initialUrlPath: string;
  threshold: any,
  verifyOTPData: IAPIResponseDTO,
  resendOTPData: IAPIResponseDTO,
  subscriptionList: string[],
  selectedSubscription: string
}

type ISetLoadingAction = IAction<'setLoading', boolean>;
type ISetLoggedInAction = IAction<'setLoggedIn', boolean>;
type ISetInitialUrlPath = IAction<'setInitialUrlPath', string>;
type ISetIndividualAction = IAction<'setIndividual', IIndividualAuthenticatedDTO>;
type ISetIndividualRolesAction = IAction<'setIndividualRoles', IIndividualRole[]>;
type ISetErrorAction = IAction<'setError', boolean>;
type ISetThresholdAction = IAction<'setThreshold', any>;
type ISetVerifyOTPAction = IAction<'setVerifyOTPData', any>;
type ISetResendOTPAction = IAction<'setResendOTPData', any>;
type ISetSubscriptionListAction = IAction<'setSubscriptionList', any>;
type ISetSelectedSubscriptionAction = IAction<'setSelectedSubscription', any>;
type IActions =
  | ISetLoadingAction
  | IResetAction
  | ISetLoggedInAction
  | ISetErrorAction
  | ISetIndividualAction
  | ISetIndividualRolesAction
  | ISetInitialUrlPath
  | ISetThresholdAction
  | ISetVerifyOTPAction
  | ISetSubscriptionListAction
  | ISetResendOTPAction
  | ISetSelectedSubscriptionAction;

const defaults: IAuthProviderState = {
  isLoading: false,
  isLoggedIn: false,
  isError: false,
  individual: individual_,
  individualRoles: [],
  initialUrlPath: `${window.location.pathname}`,
  threshold: {},
  verifyOTPData: {},
  resendOTPData: {},
  subscriptionList: [],
  selectedSubscription:""
};

const AuthContext = React.createContext<CreateActions<IAuthProviderState, IActions>>([
  defaults,
  (): React.Dispatch<IActions> => (): IAuthProviderState => defaults
]);

const reducer = (state: IAuthProviderState, action: IActions): IAuthProviderState => {
  switch (action.type) {
    case 'reset':
      return defaults;
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setLoggedIn':
      return { ...state, isLoggedIn: action.payload };
    case 'setIndividual':
      return { ...state, individual: action.payload };
    case 'setIndividualRoles':
      return { ...state, individualRoles: action.payload };
    case 'setInitialUrlPath':
      return { ...state, initialUrlPath: action.payload };
    case 'setError':
      return { ...state, isError: action.payload };
    case 'setThreshold':
      return { ...state, threshold: action.payload };
    case 'setVerifyOTPData':
      return { ...state, verifyOTPData: action.payload };
    case 'setResendOTPData':
      return { ...state, resendOTPData: action.payload };
    case 'setSubscriptionList':
      return { ...state, subscriptionList: action.payload };
    case 'setSelectedSubscription':
      return { ...state, selectedSubscription: action.payload };
    default:
      return state;
  }
};

const AuthProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaults);

  return <AuthContext.Provider value={[state, dispatch]}>{props.children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
