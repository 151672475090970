import { ICreateIndividualDTO } from '../../../lib/individual';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import { Button } from '../../common/Button';
import { Input } from '../../common/Input';
import { Layer } from '../../common/Layer';
import { create_individual_ } from '../../common/models';
import { titles } from '../../config';
import { useAuthStore } from '../../hooks/useAuthStore';
import { defaultBoxShadow, defaultGreyBackgroundColor, defaultModalStyle, formButtonStyle } from '../../styles/default';
import {useTheme,useMediaQuery} from '@material-ui/core'
import { PrimaryButton } from 'common/Button/buttons';

const labelSpacingStyle: CSSProperties = { marginBottom: 20, display: 'block' };
const contentWrapperStyle: CSSProperties = {
  boxShadow: defaultBoxShadow,
  width: '100%',
  maxWidth:'600px',
  padding: 20,
  backgroundColor: 'white'
};

const paragraphStyle: CSSProperties = { marginBottom: 20, display: 'block' };

const defaults: ICreateIndividualDTO = create_individual_;

const Register: FunctionComponent = () => {
  const [credentials, setCredentials] = useState(defaults);
  const [isError, setError] = useState(false);
  const { signup } = useAuthStore();
  const handleAfterClose = (): void => {
    setCredentials(defaults);
  };
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768))

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal ariaHideApp={false} onAfterClose={handleAfterClose} style={defaultModalStyle} isOpen>
        {isError ? (
          <p style={paragraphStyle}>{titles.error}</p>
        ) : (
          <>
            <strong style={paragraphStyle}>Thanks for signing up {credentials.name_first}.</strong>
            <p style={paragraphStyle}>One of our team members will reach out to you shortly!</p>
          </>
        )}
        <PrimaryButton maxContent={true} children={'Cancel'} onClick={hideModal} icon='close' />
      </ReactModal>
    ),
    [credentials, isError]
  );

  const handleSignup = async (): Promise<void> => {
    const individualDetailDTO = await signup({ ...credentials });
    setError(individualDetailDTO === undefined ? true : false);
    showModal();
  };

  const { name_first, name_last, name_middle, phone_mobile, email_address, password } = credentials;

  return (
    <Layer
      center
      fill
      style={{ position: 'relative', backgroundColor: defaultGreyBackgroundColor }}
      direction="column"
      width={'100%'}
    >
      <Layer direction="column" style={contentWrapperStyle}>
        <h2>Create An Account</h2>
        <br />
        Name
        <Layer fill direction={match ? 'column' : 'row'} style={{ marginBottom: 20 }}>
          <Input
            value={name_first}
            autoFocus
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, name_first: event.currentTarget.value })
            }
            placeholder="First Name"
          />
          <Input
            value={name_middle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, name_middle: event.currentTarget.value })
            }
            placeholder="Middle"
          />
          <Input
            value={name_last}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, name_last: event.currentTarget.value })
            }
            placeholder="Last Name"
          />
        </Layer>
        <label style={labelSpacingStyle}>
          Mobile
          <Input
            value={phone_mobile}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, phone_mobile: event.currentTarget.value })
            }
            placeholder="Mobile"
            type="tel"
          />
        </label>
        <label style={labelSpacingStyle}>
          Email
          <Input
            value={email_address}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, email_address: event.currentTarget.value })
            }
            type="email"
            placeholder="Email"
          />
        </label>
        <label style={labelSpacingStyle}>
          Password
          <Input
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setCredentials({ ...credentials, password: event.currentTarget.value })
            }
            type="password"
            placeholder="Password"
          />
        </label>
        <Button onClick={handleSignup} style={{ marginBottom: 20, padding: 20 }}>
          Register
        </Button>
        <p>
          <Link to="/login">Already have an account?</Link>
        </p>
      </Layer>
    </Layer>
  );
};

export { Register };
