import { ICreateTransferDTO } from '../../lib/transfer';
import { IOrganizationDTO } from '../../lib/organization';
import React, { useState } from 'react';
import { create_transfer_, organization_ } from '../common/models';
import { CreateContext } from '../types/common';

export interface ICreateTransferState {
  isLoading: boolean;
  isOpen: boolean;
  currentTransfer: ICreateTransferDTO;
  organizations: IOrganizationDTO[];
  selectedTransferOrganization: IOrganizationDTO;
}

const defaults: ICreateTransferState = {
  currentTransfer: create_transfer_,
  isOpen: false,
  isLoading: false,
  organizations: [],
  selectedTransferOrganization: organization_
};

const CreateTransferContext = React.createContext<CreateContext<ICreateTransferState>>([
  defaults,
  (): ICreateTransferState => defaults
]);

const CreateTransferProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ICreateTransferState>(defaults);

  return <CreateTransferContext.Provider value={[state, setState]}>{props.children}</CreateTransferContext.Provider>;
};

export { CreateTransferContext, CreateTransferProvider };
