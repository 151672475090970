import { ExhibitId, IExhibitDTO, ExhibitType } from '../../lib/exhibit';
import { ILocationDTO } from '../../lib/location';
import React, { CSSProperties, FunctionComponent, ReactNode, useState } from 'react';
import { GoFile } from 'react-icons/go';
import { acceptedFileTypes, isClientId } from '../config';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { defaultBorderStyle, errorColor, formButtonStyle, iconStyle, fileInputStyle } from '../styles/default';
import { AddressView } from './AddressView';
import { EditListTransitItem } from './EditListTransitItem';
import { Location } from './Location';

interface IExhibitTransitListItemProps {
  exhibit: IExhibitDTO;
}

const exhibitItemStyle: CSSProperties = { marginTop: 5, marginBottom: 0 };

const ExhibitTransitListItem: FunctionComponent<IExhibitTransitListItemProps> = ({ exhibit }) => {
  const { onUpdateExhibit, onRemoveExhibit, onUpdateExhibitLocation } = useIntegrantPaneStore();
  const [currentFile, setFile] = useState<File | null>(null);
  const [location, setLocation] = useState<ILocationDTO | null>(null);

  const [exhibitType, setexhibitType] = useState<ExhibitType | string>('Quality');

  const labelStyle: CSSProperties = {
    ...formButtonStyle,
    display: 'inline-block',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: currentFile === null ? errorColor : '#ccc'
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
    }
  };

  const onChange = (value: string): void => {
    setexhibitType(value);
  };

  const handleSave = async (currentExhibit: IExhibitDTO): Promise<void> => {
    const updatedExhibit = location !== null ? { ...currentExhibit, location } : currentExhibit;
    updatedExhibit.exhibit_type = exhibitType;
    await onUpdateExhibit(updatedExhibit, currentFile !== null ? currentFile : undefined);
  };

  const handleSaveLocation = async (location: ILocationDTO): Promise<void> => {
    setLocation(location);
    await onUpdateExhibitLocation(location, exhibit.id);
  };

  const handleRemove = async (currentExhibitId: string): Promise<void> => {
    await onRemoveExhibit(currentExhibitId, true);
  };

  const handleCancel = async (currentExhibit: IExhibitDTO): Promise<void> => {
    await onRemoveExhibit(currentExhibit.id, false);
  };

  const renderTopForm = (): ReactNode =>
    isClientId(exhibit.id) ? (
      <div>
        <input style={fileInputStyle} onChange={handleFiles} type="file" id="file" />
        <label style={labelStyle} htmlFor="file">
          <GoFile style={iconStyle}></GoFile> {currentFile && currentFile !== null ? currentFile.name : `Choose a file`}
        </label>
      </div>
    ) : null;

  const renderBottomForm = (): ReactNode => {
    return (
      <Location
        onSaveLocation={handleSaveLocation}
        isVisible={true}
        currentLocation={exhibit.location}
        locationWrapperStyle={{ padding: 20, marginTop: 20, ...defaultBorderStyle, border: '1px solid #ccc' }}
      />
    );
  };
  const renderViewOnlyItem = (): ReactNode => {
    function getFilename(url: string) {
      if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
          return m[1];
        }
      }
      return '';
    }
    return (
      <div style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', width: '100%', paddingBottom: 10 }}>
        <h5 style={exhibitItemStyle}>{exhibit.title}</h5>
        <a rel="noopener noreferrer" target="_blank" href={exhibit.url}>
          {getFilename(exhibit.url)}
        </a>
      </div>
    );
  };

  return (
    <EditListTransitItem<IExhibitDTO>
      item={exhibit}
      handleRemove={handleRemove}
      handleSave={handleSave}
      handleCancel={handleCancel}
      renderTopForm={renderTopForm}
      renderViewOnlyItem={renderViewOnlyItem}
      isSaveDisabled={isClientId(exhibit.id) && currentFile === null}
    />
  );
};

export { ExhibitTransitListItem };
