import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuthStore } from './../../hooks/useAuthStore';
import { labelMeta, currencyFormat, monthFormat, formAxiosHeader, baseURL, stripePublishableKey, subscriptionCategoriesLabel } from './../../config';
import { Link } from 'react-router-dom';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import axiosInstance from 'utils/api-interceptor';
import { loadStripe } from '@stripe/stripe-js';
import { useIndividualSubscriptionDetailStore } from 'hooks/useIndividualSubscriptionDetailStore';
import { abbrNum } from 'utils';
import MuiCircularProgress from 'common/MuiCircularProgress';
import {makeStyles, createStyles, Theme, Box, Grid, Typography} from "@material-ui/core";
import clsx from 'clsx';

const useStyle = makeStyles((theme: Theme)=> createStyles({
  addOrUpdateContainer: {
      width:'100%',
      minHeight: 'calc(100vh - 70px)',
      '&>div':{
          '&>div':{
              maxWidth: 800,
              width:'100%',
              borderRadius: 16,
              backgroundColor: '#fff',
              boxShadow: '0px 0px 10px 0px #00000026',
              padding: '20px 20px',
          },
          '&>h4,&>p':{
              textAlign:"center"
          },
          '&>h4':{
              marginBottom: 10,
          },
          '&>p':{
              marginBottom: 20
          }
      }
  },
  planDetailUl:{
      '&>div:not(:first-child,:nth-child(2),:nth-child(3))':{
          borderBottom: `1px solid rgba(0,0,0,0.1)`
      }
  },
  priceBoxRoot:{
      margin: '20px 0',
  },
  priceBox:{
      border: `2px solid #999`,
      borderRadius: 5,
      padding: 10,
      cursor:"pointer",
      maxWidth: 250,   
      margin: '0 auto',
      '&>h6':{
          fontWeight: 'bold'
      }
  },
  selectedPriceBox:{
    border: `2px solid #008F2B`,
    color: '#008F2B',
    background: '#EAF7ED'
  },
  disabledPriceBox:{
    cursor:"initial",
    border: `2px solid rgba(0,0,0,0.1)`,
    color: "#999",
    background: '#fff'
  },
  selectedPriceDataColor:{
    color: '#008F2B',
  }
}));

const BuyNewSubscription = (props: any) => {
    let stripePromise: any
    if (stripePublishableKey) {
        stripePromise = loadStripe(stripePublishableKey);
    }
  const history = useHistory();
  const { individual: { organization_id, access_token } } = useAuthStore();
  const [pageLoading, setPageLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [priceId, setPriceId] = useState('')
  const [subscriptionObj, setsubscriptionObj] = useState<any>(null)
  const params: any = useParams();
  const { initBuyNewProduct,buyNewProductDetail } = useIndividualSubscriptionDetailStore()
  const classes = useStyle();

  useEffect(() => {
    async function fetchData() {
      return await axiosInstance.post('subscription/list', { organization_id: organization_id },formAxiosHeader(access_token))
    }
    if(access_token){
      if (params.productID !== null) {
        const subscriptionListObj: any = fetchData()
        subscriptionListObj.then(function (subscriptionListObj: any) {
          const selectedplan = subscriptionListObj.plans.filter((item: any) => {
            return item.product_id === params.productID
          })
          setsubscriptionObj({
            currentPlan: subscriptionListObj.currentPlan,
            selectedPlan: selectedplan[0]
          })
          setPageLoading(pre => !pre)
        })
      }else{
        setPageLoading(false)
      }
    }
  }, [organization_id, access_token])
  

  useEffect(() => {
      async function fetchData(obj: any) {
          return await axiosInstance.post('subscription/create-payment-checkout-session', obj,formAxiosHeader(access_token))
      }
      async function handlePayment(response: any){
          const stripe: any = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: response.id,
          });
      }
    if(buyNewProductDetail?.status === "1"){
      const buyNewSub: any = fetchData({ 
            customer_id: subscriptionObj?.currentPlan?.[0]?.customer_id || buyNewProductDetail?.data?.customer_id,
            domain: baseURL,
            success_url: `${baseURL}/buy-new-product-thankyou?success=true&individualSubId=${buyNewProductDetail?.data?.id}&session_id={CHECKOUT_SESSION_ID}`,
            cancel_url: `${baseURL}/buy-new-product-thankyou?canceled=true&success=false&individualSubId=${buyNewProductDetail?.data?.id}`,
            price_id: priceId,
            trial_period_days: subscriptionObj?.selectedPlan?.product_plan[0].trial_period_days
      });
      buyNewSub.then(async function (response: any) {
        setBtnLoading(false)
        if (response.id) {
          handlePayment(response)
        } else {
          alert("Something went wrong please try again")
        }
      })
    }
  },[buyNewProductDetail])
  
  if (pageLoading || !subscriptionObj) {
      return <div className="subscription-page-load">
      <Loading title={LoadingTitle.Loading} />
    </div>
  }
  const { currentPlan, selectedPlan } = subscriptionObj
    const confirmSubscription = async () => {
      setBtnLoading(true)
      await initBuyNewProduct({
        customer_id: currentPlan?.[0]?.customer_id || selectedPlan?.customer_id,
        subscription_id: selectedPlan?.product_id,
        subscription_charges_id: selectedPlan?.product_plan[0]?.id,
        subscription_name: selectedPlan?.prouct_name,
        subscription_type: selectedPlan?.subscription_type,
        ...selectedPlan?.prouct_metadata,
      });      
    }
    let priceBoxData = selectedPlan?.product_plan?.map((item: any) => {
      return {
            label: selectedPlan?.prouct_name?.split(" - ")?.pop(),
            price: `${currencyFormat(item?.amount_decimal, item?.currency)}${monthFormat(item?.interval_count, item?.interval)}`,
            id: item?.id,
            interval: item?.interval
        }
    })
  return <Box className={classes.addOrUpdateContainer} display="flex" alignItems={"center"} justifyContent="center">
        <Box display={"flex"} flexDirection="column">
            <Typography variant='h4'>Confirm Subscription</Typography>
            <Box>
                <Grid container className={classes.planDetailUl} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{textAlign:'center', marginBottom: 10}}>{subscriptionCategoriesLabel[selectedPlan?.subscription_type]}</Typography>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                        <Typography variant='body1' style={{textAlign:'center', marginBottom: 10}} className={clsx(priceId && classes.selectedPriceDataColor)}>{selectedPlan?.prouct_name?.split(" - ")?.pop()}</Typography>
                    </Grid>
                    {
                        Object.keys(selectedPlan?.prouct_metadata).map(key => {
                        return <React.Fragment key={key}>
                                <Grid item xs={9}>
                                    <Typography variant='body1'>{labelMeta[key]}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography className={clsx(priceId && classes.selectedPriceDataColor)} variant='body1' style={{textAlign:'center'}}>{abbrNum(selectedPlan.prouct_metadata[key])}</Typography>
                                </Grid>
                            </React.Fragment>
                        })
                    }
                </Grid>
                <Grid container className={classes.priceBoxRoot} spacing={2}>
                        {priceBoxData?.map((item: any, key: number, arr: any)=>{
                            return <Grid item xs={arr.length === 2 ? 6 : arr.length === 3 ? 4 : arr.length === 4 ? 3 : 12} key={key} ><Box onClick={()=>{
                              setPriceId(item.id)
                            }} display={"flex"} flexDirection="column" className={clsx(classes.priceBox, priceId === item?.id && classes.selectedPriceBox)}>
                                <Typography variant='h6'>{item?.label}</Typography>
                                <Typography variant='body1'>{item?.price}</Typography>
                            </Box></Grid>
                        })}
                </Grid>
                {priceId && (
                    <div className="d-flex justify-content-center _pt10">
                        <div className='d-flex justify-content-center section-btn-updating'>
                            <Link className='btn-subscription cancel' to="/">Cancel</Link>
                            <div className='btn-subscription d-flex align-items-center justify-content-center' onClick={() => confirmSubscription()}>{btnLoading && (<MuiCircularProgress style={{marginRight: 10}} color='#fff' size={15} />)} Confirm</div>
                        </div>
                    </div>
                )}
            </Box>
        </Box>
    </Box>
};

export { BuyNewSubscription };
