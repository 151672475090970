import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import { IAPIResponseDTO } from '../../lib/common';

export interface IDocumentHashProviderState {
  docToolJwtData: IAPIResponseDTO;
  createFreeTrialDocumentData: IAPIResponseDTO;
  updateFreeTrialDocumentData: IAPIResponseDTO;
  deleteFreeTrialDocumentData: IAPIResponseDTO;
  getFreeTrialDocumentsData: IAPIResponseDTO;
}

export const defaults = {
  docToolJwtData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  createFreeTrialDocumentData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  updateFreeTrialDocumentData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  deleteFreeTrialDocumentData: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  getFreeTrialDocumentsData: {
    status: '',
    message: '',
    loading: false,
    data: null,
    count: 0
  }
};

const DocumentHashContext = React.createContext<CreateContext<IDocumentHashProviderState>>([
  defaults,
  (): IDocumentHashProviderState => defaults
]);

const DocumentHashProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<IDocumentHashProviderState>(defaults);
  return <DocumentHashContext.Provider value={[state, setState]}>{props.children}</DocumentHashContext.Provider>;
};

export { DocumentHashContext, DocumentHashProvider };
