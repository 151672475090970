import React, { FunctionComponent } from 'react';
import { Graph } from 'react-d3-graph';
import { Layer } from '../common';
import { d3Config, defaultNodeAxis } from '../config';
import { IEdge } from '../context/integrantGraph/types';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { GraphNode, IIntegrantNode } from './GraphNode';
import { useMediaQuery, useTheme } from '@material-ui/core';

export interface ID3GraphData {
  nodes: IIntegrantNode[];
  links: IEdge[];
}

const D3Graph: FunctionComponent = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(1024));
  const defaultGraphDimension = match ? window.innerWidth : (40 * window.innerWidth) / 100;
  const { integrantsData: { integrantsGraph } } = useIntegrantGraphStore();
  const data: any = integrantsGraph;
  const links: ID3GraphData['links'] = data.links;
  const decorateGraphNodesWithInitialPositioning = (nodes: IIntegrantNode[]): IIntegrantNode[] =>
    nodes.map((n: IIntegrantNode, index) => {
      return index !== 0
        ? {
          ...n,
          x: Math.floor(Math.random() * defaultGraphDimension),
          y: Math.floor(Math.random() * defaultGraphDimension)
        }
        : { ...n, x: defaultNodeAxis, y: defaultNodeAxis };
    });
  const graphConfig = {
    ...d3Config,
    node: {
      ...d3Config.node,
      viewGenerator: (node: any): JSX.Element => <GraphNode node={node} />
    }
  };
  const newData = {
    nodes: data.nodes ? decorateGraphNodesWithInitialPositioning(data.nodes) : [],
    links
  };
  return (
    <Layer className='d3Graph' center fill>
      <Graph config={graphConfig} id="graph" data={newData} />
    </Layer>
  );
};

export { D3Graph };
