import React, { FunctionComponent } from 'react';
import { Interceptors, Provider, Res } from 'use-http';
import { useAuthStore } from '../hooks/useAuthStore';

const Intercept: FunctionComponent<Interceptors> = ({ children }) => {
  const { individual, logout, isLoggedIn } = useAuthStore();
  const options: Partial<Interceptors['request']> = {
    interceptors: {
      request: async ({ options }: { options: RequestInit }): Promise<RequestInit> => {
        options.headers = new Headers({ ...options.headers, Authorization: `Bearer ${individual.access_token}` });
        return options;
      },
      response: async ({ response }: { response: Res<ResponseInit> }): Promise<ResponseInit> => {
        const res = response;
        if (res.status === 401 && isLoggedIn) {
          logout();
        }
        return res;
      }
    }
  };
  return <Provider options={options}>{children}</Provider>;
};

export { Intercept };
