import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
  ResponderProvided
} from 'react-beautiful-dnd';
import { LoadingTitle } from '../types/common';
import { isInheritedRank } from '../utils';
import { Loading } from './Loading';

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): CSSProperties => ({
  userSelect: 'none',
  padding: 0,
  margin: `0 0 ${grid}px 0`,
  backgroundColor: isDragging ? 'white' : 'transparent',
  ...draggableStyle
});

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);

  return list;
}

const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  backgroundColor: isDraggingOver ? 'rgba(0,0,0,0.1)' : 'transparent',
  padding: grid
});

interface IDraggableListProps<T> {
  items: T[];
  loading?: boolean;
  onDragEnd?: (result: T[], provided?: ResponderProvided) => void;
  renderItem: (item: T) => ReactNode;
}

const DraggableList = <T extends { id: string; title: string; description: string; rank: number }>(
  props: IDraggableListProps<T> & { children?: ReactNode }
): JSX.Element => {
  const { onDragEnd, items, renderItem, loading } = props;

  const [list, setList] = useState<T[]>(items);

  useEffect(() => {
    setList(items);
  }, [items]);

  const handleDrag = (result: DropResult): void => {
    if (typeof result.destination !== 'undefined' && result.destination !== null) {
      const updatedList = reorder<T>(list, result.source.index, result.destination.index);
      setList(updatedList);
      if (typeof onDragEnd !== 'undefined') {
        onDragEnd(updatedList);
      }
    }
  };

  return loading === true ? (
    <Loading title={LoadingTitle.Updating} />
  ) : (
    <DragDropContext onDragEnd={(result: DropResult): void => handleDrag(result)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot): JSX.Element => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {items.map((item, index) => {
              return (
                <Draggable
                  isDragDisabled={isInheritedRank(item.rank)}
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot): JSX.Element => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {renderItem(item)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DraggableList };
