import React, { FunctionComponent, useEffect, useState } from 'react';
import { Layer } from '../.././common/Layer';
import { CreateNewBatchButton } from '../../components/CreateNewBatchButton';
import { SelectBatches } from '../../components/SelectBatches';
import { SelectedProductInfo } from '../../components/SelectedProductInfo';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import { rightContentWrapperStyle } from '../../utils';
import {useTheme,useMediaQuery} from "@material-ui/core"
import { MdClose } from 'react-icons/md'
import "../../styles/home.css";
import { integrant_type_ } from '../../common/models';

const CreateViewBatchesSlide: FunctionComponent = () => {
  const { selectedIntegrantType, onSelectIntegrantType } = useIntegrantTypeStore();
  const [selectedIntegrantTypeId, setSelectedIntegrantTypeId] = useState('');
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));

  const handleClick = (): void => {
    onSelectIntegrantType(integrant_type_);
  };  

  useEffect(() => {
    if (typeof selectedIntegrantType !== 'undefined') {
      setSelectedIntegrantTypeId(selectedIntegrantType.id);
    } else {
      setSelectedIntegrantTypeId('');
    }
  }, [selectedIntegrantType]); 
  
  return (
    <Layer direction="column" style={rightContentWrapperStyle(selectedIntegrantTypeId)} width={match  ? '100%' : '50%'} height={'100%'}>
      {selectedIntegrantTypeId ? (
        <Layer fill direction="column">
          <MdClose className='homepage-rightside-close-icon' onClick={handleClick} />
          <SelectedProductInfo />
          <CreateNewBatchButton />
          <SelectBatches integrantTypeIdProps={selectedIntegrantTypeId} />
        </Layer>
      ) : null}
    </Layer>
  );
};

export { CreateViewBatchesSlide };