import React, { CSSProperties, FunctionComponent } from 'react';

export interface IBaseProps {
  disabled?: boolean;
  display?: CSSProperties['display'];
  direction?: CSSProperties['flexDirection'];
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  fill?: boolean;
  center?: boolean;
  align?: 'top' | 'left' | 'right' | 'bottom';
  style?: CSSProperties;
  scrollY?: boolean;
  setRef?: string | React.RefObject<HTMLDivElement> | null | undefined;
  className?: string;
}

export interface ILayerProps extends IBaseProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Layer: FunctionComponent<ILayerProps> = ({
  direction = 'row',
  display = 'flex',
  height = 'auto',
  width = 'auto',
  fill = false,
  center = false,
  scrollY = false,
  children,
  style,
  onClick,
  disabled = false,
  setRef,
  className = ''
}) => {
  const layerStyle: CSSProperties = {
    display: display,
    flex: fill ? '1 100%' : 'initial',
    flexDirection: direction,
    height: fill ? '100%' : height,
    width: fill ? '100%' : width,
    justifyContent: center ? 'center' : 'normal',
    alignItems: center ? 'center' : 'normal',
    transition: 'all 350ms cubic-bezier(0.390, 0.575, 0.565, 1.000)',
    overflowY: scrollY ? 'auto' : 'initial',
    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? 'none' : 'inherit',
    cursor: disabled ? 'wait' : 'default',
    ...style
  };
  return (
    <div ref={setRef} className={className} onClick={onClick} style={layerStyle}>
      {children}
    </div>
  );
};

export { Layer };
