import { PrimaryButton } from 'common/Button/buttons';
import React, { FunctionComponent } from 'react';
import { isClientId } from '../config';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { isEmptyString, isUndefined } from '../utils';
import {Grid} from '@material-ui/core';

const RemoveIntegrant: FunctionComponent = () => {
  const { isRemoveLoading, onRemove } = useIntegrantPaneStore();
  const { currentIntegrant, currentParentId, currentEdge } = useIntegrantGraphStore();

  return !isEmptyString(currentParentId) && !isClientId(currentIntegrant.id) && !isUndefined(currentEdge) ? (
      <Grid style={{marginBottom:"10px"}}>
        <PrimaryButton disabled={isRemoveLoading || isUndefined(currentEdge.edge_id)}
      onClick={(): Promise<void> =>
        onRemove(
          currentEdge.source,
          typeof currentEdge.edge_id !== 'undefined' && currentEdge.edge_id !== '' ? currentEdge.edge_id : ''
        )
      } icon='delete' loading={isRemoveLoading} children={`Remove ${currentIntegrant.title}`} />
      </Grid>
  ) : null;
};

export { RemoveIntegrant };
