// import React, { useEffect, useRef } from 'react';
// import { Chart } from 'chart.js';

// interface IData {
//     total: number;
//     success: number;
//     fail: number;
//     verifier_org: string | null;
//     organization_name?: string;
// }

// interface IProps {
//     data: IData[];
// }

// const PieChart: React.FC<IProps> = ({ data }) => {
//     const chartRef = useRef<HTMLCanvasElement>(null);
//     const chartInstance = useRef<any>();
//     const chartDataCopy = data
//     useEffect(() => {
//         if (chartRef.current) {
//             const ctx = chartRef.current.getContext('2d');
//             if (ctx) {
//                 const chartData = {
//                     labels: data.map((item, index) => item.organization_name || `Org ${index + 1}`),
//                     datasets: [{
//                         label: 'Results',
//                         data: data.map(item => item.total),
//                         backgroundColor: [
//                             'rgba(255, 99, 132, 0.5)',
//                             'rgba(54, 162, 235, 0.5)',
//                             'rgba(255, 206, 86, 0.5)',
//                             'rgba(75, 192, 192, 0.5)',
//                             'rgba(153, 102, 255, 0.5)',
//                             'rgba(255, 159, 64, 0.5)',
//                             'rgba(255, 99, 132, 0.5)',
//                         ],
//                         borderColor: [
//                             'rgba(255, 99, 132, 1)',
//                             'rgba(54, 162, 235, 1)',
//                             'rgba(255, 206, 86, 1)',
//                             'rgba(75, 192, 192, 1)',
//                             'rgba(153, 102, 255, 1)',
//                             'rgba(255, 159, 64, 1)',
//                             'rgba(255, 99, 132, 1)',
//                         ],
//                         borderWidth: 1,
//                     }],
//                 };

//                 if (chartInstance.current) {
//                     chartInstance.current.destroy();
//                 }

//                 chartInstance.current = new Chart(ctx, {
//                     type: 'pie',
//                     data: chartData,
//                     options: {
//                         responsive: true,
//                         plugins: {
//                             legend: {
//                                 position: 'top',
//                             },
//                             title: {
//                                 display: true,
//                                 text: 'Organization documents statistics',
//                             },
//                             tooltip: {
//                                 callbacks: {
//                                     label: function (context) {
//                                         const label = context.label || '';
//                                         const data: any = chartDataCopy[context.dataIndex]
//                                         const dataset = context.dataset;
//                                         const total = dataset.data.reduce((acc: number, cur: number) => acc + cur, 0);
//                                         const percent = Math.round((data.fail / total) * 100);
//                                         const successCount = data.success;
//                                         const failCount = data.fail;
//                                         return `${label}: ${percent}% (Total : ${data.total},Success: ${successCount}, Fail: ${failCount})`;
//                                     }
//                                 }
//                             }

//                         },
//                     },
//                 });
//             }
//         }

//         return () => {
//             if (chartInstance.current) {
//                 chartInstance.current.destroy();
//             }
//         };
//     }, [data]);

//     return (
//         <div style={{ margin: '35px 0px', height: '500px', width: '500px' }}>
//             <canvas ref={chartRef}></canvas>
//         </div>
//     );
// };

// export default PieChart;

import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface IData {
  total: number;
  success: number;
  fail: number;
  verifier_org: string | null;
  organization_name?: string;
}

interface IProps {
  data: IData[];
}

const PieChart: React.FC<IProps> = ({ data }) => {
  const successChartRef = useRef<HTMLCanvasElement>(null);
  const failChartRef = useRef<HTMLCanvasElement>(null);
  const successChartInstance = useRef<Chart | any>(null);
  const failChartInstance = useRef<Chart | any>(null);
  const failureColors = [
    'rgba(255,126,79 ,1)',
    'rgba(148,69,55, 0.5)',
    'rgba(183,83,83, 0.5)',
    'rgba(157,38,44, 0.5)',
    'rgba(288,66,52, 0.5)',
    'rgba(255, 110,103, 0.5)'
  ];
  const successColors = [
    'rgba(127,183,146, 0.5)',
    'rgba(126,168,105, 0.5)',
    'rgba(86,148,69, 0.5)',
    'rgba(215,232,180, 0.5)',
    'rgba(36,182,143, 0.5)',
    'rgba(195, 228, 195, 1)'
  ];
  const failureColorsBorder = [
    'rgba(255,126,79 ,1)',
    'rgba(148,69,55, 1)',
    'rgba(183,83,83, 1)',
    'rgba(157,38,44, 1)',
    'rgba(288,66,52, 1)',
    'rgba(255, 110,103, 1)'
  ];
  const successColorsBorder = [
    'rgba(195,128,195,0.7)',
    'rgba(126,168,105, 1)',
    'rgba(86,148,69, 1)',
    'rgba(127,183,146, 1)',
    'rgba(215,232,180, 1)',
    'rgba(36,182,143, 1)'
  ];
  useEffect(() => {
    Chart.register(ChartDataLabels);
    const renderChart = (ctx: CanvasRenderingContext2D | null, chartData: any, isFailChart: boolean) => {
      if (!ctx) return null;
      return new Chart(ctx, {
        type: 'pie',
        data: chartData,
        plugins: [ChartDataLabels],
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: isFailChart ? 'Fail documents statistics' : 'Success documents statistics'
            },
            tooltip: {
              enabled: true
            },
            datalabels: {
              color: '#000',
              display: 'auto',
              formatter: (value: any, context: any) => {
                const label = context.chart.data.labels[context.dataIndex];
                const data = context.chart.data.datasets[0].data[context.dataIndex];
                return `${label} : ${data}`; // Display label and count
              }
            }
          }
        }
      });
    };

    if (successChartRef.current && failChartRef.current) {
      const successCtx = successChartRef.current.getContext('2d');
      const failCtx = failChartRef.current.getContext('2d');

      if (successCtx && failCtx) {
        const successData = data.filter(item => item.success > 0);
        const failData = data.filter(item => item.fail > 0);

        if (successChartInstance.current) {
          successChartInstance.current.destroy();
        }

        if (failChartInstance.current) {
          failChartInstance.current.destroy();
        }

        successChartInstance.current = renderChart(
          successCtx,
          {
            labels: successData.map((item, index) => item.organization_name || `Org ${index + 1}`),
            datasets: [
              {
                label: 'Success Results',
                data: successData.map(item => item.success),
                backgroundColor: successColors,
                borderColor: successColorsBorder,
                borderWidth: 1
              }
            ]
          },
          false
        );

        failChartInstance.current = renderChart(
          failCtx,
          {
            labels: failData.map((item, index) => item.organization_name || `Org ${index + 1}`),
            datasets: [
              {
                label: 'Fail Results',
                data: failData.map(item => item.fail),
                backgroundColor: failureColors,
                borderColor: failureColorsBorder,
                borderWidth: 1
              }
            ]
          },
          true
        );
      }
    }

    return () => {
      if (successChartInstance.current) {
        successChartInstance.current.destroy();
      }
      if (failChartInstance.current) {
        failChartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ margin: '35px 0px', height: '500px', width: '500px' }}>
        <canvas ref={successChartRef}></canvas>
      </div>
      <div style={{ margin: '35px 0px', height: '500px', width: '500px' }}>
        <canvas ref={failChartRef}></canvas>
      </div>
    </div>
  );
};

export default PieChart;
