import { ILocationDTO } from '../../lib/location';
import React, { CSSProperties, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Input } from '../common/Input';
import { Layer } from '../common/Layer';
import { location_ } from '../common/models';
import { labelStyle, ttl, ttl1, labelStylettl } from '../styles/default';
import { isEmptyString } from '../utils';
import { AddressView } from './AddressView';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { useIntegrantsService } from '../hooks/api/useIntegrantsService';
import { useParams } from 'react-router-dom';
import '../styles/location.css';
import { PrimaryButton } from 'common/Button/buttons';

interface ILocationProps {
  isVisible: boolean;
  currentLocation: ILocationDTO | null;
  onSaveLocation: (currentLocation: ILocationDTO) => void;
  locationWrapperStyle?: CSSProperties;
  isSaveButtonLoading?: boolean;
  isFormVisible?: boolean;
  isTransfer?: boolean;
  isSavebutton?: boolean;
}

const LocationEditable: FunctionComponent<{
  onSaveLocation: (currentLocation: ILocationDTO) => void;
  setEditable: (isEditable: boolean) => void;
  location: ILocationDTO;
  isSaveButtonLoading: boolean;
}> = ({ setEditable, location, onSaveLocation, isSaveButtonLoading }) => {
  //const [currentLocation, setLocation] = useState(location);
  const { id, line_1 } = location;
  const isLoading = isSaveButtonLoading;
  const { locationList, locationSuggestions } = useIntegrantsStore();

  const [isExistingLoc, setExistingLoc] = useState(line_1 !== '' ? (id ? 1 : 2) : 0);

  const [currentlocationId, setCurrentlocationID] = useState(id ? id : '');
  const [CurrentlocationObj, setCurrentlocationObj] = useState(location);

  const [isLocField, setLocField] = useState(line_1 !== '' ? true : false);

  const onChange = (key: string, value: string): void => {
    setCurrentlocationObj({ ...CurrentlocationObj, [key]: value });
  };

  useEffect(() => {
    locationSuggestions();
  }, []);

  function currentlocation(e: any) {
    const { value } = e.target;
    if (value === '') {
      setCurrentlocationID('');
      setCurrentlocationObj(location_);
      setLocField(false);
      return false;
    } else {
      locationList.forEach((element: any) => {
        if (element.id === value) {
          setCurrentlocationID(value);
          setCurrentlocationObj(element);
          setLocField(true);
        }
      });
    }
  }

  function setExistingLocation(locType: number) {
    setCurrentlocationID('');
    if (locType === 1) {
      setExistingLoc(1);
      setLocField(false);
      setCurrentlocationObj(location_);
    }
    if (locType === 2) {
      setExistingLoc(2);
      setLocField(true);
      setCurrentlocationObj(location_);
    }
  }

  const onSave = (): void => {
    onSaveLocation(CurrentlocationObj);
    setEditable(false);
  };

  // const onCancel = (): void => {
  //   setCurrentlocationObj(location);
  //   setEditable(false);
  // };

  return (
    <div>
      <div className="existing-location">
        <p className="el-title">Do you want to select an existing location?</p>
        <div className="el-btn-panel">
          <div
            onClick={() => setExistingLocation(1)}
            className={'el-yes el-btn ' + (isExistingLoc === 1 && ' active ')}
          >
            Yes
          </div>
          <div onClick={() => setExistingLocation(2)} className={'el-no el-btn ' + (isExistingLoc === 2 && ' active ')}>
            No
          </div>
        </div>
      </div>
      {isExistingLoc === 1 && (
        <div>
          <h5 style={labelStyle}>
            Select location<span className="asterisk-symbol">*</span>
          </h5>
          <select value={currentlocationId} onChange={val => currentlocation(val)}>
            <option value="">Please Select</option>
            {locationList &&
              locationList.map((item: any) => {
                const { line_1, line_2, city, state, zip, id, description } = item;
                if (description) {
                  return (
                    <option value={id} key={id}>
                      {description} - {line_1} {line_2} {city} {state} {zip}
                    </option>
                  );
                }
                return (
                  <option value={id} key={id}>
                    {line_1} {line_2} {city} {state} {zip}
                  </option>
                );
              })}
          </select>
        </div>
      )}
      {isLocField && (
        <Layer direction="column" fill>
          <h5 style={labelStyle}>
            Address Type<span className="asterisk-symbol">*</span>
          </h5>
          <Input
            autoFocus={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const inputValue = e.target.value;
              onChange('description', inputValue);
            }}
            value={CurrentlocationObj.description}
            placeholder={'e.g. Corporate Office, Manufacturing Facility or Nebraska Farm...'}
          />
          <h5 style={labelStyle}>
            Address<span className="asterisk-symbol">*</span>
          </h5>
          <>
            <Layer direction="column">
              <Input
                autoFocus={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const inputValue = e.target.value;
                  onChange('line_1', inputValue);
                }}
                value={CurrentlocationObj.line_1}
                placeholder={'Line 1'}
              />
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const inputValue = e.target.value;
                  onChange('line_2', inputValue);
                }}
                value={CurrentlocationObj.line_2 !== null ? CurrentlocationObj.line_2 : ''}
                placeholder={'Line 2'}
              />
            </Layer>
            <CountryDropdown
              valueType={'short'}
              value={CurrentlocationObj.country}
              onChange={(e): void => {
                const inputValue = e;
                onChange('country', inputValue);
              }}
            />
            {CurrentlocationObj.country !== '' ? (
              <Layer className="locationSCZRootDiv" direction="row">
                <RegionDropdown
                  valueType="short"
                  country={CurrentlocationObj.country}
                  countryValueType="short"
                  value={CurrentlocationObj.state}
                  onChange={(e): void => {
                    const inputValue = e;
                    onChange('state', inputValue);
                  }}
                />
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const inputValue = e.target.value;
                    onChange('city', inputValue);
                  }}
                  value={CurrentlocationObj.city}
                  placeholder={'City'}
                />
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const inputValue = e.target.value;
                    onChange('zip', inputValue);
                  }}
                  value={CurrentlocationObj.zip}
                  placeholder={'Zip'}
                />
              </Layer>
            ) : null}
          </>
          <div style={{ paddingTop: 10 }}>
            <PrimaryButton
              children={'Save'}
              maxContent={true}
              icon="save"
              disabled={
                CurrentlocationObj.description === '' ||
                CurrentlocationObj.zip === '' ||
                CurrentlocationObj.state === ''
              }
              onClick={onSave}
              secondaryButton={true}
              loading={isLoading}
            />
            {/* <button onClick={onCancel} style={formButtonStyle}>
          Cancel
        </button> */}
          </div>
        </Layer>
      )}
    </div>
  );
};

const Location: FunctionComponent<ILocationProps> = ({
  currentLocation,
  isVisible,
  onSaveLocation,
  locationWrapperStyle,
  isSaveButtonLoading = false,
  isFormVisible = false,
  isTransfer = false,
  isSavebutton = false
}) => {
  const [isEditableLocation, setEditableLocation] = useState(isFormVisible);

  useEffect(() => {
    if (isFormVisible === false && currentLocation?.line_1 !== '') {
      setEditableLocation(false);
    } else {
      setEditableLocation(true);
    }
  }, [currentLocation, isFormVisible]);

  const renderLocation = (): ReactNode => {
    if (currentLocation !== null && !isEditableLocation) {
      const { state, zip } = currentLocation;
      return !isEmptyString(zip) && !isEmptyString(state) ? (
        <div>
          <AddressView location={currentLocation} />
          <div style={{ paddingTop: 10 }}>
            <PrimaryButton
              icon="edit"
              onClick={(): void => setEditableLocation(!isEditableLocation)}
              children={'Update Location'}
            />
          </div>
        </div>
      ) : null;
    } else {
      return (
        <div>
          <PrimaryButton
            icon="add"
            onClick={(): void => setEditableLocation(!isEditableLocation)}
            children={'Add Location'}
          />
        </div>
      );
    }
  };

  if (isSavebutton) {
    return (
      <Layer style={locationWrapperStyle} direction="column">
        <AttachmentLocation onSaveLocation={onSaveLocation} />
      </Layer>
    );
  }

  if (isSavebutton || (isFormVisible && isVisible)) {
    return (
      <Layer style={locationWrapperStyle} direction="column">
        <TransitTransferLocation isTransfer={isTransfer} isEditableLocation={isEditableLocation} />
      </Layer>
    );
  }

  return isVisible ? (
    <Layer style={locationWrapperStyle} direction="column">
      {/* <Button
        onClick={(): void => setEditableLocation(!isEditableLocation)}
        style={{ ...wideDefaultButtonStyle, width: 'auto' }}
      >
        <GoPlus style={iconStyle} />
        <span>Add Location</span>
      </Button> */}
      {isEditableLocation ? (
        <LocationEditable
          isSaveButtonLoading={isSaveButtonLoading}
          onSaveLocation={onSaveLocation}
          setEditable={setEditableLocation}
          location={currentLocation !== null ? currentLocation : location_}
        />
      ) : (
        renderLocation()
      )}
    </Layer>
  ) : null;
};

export { Location };

const LocationFormat: FunctionComponent<{
  location: ILocationDTO;
  name: string;
  organization_id?: string;
  onChange: (key: string, value: any, name: string) => void;
  isDynamicLocation: any;
  locationType?: any;
}> = ({ location, name, organization_id, onChange, isDynamicLocation = false, locationType }) => {
  const { id, line_1 } = location;
  const [locationData, setLocationData] = useState([]);
  const { locationSuggestionsReturn } = useIntegrantsStore();
  const [currentlocationId, setCurrentlocationID] = useState(id ? id : '');
  const [isExistingLoc, setExistingLoc] = useState(line_1 !== '' ? (id ? 1 : 2) : 0);
  const [isLocField, setLocField] = useState(line_1 !== '' ? true : false);
  const [CurrentlocationObj, setCurrentlocationObj] = useState(location);
  useEffect(() => {
    if (typeof organization_id === 'string' && location.line_1 === '') {
      setExistingLoc(0);
      setLocField(false);
      setCurrentlocationID('');
    }
  }, [organization_id]);

  const actionYes = async (): Promise<void> => {
    const locationlist = await locationSuggestionsReturn(organization_id);
    setLocationData(locationlist);
    setExistingLoc(1);
  };

  useEffect(() => {
    if (location?.id) {
      actionYes();
      setCurrentlocationObj(location);
      setCurrentlocationID(location?.id);
      setExistingLoc(location?.line_1 !== '' ? (location?.id ? 1 : 2) : 0);
      setLocField(location?.line_1 !== '' ? true : false);
    }
  }, [location]);

  function currentlocation(e: any) {
    const { value } = e.target;
    if (value === '') {
      setCurrentlocationID('');
      setCurrentlocationObj(location_);
      setLocField(false);
      return false;
    } else {
      locationData.forEach((element: any) => {
        if (element.id === value) {
          setCurrentlocationID(value);
          setCurrentlocationObj(element);
          setLocField(true);
          onChange(name, element, name);
        }
      });
    }
  }

  function setExistingLocation(locType: number) {
    setCurrentlocationID('');
    setCurrentlocationObj(location_);
    onChange(name, location_, name);
    if (locType === 1) {
      setExistingLoc(1);
      setLocField(false);
    }
    if (locType === 2) {
      setExistingLoc(2);
      setLocField(true);
    }
  }

  function onChangeInput(fieldName: string, value: string) {
    let cloneCurrentlocationObj01: any = { ...CurrentlocationObj };
    cloneCurrentlocationObj01[fieldName] = value;
    onChange(fieldName, value, name);
    setCurrentlocationObj(cloneCurrentlocationObj01);
  }

  if (locationType == 'AttachmentLocation') {
    isDynamicLocation = false;
  }

  return (
    <div className="existing-location-widthoutsave">
      <>
        {
          <div className="existing-location">
            <p className="el-title">Do you want to select an existing location?</p>
            <div className="el-btn-panel">
              <div
                onClick={() => actionYes()}
                className={
                  'el-yes el-btn ' + (isExistingLoc === 1 && ' active ') + (isDynamicLocation && ' disabled-from-btn')
                }
              >
                Yes
              </div>
              <div
                onClick={() => setExistingLocation(2)}
                className={'el-no el-btn ' + (isExistingLoc === 2 && ' active ')}
                style={{ pointerEvents: isDynamicLocation && 'none', cursor: isDynamicLocation && 'initial' }}
              >
                No
              </div>
            </div>
          </div>
        }
        {isExistingLoc === 1 && (
          <div>
            <h5 style={labelStyle}>Select location</h5>
            <select
              value={currentlocationId}
              name=""
              onChange={val => currentlocation(val)}
              disabled={isDynamicLocation}
            >
              <option value="">Please Select</option>
              {locationData &&
                locationData.map((item: any) => {
                  const { line_1, line_2, city, state, zip, id, description } = item;
                  if (description) {
                    return (
                      <option value={id} key={id}>
                        {description} - {line_1} {line_2} {city} {state} {zip}
                      </option>
                    );
                  }
                  return (
                    <option value={id} key={id}>
                      {line_1} {line_2} {city} {state} {zip}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {(isLocField || isDynamicLocation) && (
          <Layer direction="column" fill>
            <h5 style={labelStyle}>Address Type</h5>
            <Input
              autoFocus={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                const inputValue = e.target.value;
                onChangeInput('description', inputValue);
              }}
              value={CurrentlocationObj.description}
              placeholder={'e.g. Corporate Office, Manufacturing Facility or Nebraska Farm...'}
              disabled={isDynamicLocation}
            />
            <h5 style={labelStyle}>Address</h5>
            <>
              <Layer direction="column">
                <Input
                  autoFocus={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const inputValue = e.target.value;
                    onChangeInput('line_1', inputValue);
                  }}
                  value={CurrentlocationObj.line_1}
                  placeholder={'Line 1'}
                  disabled={isDynamicLocation}
                />
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const inputValue = e.target.value;
                    onChangeInput('line_2', inputValue);
                  }}
                  value={CurrentlocationObj.line_2 !== null ? CurrentlocationObj.line_2 : ''}
                  placeholder={'Line 2'}
                  disabled={isDynamicLocation}
                />
              </Layer>
              <CountryDropdown
                valueType={'short'}
                value={CurrentlocationObj.country}
                onChange={(e): void => {
                  const inputValue = e;
                  onChangeInput('country', inputValue);
                }}
                disabled={isDynamicLocation}
              />
              {CurrentlocationObj.country !== '' ? (
                <Layer direction="row">
                  <RegionDropdown
                    valueType="short"
                    country={CurrentlocationObj.country}
                    countryValueType="short"
                    value={CurrentlocationObj.state}
                    onChange={(e): void => {
                      const inputValue = e;
                      onChangeInput('state', inputValue);
                    }}
                    disabled={isDynamicLocation}
                  />
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      const inputValue = e.target.value;
                      onChangeInput('city', inputValue);
                    }}
                    value={CurrentlocationObj.city}
                    placeholder={'City'}
                    disabled={isDynamicLocation}
                  />
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      const inputValue = e.target.value;
                      onChangeInput('zip', inputValue);
                    }}
                    value={CurrentlocationObj.zip}
                    placeholder={'Zip'}
                    disabled={isDynamicLocation}
                  />
                </Layer>
              ) : null}
            </>
          </Layer>
        )}
      </>
    </div>
  );
};

const TransitTransferLocation: FunctionComponent<{ isTransfer: any; isEditableLocation: boolean }> = ({
  isTransfer,
  isEditableLocation
}) => {
  const { selectedTransferOrganization, organizations } = useCreateTransferStore();
  const { selectedOrganization } = useOrganizationStore();

  const {
    setCurrentTransitLocation,
    from_location,
    to_location,
    effective_date,
    to_organization_id,
    edit_location
  } = useCreateTransitStore();
  const [startDate, setStartDate] = useState(effective_date);
  const transitLocationObj: {
    from_location: ILocationDTO | any;
    to_location: ILocationDTO | any;
    effective_date: any;
    to_organization_id: any;
  } = {
    from_location: from_location,
    to_location: to_location,
    effective_date: effective_date,
    to_organization_id: to_organization_id
  };

  const [transitLocation, settransitLocation] = useState(transitLocationObj);
  const onChange = (key: string, value: string, name: string): void => {
    if (name === 'from_location') {
      if (name === key && typeof value === 'object') {
        transitLocation.from_location = value;
      } else {
        transitLocation.from_location = {
          ...transitLocation.from_location,
          [key]: value
        };
      }
    }
    if (name === 'to_location') {
      if (name === key && typeof value === 'object') {
        transitLocation.to_location = value;
      } else {
        transitLocation.to_location = {
          ...transitLocation.to_location,
          [key]: value
        };
      }
    }
    if (name === 'to_organization_id') {
      transitLocation.to_organization_id = value;
      transitLocation.to_location = location_;
    }
    setCurrentTransitLocation(transitLocation);
    settransitLocation(transitLocation);
  };
  function dateFunction(date: any) {
    setStartDate(date);
    transitLocation.effective_date = date;
    setCurrentTransitLocation(transitLocation);
    settransitLocation(transitLocation);
  }

  const { getDetail } = useIntegrantsService();
  const { batchId } = useParams();
  const [isDynamicLocation, setIsDynamicLocation] = useState(false);

  useEffect(() => {
    (async () => {
      if (typeof batchId === 'string' && !edit_location) {
        let getIntegrants = await getDetail(batchId);
        if (getIntegrants?.integrantInfo?.transit?.length) {
          let obj = { ...transitLocation };
          obj['from_location'] = getIntegrants?.integrantInfo?.transit[0].toLocation;
          setCurrentTransitLocation(obj);
          settransitLocation(obj);
          setIsDynamicLocation(true);
        } else if (getIntegrants?.integrantInfo?.location_id) {
          let obj = { ...transitLocation };
          obj['from_location'] = getIntegrants?.integrantInfo?.location;
          setCurrentTransitLocation(obj);
          settransitLocation(obj);
          setIsDynamicLocation(true);
        }
      }
    })();
  }, [batchId]);

  useEffect(() => {
    if (edit_location) {
      setIsDynamicLocation(true);
    } else {
      setIsDynamicLocation(false);
    }
  }, [edit_location]);

  return (
    <Layer direction="column">
      {isTransfer && (
        <div style={ttl1}>
          <p style={labelStylettl}>
            To Organization<span style={{ color: 'red' }}>*</span>
          </p>
          <select
            name="to_organization_id"
            value={to_organization_id}
            onChange={e => onChange('to_organization_id', e.target.value, 'to_organization_id')}
          >
            {organizations &&
              organizations.map((item: any) => {
                const { name, id } = item;
                if (selectedOrganization && selectedOrganization.id === id) {
                  return false;
                }
                return (
                  <option value={id} key={id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </div>
      )}
      <div style={ttl1}>
        <p style={labelStylettl}>
          From Location<span style={{ color: 'red' }}>*</span>
        </p>
        <LocationFormat
          location={transitLocation.from_location}
          name="from_location"
          isDynamicLocation={isDynamicLocation}
          onChange={onChange}
        />
      </div>

      <div style={ttl1}>
        <p style={labelStylettl}>
          To Location<span style={{ color: 'red' }}>*</span>
        </p>
        <LocationFormat
          organization_id={to_organization_id}
          location={transitLocation.to_location}
          name="to_location"
          isDynamicLocation={false}
          onChange={onChange}
        />
      </div>

      <div style={ttl}>
        <p style={labelStylettl}>
          Effective Date<span style={{ color: 'red' }}>*</span>
        </p>
        <div className="location-date">
          <DatePicker
            selected={startDate}
            onChange={(date: any) => dateFunction(date)}
            // onChangeRaw={e => e.preventDefault()}
            dateFormat="MM/dd/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="MM/DD/YYYY"
          />
        </div>
      </div>

      <div style={{ minHeight: 150 }}></div>
    </Layer>
  );
};

const AttachmentLocation: FunctionComponent<{ onSaveLocation: any }> = ({ onSaveLocation }) => {
  const [attachmentlocation, setAttachmentLocation] = useState(location_);
  const onChange = (key: string, value: string): void => {
    if (typeof value === 'object') {
      setAttachmentLocation(value);
      onSaveLocation(value);
    } else {
      let updateLocObj = {
        ...attachmentlocation,
        [key]: value
      };
      onSaveLocation(updateLocObj);
      setAttachmentLocation(updateLocObj);
    }
  };
  return (
    <Layer direction="column">
      <LocationFormat
        location={attachmentlocation}
        name="to_location"
        onChange={onChange}
        isDynamicLocation="true"
        locationType="AttachmentLocation"
      />
    </Layer>
  );
};
