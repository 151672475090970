import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const UpgradeSuccessfully = (props: any) => {
  return <div className="cs">
    <div className="container">
      <p className="page-title">Subscription successfully changed</p>
      <div className='text-center'>
        <p className='_pt15'>Your subscription has been successfully updated.</p>
        <br />
        <Link to="/subscription" className='btn-subscription'>Subscription & Billing</Link>
      </div>
    </div >
  </div >
};

export { UpgradeSuccessfully };
