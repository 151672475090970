import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ToastProvider, ToastContext, IToastProviderState } from '../context/toastContext';

const errorMes: any = {
  isLoading: "Loading... Please Wait",
  isError: "Something went wrong please try again later",
  success: "Successful",
  warn: "Warning",
  pending: 'Loading... Please Wait',
  error: 'Something went wrong please try again later'
}

interface IToastStore {
  isOpenToast: (toastType: string, toastText?: string) => void;
  isGroupToast: (toastType?: string, toastText?: string) => void;
}

const useToastStore = (): IToastStore => {
  const isOpenToast: IToastStore['isOpenToast'] = (toastType, toastText): void => {
    toast.dismiss();
    switch (toastType) {
      case 'isLoading':
        toast.info(errorMes.isLoading);
        break;
      case 'warn':
        toast.warn(toastText ? toastText : errorMes[toastType]);
        break;
      case 'success':
        toast.success(toastText ? toastText : errorMes[toastType]);
        break;
      case 'isError':
        toast.error(toastText ? toastText : errorMes[toastType]);
        break;
      default:
    }
  };
  const isGroupToast: IToastStore['isGroupToast'] = (toastType: any, toastText: any): void => {
    let id: any = toast.loading("Please wait...");
    toast.update(id, { render: toastText ? toastText : errorMes[toastType], type: toastType, isLoading: false });
  }
  return {
    isOpenToast,
    isGroupToast
  };
};

export { useToastStore };
