import React, { useState } from 'react';
import { APIResponseDefaultState } from 'static/commonDefaultState';
import { IAPIResponseDTO } from '../../lib/common';
import { CreateContext } from '../types/common';

export interface ISurveyProviderState {
  surveySubmissionList: IAPIResponseDTO
}

export const defaults = { 
    surveySubmissionList: {...APIResponseDefaultState, chartData: null}
};

const SurveyContext = React.createContext<CreateContext<ISurveyProviderState>>([
  defaults,
  (): ISurveyProviderState => defaults
]);

const SurveyProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ISurveyProviderState>(defaults);
  return <SurveyContext.Provider value={[state, setState]}>{props.children}</SurveyContext.Provider>;
};

export { SurveyContext, SurveyProvider };
