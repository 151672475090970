import React,{FunctionComponent} from 'react';
import {CircularProgress,makeStyles,Theme, createStyles} from '@material-ui/core';
import clsx from 'clsx';

interface IMuiCircularProgressProps{
    size?: number;
    color?: string;
    style?: any;
}

const defaultProps:IMuiCircularProgressProps = {
    size: 20,
    color: "primary",
    style: {}
}

const useStyle = makeStyles((theme:Theme)=>createStyles({
    primaryColor:{
        color: '#008F2B'
    }
}))

export const  MuiCircularProgress: FunctionComponent<IMuiCircularProgressProps> = ({color,size, style}) => {
    const classes = useStyle();
  return (
    <CircularProgress size={size} className={clsx(color === "primary" && classes.primaryColor)} style={ color !== "primary"  ? {color, ...style} : style} />
  )
}

MuiCircularProgress.defaultProps = defaultProps;

export default MuiCircularProgress;
