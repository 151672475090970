import { ICreateExhibitDTO, IExhibitDTO } from '../../../lib/exhibit';
import { ICreateTransitDTO, ITransitDTO } from '../../../lib/transit';
import { useCallback } from 'react';
import useFetch from 'use-http';
import { formDataConfig, url, requestDataConfigGet } from '../../config';
import { useAuthStore } from '../useAuthStore';
import { useIntegrantGraphStore } from '../useIntegrantGraphStore';

interface ITransitsService {
  isLoading: boolean;
  postTransit: (createTransit: ICreateTransitDTO) => Promise<ITransitDTO>;
  deleteTransit: (transit_id: string) => Promise<ITransitDTO>;
  postTransitExhibit: (transit_id: string, file: File, exhbiit: ICreateExhibitDTO) => Promise<IExhibitDTO>;
  deleteTransitExhibit: (transit_id: string, exhibit_id: string) => Promise<IExhibitDTO>;
  getTransitList: (transit_id: string) => Promise<any>;
}

const useTransitsService = (): ITransitsService => {
  const { currentIntegrant } = useIntegrantGraphStore();
  const integrantsURL = `${url}integrants/${currentIntegrant.id}/`;
  const [request, , isLoading] = useFetch(integrantsURL);
  const { individual } = useAuthStore();
  const postTransit: ITransitsService['postTransit'] = useCallback(
    async createTransit => {
      const updatedTransit: ITransitDTO = await request.post('transits', createTransit);
      return updatedTransit;
    },
    [request]
  );

  const deleteTransit: ITransitsService['deleteTransit'] = useCallback(
    async transit_id => {
      const updatedIntegrant: ITransitDTO = await request.delete(`transits/${transit_id}`);
      return updatedIntegrant;
    },
    [request]
  );

  const postTransitExhibit: ITransitsService['postTransitExhibit'] = async (transit_id, file, exhibit) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('exhibit', JSON.stringify(exhibit));
    const response = await fetch(
      `${integrantsURL}/transits/${transit_id}/exhibits`,
      formDataConfig(formData, individual.access_token)
    );

    return response.json();
  };

  const deleteTransitExhibit: ITransitsService['deleteTransitExhibit'] = async (transit_id, exhibit_id) => {
    const updatedExhibit: IExhibitDTO = await request.delete(
      `${integrantsURL}/transits/${transit_id}/exhibits/${exhibit_id}`
    );
    return updatedExhibit;
  };

  const getTransitList: ITransitsService['getTransitList'] =
    async (transit_id: any) => {
      const transitApiReq: any = await fetch(`${url}/transit/${transit_id}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${individual.access_token}`
        })
      });
      const transitList = await transitApiReq.json()
      return transitList;
    }

  return { isLoading, postTransit, deleteTransit, postTransitExhibit, deleteTransitExhibit, getTransitList };
};

export { useTransitsService };
