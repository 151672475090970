import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Collapse
} from '@material-ui/core';
import { useSurveyStore } from 'hooks/useSurveyStore';
import MuiCircularProgress from 'common/MuiCircularProgress';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { useIntersection } from 'hooks/useIntersection';
import { LazyLoading } from 'common';
Chart.register(...registerables);

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    surveySubmisstionContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700
        }
      }
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    },
    collapseTable: {
      margin: '15px 0px 20px'
    },
    submissionContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      '&>h1': {
        fontSize: '34px',
        lineHeight: '40px',
        fontWeight: 700,
        color: '#000',
        marginBottom: 10
      }
    },
    surveySubmissionChartDiv: {
      height: 300,
      marginBottom: 30,
      '&>div': {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }
    },
    surveySubmissionChart: {
      width: '100%',
      height: '100%'
    }
  })
);

interface ISurveySubmissionProps {
  integrant_type_id: string;
}

let chart: any = null;
let surveySubmissionFlag: boolean = false;

export const SurveySubmission: FunctionComponent<ISurveySubmissionProps> = ({ integrant_type_id }) => {
  const classes = useStyle();
  const limit: number = 10;
  const { initGetSurveySubmissionList, surveySubmissionList } = useSurveyStore();
  const [openCollapseTable, setOpenCollapseTable] = useState<number[]>([]);
  const [rows, setRows] = useState([]);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1
  });
  useEffect(() => {
    const { intersectionRatio }: any = entry;
    let offsetStatus = surveySubmissionList?.data?.length - rows.length > 0;
    if (intersectionRatio && intersectionRatio >= 0.1 && offsetStatus) {
      setRows(rows.concat(surveySubmissionList.data?.slice(rows.length, rows.length + limit) || []));
    }
  }, [entry]);
  useEffect(() => {
    initGetSurveySubmissionList(integrant_type_id);
    surveySubmissionFlag = true;
  }, []);
  useEffect(() => {
    if (!surveySubmissionList?.loading && surveySubmissionList?.data) {
      if (chart) {
        chart?.destroy();
        chart = null;
      }
      const ctx = document.getElementById('myChart') as HTMLCanvasElement;
      if (ctx) {
        chart = new Chart(ctx, {
          type: 'line',
          data: {
            datasets: [
              {
                label: `Survey Responses (${surveySubmissionList?.chartData?.length})`,
                data: surveySubmissionList.chartData,
                backgroundColor: '#008F2B',
                borderColor: 'rgba(0, 143, 43, 0.5)',
                tension: 0.5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  usePointStyle: true
                }
              },
              title: {
                display: true,
                text: 'Customers Survey Submission Chart'
              }
            },
            scales: {
              y: {
                display: false,
                grid: {
                  drawOnChartArea: false
                },
                min: 0
              },
              x: {
                type: 'time',
                grid: {
                  drawOnChartArea: false
                },
                time: {
                  displayFormats: { day: 'MMM YYYY' },
                  tooltipFormat: 'MM/DD/YYYY',
                  unit: 'month'
                },
                ticks: {
                  source: 'data',
                  autoSkip: true
                }
              }
            }
          }
        });
      }
    }
  }, [surveySubmissionList?.chartData?.join(',')]);
  useEffect(() => {
    if (
      !surveySubmissionList?.loading &&
      surveySubmissionList?.status === '1' &&
      surveySubmissionList?.data &&
      surveySubmissionFlag
    ) {
      setRows(surveySubmissionList?.data?.slice(0, limit));
      surveySubmissionFlag = false;
    }
    if (!surveySubmissionList?.loading && surveySubmissionList?.status === '0' && surveySubmissionFlag) {
      setRows([]);
      surveySubmissionFlag = false;
    }
  }, [surveySubmissionList?.loading, surveySubmissionList?.status]);
  return (
    <div className={classes.surveySubmisstionContainer}>
      <div className={classes.surveySubmissionChartDiv}>
        <div>
          {surveySubmissionList?.loading &&
          (!surveySubmissionList?.data || surveySubmissionList?.data?.length === 0) ? (
            <Grid container alignItems="center" justifyContent="center">
              Loading...&nbsp;
              <MuiCircularProgress />
            </Grid>
          ) : surveySubmissionList?.data?.length > 0 ? (
            <canvas id="myChart"></canvas>
          ) : (
            <Grid container alignItems="center" justifyContent="center">
              No survey submissions found.
            </Grid>
          )}
        </div>
      </div>
      <div className={classes.submissionContainer}>
        <Typography variant="h1">Submissions</Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell />
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Batch</TableCell>
                <TableCell align="center">Offer</TableCell>
                <TableCell align="center">Submission Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveySubmissionList?.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <Grid container alignItems="center" justifyContent="center">
                      Loading...&nbsp;
                      <MuiCircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : surveySubmissionList?.data?.length > 0 ? (
                rows?.map((item: any, key: number) => {
                  return (
                    <>
                      <TableRow key={key}>
                        <TableCell align="center">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                              setOpenCollapseTable((pre: number[]) =>
                                pre?.includes(moment(item?.created_on).valueOf())
                                  ? pre?.filter((createdAt: number) => createdAt !== moment(item?.created_on).valueOf())
                                  : [...pre, moment(item?.created_on).valueOf()]
                              )
                            }
                          >
                            {openCollapseTable?.includes(moment(item?.created_on).valueOf()) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">{item?.user?.name}</TableCell>
                        <TableCell align="center">{item?.integrant?.external_id}</TableCell>
                        <TableCell align="center">{item?.offer ? item?.offer : ''}</TableCell>
                        <TableCell align="center">{moment(item?.created_on).format('MM/DD/YYYY hh:mm A')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                          <Collapse
                            in={openCollapseTable?.includes(moment(item?.created_on).valueOf())}
                            timeout="auto"
                            unmountOnExit
                            className={classes.collapseTable}
                          >
                            <Table size="small" className={classes.table}>
                              <TableHead className={classes.tableHead}>
                                <TableRow>
                                  <TableCell align="left">Question</TableCell>
                                  <TableCell align="left">Answer</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item?.questionAnswers && item?.questionAnswers?.length > 0 ? (
                                  item?.questionAnswers?.map((qaRow: any, qaKey: number) => (
                                    <TableRow key={qaKey}>
                                      <TableCell>{qaRow?.question}</TableCell>
                                      <TableCell>{qaRow?.answer}</TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                      No user survey submission found.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    No survey submissions found.
                  </TableCell>
                </TableRow>
              )}
              {surveySubmissionList?.data?.length - rows?.length > 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <LazyLoading setRef={setTarget} />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SurveySubmission;
