import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { labelMeta } from './../../config';
function isUnlimited(value: any) {
    if (value === -1) {
        return "Unlimited"
    }
    return value
}
const PlanDetails = (props: any) => {
    const { prouct_name, prouct_metadata, product_id, children } = props
    if (product_id === undefined) {
        return null
    } else {
        const metaData = Object.keys(prouct_metadata)
        return (
            <div className="sSection">
                <p className="base-header">Order Detail</p>
                <Link to="/plans" className="link-order-plans" >Change Plan</Link>
                <div className="item-cart">
                    <div className="item-cart-name">{prouct_name}</div>
                    <div className="item-cart-type">INCLUDES:</div>
                    <ul>
                        {
                            metaData.length ? metaData.map((item: any, index) => {
                                if (item === 'is_active') {
                                    return null
                                }
                                return <li key={index}>
                                    {isUnlimited(prouct_metadata[item])} {labelMeta[item]}
                                </li>
                            }) : <></>
                        }
                    </ul>
                    <div className="item-cart-type">PRICE DETAILS:</div>
                    {children}
                </div>
            </div>
        )
    }
}
export default PlanDetails