import React, { FunctionComponent } from 'react';
import UseAnimations from 'react-useanimations';
import { Layer } from '../common';

const IntegrantDraftAlert: FunctionComponent<{ isVisible: boolean; title: string; description: string }> = ({
  isVisible,
  title,
  description
}) => {
  return isVisible ? (
    <Layer direction="column" center>
      <UseAnimations play animationKey="alertCircle" size={100} style={{ marginRight: 10 }} />
      <br />
      <strong>{title}</strong>
      <small style={{ padding: '10px' }}>{description}</small>
    </Layer>
  ) : null;
};

export { IntegrantDraftAlert };
