import { IIntegrantTypeDTO } from '../../lib/integrantType';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { useIntegrantTypeStore } from '../hooks/useIntegrantTypeStore';
import { useSuggestIntegrantChildStore } from '../hooks/useSuggestIntegrantChildStore';
import { DropdownContent } from './DropdownContent';
import { ListView } from './ListView';
import { ListItem } from './ListItem';

interface ISuggestIntegrantTypeDropdownProps {
  source: string;
  helperText?: string;
}

const SuggestIntegrantTypeDropdown: FunctionComponent<ISuggestIntegrantTypeDropdownProps> = ({
  source,
  helperText = ''
}) => {
  const { onSelectIntegrantType, currentIntegrant } = useIntegrantGraphStore();
  const { integrants, isLoading, isOpen, onToggleMenu } = useSuggestIntegrantChildStore(currentIntegrant.id);
  const { resetTabs } = useIntegrantPaneStore();
  const { initIntegrantType, integrantTypes } = useIntegrantTypeStore();

  const createIntegrantList = (integrant: IIntegrantTypeDTO): React.ReactNode => {
    return (
      <ListItem title={integrant.title} photoURL={integrant.image_url} external_id_slug={integrant.external_id_slug} />
    );
  };

  const listItemStyle: CSSProperties = {
    padding: 0,
    borderColor: '#ccc',
    marginTop: 10
  };
  const liveSearch = (searchValue: string): void => {
    initIntegrantType(searchValue);
  };

  const renderContent = (): ReactNode => (
    <ListView<IIntegrantTypeDTO>
      style={{ height: '36vh', minHeight: 250 }}
      scrollY
      searchPlaceholder={'Search by name'}
      filter={(integrantType, searchResult): boolean =>
        integrantType.title.toLowerCase().includes(searchResult.toLowerCase())
      }
      onListItemSelect={(integrantType: IIntegrantTypeDTO): void => {
        resetTabs();
        onToggleMenu(false);
        onSelectIntegrantType(integrantType, source);
      }}
      dataSource={integrantTypes.filter(item => {
        return item.title !== currentIntegrant.title;
      })}
      renderListItem={createIntegrantList}
      isLoading={isLoading}
      listItemStyle={(): CSSProperties => listItemStyle}
      isLiveSearch={true}
      liveSearch={liveSearch}
      helperText={helperText}
    />
  );
  return (
    <DropdownContent
      handleCancel={(): void => onToggleMenu(false)}
      isOpen={isOpen}
      renderContent={renderContent}
      type="integrantSuggestions"
    />
  );
};

export { SuggestIntegrantTypeDropdown };
