import React, { useState } from 'react';
import { CreateContext } from '../types/common';

export interface IIntegrantPaneContextState {
  tabIndex: number;
  isLoading: boolean;
  isRemoveLoading: boolean;
  isSaveLoading: boolean;
  isEditableLocation: boolean;
}

const defaults = {
  tabIndex: 0,
  isLoading: false,
  isRemoveLoading: false,
  isSaveLoading: false,
  isEditableLocation: false
};
const IntegrantPaneContext = React.createContext<CreateContext<IIntegrantPaneContextState>>([
  defaults,
  (): IIntegrantPaneContextState => defaults
]);

const IntegrantPaneProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<IIntegrantPaneContextState>(defaults);

  return <IntegrantPaneContext.Provider value={[state, setState]}>{props.children}</IntegrantPaneContext.Provider>;
};

export { IntegrantPaneContext, IntegrantPaneProvider };
