import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import { IAPIResponseDTO } from '../../lib/common';

export interface ITestingLabProviderState {
  createTestingLab: IAPIResponseDTO;
  testingLabs: IAPIResponseDTO;
}

export const defaults = {
  createTestingLab: {
    status: '',
    message: '',
    loading: false,
    data: null
  },
  testingLabs: {
    status: '',
    message: '',
    loading: false,
    data: []
  }
};

const TestingLabContext = React.createContext<CreateContext<ITestingLabProviderState>>([
  defaults,
  (): ITestingLabProviderState => defaults
]);

const TestingLabProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<ITestingLabProviderState>(defaults);
  return <TestingLabContext.Provider value={[state, setState]}>{props.children}</TestingLabContext.Provider>;
};

export { TestingLabContext, TestingLabProvider };
