import { useContext } from 'react';
import { TestingLabContext, ITestingLabProviderState } from '../context/testingLabContext';
import { useExhibitsService } from './api/useExhibitsService';
import { useToastStore } from './useToastStore';

interface ITestingLabStore extends ITestingLabProviderState {
  postTestingLab: (data: any) => any;
  getTestingLabsList: () => any;
}

const useTestingLabStore = (): ITestingLabStore => {
  const [state, setState] = useContext(TestingLabContext);
  const { getTestingLabsApi, createTestingLabApi } = useExhibitsService();
  const { createTestingLab, testingLabs } = state;
  const { isOpenToast } = useToastStore();

  const postTestingLab: ITestingLabStore['postTestingLab'] = async (data: any) => {
    setState(pre => ({ ...pre, createTestingLab: { ...pre.createTestingLab, loading: true } }));
    const response = await createTestingLabApi(data);
    let testingObj: any = null;
    if (response?.data) {
      testingObj = {
        id: response?.data?.id,
        name: response?.data?.name,
        logo_url: response?.data?.logo_url,
        map_thumb_url: response?.data?.map_thumb_url
      };
    }
    isOpenToast(response?.status === '1' ? 'success' : 'isError', response?.message);
    setState(pre => ({
      ...pre,
      createTestingLab: { ...pre.createTestingLab, loading: false, ...response },
      testingLabs: testingObj ? { ...pre.testingLabs, data: [...pre?.testingLabs?.data, testingObj] } : pre.testingLabs
    }));
  };

  const getTestingLabsList: ITestingLabStore['getTestingLabsList'] = async () => {
    setState(pre => ({ ...pre, testingLabs: { ...pre.testingLabs, loading: true } }));
    const response = await getTestingLabsApi();
    setState(pre => ({ ...pre, testingLabs: { ...pre.testingLabs, loading: false, ...response } }));
  };

  return {
    createTestingLab,
    testingLabs,
    postTestingLab,
    getTestingLabsList
  };
};

export { useTestingLabStore };
