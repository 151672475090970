import React, { FunctionComponent,useEffect, useState } from 'react'
import {Grid,makeStyles,Theme,Typography, createStyles} from '@material-ui/core';
import clsx from 'clsx';
import { Chart,registerables } from 'chart.js';
import { useUserStore } from 'hooks/useUserStore';
import MuiCircularProgress from 'common/MuiCircularProgress';
Chart.register(...registerables);

const useStyle = makeStyles((theme: Theme)=>createStyles({
    potentialReachRow:{
        padding: 10,
        boxSizing:"border-box",
        backgroundColor: "#FBFBFB",
        minHeight: 48,
        '&>div':{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            '&:last-child':{
                paddingLeft: 10,
                boxSizing:"border-box"
            }
        }
    },
    potentialReachTexts:{
        '&>*': {
            fontSize: '14px',
            lineHeight:"16px",
        },
        '&>h6':{
            fontWeight: 700,
            color:"#000",
        },
        '&>p':{
            fontWeight: 700,
            color:"#008F2B"
        }
    },
    potentialReachPieChartText:{
        '&>p':{
            color:"#000",
            fontWeight: 200
        }
    },
    potentialReachPieChart:{
        padding: 10,
        boxSizing:"border-box",
        flexDirection:"column",
        '&>div':{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            '&:last-child':{
                marginTop: 10,
            }
        }
    },
    potentialReachChart:{
        width: '100%',
        height: 300,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]:{
            maxWidth: 250
        }
    }
}))

let chart: any = null;

export const PotentialReach: FunctionComponent = () => {
    const {userFilteredCount} = useUserStore();
    const classes = useStyle();
    useEffect(()=>{
        if(!userFilteredCount.loading && userFilteredCount.data){
            if(chart){
                chart.destroy();
                chart = null;
            }
            const ctx = document.getElementById("myChart") as HTMLCanvasElement;
            if(ctx) {
                chart = new Chart(ctx, {
                    type: "doughnut",
                    data: {
                      labels: ["Potential Customers", "Other Customers"],
                      datasets: [
                        {
                          label: "Customers Potentials",
                          data: [userFilteredCount.data?.filteredUserCount, userFilteredCount.data?.totalUsers - userFilteredCount.data?.filteredUserCount],
                          backgroundColor: [
                            "#008F2B",
                            "#DEDEDE",
                          ],
                        }
                      ]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Customers Potentials Chart'
                            }
                        }
                    },
                });
            }
        }
    },[userFilteredCount.data])
    return <Grid item>
        <Grid container className={classes.potentialReachRow}>
            <Grid item md={6} className={classes.potentialReachTexts}>
                <Typography variant="h6">Potential Reach</Typography>
            </Grid>
            <Grid item md={6} className={classes.potentialReachTexts}>
                <Typography variant="body1">{userFilteredCount?.data?.filteredUserCount} Customers</Typography>
            </Grid>
        </Grid>
        <Grid container className={classes.potentialReachPieChart}>
            <Grid item >
                <canvas id="myChart" width={300} height={300} className={classes.potentialReachChart}></canvas>
            </Grid>
            <Grid item className={clsx(classes.potentialReachTexts,classes.potentialReachPieChartText)}>
                <Typography variant="body1">Your audience selection is fairly broad.</Typography>
            </Grid>
        </Grid>
    </Grid>
}

export default PotentialReach
