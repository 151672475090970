import React, { FunctionComponent } from 'react';
import { Layer } from '../common';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { IntegrantTrackingInput } from './IntegrantTrackingInput';
import { PrimaryToggle } from './PrimaryToggle';
import { IIntegrantOptionPaneProps } from '../types/common';

const IntegrantDetails: FunctionComponent<IIntegrantOptionPaneProps> = ({
  isVisible,
  isDisabled
}): JSX.Element | null => {
  const { currentIntegrant } = useIntegrantGraphStore();
  return isVisible ? (
    <Layer className='integrantDetails' disabled={isDisabled} direction="column" fill scrollY>
      <IntegrantTrackingInput
        integrantTrackingId={currentIntegrant.tracking_id}
        buyAgainUrl={currentIntegrant.buy_again_url}
      />
      {/* <h5 style={labelStyle}>Primary Integrant</h5>
      <PrimaryToggle /> */}
    </Layer>
  ) : null;
};

export { IntegrantDetails };
