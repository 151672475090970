import { useContext } from 'react';
import { NotificationContext,INotificationProviderState} from '../context/notificationContext';
import {useNotificationService} from './api/useNotificationService';
import {useOrganizationStore} from './useOrganizationStore';
import {IReadNotificationDTO} from '../../lib/notification';

interface INotificationStore extends INotificationProviderState {
  initNotificationList: ()=> any;
  initReadNotification: (params: IReadNotificationDTO)=> any;
}

const useNotificationStore = (): INotificationStore => {
  const [state, setState] = useContext(NotificationContext);
  const {getNotifications,readNotification} = useNotificationService();
  const {selectedOrganization} = useOrganizationStore()
  const {notificationList,readNotificationData} = state;

  const initNotificationList: INotificationStore["initNotificationList"] = async () => {
    setState({...state,notificationList:{...state.notificationList, loading:true}});
    const data = await getNotifications(selectedOrganization.id)
    setState({...state,notificationList:{...data, loading:false}})
  }

  const initReadNotification: INotificationStore["initReadNotification"] = async (params) => {
    const notificationIds = notificationList?.data?.filter((item: any)=>item?.action_id === params?.action_id && item?.type === params?.type)?.map((item: any)=>item?.id);  
    console.log(notificationIds)
    if(notificationIds?.length){
      setState({...state,readNotificationData:{...state.readNotificationData, loading:true}});
      const data = await readNotification({notificationIds})
      if(data?.status === "1"){
          let obj = {...state};
          if(obj.notificationList.data && obj.notificationList?.data?.length){
              obj["notificationList"]["data"]  = obj?.notificationList?.data?.filter((item: any)=> !data?.data?.includes(item?.id))
          }
          obj["readNotificationData"] = {...data,loading: false};
          setState(obj);
      }else{
          setState({...state,readNotificationData:{...data, loading:false}})
      }
    }
  }

  return {
    notificationList,
    readNotificationData,
    initNotificationList,
    initReadNotification
  };
};

export { useNotificationStore };
