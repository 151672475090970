import React, { FunctionComponent } from 'react';
import { IntegrantsProvider } from '../context/products/integrantsContext';
import { IntegrantTypeProvider } from '../context/products/integrantTypeContext';

const IntegrantTypesWrapper: FunctionComponent = ({ children }) => {
  return (
    <IntegrantsProvider>
      <IntegrantTypeProvider>
        <>{children}</>
      </IntegrantTypeProvider>
    </IntegrantsProvider>
  );
};

export { IntegrantTypesWrapper };
