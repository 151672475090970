import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import { IAPIResponseDTO } from '../../../lib/common';
import axiosInstance from './../../utils/api-interceptor';

interface INIHService {
  searchProduct: (data: any) => Promise<any>;
  getLabelById: (id: number) => Promise<any>;
  productAndBatchesApi: () => Promise<IAPIResponseDTO>;
  getIntegrantDetailApi: (data: any) => Promise<IAPIResponseDTO>;
  readDocumentApi: (data: any) => Promise<IAPIResponseDTO>;
}

const useNIHService = (): INIHService => {
  const { individual } = useAuthStore();
  const searchProduct: INIHService['searchProduct'] = async (data: any) => {
    try {
      return await axiosInstance.get(
        `https://api.ods.od.nih.gov/dsld/v9/browse-products?method=${data?.method}&q=${data?.q}&size=${data?.size}&from=${data?.from}`
      );
    } catch (error) {
      return null;
    }
  };

  const getLabelById: INIHService['getLabelById'] = async (id: number) => {
    try {
      return await axiosInstance.get(`https://api.ods.od.nih.gov/dsld/v9/label/${id}`);
    } catch (error) {
      return null;
    }
  };

  const productAndBatchesApi: INIHService['productAndBatchesApi'] = async () => {
    try {
      return await axiosInstance.get(
        'integrant-type/integrant-type-with-integrant-for-nih',
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  const readDocumentApi: INIHService['readDocumentApi'] = async (data: any) => {
    try {
      return await axiosInstance.post('document-ai/readDocument', data, formAxiosHeader(individual.access_token));
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  const getIntegrantDetailApi: INIHService['getIntegrantDetailApi'] = async (data: any) => {
    try {
      return await axiosInstance.post('client-app/integrant-detail', data);
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.',
        data: null
      };
    }
  };

  return { searchProduct, getLabelById, productAndBatchesApi, getIntegrantDetailApi, readDocumentApi };
};

export { useNIHService };
