import React, { CSSProperties, FunctionComponent } from 'react';
import { defaultInputStyle, errorColor, tabItemStyle } from '../../styles/default';
import { ILayerProps } from '../Layer';

interface IInputProps extends ILayerProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  name?: string;
  checked?: boolean;
  type?:
    | 'button'
    | 'checkbox'
    | 'date'
    | 'password'
    | 'email'
    | 'file'
    | 'number'
    | 'text'
    | 'search'
    | 'submit'
    | 'radio'
    | 'tel';
}

const Input: FunctionComponent<IInputProps> = ({
  style,
  onChange,
  disabled = false,
  required = false,
  autoFocus = false,
  checked = false,
  placeholder = '',
  name = '',
  type = 'text',
  defaultValue,
  value
}) => {
  const inputStyle: CSSProperties = {
    ...tabItemStyle,
    ...defaultInputStyle,
    width: '100%',
    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? 'none' : 'inherit',
    cursor: disabled ? 'wait' : 'inherit',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: required ? errorColor : '#ccc',
    ...style
  };

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    autoFocus,
    placeholder,
    defaultValue,
    required,
    onChange,
    value,
    name,
    type,
    disabled,
    checked
  };
  return <input {...inputProps} style={inputStyle} />;
};

export { Input };
