import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface BarChartProps {
  data: number[];
  labels: string[];
  otherData: any;
}

const BarChart: React.FC<BarChartProps> = ({ data, labels, otherData }) => {
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | any>(null);
  useEffect(() => {
    Chart.register(ChartDataLabels);
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: 'doughnut',
          plugins: [ChartDataLabels],
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Fail Substance',
                data: data,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.6)',
                  'rgba(54, 162, 235, 0.6)',
                  'rgba(255, 206, 86, 0.6)',
                  'rgba(75, 192, 192, 0.6)',
                  'rgba(153, 102, 255, 0.6)'
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1
              }
            ]
          },
          options: {
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: true
              },
              datalabels: {
                color: '#000',
                display: 'auto',
                formatter: (value: any, context: any) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  console.log('context', context);
                  return label;
                }
              }
            }
          }
        });
      }
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, labels]);
  console.log(otherData);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ margin: '35px 0px', height: '500px', width: '500px' }}>
        <canvas ref={chartContainer} />
      </div>
      <div
        style={{
          display: 'flex',
          margin: '35px 0px',
          height: '500px',
          alignItems: 'center',
          width: '500px',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            <b>Total Uploaded doc </b> : {otherData.total}{' '}
          </span>
          <span>
            <b>Success doc </b>: {otherData.success}{' '}
          </span>
          <span>
            <b>Fail doc </b> : {otherData.fail}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
