import React, { FunctionComponent, CSSProperties } from 'react';
import { Layer } from '../.././common/Layer';
import { rightContentWrapperStyle } from '../../utils';
import { useCreateTransferInStore } from '../../hooks/useCreateTransferInStore';
import { isEmptyObj } from '../../utils';
import { titles } from '../../config';
import { IntegrantAlert } from '../../components/IntegrantAlert';
import { CloneTransferIn } from '../../../src/components/CloneTransferIn';
import { ListView } from '../../components/ListView';
import ReactTooltip from 'react-tooltip';
import {useTheme,useMediaQuery} from "@material-ui/core"

const titleStyle: CSSProperties = {
    textOverflow: 'ellipses',
    wordBreak: 'break-word',
    margin: 0
};
const layerStyle: CSSProperties = {
    padding: '20px 10px 0'
};

const createNewBatchButtonStyle = {
    paddingLeft: '10px'
};


const ViewTransferSlide: FunctionComponent = () => {
    const { selectedIntegrantId, selectedIntegrantObj, closeProduct } = useCreateTransferInStore()
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down(768)); 
    if (isEmptyObj(selectedIntegrantObj)) {
        return null
    } else {
        const { transfer, organizations, external_id } = selectedIntegrantObj
        const renderListItem = (product: any): React.ReactNode => {
            const { fromTransferLocation, toTransferLocation, effective_date, description, title } = product
            let date = ''
            if (effective_date !== '') {
                date = new Date(effective_date).getMonth() + 1 + '/' + new Date(effective_date).getDate() + '/' + new Date(effective_date).getFullYear()
            }
            return (<>
                <Layer direction="column">
                    <h5 style={titleStyle}>{title}</h5>
                    <small>{description}</small>
                    <small className='txt-update'>Effective date: {new Date(date).toLocaleString()}</small>
                    {fromTransferLocation ? <small>From Location: {fromTransferLocation.line_1} {fromTransferLocation.city} {fromTransferLocation.state} {fromTransferLocation.country} {fromTransferLocation.zip}</small> : null}
                    {toTransferLocation ? <small>To Location: {toTransferLocation.line_1} {toTransferLocation.city} {toTransferLocation.state} {toTransferLocation.country} {toTransferLocation.zip}</small> : null}
                    <CloneTransferIn integrant={product} />
                </Layer>
            </>
            );
        };
        return <Layer
            width={match ? '100%' :'50%'}
            height={'100%'}
            direction="column"
            style={rightContentWrapperStyle(selectedIntegrantId)}
        >
            <ReactTooltip />
            <div onClick={() => closeProduct()} className="btn-close">+</div>
            <div style={layerStyle}>
                <p>Batch: <strong>{external_id}</strong></p>
                <small>Organization: {organizations.name} ({organizations.tag_line})</small>

                {/* <CloneTransferIn integrant={selectedIntegrantObj} />

                        <div className="title_transit">Transfer Activity</div> */}
                {/* <div className="table_transit">
                            <div className="head_transit row_transit">
                                <div className="t_1">Date</div>
                                <div className="t_1">Title</div>
                                <div className="t_1">Description</div>
                                <div className="t_2">From Location</div>
                                <div className="t_3">To Location</div>
                            </div>
                            <div className="body_transit">
                                {transfer.map((item: any) => {
                                    const { fromTransferLocation, toTransferLocation, effective_date, id, description, title } = item
                                    let date = ''
                                    if (effective_date !== '') {
                                        date = new Date(effective_date).getMonth() + 1 + '/' + new Date(effective_date).getDate() + '/' + new Date(effective_date).getFullYear()
                                    }
                                    return <div key={id} className="row_transit">
                                        <div className="t_1">{date}</div>
                                        <div className="t_1">{title}</div>
                                        <div className="t_1">
                                            {description}
                                        </div>
                                        {fromTransferLocation ? <div className="t_2">{fromTransferLocation.line_1} {fromTransferLocation.city} {fromTransferLocation.state} {fromTransferLocation.country} {fromTransferLocation.zip}</div> : null}
                                        {toTransferLocation ? <div className="t_3">{toTransferLocation.line_1} {toTransferLocation.city} {toTransferLocation.state} {toTransferLocation.country} {toTransferLocation.zip}</div> : null}

                                    </div>
                                })
                                }
                            </div>
                        </div> */}
            </div>
            {/* <small>{organizations.tag_line}</small> */}

            <div className='transferred-list'>
                <ListView
                    searchPlaceholder={`Search by ${titles.transfer} Title`}
                    filter={(integrantType: any, searchResult): boolean =>
                        integrantType.title.toLowerCase().includes(searchResult.toLowerCase())
                    }
                    // onListItemSelect={selectedProduct}
                    renderListItem={renderListItem}
                    dataSource={transfer}
                    listItemStyle={(currItem): CSSProperties => {
                        const isCurr = currItem.id === selectedIntegrantId
                        return {
                            padding: '15px 0 12px',
                            borderWidth: '0 0 1px',
                            borderColor: '#ccc',
                            borderStyle: 'solid',
                            borderRadius: 0
                        };
                    }}
                    emptyTitle={`Looks like no ${titles.organization} have transferred any ${titles.trackingPlural}`}
                />
            </div>
        </Layer >
    }

}

export { ViewTransferSlide };
