import React, { useState } from 'react';
import { integrant_ } from '../../common/models';
import { CreateContext } from '../../types/common';
import { IIntegrantState } from './types';
import { useOrganizationStore } from '../../hooks/useOrganizationStore';

const defaults: IIntegrantState = {
  isLoading: true,
  integrants: [],
  selectedIntegrant: integrant_,
  transferredIntegrants: [],
  locationList: [],
  feedbackList: [],
  categoryList: [],
  surveyQuestionList: [],
  surveyQuestionAnswerList: [],
  surveyQuestionLoading: false,
  offerList: {}
};

const IntegrantsContext = React.createContext<CreateContext<IIntegrantState>>([
  defaults,
  (): IIntegrantState => defaults
]);

const IntegrantsProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const { selectedOrganization } = useOrganizationStore();
  const [state, setState] = useState<IIntegrantState>({
    ...defaults,
    selectedIntegrant: { ...defaults.selectedIntegrant, organization_id: selectedOrganization.id }
  });

  return <IntegrantsContext.Provider value={[state, setState]}>{props.children}</IntegrantsContext.Provider>;
};

export { IntegrantsContext, IntegrantsProvider };
