import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Input, Layer } from '../common';
import { isClientId, titlesTooltips } from '../config';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { isEmptyString } from '../utils';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Icon from '../common/Icon';
import { useIntegrantsStore } from '../hooks/useIntegrantsStore';
import ConfirmationDialog from '../common/Dialogs/ConfirmationDialog';
import { PrimaryButton } from 'common/Button/buttons';
import { Grid, makeStyles, createStyles, Theme, FormHelperText } from '@material-ui/core';
import ReactQuillEditor from 'common/Editors/ReactQuillEditor';
import { useLocation } from 'react-router-dom';

interface IListItemProps<T> {
  isSaveDisabled: boolean;
  item: T;
  handleSave: (currentItem: T) => Promise<void>;
  handleRemove: (currentItemId: string) => Promise<void>;
  handleCancel: (currentItem: T) => Promise<void>;
  handleDeleteTrait: (currentItemId: string) => Promise<void>;
}

interface IEditListItemProps<T> extends IListItemProps<T> {
  renderViewOnlyItem: (item: T) => ReactNode;
  renderTopForm: (currentItem: T) => ReactNode;
  renderBottomForm?: (currentItem: T) => ReactNode;
  item: any;
  type: string;
}

interface IEditableListItemProps<T> extends IListItemProps<T> {
  setEditable: (isEditable: boolean) => void;
  renderTopForm: (currentItem: T) => ReactNode;
  renderBottomForm?: (currentItem: T) => ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'max-content',
      marginTop: 10,
      gap: 10
    },
    reactQuillRoot: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      '&>label': {
        marginBottom: 5,
        color: 'rgb(136, 136, 136)'
      }
    }
  })
);

const itemStyle: CSSProperties = { borderBottom: '1px solid #ccc', paddingTop: 10, paddingBottom: 10 };
const editButtonStyle: CSSProperties = { paddingTop: 3, paddingLeft: 7, marginRight: 5, cursor: 'pointer' };
const editableItemStyle: CSSProperties = { width: '100%', paddingRight: 10, paddingLeft: 10 };

const EditableListItem = <
  T extends {
    id: string;
    title: string;
    description: string;
    effective_date?: any;
    type?: any;
    group_id?: number;
    isOld?: boolean;
  }
>(
  props: IEditableListItemProps<T> & { children?: ReactNode; type?: any }
): JSX.Element => {
  const {
    item,
    handleRemove,
    handleSave,
    setEditable,
    renderTopForm,
    handleCancel,
    isSaveDisabled,
    renderBottomForm,
    type,
    handleDeleteTrait
  } = props;
  const classes = useStyles();
  const { isDraft } = useCreateBatchStore();
  const [currentItem, setItem] = useState(item);
  const [isLoading, setLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const { id, title, description, effective_date, group_id, isOld } = currentItem;

  const onChange = (key: any, value: any): void => {
    if (key === 'group_id') {
      if (value === '') {
        value = null;
      }
    }
    setItem({ ...currentItem, [key]: value });
  };

  const onSave = async (): Promise<void> => {
    setLoading(true);
    await handleSave(currentItem);
    setLoading(false);
    setEditable(false);
  };

  const onCancel = async (): Promise<void> => {
    if (isClientId(currentItem.id) && !item?.title) {
      await handleCancel(currentItem);
    }
    setEditable(false);
  };

  const onDelete = async (): Promise<void> => {
    setDeleteLoading(true);
    await handleRemove(id);
    setDeleteLoading(false);
    setEditable(false);
  };

  const onDeleteTrait = async (): Promise<void> => {
    await handleDeleteTrait(id);
    setEditable(false);
  };

  const isCurrentItemEmpty = isEmptyString(title) || isEmptyString(description);
  const isButtonDisabled = isDeleteLoading || isLoading;

  const renderSaveButton = (isVisible: boolean): JSX.Element | null => {
    return isVisible || type === 'exhibit' ? (
      <>
        <PrimaryButton
          secondaryButton={true}
          maxContent={true}
          children={'Save'}
          disabled={isCurrentItemEmpty || isButtonDisabled || isSaveDisabled}
          onClick={onSave}
          loading={isLoading}
          icon="save"
        />
      </>
    ) : null;
  };

  const dateFunction = (date: any) => {
    onChange('effective_date', date);
  };

  const isServerId = !isClientId(currentItem.id);
  return (
    <div style={editableItemStyle}>
      {renderTopForm(currentItem)}
      <Input
        disabled={isServerId}
        // required={false}
        autoFocus={!isServerId}
        placeholder="Title *"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange('title', e.target.value)}
        defaultValue={title}
      />
      {typeof renderBottomForm !== 'undefined' ? (
        <Input
          autoFocus={isServerId}
          // required={isEmptyString(description)}
          placeholder="Description *"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange('description', e.target.value)}
          defaultValue={description}
        />
      ) : (
        <>
          <div className={classes.reactQuillRoot}>
            <label>Description *</label>
            <ReactQuillEditor data={description} onChange={(str: string) => onChange('description', str)} />
          </div>
          <div className={classes.reactQuillRoot}>
            <label>Select Group</label>
            <select value={group_id} onChange={(evt: any): void => onChange('group_id', evt.target.value)}>
              <option value={''}>None</option>
              {[...Array(10)].map((e, i) => {
                return (
                  <option value={`${i + 1}`} key={i}>
                    Group {i + 1}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}

      {typeof renderBottomForm !== 'undefined' && (
        <div className="attachment-location-date">
          <div className="text-attachment-location">Effective Date: </div>
          <DatePicker
            selected={effective_date}
            onChange={(date: any) => dateFunction(date)}
            // onChangeRaw={e => e.preventDefault()}
            dateFormat="MM/dd/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="MM/DD/YYYY"
          />
        </div>
      )}

      {typeof renderBottomForm !== 'undefined' ? renderBottomForm(currentItem) : null}
      <Grid className={classes.btnRoot}>
        {renderSaveButton(!isCurrentItemEmpty && !isSaveDisabled)}
        {isDraft ? (
          <>
            <PrimaryButton
              maxContent={true}
              // children={['integrantTypeFacets'].includes(type) ? 'Delete' : 'Cancel'}
              children={'Cancel'}
              icon="close"
              disabled={isButtonDisabled}
              onClick={onCancel}
            />

            {!isCurrentItemEmpty && isOld && (
              <PrimaryButton
                maxContent={true}
                children={'Delete'}
                icon="delete"
                loading={isDeleteLoading}
                disabled={isButtonDisabled}
                onClick={onDeleteTrait}
              />
            )}
          </>
        ) : (
          <PrimaryButton
            maxContent={true}
            children={'Delete'}
            icon="delete"
            loading={isDeleteLoading}
            disabled={isButtonDisabled}
            onClick={onDelete}
          />
        )}
      </Grid>
      {typeof renderBottomForm !== 'undefined' && (isCurrentItemEmpty || isButtonDisabled || isSaveDisabled) ? (
        <FormHelperText error={true}>* You Need Title, Description And Attachment File Filled Out.</FormHelperText>
      ) : null}
    </div>
  );
};

const EditListItem = <T extends { id: string; title: string; description: string; rank: number; isOld: boolean }>(
  props: IEditListItemProps<T> & { children?: ReactNode }
): JSX.Element => {
  const [isEditable, setEditable] = useState(false);
  const {
    item,
    handleRemove,
    handleSave,
    renderViewOnlyItem,
    renderTopForm,
    handleCancel,
    isSaveDisabled,
    renderBottomForm,
    type,
    handleDeleteTrait
  } = props;
  const location = useLocation();
  const { title, description, isOld } = props.item;
  const { initDeleteAttachment } = useIntegrantsStore();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleCloseConfirmationDialog = (value: boolean): void => {
    setOpenConfirmationDialog(false);
    if (value) {
      initDeleteAttachment(item.url);
    }
  };

  const handleDelete = (): void => {
    setOpenConfirmationDialog(true);
  };

  useEffect(() => {
    if (isEmptyString(title) || isEmptyString(description)) {
      setEditable(true);
    }
  }, [item, title, description]);

  useEffect(() => {
    return (): void => {
      setEditable(false);
    };
  }, []);
  // isInheritedRank(item.rank)
  return (
    <Layer style={itemStyle} direction="row">
      <ReactTooltip />
      {!isEditable && type !== 'exhibit' ? (
        typeof item.location === 'object' ? (
          <div style={editButtonStyle}>
            <Icon icon="archive" dataTip={titlesTooltips.inherited_facet} />
          </div>
        ) : (
          <div style={editButtonStyle}>
            <Icon icon="edit" onClick={(): void => setEditable(!isEditable)} />
          </div>
        )
      ) : null}
      {isEditable ? (
        <EditableListItem<T>
          handleRemove={handleRemove}
          handleSave={handleSave}
          handleCancel={handleCancel}
          setEditable={setEditable}
          item={item}
          type={type}
          renderTopForm={renderTopForm}
          renderBottomForm={renderBottomForm}
          isSaveDisabled={isSaveDisabled}
          handleDeleteTrait={handleDeleteTrait}
        />
      ) : (
        <>
          {type === 'exhibit' && !location.pathname?.includes('/batches/create') ? (
            <div style={editButtonStyle}>
              <Icon icon="delete" dataTip={titlesTooltips.deleteAttachment} onClick={handleDelete} />
            </div>
          ) : null}
          {renderViewOnlyItem(item)}
        </>
      )}
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleClose={handleCloseConfirmationDialog}
        title="Confirmation"
        message="Are you sure you want to delete this attachment?"
      />
    </Layer>
  );
};

export { EditListItem };
