import { formAxiosHeader } from 'config';
import { useAuthStore } from 'hooks/useAuthStore';
import { IAPIResponseDTO } from '../../../lib/common';
import axiosInstance from '../../utils/api-interceptor';

interface IDocumentHashService {
  getDocToolJWT: (data: any) => Promise<IAPIResponseDTO>;
  createFreeTrialDocument: (data: any) => Promise<IAPIResponseDTO>;
  updateFreeTrialDocument: (data: any) => Promise<IAPIResponseDTO>;
  deleteFreeTrialDocument: (id: string) => Promise<IAPIResponseDTO>;
  getFreeTrialDocuments: (params: any) => Promise<IAPIResponseDTO>;
}

const useDocumentHashService = (): IDocumentHashService => {
  const { individual } = useAuthStore();

  const getDocToolJWT: IDocumentHashService['getDocToolJWT'] = async (data: any) => {
    try {
      return await axiosInstance.post(
        '/document-hash/generate-jwt-token',
        data,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  const createFreeTrialDocument: IDocumentHashService['createFreeTrialDocument'] = async (data: any) => {
    try {
      return await axiosInstance.post(
        '/publisher-free-trial-documents',
        data,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  const updateFreeTrialDocument: IDocumentHashService['updateFreeTrialDocument'] = async (data: any) => {
    try {
      return await axiosInstance.put(
        '/publisher-free-trial-documents/update',
        data,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  const deleteFreeTrialDocument: IDocumentHashService['deleteFreeTrialDocument'] = async (id: string) => {
    try {
      return await axiosInstance.delete(
        `/publisher-free-trial-documents/${id}`,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  const getFreeTrialDocuments: IDocumentHashService['getFreeTrialDocuments'] = async (params: any) => {
    try {
      return await axiosInstance.get(
        `/publisher-free-trial-documents?limit=${params?.limit || '10'}&offset=${params?.offset || '0'}`,
        formAxiosHeader(individual.access_token)
      );
    } catch (error) {
      return {
        status: '0',
        message: 'Something went wrong! Please try after sometime.'
      };
    }
  };

  return {
    getDocToolJWT,
    createFreeTrialDocument,
    deleteFreeTrialDocument,
    getFreeTrialDocuments,
    updateFreeTrialDocument
  };
};

export { useDocumentHashService };
