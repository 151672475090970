import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseAnimations from 'react-useanimations';
import useFetch from 'use-http';
import { useAuthStore } from './../../hooks/useAuthStore';
import {
  labelMeta,
  dateConverter,
  updatedUrl,
  currencyFormat,
  formAxiosHeader,
  subscriptionCategoriesLabel
} from './../../config';
import { Link } from 'react-router-dom';
import { LoadingTitle } from '../../types/common';
import { Loading } from '../../components/Loading';
import axiosInstance from './../../utils/api-interceptor';
import '../../styles/subscription.css';
import { MuiIconButton, PrimaryButton } from 'common/Button/buttons';
import { abbrNum } from 'utils';
import { Tab, Tabs, makeStyles, Theme, Divider, Box, Grid, Typography, Button, createStyles } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tabsRoot: {
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: '#008F2B'
      },
      '&>div>span': {
        backgroundColor: '#008F2B !important'
      },
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      margin: '20px 0'
    },
    tabRoot: {
      textTransform: 'capitalize',
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 400,
      minWidth: 100
    },
    primaryColor: {
      color: '#008F2B'
    },
    primaryBgColor: {
      backgroundColor: '#008F2B'
    },
    tabsHr: {
      margin: '0 70px 30px 70px',
      width: '100%'
    },
    actionBtns: {
      '&>*:not(:last-child)': {
        marginRight: 10
      }
    }
  })
);
const SubscriptionDetails = (props: any) => {
  const history = useHistory();
  const classes = useStyle();
  const { request } = useFetch(updatedUrl);
  const {
    individual: { organization_id, access_token },
    subscriptionList,
    selectedSubscription
  } = useAuthStore();
  const [subscription, getSubscription] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [activeSubscriptionTab, setActiveSubscriptionTab] = useState(selectedSubscription || '');
  async function loadInitialData() {
    await axiosInstance
      .post('subscription/retrieve-subscription', { organization_id }, formAxiosHeader(access_token))
      .then((subscriptionData: any) => {
        if (subscriptionData?.length) {
          getSubscription(subscriptionData);
        }
        setPageLoading(true);
      })
      .catch(err => {
        return err;
      });
  }
  useEffect(() => {
    if (organization_id === null) {
      setPageLoading(true);
    } else {
      loadInitialData();
    }
  }, [axiosInstance]);

  useEffect(() => {
    setActiveSubscriptionTab(selectedSubscription);
  }, [selectedSubscription]);

  if (organization_id === null) {
    return (
      <div className="cs">
        <div className="container">
          <div className="cs-title">
            <div className="d-flex justify-content-between align-items-center">
              <p className="subscription-title">Your Subscription</p>
              <PrimaryButton
                onClick={() => history.push('/plans')}
                icon="detail"
                children={'Complete Your Registration'}
                maxContent={true}
                secondaryButton={true}
              />
            </div>
          </div>
          <br />
          <p>Your registration process not yet completed. Please complete as soon as possible.</p>
        </div>
      </div>
    );
  }
  if (pageLoading && subscription?.length === 0) {
    return (
      <div className="cs">
        <div className="container">
          <div className="cs-title">
            <div className="d-flex justify-content-between align-items-center">
              <p className="subscription-title">Your Subscription</p>
              <PrimaryButton
                onClick={() => history.push('/plans')}
                icon="detail"
                children={'Purchase New Subscription'}
                maxContent={true}
                secondaryButton={true}
              />
            </div>
          </div>
          <br />
          <p>You don't have any active subscription. Please purchase as soon as possible.</p>
        </div>
      </div>
    );
  }
  if (!pageLoading) {
    return (
      <div className="subscription-page-load">
        <Loading title={LoadingTitle.Subscription} />
      </div>
    );
  }

  return (
    <div className="cs">
      <div className="container">
        <div className="cs-title">
          <div className="d-flex justify-content-between align-items-center flex-wrap-wrap">
            <div className="subscription-title">Your Subscriptions</div>
            <PrimaryButton
              onClick={() => history.push('/plans')}
              icon="edit"
              children={'Upgrade Your Subscription'}
              maxContent={true}
              secondaryButton={true}
            />
          </div>
        </div>
        <Tabs
          value={activeSubscriptionTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e: any, newValue: string) => setActiveSubscriptionTab(newValue)}
          className={classes.tabsRoot}
          variant="scrollable"
          scrollButtons="off"
        >
          {Object.entries(subscriptionCategoriesLabel)?.map((item: any, key: number) => {
            if (!subscriptionList.includes(item[0])) return null;
            return <Tab label={item[1]} className={classes.tabRoot} value={item[0]} key={key} />;
          })}
          <Tab label={'Billing'} className={classes.tabRoot} value={'billing'} />
        </Tabs>
        {subscription
          ?.filter((item: any) => item?.subscription_type === activeSubscriptionTab)
          ?.map((item: any, key: number) => {
            const {
              status,
              current_period_start,
              current_period_end,
              product,
              plan,
              nextInvoice,
              invoice,
              currentmetadata,
              subscription_type
            } = item;
            if (typeof product !== 'object') return null;
            return (
              <div className="section-plan" key={key}>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Status</strong>
                  </div>
                  <div className="col text-capitalize">{status}</div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Billing Period</strong>
                  </div>
                  <div className="col">
                    {dateConverter(current_period_start)} - {dateConverter(current_period_end)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Plan</strong>
                  </div>
                  <div className="col">{product.name}</div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Price</strong>
                  </div>
                  <div className="col">
                    <strong>{currencyFormat(plan.amount, plan.currency)}</strong> per {plan.interval}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Subscribe items</strong>
                  </div>
                  <div className="col overflow-auto">
                    <div className="cs-table">
                      <div className="cs-table-head container-fluid">
                        <div className="row no-gutters">
                          <div className="col-6">Items Type</div>
                          <div className="col-2">Allowed Items</div>
                          <div className="col-2">Created Items</div>
                          <div className="col-2">Remaining Items</div>
                        </div>
                      </div>
                      <div className="cs-table-body container-fluid">
                        {Object.keys(product.metadata).map(key => {
                          if (['num_daily_hashes'].includes(key)) {
                            return null;
                          }
                          return (
                            <div key={key} className="row no-gutters">
                              <div className="col-6">{labelMeta[key]}</div>
                              <div className="col-2">{abbrNum(product.metadata[key])}</div>
                              <div className="col-2">{abbrNum(currentmetadata[key])}</div>
                              <div className="col-2">{abbrNum(product.metadata[key] - currentmetadata[key])}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Next invoice</strong>
                  </div>
                  <div className="col">
                    {currencyFormat(nextInvoice.amount_due, nextInvoice.currency)} on{' '}
                    {dateConverter(nextInvoice.next_payment_attempt)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <strong>Credits balance</strong>
                  </div>
                  <div className="col">
                    {currencyFormat(Math.abs(nextInvoice.ending_balance), nextInvoice.currency)}
                  </div>
                </div>
              </div>
            );
          })}
        {activeSubscriptionTab === 'billing' && (
          <div className="section-invoice">
            <div className="overflow-auto">
              <div className="cs-table text-center">
                <div className="cs-table-head container-fluid">
                  <div className="row no-gutters">
                    <div className="col-1">Status</div>
                    <div className="col-2">Plan</div>
                    <div className="col-2">Date</div>
                    <div className="col-2">Amount Due</div>
                    <div className="col-2">Amount Paid</div>
                    <div className="col-2">Billing Reason</div>
                    <div className="col-1">Action</div>
                  </div>
                </div>
                <div className="cs-table-body container-fluid">
                  {subscription[0]?.invoice?.map((item: any) => {
                    return (
                      <div className="row no-gutters" key={item.period_start}>
                        <div className="col-1">
                          <span className="status">{item.status}</span>
                        </div>
                        <div className="col-2">{item?.product_name || 'Free Introduction'}</div>
                        <div className="col-2">{dateConverter(item.period_start)}</div>
                        <div className="col-2">{currencyFormat(item.amount_due, item.currency)}</div>
                        <div className="col-2">{currencyFormat(item.amount_paid, item.currency)}</div>
                        <div className="col-2 text-capitalize">{item.billing_reason.replace('_', ' ')}</div>
                        <div className="col-1 text-right">
                          <Box
                            display={'flex'}
                            alignItems="center"
                            justifyContent={'center'}
                            className={classes.actionBtns}
                          >
                            <MuiIconButton
                              title="View Details"
                              icon="visibility"
                              onClick={(e: any): any => window.open(item.hosted_invoice_url, '_blank')}
                            />
                            <MuiIconButton
                              title="Download PDF"
                              icon="cloudDownload"
                              onClick={(e: any): any => window.open(item.invoice_pdf, '_blank')}
                            />
                          </Box>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { SubscriptionDetails };
