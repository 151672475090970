import React, { FunctionComponent } from 'react';
import { CreateTransferInProvider } from '../context/createTransferInContext';

const TransferInWrapper: FunctionComponent = ({ children }) => {
  return (
    <CreateTransferInProvider>
      <>{children}</>
    </CreateTransferInProvider>
  );
};

export { TransferInWrapper };
