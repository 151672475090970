import React,{FunctionComponent,useEffect,useMemo} from 'react';
import {makeStyles, createStyles,Theme,Grid,Typography} from '@material-ui/core';
import clsx from 'clsx';
import {useUserStore} from '../../hooks/useUserStore';
import { Chart,registerables } from 'chart.js';
Chart.register(...registerables);

const labelName: {[key: string]: string} = {
    gender:"Gender",
    race:"Race",
    age:"Age (in years)",
    marital_status:"Marital Status",
    family_and_dependents:"Family and Dependents",
    education_level:"Education Level",
    general_wellness:"General Wellness",
    height:"Height (in inch)",
    weight:"Weight (in pound)",
};

const useStyle = makeStyles((theme: Theme)=>createStyles({
    potentialReachRow:{
        padding: 10,
        boxSizing:"border-box",
        backgroundColor: "#FBFBFB",
        minHeight: 48,
        '&>div':{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            '&:last-child':{
                paddingLeft: 10,
                boxSizing:"border-box"
            }
        }
    },
    potentialReachTextHeading:{
        justifyContent:"flex-start !important",
        paddingLeft: 'unset !important'
    },
    potentialReachTexts:{
        '&>*': {
            fontSize: '14px',
            lineHeight:"16px",
        },
        '&>h6':{
            fontWeight: 700,
            color:"#000",
        },
        '&>p':{
            fontWeight: 700,
            color:"#008F2B"
        }
    },
    potentialReachPieChartText:{
        '&>p':{
            color:"#000",
            fontWeight: 200
        }
    },
    potentialReachPieChart:{
        padding: 10,
        boxSizing:"border-box",
        flexDirection:"column",
        '&>div':{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            '&:last-child':{
                marginTop: 10,
            }
        }
    },
    audienceDetails: {
        display:"flex",
        flexDirection:"column",
        padding: 12,
        '&>h6':{
            fontSize: '14px',
            lineHeight:"16px",
            fontWeight: 700,
            color:"#000",
            marginBottom: 10
        }
    },
    audienceDetailText:{
        fontSize:"14px",
        lineHeight:"16px",
        fontWeight: 400,
        color:"#000",
        marginBottom: 5,
        '&>span':{
            marginRight: 5,
            fontWeight: 600,
        }
    },
    potentialReachSummaryChart:{
        width: '100%',
        height: 300,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]:{
            maxWidth: 250
        }
    }
}))

interface ISummaryRightProps {
    audienceFormFields: any;
    chartCaption?: string;
    heading?: string;
}

let chart: any = null;

export const SummaryRight: FunctionComponent<ISummaryRightProps> = ({audienceFormFields,chartCaption,heading})=>{
    const {userFilteredCount} = useUserStore();
    const classes = useStyle();
    const audienceFormFieldsData: any = audienceFormFields.filter((item: any)=>Boolean(item.value))?.map((item: any)=>{
        return item?.key ? {...item, label: item.key} : item
    });
    const customersSummary = useMemo(()=>{
        return userFilteredCount.data ? {
            filteredUserCount: userFilteredCount.data?.filteredUserCount,
            totalUsers: userFilteredCount.data?.totalUsers
        } : null
    },[userFilteredCount.data?.filteredUserCount,userFilteredCount.data?.totalUsers])
    useEffect(()=>{
        if(customersSummary){
            if(chart){
                chart.destroy();
                chart = null;
            }
            const ctx = document.getElementById("myChart") as HTMLCanvasElement;
            if(ctx) {  
                chart = new Chart(ctx, {
                    type: "doughnut",
                    data: {
                      labels: ["Potential Customers", "Other Customers"],
                      datasets: [
                        {
                          label: "Customers Potentials",
                          data: [customersSummary.filteredUserCount, customersSummary.totalUsers - customersSummary.filteredUserCount],
                          backgroundColor: [
                            "#008F2B",
                            "#DEDEDE",
                          ],
                        }
                      ]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Customers Potentials Chart'
                            }
                        }
                    },
                });
            }
        }
    },[customersSummary])
    return <Grid item>
    <Grid container className={classes.potentialReachRow}>
        {heading ? <Grid item md={6} className={clsx(classes.potentialReachTexts,classes.potentialReachTextHeading)}>
            <Typography variant="h6">{heading}</Typography>
        </Grid> : <>
            <Grid item md={6} className={classes.potentialReachTexts}>
            <Typography variant="h6">Potential Reach</Typography>
        </Grid>
        <Grid item md={6} className={classes.potentialReachTexts}>
            <Typography variant="body1">{userFilteredCount.data?.filteredUserCount} Customers</Typography>
        </Grid></>}
    </Grid>
    <Grid container className={classes.potentialReachPieChart}>
        <Grid item >
            <canvas id="myChart" width={300} height={300} className={classes.potentialReachSummaryChart}></canvas>
        </Grid>
        <Grid item className={clsx(classes.potentialReachTexts,classes.potentialReachPieChartText)}>
            <Typography variant="body1">{chartCaption ? chartCaption : "Your audience selection is fairly broad."}</Typography>
        </Grid>
    </Grid>
    {audienceFormFieldsData.length > 0 && 
    <Grid container className={classes.audienceDetails}>
        <Typography variant="h6">Audience Details:</Typography>
        {audienceFormFieldsData?.map((item: any,key:number)=>{
            return item.value ? <Typography key={key} variant="body1" className={classes.audienceDetailText}><span>{labelName[item.label]}:</span>{item.value}</Typography> : ""
        })}
    </Grid>}
</Grid>
}

export default SummaryRight;