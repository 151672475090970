import React,{ FunctionComponent } from 'react';
import { CircularProgress,makeStyles,createStyles,Theme } from '@material-ui/core';
import { ForumRounded,FileCopyOutlined} from '@material-ui/icons';
import { GoRepoClone,GoPencil,GoPlus,GoLocation,GoLink,GoX,GoCloudDownload,GoTrashcan,GoArrowBoth,GoArchive,GoFile,GoSignIn,GoInfo } from 'react-icons/go';
import { FaSave,FaSignInAlt } from 'react-icons/fa';
import clsx from 'clsx';
import { TiWorld } from 'react-icons/ti';
import { BiDetail } from "react-icons/bi";
import { MdArrowBack,MdArrowForward,MdVisibility } from "react-icons/md";

interface IIconProps{
    onClick?: (e?: any) => any;
    dataTip?: string;
    icon: string;
    loading?: boolean;
    onlyIcon?: boolean;
    style?: any;
}

const defaultProps: IIconProps = {
    onClick: ()=>{},
    dataTip: '',
    icon: '',
    loading:false,
    onlyIcon: false,
    style: {},
}

export const getIcon = (icon: string): any=>{
    switch(icon){
        case "back": return <MdArrowBack />
        case "next": return <MdArrowForward />
        case "delete": return <GoTrashcan />
        case "edit": return <GoPencil />
        case "surveyQuestion": return <ForumRounded />
        case "add": return <GoPlus />
        case "close": return <GoX />
        case "locationOn": return <GoLocation />
        case "link": return <GoLink />
        case "cloudDownload": return <GoCloudDownload />
        case "clone": return <GoRepoClone />
        case "arrowBoth": return <GoArrowBoth />
        case "archive": return <GoArchive />
        case "file": return <GoFile />
        case "copy": return <FileCopyOutlined />
        case "signin": return <FaSignInAlt />
        case "save": return <FaSave />
        case "world": return <TiWorld />
        case "detail": return <BiDetail />
        case "info": return <GoInfo />
        case "visibility": return <MdVisibility />
        default: return "";
    }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryIcon: {
        width: 25,
        height: 25,
        borderRadius: "50%",
        border: "1px solid #008F2B",
        cursor: 'pointer',
        transition: "0.5s all",
        backgroundColor: "transparent",
        padding: 2,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        '&>svg':{
            width: 15,
            height: 15,
            color: "#008F2B"
        },
        '&:hover':{
            backgroundColor: "#008F2B",
            '&>svg':{
                color: "#fff"
            }
        }
    },
    onlyIconSpan:{
        cursor:"pointer",
        fontSize:"unset !important",
        width:'max-content',
        display: 'flex',
        alignItems:"center",
        justifyContent:'center',
        '&>svg':{
            width: 15,
            height: 15,
            color: "#008F2B"
        }
    }
  }),
);

const Icon: FunctionComponent<IIconProps> = ({onClick,dataTip,icon,loading,onlyIcon,style}) => {
    const classes = useStyles();
  return loading ? <CircularProgress size={20} style={{color: "#008F2B" }} /> : onlyIcon ? <span style={style} className={clsx(classes.onlyIconSpan)} data-tip={dataTip} onClick={onClick}>
    {getIcon(icon)}
  </span> :  <div onClick={onClick} style={style} data-tip={dataTip} className={clsx(classes.primaryIcon)} >
        {getIcon(icon)}
    </div>
}

Icon.defaultProps = defaultProps;

export default Icon