import React, { FunctionComponent, useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@material-ui/core';
import { useIntegrantTypeStore } from '../../hooks/useIntegrantTypeStore';
import MuiCircularProgress from 'common/MuiCircularProgress';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    summaryContainer: {
      flexDirection: 'column',
      '&>div': {
        width: '100%'
      }
    },
    summaryMainHeading: {
      fontSize: '34px',
      lineHeight: '40px',
      fontWeight: 700,
      color: '#000',
      marginBottom: 10
    },
    summarySecondaryHeading: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 700,
      color: '#000',
      marginBottom: 10
    },
    summaryRewardsText: {
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000',
      fontWeight: 400
    },
    tableContainer: {
      marginTop: 20,
      marginBottom: 30
    },
    tableHead: {
      background: '#FBFBFB',
      '&>tr': {
        '&>th': {
          fontWeight: 700
        }
      }
    },
    survayProduct: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      color: '#000',
      marginBottom: 5,
      '&>span': {
        fontWeight: 600,
        marginRight: 5
      }
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'max-content'
      }
    }
  })
);

interface ISummaryLeftProps {
  integrant_type_id: string;
}

let questionListFlag = false;

export const SummaryLeft: FunctionComponent<ISummaryLeftProps> = ({ integrant_type_id }) => {
  const classes = useStyle();
  const { surveyList, initGetSurveyList, surveyQuestionsList, initGetSurveyQuestionsList } = useIntegrantTypeStore();
  let survey = surveyList.data?.filter((item: any) => item.id === integrant_type_id);
  if (survey && survey.length) {
    survey = survey[0];
  }
  useEffect(() => {
    if (!survey?.title) {
      initGetSurveyList();
      questionListFlag = true;
    }
  }, []);
  useEffect(() => {
    if ((surveyList.status === '1' && !surveyList.loading && questionListFlag) || survey?.title) {
      if (integrant_type_id) {
        initGetSurveyQuestionsList(integrant_type_id);
        questionListFlag = false;
      }
    }
  }, [surveyList]);
  return (
    <Grid container className={classes.summaryContainer}>
      <Grid item>
        <Typography variant="h1" className={classes.summaryMainHeading}>
          Survey Detail
        </Typography>
        <Typography variant="body1" className={classes.survayProduct}>
          <span>Product:</span>
          {survey?.title}
        </Typography>
        {/* <Typography variant="body1" className={classes.survayProduct}><span>Batch:</span>maaza-001</Typography> */}
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="left">Question</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveyQuestionsList.loading || surveyList.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Grid container alignItems="center" justifyContent="center">
                      Loading...&nbsp;
                      <MuiCircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : surveyQuestionsList?.data?.length ? (
                surveyQuestionsList?.data?.map((item: any, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{item.question}</TableCell>
                      <TableCell align="right">{item.input_type}</TableCell>
                      <TableCell align="right">{item.options.length ? item.options.join(',') : '-'}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    You have not created any survey questions yet.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <Typography variant="h2" className={classes.summarySecondaryHeading}>
          Rewards
        </Typography>
        <Typography variant="body1" className={classes.summaryRewardsText}>
          {surveyQuestionsList.offerData
            ? surveyQuestionsList?.offerData?.offer
            : 'No rewards have been set for this survey yet.'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SummaryLeft;
