import React, { useState } from 'react';
import { CreateContext } from '../types/common';
import {IAPIResponseDTO} from '../../lib/common'


export interface IIndividualSubscriptionDetailProviderState {
  dashboardOverview: IAPIResponseDTO,
  buyNewProductDetail: IAPIResponseDTO,
  querySearch: any,
}

export const defaults = { 
    dashboardOverview:{
        status:"",
        message:"",
        loading: false,
        data: null
    },
    buyNewProductDetail:{
      status:"",
      message:"",
      loading: false,
      data: null
    },
    querySearch: ""
};

const IndividualSubscriptionDetailContext = React.createContext<CreateContext<IIndividualSubscriptionDetailProviderState>>([
  defaults,
  (): IIndividualSubscriptionDetailProviderState => defaults
]);

const IndividualSubscriptionDetailProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const [state, setState] = useState<IIndividualSubscriptionDetailProviderState>(defaults);
  return <IndividualSubscriptionDetailContext.Provider value={[state, setState]}>{props.children}</IndividualSubscriptionDetailContext.Provider>;
};

export { IndividualSubscriptionDetailContext, IndividualSubscriptionDetailProvider };
