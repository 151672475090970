import React, { CSSProperties, FunctionComponent } from 'react';
import { Layer } from '../common/Layer';
import { isClientId, titles } from '../config';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { IntegrantCancelButton } from './IntegrantCancelButton';
import { IntegrantDraftAlert } from './IntegrantDraftAlert';
import { IntegrantNextButton } from './IntegrantNextButton';
import { IntegrantSaveButton } from './IntegrantSaveButton';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { IntegrantKind } from '../types/common';
import { useCreateTransferStore } from '../hooks/useCreateTransferStore';
import { isEmptyString } from '../utils';

const IntegrantPaneFooter: FunctionComponent = () => {
  const { tabIndex, onChangeIndex } = useIntegrantPaneStore();
  const { currentIntegrant, integrantKind, onCancelIntegrant, currentParentId } = useIntegrantGraphStore();
  const { isDraftAlertVisible, setDraftAlertVisible, isDraft } = useCreateBatchStore();
  const isTransfer = integrantKind === IntegrantKind.Transfer;
  const { selectedTransferOrganization } = useCreateTransferStore();

  const handleOnCancel = (): void => {
    onCancelIntegrant();
    onChangeIndex(0);
    setDraftAlertVisible(false);
  };

  return (
    <Layer className='integrantPaneFooter' direction="column">
      <IntegrantDraftAlert
        description={
          isTransfer ? `Confirm and Send Below` : `Add as many details as you would like and then click save below`
        }
        title={
          isTransfer
            ? `You will be ${titles.transferAction.toLowerCase()} this to ${selectedTransferOrganization.name}`
            : 'Complete Options:'
        }
        isVisible={isDraftAlertVisible}
      />
      {isClientId(currentIntegrant.id) ? (
        <Layer direction="row">
          <IntegrantCancelButton isVisible={isDraft && !isEmptyString(currentParentId)} onCancel={handleOnCancel} />
          {tabIndex === 4 ? <IntegrantSaveButton /> : <IntegrantNextButton isVisible={tabIndex > -1} />}
        </Layer>
      ) : null}
    </Layer>
  );
};

export { IntegrantPaneFooter };
