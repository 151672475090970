import {IAPIResponseDTO} from '../../../lib/common';
import {IReadNotificationDTO} from '../../../lib/notification';
import axiosInstance from './../../utils/api-interceptor';
import { formAxiosHeader } from '../../config';
import {useAuthStore} from '../useAuthStore';

interface INotificationService {
    getNotifications: (organization_id: string)=> Promise<IAPIResponseDTO>;
    readNotification: (data: IReadNotificationDTO) => Promise<IAPIResponseDTO>
}

const useNotificationService = (): INotificationService => {
    const { individual } = useAuthStore();

    const getNotifications: INotificationService["getNotifications"] = async (organization_id) => {
        try {
            return await axiosInstance.post("/notification/get-all",{organization_id},formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
                data: null
            }
        }
    }

    const readNotification: INotificationService["readNotification"] = async (data)=>{
        try {
            return await axiosInstance.post("/notification/read-notification",data,formAxiosHeader(individual.access_token));
        } catch (error) {
            return {
                status:"0",
                message:"Something went wrong! Please try after sometime.",
                data: null
            }
        }
    }

  return { getNotifications,readNotification };
};

export { useNotificationService };
