import { IExhibitDTO } from '../../lib/exhibit';
import React, { FunctionComponent, ReactNode } from 'react';
import { Layer } from '../common';
import { exhibit_ } from '../common/models';
import { isClientId, titles, titlesAlert } from '../config';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { reduceRank } from '../utils';
import { DraggableList } from './DraggableList';
import { ExhibitListItem } from './ExhibitListItem';
import { ExhibitTransitListItem } from './ExhibitTransitListItem';
import { IIntegrantOptionPaneProps } from '../types/common';
import { useIntegrantsService } from '../hooks/api/useIntegrantsService';
import { titleStyle } from './ProductListItem';
import { IntegrantKind } from '../types/common';
import { useCreateTransitStore } from '../hooks/useCreateTransitStore';
import { PrimaryButton } from 'common/Button/buttons';

const IntegrantExhibits: FunctionComponent<IIntegrantOptionPaneProps> = ({ isVisible, isDisabled }): JSX.Element => {
  const { currentIntegrant, integrantKind, setExhibits } = useIntegrantGraphStore();
  const { currentTransit } = useCreateTransitStore();
  const { onUpdateExhibit, isLoading } = useIntegrantPaneStore();
  const { updateExhibitsBulk } = useIntegrantsService();

  const handleDragEnd = async (updatedExhibits: IExhibitDTO[]): Promise<void> => {
    //const exhibits = setExhibits(updatedExhibits);
    // if (!isClientId(currentIntegrant.id)) {
    //   await updateExhibitsBulk(currentIntegrant.id, exhibits);
    // }
  };

  const renderExhibits = (isTransit: boolean): React.ReactNode => {
    const { exhibit } = currentTransit
    let updatedExhibit = []
    if (exhibit && exhibit.length) {
      updatedExhibit = [...exhibit, ...currentIntegrant.exhibits]
    } else {
      updatedExhibit = [...currentIntegrant.exhibits]
    }
    return (
      <>
        {/* <Layer style={{ marginLeft: 10 }}>
          <UseAnimations play animationKey="alertCircle" size={30} />
          <small style={{ ...titleStyle, padding: 10 }}>{titlesAlert.attachments}</small>
        </Layer> */}
        <DraggableList<IExhibitDTO>
          renderItem={(exhibitItem: IExhibitDTO): ReactNode => isTransit ? <ExhibitTransitListItem key={exhibitItem.id} exhibit={exhibitItem} /> :
            <ExhibitListItem key={exhibitItem.id} exhibit={exhibitItem} />
          }
          onDragEnd={handleDragEnd}
          items={updatedExhibit.sort((a, b) => (a.rank > b.rank ? 1 : -1))}
          loading={isLoading}
        />
        <div style={{ marginBottom: 50 }}></div>
      </>
    );
  };

  const onAddNewExhibit = (): void => {
    const highestRank = reduceRank(currentIntegrant.exhibits);
    onUpdateExhibit({ ...exhibit_, rank: highestRank });
  };

  return isVisible ? (
    <Layer className='integrantExhibits' disabled={isDisabled} direction="column" fill scrollY>
      <div style={{ padding: 10, width: '100%' }}>
        <PrimaryButton icon="add" onClick={onAddNewExhibit} children={`Add New ${titles.exhibits}`} />
      </div>
      {renderExhibits(integrantKind === IntegrantKind.Transit || integrantKind === IntegrantKind.Transfer)}
    </Layer>
  ) : (
    <div />
  );
};

export { IntegrantExhibits };
