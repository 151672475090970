import moment from 'moment';
import { useContext } from 'react';
import { SurveyContext,ISurveyProviderState } from '../context/surveyContext';
import { useSurveyService } from './api/useSurveyService';
import {months} from '../utils'

interface ISurveyStore extends ISurveyProviderState {
  initGetSurveySubmissionList: (integrant_type_id: string) => any; 
}

const useSurveyStore = (): ISurveyStore => {
  const [state, setState] = useContext(SurveyContext);
  const {getSurveySubmissions} = useSurveyService();
  const {surveySubmissionList} = state;

  const initGetSurveySubmissionList: ISurveyStore["initGetSurveySubmissionList"] = async (integrant_type_id)=>{
    setState(pre=>({...pre,surveySubmissionList:{...pre.surveySubmissionList,loading: true}}));
    const data = await getSurveySubmissions(integrant_type_id);
    // let chartData: any = {};
    let chartData: any = [];
    if(data?.data?.length){
      const dates = data.data.map((item: any)=>moment(item.created_on).format('MM/DD/YYYY'));
      for(let date of dates){
        let isExist = chartData.filter((item: any)=>item.x === date);
        if(isExist.length){
          continue;
        }else{
          chartData.push({x:date,y: dates.filter((item: string)=> item === date).length})
        }
      }
      // for(let date of dates){
      //   const year = moment(date).year();
      //   const month = moment(date).format('MMMM');
      //   if(chartData[year]){
      //     if(chartData[year].filter((item: any)=>item.label === month).length){
      //       chartData[year] = chartData[year].map((item: any)=>{
      //         return item.label === month ?  {
      //           ...item,
      //           value: item.value + 1
      //         }: item
      //       })
      //     }else{
      //       chartData[year] = [...chartData[year],{label: month,value: 1}]
      //     }
      //   }else{
      //     chartData[year] = [{label: month, value: 1}]
      //   }
      // }
    }
    // chartData = Object.fromEntries(Object.entries(chartData).map(([year,arr]: any)=> [year, arr.sort((a: any,b: any) => months.indexOf(a.label) - months.indexOf(b.label))]))
    setState(pre=>({...pre,surveySubmissionList:{...data,chartData,loading: false}}));
  }

  return {
    surveySubmissionList,
    initGetSurveySubmissionList
  };
};

export { useSurveyStore };
