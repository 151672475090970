import React, { CSSProperties, FunctionComponent } from 'react';
import { Layer } from '../common/Layer';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { IntegrantTypeNextButton } from './IntegrantTypeNextButton';
import { IntegrantTypeSaveButton } from './IntegrantTypeSaveButton';
import { IntegrantCancelButton } from './IntegrantCancelButton';
import { useHistory } from 'react-router-dom';

const bottomMenuStyle: CSSProperties = { position: 'absolute', bottom: 0, left: 0, width: '100%' };

interface ICreateIntegrantTypePaneFooterProps {
  isUpdate: boolean;
  type?: string;
}

const CreateIntegrantTypePaneFooter: FunctionComponent<ICreateIntegrantTypePaneFooterProps> = ({
  isUpdate = false,
  type = ''
}) => {
  const history = useHistory();
  const { slideIndex, gotoSlideIndex, setOpen, onClearData } = useCreateIntegrantTypeStore();

  const handleOnCancel = (): void => {
    gotoSlideIndex(0, false);
    setOpen(false);
    onClearData();
    history.push('/');
  };
  return (
    <Layer style={type === 'importNIHProduct' ? { width: '100%', marginTop: 20 } : bottomMenuStyle} direction="column">
      <Layer direction="row">
        <IntegrantCancelButton onCancel={handleOnCancel} isVisible={slideIndex === 0} />
        <IntegrantTypeNextButton
          isUpdate={isUpdate}
          isVisible={type === 'importNIHProduct' ? [0, 1].includes(slideIndex) : slideIndex === 0}
          type={type}
        />
        <IntegrantTypeSaveButton
          isUpdate={isUpdate}
          isVisible={type === 'importNIHProduct' ? slideIndex === 2 : slideIndex === 1}
          type={type}
        />
      </Layer>
    </Layer>
  );
};

export { CreateIntegrantTypePaneFooter };
