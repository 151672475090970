import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { baseURL, stripePublishableKey, updatedUrl, titles } from './../../config';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAuthStore } from '../../hooks/useAuthStore';
import UseAnimations from 'react-useanimations';
export default function ThankYou(props: any) {
  const isLoggedIn = props.isLoggedIn;
  const location: any = useLocation();
  const history = useHistory();
  const [cartItem, setCartItem] = useState<any>({});
  const [pageLoading, setPageLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get('session_id');
  const { logout1 } = useAuthStore();
  const [subscriptionId, setSubscriptionId] = useState();
  useEffect(() => {
    const pageUrl = updatedUrl + '/pre-registration/init';
    const itemCart = sessionStorage.getItem('item');
    const userData = sessionStorage.getItem('userData');
    setPageLoading(true);
    async function APIDataUpdate() {
      const getTransactionIdUrl = updatedUrl + '/subscription/create-portal-session';
      await fetch(getTransactionIdUrl, {
        method: 'POST',
        body: JSON.stringify({
          session_id: sessionId
        }),
        headers: new Headers({
          'Content-type': 'application/json'
        })
      })
        .then(response => response.json())
        .then(async data => {
          const { subscription } = data;
          if (itemCart && itemCart !== '' && userData && userData !== '') {
            const userDataObj = JSON.parse(userData);
            const itemCartObj = JSON.parse(itemCart);
            const { email_address, name_first, subscriptionValue } = userDataObj;
            const formData: any = new FormData();
            formData.append('registration_step', 4);
            formData.append('created_by', name_first);
            formData.append('updated_by', name_first);
            formData.append('email_address', email_address);
            formData.append('status', 'active');
            formData.append('transaction_id', subscription);
            console.log(itemCartObj);
            if (itemCartObj) {
              const { prouct_metadata, prouct_name, product_id, subscription_type, product_plan } = itemCartObj;
              formData.append('subscription_id', product_id);
              formData.append('subscription_charges_id', product_plan[subscriptionValue].id);
              formData.append('subscription_name', prouct_name);
              formData.append('subscription_type', subscription_type);
              Object.keys(prouct_metadata).forEach(key => formData.append(key, prouct_metadata[key]));
            }
            await fetch(pageUrl, {
              method: 'POST',
              body: formData
            })
              .then(response => {
                logout1();
                setPageLoading(false);
              })
              .catch(error => {
                alert(titles.error);
                history.replace('/');
              });
          }
        })
        .catch(error => {
          alert(titles.error);
          setPageLoading(false);
          console.error('Error:', error);
        });
    }
    APIDataUpdate();
  }, []);

  if (pageLoading) {
    return (
      <div className="subscription-checkout-load">
        <UseAnimations animationKey="loading" size={40} />
        <div className="txt-please-wait">Please Wait...</div>
      </div>
    );
  }
  return (
    <div className="thankYou">
      <div>
        <h1>Thank You!</h1>
        <p>Great! Your Registration process successfully complete.</p>
        <a href={baseURL}>{props.isLoggedIn ? 'Continue' : 'Login'}</a>
      </div>
    </div>
  );
}
