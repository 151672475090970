import React, { FunctionComponent } from 'react';
// import { Route } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

type Props = { as: FunctionComponent; isLoggedIn: boolean; path: string; otpVerified: string | null };

const ProtectedRoute: FunctionComponent<Props> = ({ as: Component, ...props }) => {
  const { ...rest } = props;
  return (
    <Route {...rest} exact>
      {/* <Component {...rest} /> */}
      {props.isLoggedIn && props.otpVerified ? (
        <Component {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: !props.otpVerified && props.isLoggedIn ? '/verify-otp' : '/login'
          }}
        />
      )}
    </Route>
  );
};

export default ProtectedRoute;
