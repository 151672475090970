import React,{ FunctionComponent } from 'react';
import { Dialog,DialogActions,DialogContent,DialogTitle} from '@material-ui/core';
import '../../styles/commonComponent.css';

interface IConfirmationDialogProps{
    open: boolean;
    handleClose: (value: boolean) => any;
    title: string;
    message: string;
}

const ConfirmationDialog: FunctionComponent<IConfirmationDialogProps> = ({open,handleClose,title,message}) => {
  return (
    <Dialog open={open} onClose={()=>handleClose(false)}>
        <DialogTitle className='dialog-title'>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions className='dialog-actions'>
            <button className='mui-btn-root' onClick={()=>handleClose(false)}>No</button>
            <button className='mui-btn-root' onClick={()=>handleClose(true)}>Yes</button>
        </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog